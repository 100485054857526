import React, { useState, useEffect } from 'react';
import { baseURL } from '../../../http';
import Select from 'react-select';
import {
  MDBModalFooter,
  MDBTextArea, MDBRow, MDBCol
} from 'mdb-react-ui-kit';
const Swal = require("sweetalert2");

function Stscroll7ITSkillsAddModal({ togglingModal }) {

  const userInfo                                               = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                                              = userInfo.email;

  const [selectedOptionYear, setSelectedOptionYear]            = useState(null); // value-1
  const [selectedOptionMonth, setselectedOptionMonth]          = useState(null);
  const [selectedOptionLastUsed, setSelectedOptionLastUsed]    = useState(null);
  const [addData, setAddData]                                  = useState({
                                                                         skills:'',
                                                                         version: '',
                                                                         lastUsed: '',
                                                                         itExpYear: '',
                                                                         itExpMonth: '',
                                                                        }); 

  const [currentYear, setCurrentYear]                                 = useState(null);
  const [currentDate, setCurrentDate]                                 = useState(new Date());

  const addSkills = () => {

            const updates = {
              email     : userEmail, 
              skills    : addData.skills,
              version   : addData.version,
              lastUsed  : selectedOptionLastUsed ? selectedOptionLastUsed.value : null,
              itExpYear : selectedOptionYear ? selectedOptionYear.value : null,
              itExpMonth: selectedOptionMonth ? selectedOptionMonth.value : null
             };


          fetch(`${baseURL}/createITSkills`, {
            method: 'POST',
            body: JSON.stringify(updates),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          })
            .then((response) => {
              if (response.ok) {
                Swal.fire({
                  title: "IT Skills added",
                  icon: "success",
                  timer: 900,
                  position: "center",
                  timerProgressBar: true, 
                  showConfirmButton: false,
                })
                 togglingModal();
                console.log('Item updated successfully');
              } else {
                console.error('Failed to update item');
              }
            })
            .catch((error) => {
              console.error('Item updating error:', error);
            });
};


   /**---------------------------experience dropdown------------------------------------------------ */
   const expYearOptions = [                              // in options-2
   { value: '1 Year', label: '1 Year'     },
   { value: '2 Years', label: '2 Years'   },
   { value: '3 Years', label: '3 Years'   },
   { value: '4 Years', label: '4 Years'   },
   { value: '5 Years', label: '5 Years'   },
   { value: '6 Years', label: '6 Years'   },
   { value: '7 Years', label: '7 Years'   },
   { value: '8 Years', label: '8 Years'   },
   { value: '9 Years', label: '9 Years'   },
   { value: '10 Years', label: '10 Years' },
   ];
   
   const expMonthsOptions = [
   { value: '1  Month', label: ' 1  Month'  },
   { value: '2  Months', label: '2  Months' },
   { value: '3  Months', label: '3  Months' },
   { value: '4  Months', label: '4  Months' },
   { value: '5  Months', label: '5  Months' },
   { value: '6  Months', label: '6  Months' },
   { value: '7  Months', label: '7  Months' },
   { value: '8  Months', label: '8  Months' },
   { value: '9  Months', label: '9  Months' },
   { value: '10 Months', label: '10 Months' },
   ];
     
    const lastUsedOptions =[
      { value: currentYear - 0, label: currentYear - 0 },
      { value: currentYear - 1, label: currentYear - 1 },
      { value: currentYear - 2, label: currentYear - 2 },
      { value: currentYear - 3, label: currentYear - 3 },
      { value: currentYear - 4, label: currentYear - 4 },
      { value: currentYear - 5, label: currentYear - 5 },
      { value: currentYear - 6, label: currentYear - 6 },
      { value: currentYear - 7, label: currentYear - 7 },
      { value: currentYear - 8, label: currentYear - 8 },
      { value: currentYear - 9, label: currentYear - 9 },
    ];
    useEffect(() => {
      const intervalId = setInterval(() => {  setCurrentDate(new Date()); }, 1000); // Update the date every second
                                              // console.log('current date:='+currentDate.getFullYear());
                                              setCurrentYear(currentDate.getFullYear());
      return () => { clearInterval(intervalId); };
    }, []);
  
  
  const handleInputChange = (exp_inputValue) => {         // onInputChange={handleInputChange}-3
    return exp_inputValue;
  };

  const selectExpYear   = (selectedOptionYear) => {        //onChange={selectExpYear}  - 4
    setSelectedOptionYear(selectedOptionYear);
  };

  const selectExpMonth = (selectedOptionMonth)=> {
  setselectedOptionMonth(selectedOptionMonth);
  }
  const selectLastUsed = (selectedOptionLastUsed)=> {
    setSelectedOptionLastUsed(selectedOptionLastUsed);
    }

  return (
    <div>
        <div style={{ marginLeft: '40px', marginRight:'30px' }}>
          <br />
          <h5>IT Skills</h5> <br />
                      <MDBRow>
                        <MDBCol >
                            <label htmlFor="exampleFormControlSelect1" >Skill / software name</label>
                              <input type="text"
                                className="form-control" 
                                id="formGroupExampleInput1" placeholder='Skill / software name'
                                value={addData.skills}
                                onChange={e=>setAddData({...addData, skills:e.target.value})} 
                                />
                            <br></br>
                          
                          </MDBCol>
                          </MDBRow>

                              <MDBRow>
                                    
                                  <MDBCol style={{width:'200px'}}>
                                    <label htmlFor="exampleFormControlSelect2" >Software Version</label>
                                      
                                    <input type="text"
                                        className="form-control" 
                                        id="formGroupExampleInput1" placeholder='Software Version'
                                        value={addData.version}
                                        onChange={e => setAddData({ ...addData, version: e.target.value })}
                                        
                                        />
                                  </MDBCol>
                                        <MDBCol >
                                        <label htmlFor="exampleFormControlSelect3" >Last Used</label>
                                          <Select
                                            value={selectedOptionLastUsed}
                                            options={lastUsedOptions}
                                            onInputChange={handleInputChange}
                                            onChange={selectLastUsed}
                                            isClearable={true}
                                            placeholder="Last Used"

                                          />
                                        </MDBCol> 
                              </MDBRow> <br></br>

                              <div className=''>
                                <label htmlFor="exampleFormControlSelect2" >Total Experience</label><br></br>
                                <label htmlFor="exampleFormControlSelect2" >Years</label>
                                <label htmlFor="exampleFormControlSelect2" style={{marginLeft:'343px'}}>Months</label>
                                  <MDBRow >
                                    <MDBCol>
                                      <Select
                                        value={selectedOptionYear}//selectedOptionYear
                                        options={expYearOptions}
                                        onInputChange={handleInputChange}
                                        onChange={selectExpYear}
                                        isClearable={true}// type to filter
                                        placeholder="Select"
                                      />
                                    </MDBCol>
                                    <MDBCol >
                                      <Select
                                        value={selectedOptionMonth}
                                        options={expMonthsOptions}
                                        onInputChange={handleInputChange}
                                        onChange={selectExpMonth}
                                        isClearable={true}
                                        placeholder="Select"
                                      />
                                    </MDBCol>
                                  </MDBRow><br></br>
                                </div>
         
        </div>
  
        <MDBModalFooter>
          
            <button type="button" className="dropbtnprofilepagesave"    onClick={addSkills}> Save   </button>&nbsp;
            <button type="button" className="dropbtnprofilepagecancel2" onClick={togglingModal}>   Cancel </button>&nbsp;
              
        </MDBModalFooter>
      </div>
  )
}

export default Stscroll7ITSkillsAddModal
