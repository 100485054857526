import React from "react";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";

import { Helmet } from "react-helmet";

function InternshipJoin() {
  return (
    <>
     <Helmet>
          <title>Internship and Start Learning | TPA</title>
         
          <meta
  name="keywords"
  content="Internship, Start Learning, TPA, Talent Pool Academy, Engineering Internship, IT Internship, Internship Programs, Professional Development, Skill Enhancement, Engineering, IT, Design, Analysis, Electronics, Language"
/>

      <meta
        name="description"
        content="Join our internship program at Talent Pool Academy (TPA) and start learning today. Explore internship opportunities in various fields such as engineering, IT, design, analysis, electronics, and language. Apply now and gain hands-on experience to boost your professional development and skill enhancement."
      />


        </Helmet>

      <MDBContainer fluid className="p-5">
        <MDBRow>
          <MDBCol md="4">
            <MDBCard className="h-100 ">
              <div className="circle-img  mt-3" style={{ textAlign: "center" }}>
                <span className="circle-number tpa-internship-number">1</span>
              </div>
              <MDBCardBody>
                <MDBCardTitle
                  className="text-center"
                  style={{ color: "black" }}
                >
                  SELECT
                </MDBCardTitle>
                <MDBCardText style={{ color: "black" }}>
                  Tell us who you are, what you’re interested in and when you’re
                  available.
                  <br />
                  We’ll take care of the rest. You’ll be notified as soon as we
                  find a good match!
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol center md="4">
            <MDBCard className="h-100">
              <div className="circle-img mt-3" style={{ textAlign: "center" }}>
                <span className="circle-number tpa-internship-number">2</span>
              </div>
              <MDBCardBody>
                <MDBCardTitle
                  className="text-center"
                  style={{ color: "black" }}
                >
                  TRAIN
                </MDBCardTitle>
                <MDBCardText style={{ color: "black" }}>
                  We’ll provide you with everything you need to hit the ground
                  running on Day one.
                  <br />
                  Expect to learn project specific skills, company information,
                  and key processes for your.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol center md="4">
            <MDBCard className="h-100">
              <div className="circle-img  mt-3" style={{ textAlign: "center" }}>
                <span className="circle-number tpa-internship-number">3</span>
              </div>
              <MDBCardBody>
                <MDBCardTitle
                  className="text-center"
                  style={{ color: "black" }}
                >
                  INTERN
                </MDBCardTitle>
                <MDBCardText style={{ color: "black" }}>
                  Put your training to the test and gain real-world experience.
                  <br />
                  Our system provides great collaboration with your supervisor,
                  and our team will help you throughout your internship.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default  InternshipJoin;