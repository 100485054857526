import React from "react";
import {
 
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBContainer,
  MDBNavbarLink,
  MDBTypography,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";

import CourseModel1 from "../static/CourseModel1.jpg";
import CourseModel2 from "../static/CourseModel2.png";
import CourseModel3 from "../static/CourseModel3.png";


 function C5atiaCourseModel() {
  return (
    <MDBContainer
      id="course"
      fluid
      className="p-1 rounded-7 mt-5 text-align-center"
      style={{ color: "black" }}
    >
      <MDBTypography
        variant="h1 courseoverview-body text-start mt-3 mx-3 "
        style={{ color: "black" }}
      >
        Course Model
      </MDBTypography>
      <div className="row mt-5">
        <div class="col-md-4 ">
          <img
            src={CourseModel1}
            width="150px"
            height="150px"
            alt="course-model-image"
          ></img>

          <h3 className="mt-3" style={{ color: "black" }}>
            Catia CAA
          </h3>
          <p className="mt-3 fs-6" style={{ color: "black" }}>
            CATIA Automation & Customization using C++, VB,Python CATIA CAA RADE
          </p>
        </div>
        <div class="col-md">
          <img
            src={CourseModel2}
            width="150px"
            height="150px"
            alt="course-model-image"
          ></img>
          <h3 className="mt-3 " style={{ color: "black" }}>
            NX Open
          </h3>
          <p className="mt-3 fs-6" style={{ color: "black" }}>
            NX Customization & Automation using NX Open API with VB.NET or
            C#.NET
          </p>
        </div>
        <div class="col-md">
          <img
            src={CourseModel3}
            width="150px"
            height="150px"
            alt="course-model-image"
          ></img>
          <h3 className="mt-3 " style={{ color: "black" }}>
            Creo Tool Kit
          </h3>
          <p className="mt-3 fs-6 " style={{ color: "black" }}>
            CREO Automation & Customization using C,C++ and CREO TOOLKIT.
          </p>
        </div>
      </div>
    </MDBContainer>
  );
};

export default C5atiaCourseModel;
