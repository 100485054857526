import { useState, useEffect } from "react";
import http from "../../http";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";

function ContactBody() {
  const [formData, setFormData] = useState({
    first_name: "",
    email: "",
    mobile: "",
    courses: "",
    message: "",
  });

  const [options, setOptions] = useState([
    { value: "CATIA Coustmization", label: "CATIA Coustmization" },
    { value: "CREO Coustmization", label: "CREO Coustmization" },
    { value: "NX Coustmization", label: "NX Coustmization" },
    { value: "CATIA Design", label: "CATIA Design" },
    { value: "NX Design", label: "NX Design" },
    { value: "CREO Design", label: "CREO Design" },
    { value: "Full Stack", label: "Full Stack" },
    { value: "Internship", label: "Internship" },
    { value: "Career", label: "Career" },
    { value: "Trainer", label: "Trainer" },
  ]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneInput = (number) => {
    setFormData((prevData) => ({
      ...prevData,
      mobile: number,
    }));
  };

  const handleCourses = (selectedOptions) => {
    // Join the selected labels into a single string
    const selectedLabels = selectedOptions.map((option) => option.label).join(', ');
    setFormData((prevData) => ({
      ...prevData,
      courses: selectedLabels,
    }));
  };

  const submitForm = () => {
    http
      .post("/enquiries", formData)
      .then((res) => {
        swal("Submitted! Thank you, Your Slot Booked.", "", "success");
        // Reset the form fields
        setFormData({
          first_name: "",
          email: "",
          mobile: "",
          courses: "",
          message: "",
        });
      })
      .catch((error) => {
        swal("Not Submitted!!", "", "error");
        console.error(error);
      });
    http
      .post("/sendEnquiryMail", 
      ({
        // first_name: formData.first_name,
        email: formData.email,
        subject: "Course Enquired -"+formData.first_name,
        message: formData.message,

        mobile: formData.mobile,
        courses:formData.courses,
      })
      )
      .then((res) => {
        console.log("mail sent.", "", "success");
        // Reset the form fields
       
      })
      .catch((error) => {
        swal(" mail Not sent !!", "", "error");
        console.error(error);
      });
  };

  useEffect(() => {
    fetchAllCourses();
  }, []);

  const fetchAllCourses = () => {
    http
      .get("/courses")
      .then((res) => {
        if (res.data) {
          // Transform the data to match the format expected by react-select
          const transformedOptions = res.data.map((course) => ({
            value: course.id,
            label: course.name,
          }));
          // Merge the dynamic options with the hardcoded options
          const allOptions = [
            ...options, // Add the hardcoded options here
            ...transformedOptions, // Add the dynamic options here
          ];
          setOptions(allOptions);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <MDBCard>
      <MDBCardBody className="p-5">
      
        <MDBCol col="6">
            <MDBInput 
              wrapperClass="mb-4"
              label="Name"
              id="name"
              size="lg"
              name="email"
              value={formData.first_name}
              onChange={handleChange}
              style={{ backgroundColor:'white'}}
            />
          </MDBCol>

        <MDBRow>
          <PhoneInput
            inputStyle={{
              width: "100%",
              height: "42px",
            }}
            country={"in"}
            name="mobile"
            value={formData.mobile}
            onChange={handlePhoneInput}
          />
        </MDBRow>

        <br />
          
          <MDBCol col="6">
            <MDBInput 
              wrapperClass="mb-4"
              label="Email"
              id="email"
              size="lg"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={{ backgroundColor:'white'}}
            />
          </MDBCol>

        

        <Select 
          isMulti
          name="courses"
          options={options}
          // className="basic-multi-select demo-input-2"
          classNamePrefix="Enquiry For"
          size="lg"
          onChange={handleCourses}
          value={options.filter(
            (option) => formData.courses.split(', ').indexOf(option.label) !== -1
          )}
          style={{color:'black'}}
        />

        <br />

        <textarea style={{width:"100%"}}
          className="form-control"  
          rows={3}
          name="message"
          value={formData.message}
          onChange={handleChange}
        />

        <br />

        <button
          
          style={{ color: "white" , backgroundColor:'blue', borderRadius:'10px', border:'3px solid blue'}}
          onClick={submitForm}
        >
          Submit
        </button>
      </MDBCardBody>
     </MDBCard>
  );
}

export default ContactBody;
