import React from "react";
import { MDBContainer, MDBTypography } from "mdb-react-ui-kit";

export default function App() {
  return (
    <MDBContainer
      id="course"
      fluid
      className="p-1 rounded-7  "
      style={{ color: "black" }}
    >
      
      <MDBTypography
          variant="h1 courseoverview-body text-start mt-3 mx-1  "
          style={{ color: "black" }}
        >
        Course Description
      </MDBTypography>
      <p
        md="8"
        className="fs-5 courseoverview-body-start text-start m-3 "
        style={{ color: "black" }}
      >
        This Program is engaged by Competency Development team of TPA which is a
        “transforming platform” for Engineering graduates of Mechanical &
        related streams. This Program Ensures hands on project experience in
        design and engineering by providing “Experienced Work Methodology” &
        “Industry Ready Design Skills” using the methods followed by the top
        OEM’s and Tier 1 companies thereby developing their employability and
        inculcating the long term growth in the Industry with high payroll.
      </p>
    </MDBContainer>
  );
}
