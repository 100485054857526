import React, { useContext } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import ModalContext from "./ModalContext";

const ContactModal = () => {
  const { modalOpen, modalContent, modalTitle, closeModal } =
    useContext(ModalContext);

  return (
    <MDBModal isOpen={modalOpen} toggle={closeModal}>
      <MDBModalHeader toggle={closeModal}>{modalTitle}</MDBModalHeader>
      <MDBModalBody>{modalContent}</MDBModalBody>
    </MDBModal>
  );
};

export default ContactModal;
