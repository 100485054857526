import React, { useState, useContext } from "react";

import Carousel from "react-multi-carousel";
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBCard,
  MDBModalBody,
} from "mdb-react-ui-kit";

import "react-phone-input-2/lib/style.css";
import Praveen from "../static/Praveen.jpeg";
import Gireesh from "../static/Gireesh.jpeg";
import Rohan from "../static/Rohan.jpg";
import Naveenbg from "../static/Naveen B G.jpeg";
import Abhishek from "../static/Abhishek.jpeg";

import ModalContext from "../Context/ModalContext";
import ContactBody from "../More/ContactBodyHome";

function StudentReview() {
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


 
 

  const { openModal } = useContext(ModalContext);

  const handleOpenModal = () => {
    const modalContent = <ContactBody />;   // <RaiseArequest />
    const modalTitle = "";
    openModal(modalContent, modalTitle);
  };

  return (
    <div>
      <MDBRow>
        <MDBCol center md="3">
          <MDBCard className="p-5">
            <MDBTypography
              tag="h3"
              color="dark"
              className="py-2"
            ></MDBTypography>
            <MDBTypography tag="h4" color="dark" className="pt-2">
              Do You Want To Communicate With Our Candidate?
              <br />
              
              <MDBBtn
                outline
                color="dark"
                onClick={handleOpenModal}
                className="mt-3 tpa-info-button"
              >
                Raise a Request
              </MDBBtn>
            </MDBTypography>
          </MDBCard>
        </MDBCol>
        <MDBCol center md="9 " className="mt-5">
          <Carousel
            responsive={responsive}
            className="mb-6"
            autoPlay={true}
            autoPlaySpeed={5000}
            shouldResetAutoplay={false}
            infinite={true}
            rewindWithAnimation={true}
            rewind={true}
            transitionDuration={500}
            keyBoardControl={true}
          >
            {/* {[...Array(11).keys()].map((key) => ( */}

            <MDBCard className="h-80 review-body-width">
              <div
                className="d-flex align-items-center "
                style={{ height: "90px" }}
              >
                <MDBCardImage
                  style={{ width: "70px", height: "80px" }}
                  className="img-fluid  border border-dark border-3 round_img"
                  src={Abhishek}
                  alt="Generic placeholder image"
                  fluid
                />
                <div>
                  <MDBTypography
                    variant="h6"
                    className="text-dark fw-normal  mx-2 mt-4"
                  >
                    <b>Abhishek </b>
                  </MDBTypography>
                  <br />
                </div>
              </div>
              <hr />
              <div style={{ height: "140px" }}>
                <p className="text-start mx-2">
                  <MDBIcon far icon="user-circle" className="me-2" />
                  <b>Developer</b>
                </p>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2 "
                >
                  Company Name:<b>ASM</b>
                </MDBTypography>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Course:<b>C++,CATIA</b>
                  </small>
                </MDBTypography>
                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Duration:<b>3-6 month</b>
                  </small>
                </MDBTypography>
              </div>

              <MDBBtn
                outline
                color="dark"
                onClick={toggleShow}
                className="tpa-info-button"
              >
                Connect With Me
              </MDBBtn>
            </MDBCard>
            <MDBCard className="h-80 review-body-width">
              <div
                className="d-flex align-items-center "
                style={{ height: "90px" }}
              >
                <MDBCardImage
                  style={{ width: "70px", height: "80px" }}
                  className="img-fluid  border border-dark border-3 round_img"
                  src={Naveenbg}
                  alt="Generic placeholder image"
                  fluid
                />
                <div>
                  <MDBTypography
                    variant="h6"
                    className="text-dark fw-normal  mx-2 mt-4"
                  >
                    <b>Naveen </b>
                  </MDBTypography>
                  <br />
                </div>
              </div>
              <hr />
              <div style={{ height: "140px" }}>
                <p className="text-start mx-2">
                  <MDBIcon far icon="user-circle" className="me-2" />
                  <b>Developer</b>
                </p>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2 "
                >
                  Company Name:<b>Tata Motors</b>
                </MDBTypography>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Course:<b>C++,CATIA</b>
                  </small>
                </MDBTypography>
                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Duration:<b>3-6 month</b>
                  </small>
                </MDBTypography>
              </div>
              <MDBBtn
                outline
                color="dark"
                onClick={toggleShow}
                className="tpa-info-button"
              >
                Connect With Me
              </MDBBtn>
            </MDBCard>
            <MDBCard className="h-80 review-body-width">
              <div
                className="d-flex align-items-center"
                style={{ height: "90px" }}
              >
                <MDBCardImage
                  style={{ width: "70px", height: "80px" }}
                  className="img-fluid  border border-dark border-3 round_img"
                  src={Rohan}
                  alt="Generic placeholder image"
                  fluid
                />
                <div>
                  <MDBTypography
                    variant="h6"
                    className="text-dark fw-normal  mx-2 mt-4"
                  >
                    <b>Rohan </b>
                  </MDBTypography>
                  <br />
                </div>
              </div>
              <hr />
              <div style={{ height: "140px" }}>
                <p className="text-start mx-2">
                  <MDBIcon far icon="user-circle" className="me-2" />
                  <b>CAA Engineer</b>
                </p>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2 "
                >
                  Company Name:<b>TTL</b>
                </MDBTypography>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Course:<b>C++,CATIA</b>
                  </small>
                </MDBTypography>
                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Duration:<b>3-6 month</b>
                  </small>
                </MDBTypography>
              </div>
              <MDBBtn
                outline
                color="dark"
                onClick={toggleShow}
                className="tpa-info-button"
              >
                Connect With Me
              </MDBBtn>
            </MDBCard>
            <MDBCard className="h-80 review-body-width">
              <div
                className="d-flex align-items-center"
                style={{ height: "90px" }}
              >
                <MDBCardImage
                  style={{ width: "70px", height: "80px" }}
                  className="img-fluid  border border-dark border-3 round_img"
                  src={Gireesh}
                  alt="Generic placeholder image"
                  fluid
                />
                <div>
                  <MDBTypography
                    variant="h6"
                    className="text-dark fw-normal  mx-2 mt-4"
                  >
                    <b>Gireesh</b>
                  </MDBTypography>
                  <br />
                </div>
              </div>
              <hr />
              <div style={{ height: "140px" }}>
                <p className="text-start mx-2">
                  <MDBIcon far icon="user-circle" className="me-2" />
                  <b>Developer</b>
                </p>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2 "
                >
                  Company Name:<b>ALTEN</b>
                </MDBTypography>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Course:<b>C++,CATIA</b>
                  </small>
                </MDBTypography>
                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Duration:<b>3-6 month</b>
                  </small>
                </MDBTypography>
              </div>
              <MDBBtn
                outline
                color="dark"
                onClick={toggleShow}
                className="tpa-info-button"
              >
                Connect With Me
              </MDBBtn>
            </MDBCard>
            <MDBCard className="h-80 review-body-width">
              <div
                className="d-flex align-items-center "
                style={{ height: "90px" }}
              >
                <MDBCardImage
                  style={{ width: "70px", height: "80px" }}
                  className="img-fluid  border border-dark border-3 round_img"
                  src={Praveen}
                  alt="Generic placeholder image"
                  fluid
                />
                <div>
                  <MDBTypography
                    variant="h6"
                    className="text-dark fw-normal  mx-2 mt-4"
                  >
                    <b>Praveen S</b>
                  </MDBTypography>
                  <br />
                </div>
              </div>
              <hr />
              <div style={{ height: "140px" }}>
                <p className="text-start mx-2">
                  <MDBIcon far icon="user-circle" className="me-2" />
                  <b>CAA Engineer</b>
                </p>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2 "
                >
                  Company Name:<b>SOPRA STERIA</b>
                </MDBTypography>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Course:<b>C++,CATIA</b>
                  </small>
                </MDBTypography>
                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Duration:<b>3-6 month</b>
                  </small>
                </MDBTypography>
              </div>
              <MDBBtn
                outline
                color="dark"
                onClick={toggleShow}
                className="tpa-info-button"
              >
                Connect With Me
              </MDBBtn>
            </MDBCard>
            {/* <MDBCard className="h-80 review-body-width">
              <div
                className="d-flex align-items-center "
                style={{ height: "90px" }}
              >
                <MDBCardImage
                  style={{ width: "70px", height: "80px" }}
                  className="img-fluid  border border-dark border-3 round_img"
                  src={""}
                  alt="Generic placeholder image"
                  fluid
                />
                <div>
                  <MDBTypography
                    variant="h6"
                    className="text-dark fw-normal  mx-2 mt-4"
                  >
                    <b>Malingaray </b>
                  </MDBTypography>
                  <br />
                </div>
              </div>
              <hr />
              <div style={{ height: "140px" }}>
                <p className="text-start mx-2">
                  <MDBIcon far icon="user-circle" className="me-2" />
                  <b>CAA Engineer</b>
                </p>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2 "
                >
                  Company Name: <b>DASSAULT</b>
                </MDBTypography>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Course:<b>C++,CATIA</b>
                  </small>
                </MDBTypography>
                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Duration:<b>3-6 month</b>
                  </small>
                </MDBTypography>
              </div>
              <MDBBtn
                outline
                color="dark"
                onClick={toggleShow}
                className="tpa-info-button"
              >
                Connect With Me
              </MDBBtn>
            </MDBCard>
            <MDBCard className="h-80 review-body-width">
              <div
                className="d-flex align-items-center "
                style={{ height: "90px" }}
              >
                <MDBCardImage
                  style={{ width: "70px", height: "80px" }}
                  className="img-fluid  border border-dark border-3 round_img"
                  src={""}
                  alt="Generic placeholder image"
                  fluid
                />
                <div>
                  <MDBTypography
                    variant="h6"
                    className="text-dark fw-normal  mx-2 mt-4"
                  >
                    <b>Aravind </b>
                  </MDBTypography>
                  <br />
                </div>
              </div>
              <hr />
              <div style={{ height: "140px" }}>
                <p className="text-start mx-2">
                  <MDBIcon far icon="user-circle" className="me-2" />
                  <b>CAA Engineer</b>
                </p>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2 "
                >
                  Company Name:<b>VALEO</b>
                </MDBTypography>

                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Course:<b>C++,CATIA</b>
                  </small>
                </MDBTypography>
                <MDBTypography
                  variant="h6"
                  className="text-dark fw-normal  mx-2"
                >
                  <small>
                    Duration:<b>3-6 month</b>
                  </small>
                </MDBTypography>
              </div>
              <MDBBtn
                outline
                color="dark"
                onClick={toggleShow}
                className="tpa-info-button"
              >
                Connect With Me
              </MDBBtn>
            </MDBCard> */}
          </Carousel>
        </MDBCol>
      </MDBRow>

      <MDBModal
        tabIndex="-1"
        show={fullscreenXlModal}
        setShow={setFullscreenXlModal}
      >
        <MDBModalDialog size="fullscreen-xl-down" style={{ color: "black" }}>
          <MDBModalContent>
            <MDBModalHeader>
              {/* <MDBModalTitle>Book a time slot with candidate.</MDBModalTitle> */}
              <MDBBtn
                type="button"
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <ContactBody/>
              {/* <RaiseArequest /> */}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}

export default StudentReview;
