import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import React, {  useContext } from "react";
import {  MDBContainer,  MDBNavbar,  MDBNavbarNav,  MDBNavbarItem,  MDBIcon,  MDBNavbarToggler,  MDBNavbarBrand,  MDBCollapse,  
  MDBDropdown,  MDBDropdownToggle,  MDBDropdownMenu,  MDBDropdownItem,  MDBBtn,  MDBNavbarLink } from "mdb-react-ui-kit";
import Logo from "../static/Transparent.png";
import ContactBody from "../More/ContactBodyEnqModal";
import ModalContext from "../Context/ModalContext";

function Header() {

  const [showNavColorThird, setShowNavColorThird] = React.useState(false);

  const triggerClick = () => {
    const mto = document.getElementById("MDBDropdownToggle");
    mto.click();
  };

  const triggerClick3 = () => {
    const mto = document.getElementById("MDBDropdownToggle3");
    mto.click();
  };

  const { openModal } = useContext(ModalContext);

  const handleOpenModal = () => {
    const modalTitle = "Enquiry";
    const modalContent = <><ContactBody /></>;
    openModal(modalContent, modalTitle);
  };

  return (
    <div>

    <MDBNavbar sticky expand="lg" light className="tpa-header-navbar MBheader">
      <MDBContainer fluid>

        <MDBNavbarBrand href={""} className="tpa-header-navbar-brand">
          <Link to={""}>
            <img src={Logo} height="60" alt="website logo" loading="lazy" />
          </Link>
        </MDBNavbarBrand>

        <MDBNavbarToggler aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          onClick={() => setShowNavColorThird(!showNavColorThird)} >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
          
        <MDBCollapse className="tpa-navbar-collapse" show={showNavColorThird} navbar >
          <MDBNavbarNav className="me-auto mb-2 mb-lg-0 w-auto">

            <MDBNavbarItem><MDBNavbarLink href="/#">Home</MDBNavbarLink></MDBNavbarItem>

            <MDBNavbarItem className="active">
              <MDBDropdown onMouseEnter={triggerClick} onMouseLeave={triggerClick} >
                <MDBDropdownToggle tag="a" className="nav-link" id="MDBDropdownToggle" role="button" > Job Guarantee Courses </MDBDropdownToggle>
                <MDBDropdownMenu className="m-0">
                  <MDBDropdownItem>
                    <MDBDropdownItem link><HashLink style={{ color: "inherit" }} to="#mechanical">Mechanical &raquo;</HashLink>
                    </MDBDropdownItem>
                    <ul className="dropdown-menu dropdown-submenu">
                      <MDBDropdownItem>
                        <MDBDropdownItem link href="/product-design">Automotive Design</MDBDropdownItem>
                      </MDBDropdownItem>
                      
                      <MDBDropdownItem>
                        <MDBDropdownItem link href="/C1atiaProductDesign">CATIA Automation & Customization</MDBDropdownItem>
                      </MDBDropdownItem>
                      
                      <MDBDropdownItem>
                        <MDBDropdownItem link href="./CAE1ProductDesign">CAE-Computer Aided Engineering</MDBDropdownItem>
                      </MDBDropdownItem>
                    </ul>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownItem link href="#mechanical">IT &raquo;</MDBDropdownItem>
                      <ul className="dropdown-menu dropdown-submenu">
                          <MDBDropdownItem>
                            <MDBDropdownItem link href="/FS1ProductDesign">Full Stack Webdevelopment</MDBDropdownItem>
                          </MDBDropdownItem>
                          
                          <MDBDropdownItem>
                            <MDBDropdownItem link href="ST1ProductDesign">Software Testing</MDBDropdownItem>
                          </MDBDropdownItem>
                    </ul>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink href="/internship">Internship</MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem className="active">
              <MDBDropdown onMouseEnter={triggerClick3} onMouseLeave={triggerClick3} >
                <MDBDropdownToggle tag="a" className="nav-link" id="MDBDropdownToggle3" role="button" > More </MDBDropdownToggle>
                <MDBDropdownMenu className="m-0">

                  <MDBDropdownItem>
                    <MDBDropdownItem link href="/aboutus"> About US </MDBDropdownItem>
                  </MDBDropdownItem>

                  <MDBDropdownItem>
                    <MDBDropdownItem link href="/faq"> Faq </MDBDropdownItem>
                  </MDBDropdownItem>

                  <MDBDropdownItem>
                    <MDBDropdownItem link href="/privacypolicies"> Privacy Policies </MDBDropdownItem>
                  </MDBDropdownItem>

                  <MDBDropdownItem>
                    <MDBDropdownItem link href="/termsandcondition"> Term And Condition </MDBDropdownItem>
                  </MDBDropdownItem>

                  <MDBDropdownItem link href="/contactus">Contact US</MDBDropdownItem>

                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
  
            <MDBNavbarItem>
                <MDBNavbarLink onClick={handleOpenModal}>Enquiry</MDBNavbarLink>
            </MDBNavbarItem>
            
            <MDBNavbarItem>
              <MDBNavbarLink href="/HireTalent"><p className="Hire"><b>Hire Talents From US</b></p></MDBNavbarLink>
            </MDBNavbarItem>


          </MDBNavbarNav>
{/* new */}
          <Link to={"/SignUp"}> <button className="btnsignup">Register</button>     </Link> &nbsp;
          <Link to={"/Signin"}>  <button className="btnsignup">Login</button>     </Link> &nbsp;  
          <Link to={"/"}><MDBBtn outline color="dark" className="me-2 tpa-info-button" type="button" >
              <MDBIcon icon="phone" className="me-3 " /> +91 7483868010 </MDBBtn>
          </Link>

        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>

    </div>
  );
}

export default Header;