import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBContainer,
  MDBTypography,
} from "mdb-react-ui-kit";

import FullstackProgram from "../static/FullstackProgram.jpg";

 function FS6Program() {
  return (
    <MDBContainer fluid className="p-5 rounded-7 mt-4 " id="program">
      <MDBRow>
        <MDBTypography
          variant="h2 courseoverview-body "
          style={{ color: "black" }}
        >
          Program
        </MDBTypography>

        <MDBCol md="4">
          <img
            src={FullstackProgram}
            className="img-fluid rounded mb-2"
            width=""
            alt=""
          />
        </MDBCol>
        <MDBCol center size="8" className="text-start ">
          <MDBTypography
            variant="h4 "
            className="text-start fw-bold"
            style={{ color: "black" }}
          >
            Real Life Projects
          </MDBTypography>
          <p className="fs-5 text-start fw-normal " style={{ color: "black" }}>
            In our complete full stack web developer course you will learn a
            variety of frameworks such as but not limited to HTML, CSS,
            Javascript, PHP, Bootstrap,MDBBootstrap,React js, Laraval and more!
            If you know some PHP then its a good time to learn a framework by
            the way it is essential to learn a framework a good framework.
          </p>
        </MDBCol>
        <hr />
        <MDBTypography
          variant="h4 "
          className="text-start fw-bold"
          style={{ color: "black" }}
        >
          Laravel
        </MDBTypography>
        <p className="fs-5 text-start fw-normal " style={{ color: "black" }}>
          Laravel is fastest growing php framework it has more then 40k stars on
          Github. Clean documentation and Model View Controller (MVC) support
          and so it is easy to understand. Laravel provide you an easy way to
          Integration with mail services. Laravel comes with Blade templating
          engine is very intuitive and helps to work with the typical PHP/HTML
          spaghetti so much better, that’s it one of the best features of the
          framework. Key features: Modularity or Multi-app, Authentication,
          Application Logic, Caching, Method or Dependency Injection, Routing,
          Restful Controllers, Testing & Debuging, Automatic Pagination,
          Template Engine, Database Query Builder, Multiple file system,
          Authentication systems and secure authorization and Implementing
          authentication is very simple with Laravel because almost everything
          is modified out-of-the-box. The framework provides an easy way to
          organize authorization logic and control access to all resources.
          Thus, a web application owner can be sure that access to secured
          resources won’t be granted to unauthorized users
        </p>
        <MDBTypography
          variant="h4 "
          className="text-start fw-bold"
          style={{ color: "black" }}
        >
          our Full Stack Web Developer Course Contains:
        </MDBTypography>
        <p className="fs-5 text-start fw-normal " style={{ color: "black" }}>
          It is import to learn the basics before starting any project in that
          specific language or framework. This course contains more then 5
          sections on the basics, such as HTML, CSS, Javascript, PHP and more.
          We also learn the fundamental or key features of Laravel.
        </p>
        <MDBTypography
          variant="h4 "
          className="text-start fw-bold"
          style={{ color: "black" }}
        >
          Who this course is for:
        </MDBTypography>
        <p className="fs-5 text-start fw-normal " style={{ color: "black" }}>
          This course is for anyone looking to lean how to build a real,
          comprehensive projects and become a full stack web delveloper! This
          course is for you if you would like to learn how to build their very
          own eCommerce web app. This course is for students looking to learn
          Laravel and/or advance their knowledge in PHP. This course is for you
          if you are looking to learn the skills needed to build an online
          business and become a full stack developer Students Who Want to learn
          how to build E-commerce Systems or Websites should take this course.
        </p>
      </MDBRow>
    </MDBContainer>
  );
};

export default FS6Program;
