import React from "react";
import { MDBContainer, MDBTypography } from "mdb-react-ui-kit";

function FS4Description() {
  return (
    <MDBContainer
      id="course"
      fluid
      className="p-1 rounded-7  "
      style={{ color: "black" }}
    >
      <MDBTypography
        variant="h2 courseoverview-body text-start m-3 "
        style={{ color: "black" }}
      >
        Course Description
      </MDBTypography>
      <p
        md="8"
        className="fs-6 courseoverview-body-start text-start m-3 "
        style={{ color: "black" }}
      >
        Full stack development refers to the development of both front end
        (client side) and back end (server side) portions of web
        application.This Program is engaged by software development and is
        knowledgeable in both the front end and back end of an application
        Development team of TPA which is a “transforming platform” for
        Engineering graduates of IT & Any related streams. This Program Ensures
        hands on project experience through diverse skill set. also well-versed
        in databases, server configuration and user interface. we taught ability
        to design complete web applications and websites. .
      </p>
    </MDBContainer>
  );
};

export default FS4Description;
