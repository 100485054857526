import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
  MDBTypography,
  MDBIcon,
  MDBCardBody,
} from "mdb-react-ui-kit";

// import C2atiaTrainingOptions1 from "./C2atiaTrainingOptions1";
import C3atiaCurriculum1 from "./C3atiaCurriculum1";
import C4atiaDescription from "./C4atiaDescription";
import C5atiaCourseHighlights from "./C5atiaCourseHighlights";
import C5atiaCoursModel from "./C5atiaCourseModel";
import C7atiaContent from "./C7atiaContent";
import C8atiaReviews from "./C8atiaReviews";

import car from "../static/car.webp";

import { Helmet } from "react-helmet";

function C1atiaProductDesign() {
  return (
    <>
      <div>
        <Helmet>
        <title>Job Ready – CATIA Automation and Customization Training</title>
          <meta
            name="description"
            content="Get job-ready with our interactive online training for advanced CATIA customization using C++ and CAA RADE (Rapid Application Development). Learn CAD automation, engineering software development, and get job assistance. Enroll now!"
          />
          <meta
            name="keywords"
            content="CATIA Automation, Mock Interview, CAA RADE, Placement Guaranteed, Unlimited Placement Support, CATIA Programming, CAD Software Development, Job Assistance, Online Training, Grooming Sessions, Placement Support"
          />
        </Helmet>
      </div>
      <MDBContainer fluid className="p-1 rounded-7 fullstackBody">
        <figure className="text-start text-light mb-0 mx-4">
          <MDBTypography variant="h1">
            Job Ready – Catia Automation and Customization
          </MDBTypography>

          <p class="text-light mb-0">
            <MDBIcon far icon="bookmark pe-2" />
            Category: MECHANICAL PROGRAMS / C1atiaProductDesign{" "}
            <span class="fw-bold">6</span>
          </p>
        </figure>

        <MDBRow>
          <MDBCol md="8">
            <MDBCardBody>
              <div className="bg-image hover-zoom">
                <img
                  style={{ width: "100%", maxHeight: "500px" }}
                  alt="car"
                  src={car} loading="lazy"
                  // src={Catia} loading="lazy"
                  className="round"
                />
              </div>
            </MDBCardBody>
          </MDBCol>
          <MDBCol start md="4">
            <MDBCardBody className="mechanical-body tpa-list-group">
              <MDBListGroup light>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="users pe-3" size="2x" />
                  <span>90 students</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item "
                >
                  <MDBIcon far icon="clock pe-4" size="2x" />
                  <span>Duration:180 hours</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="chalkboard-teacher pe-3" size="2x" />
                  <span> Lectures:85</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="book pe-4" size="2x" />
                  <span>Course completion</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item-google"
                >
                  <MDBIcon fab icon="google pe-4" size="2x" />
                  <span className="text-dark fs-4 fw-bold pe-2">4.5</span>
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star-half-alt" />
                </MDBListGroupItem>

                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="hand-point-right pe-4" size="2x" />
                  <span>Enroll Now</span>
                </MDBListGroupItem>
              </MDBListGroup>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <C3atiaCurriculum1 />
      <C4atiaDescription /> 
      <C5atiaCourseHighlights />
       <C5atiaCoursModel /> 
      <C7atiaContent />
      <C8atiaReviews />
    </>
  );
}

export default C1atiaProductDesign;
