import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MDBCol, MDBRow, MDBIcon } from 'mdb-react-ui-kit';
import { baseURL } from '../../../http';

function Stscroll2Resume() {
  const [resumeFile, setResumeFile] = useState(null);
  // const [resumeType, setResumeType] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem('user-info'));
  const userEmail = userInfo.email;
  const userName  = userInfo.name;


  const onDrop = (acceptedFiles) => {
    setResumeFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
                                                      onDrop,
                                                      });

  const saveResume = () => {
    if (resumeFile) {
      const formData = new FormData();
      formData.append('document', resumeFile);

      // formData.append('resumeTypeName', 'TypeValue');

      fetch(`${baseURL}/uploadResume/${userEmail}`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {    console.log('FE-Document uploaded successfully');                     } 
          else             {    console.error('Error uploading document:', response.statusText);      }
        })
        .catch((error) => {     console.error('Network error while uploading document:', error);
        });

    } else {                    console.error('No resume file selected');                             }
  };

  const deleteResume = () => {
    if (userEmail) {
      fetch(`${baseURL}/deleteResume/${userEmail}`, {
        method: 'DELETE', 
      })
        .then((response) => {
          if (response.ok) {     console.log('Resume deleted successfully');                          } 
          else             {     console.error('Error deleting resume:', response.statusText);        }
        })
        .catch((error) => {      console.error('Network error while deleting resume:', error);
        });
    } else {                     console.error('User email is missing. Cannot delete resume.');       }
  };
  
  const downloadResume = () => {
    fetch(`${baseURL}/downloadResume/${userEmail}`,
            {
              method: 'GET', // Use the GET method to request the resume file
            })
            .then((response) => {
              if (response.ok) {
                // Create a blob from the response data
                return response.blob();
              } else {
                console.error('Error downloading document:', response.statusText);
                return null;
              }
            })
            .then((blob) => {
              if (blob) {
                // Create a URL for the blob and trigger a download
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                          a.href = url;
                          a.download = userName+'.pdf'; // You can set the desired file name here
                          document.body.appendChild(a);
                          a.click();
                window.URL.revokeObjectURL(url);
              }
            })
            .catch((error) => {
              console.error('Network error while downloading document:', error);
            });
  };


  return (
    <div>
      <div >
        <div >
          <h5 style={{ color: 'black' }}>Resume</h5>
          {/* <h5>Name={userName}</h5> */}
          <p >
            Resume is the most important document recruiters look for. Recruiters generally do not look at profiles without resumes.
          </p>
        </div>
      </div>
      <MDBRow style={{width:'100%'}}> 
          <MDBCol>
            <h6 style={{color:'black'}}> Resume name </h6>
          </MDBCol>

          <MDBCol style={{ textAlign:'right', color:'blue'}}>
            <MDBIcon icon="download"      style={{ cursor:'pointer'}} onClick={downloadResume}/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MDBIcon far icon="trash-alt" style={{ cursor:'pointer'}} onClick={deleteResume}/>
          </MDBCol>
      </MDBRow>


      {/*```````````````````````````````````` Resume upload section````````````````````````````````*/}
      <div className="row" style={{ border: '2px dashed #ccc', paddingTop: '20px', marginRight: '10px', textAlign: 'center', alignContent: 'center' }}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <button className='dropbtn2' onClick={saveResume}>Update Resume</button>
          <p>Drag 'n' drop your resume file here, or click to select a file</p>

          {resumeFile && <p>Selected file: {resumeFile.name}</p>}
        </div>
      {/*_______________________________________Resume upload section______________________________*/}
      </div>


    </div>
  );
}

export default Stscroll2Resume;
