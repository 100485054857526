import React from "react";

import {
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBTypography,
  
} from "mdb-react-ui-kit";

import InternshipLearning from "./InternshipLearning";
import InternshipJoin from "./InternshipJoin";
import InternshipRegister from "./InternshipRegister";
import InternshipAdvantages from "./InternshipAdvantages";
import { Helmet } from "react-helmet";

import Internship1 from "../static/Internship1.webp";



function Internship() {
  
  return (
    <>
      <div>
        <Helmet>
          <title>Internship and Start Learning | TPA</title>
         
           <meta
        name="keywords"
        content="Internship, Learning, Training, Professional Development, Skill Development, TPA, Engineering Internship, IT Internship, Internship Programs, Internship Opportunities in bangalore"
      />

      <meta
        name="description"
        content="Explore internship opportunities for professional development and skill enhancement at Talent Pool Academy (TPA). Our internship programs cover various fields such as engineering, IT, design, analysis, electronics, and language. Apply now and gain hands-on experience in your area of interest."
      />

        </Helmet>
      </div>

      <MDBRow className="MBintern">
        <MDBTypography
          className="fw-bold mt-3 "
          variant="h2 text-center "
          style={{ color: "black" }}
        >
          INTERNSHIP
        </MDBTypography>
        <MDBCol md="8">
          <MDBCardBody>
            <p
              class="fs-2 fw-bold text-start mt-0 "
              style={{
                color: "black",
              }}
            >
              Students
            </p>
            <p
              className="fw-normal text-start fs-5"
              style={{
                color: "black",textAlign:"justify"
              }}
            >
              TPA internship program is designed for students or those seeking
              practical experience. You'll work alongside professionals in a
              real work environment to apply your knowledge. Tasks may include
              research, administrative duties, or assisting with projects.
              You'll receive guidance and feedback from experienced
              professionals to help you grow. Internships are valuable for
              gaining hands-on experience and exploring future career options.
              <br />
            </p>
          </MDBCardBody>
        </MDBCol>
        <MDBCol md="4">
          <img
            src={Internship1} alt="intershipImg"
            className="hifi-img intern-round"
          
          ></img>

         

        </MDBCol>
      </MDBRow>

      <InternshipLearning />

      {/* <IntershipProgram2 /> */}
      <InternshipJoin />
      <InternshipRegister />

      <InternshipAdvantages />
    </>
  );
}

export default Internship;