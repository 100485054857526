import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCardText,
  MDBTypography,
} from "mdb-react-ui-kit";
import { Helmet } from "react-helmet";

import Hirefrom2 from "../static/Hirefrom2.jpg";
import Hirefrom3 from "../static/Hirefrom3.jpg";
import Hirefrom4 from "../static/Hirefrom4.jpg";

function TPABusinessHire() {
  return (
    <>
    <Helmet>
      <title>Why hire from TPA?</title>
      <meta
        name="keywords"
        content="Hire from TPA, Expertise, Timely Delivery, Customized Solutions, Business Services"
      />
      <meta
        name="description"
        content="Discover the reasons to hire from TPA. We offer expertise, timely delivery, and customized solutions for your business needs."
      />
    </Helmet>
      <MDBRow style={{ color: "black" }} className="p-5">
        <MDBTypography variant="h1" className="mb-5">
          Why hire from TPA?
        </MDBTypography>

        <MDBCol md="4">
          <img
            src={Hirefrom2}
            alt="Expertise Hriring"
            class="round"
            width="100%"
            maxHeight="500px"
          ></img>
          <MDBCardText>
            <h4 class="mb-2 mt-4 text-start"><b>Expertise</b></h4>
            
            <p fs-5 style={{ textAlign:"justify"}}>
              Our team consists of highly skilled professionals with extensive expertise in their respective fields. When you hire from us, you can be confident that you are getting access to top-notch talent who are well-equipped to handle your specific needs.
            </p>
          </MDBCardText>
        </MDBCol>
        <MDBCol md="4">
          <img
            src={Hirefrom3}
            alt="Timely Delivery"
            class="round"
            style={{ width: "100%", maxHeight: "500px" }}
          ></img>
          <MDBCardText>
            <h4 class="mb-2 mt-4"> <b>  Timely Delivery </b> </h4>
            <p fs-5 style={{ textAlign:"justify"}}>
         We understand the importance of time in today's fast-paced business environment. When you hire from us, you can count on us to deliver your projects promptly. We prioritize meeting deadlines without compromising on the quality of the work we provide.
            </p>
          </MDBCardText>
        </MDBCol>
        <MDBCol md="4">
          <img
            src={Hirefrom4}
            alt="Customized Solutions"
            class="round"
            style={{ width: "100%", maxHeight: "500px" }}
          ></img>

          <MDBCardText>
            <h4 class="mb-2 mt-4"><b>Customized Solutions</b></h4>
            
            <p fs-5 style={{ textAlign:"justify"}}>Customized Solutions: We believe in tailoring our services to meet the unique requirements of each client. Whether you need assistance with a specific project or ongoing support, we work closely with you to understand your objectives and provide customized solutions that align with your goals.</p>
          </MDBCardText>
        </MDBCol>
      </MDBRow>

      
    </>
  );
}

export default  TPABusinessHire;
