import React, { useState, useEffect } from 'react'
import { MDBCol, MDBRow, MDBRadio } from 'mdb-react-ui-kit'
import Select from 'react-select';
import { baseURL } from '../../../http';
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBModalFooter, MDBTextArea } from 'mdb-react-ui-kit';
import Stscroll6EducationAddModal from './Stscroll6EducationAddModal';

function Stscroll6Education() {

  const [educList, setEducList]                                                      = useState([]); // from DB
  const userInfo                                                                     = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                                                                    = userInfo.email;
  const [addModalStatus, setAddModalStatus]                                          = useState(false);
  const [editModalStatus, setEditModalStatus]                                        = useState(false);

  const [selectedCourseStartYear, setSelectedCourseStartYear]                        = useState(null);
  const [selectedCourseEndYear, setSelectedCourseEndYear]                            = useState(null);
  const [selectedEducation, setSelectedEducation]                                    = useState(null);
  const [selectedCourse, setSelectedCourse]                                          = useState(null);
  const [selectedOptionSpecialization, setSelectedOptionSpecialization]              = useState(null);
  const [University, setUniversity]                                                  = useState(null);
  const [courseType, setCourseType]                                                  = useState(null);
  
  const [currentYear, setCurrentYear]                                              = useState(null);
  const [currentDate, setCurrentDate]                                              = useState(new Date());

  const [editItemID, setEditItemID]                                                = useState(null);
  const [editData, setEditData]                   = useState({
                                                              curEmp:'',                        empType: '',
                                                              fullExpYears: '',                 fullExpMonths: '',
                                                              currentCompanyname: '',           currentDesignation: '',
                                                              });
    function toggleAddModal(e){  setAddModalStatus(!addModalStatus);  }
    
    const toggleEditModal = (itemID) => {
      setEditModalStatus(!editModalStatus);

      const selectedItem = educList.find(item => item.id === itemID);

      console.log('edit modal'+JSON.stringify(selectedItem));
      if (selectedItem) {
        setEditItemID(itemID);
        setSelectedEducation(educationOptions.find(option => option.value ===selectedItem.education));
        setUniversity(selectedItem.university);
        setSelectedCourse(courseOptions.find(option => option.value === selectedItem.course));
        setSelectedOptionSpecialization(specializationOptions.find(option => option.value === selectedItem.specialization));
        setCourseType(selectedItem.courseType);
        setSelectedCourseStartYear(courseYearOptions.find(option => option.value === selectedItem.courseStart));
        setSelectedCourseEndYear(courseYearOptions.find(option => option.value === selectedItem.courseEnd));
  
      }
    }
    /*--------------------Fetching Student's of all emp_details-------------------- */
  const fetchStudentEducList = () => {
    const requestBody = {
      email: userEmail,
    };

    fetch(`${baseURL}/getAllStudentEducation`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: { 'Content-Type': 'application/json', },
    })
      .then((response) => response.json())
      .then((data) => {
        setEducList(data || "");
      })
      .catch((error) => { console.error('Error fetching Emp_details:', error); });
  };

  useEffect(() => {
    fetchStudentEducList();
  }, []);

  /*-----------------------save edit api starts-----------------*/
const saveEditedItem = () => {

  const updates = {
                  id            : editItemID,
                  email         : userEmail,

                  education     : selectedEducation?selectedEducation.value:'',
                  university    : University,
                  course        : selectedCourse?selectedCourse.value:'',
                  specialization: selectedOptionSpecialization?selectedOptionSpecialization.value:'',
                  courseType    : courseType,
                  courseStart   : selectedCourseStartYear?selectedCourseStartYear.value:'',
                  courseEnd     : selectedCourseEndYear?selectedCourseEndYear.value:'',
                  };
                  

      fetch(`${baseURL}/updateStudentEducation/${editItemID}`, {
      method: 'POST',
      headers: {
              'Content-Type': 'application/json',
              },
      body: JSON.stringify(updates),
    })
    .then((response) => {
      if (response.ok) {
          const updatedList = educList.map((item) =>
          item.id === editItemID.id ? { ...item, ...editData } : item
          );
          setEducList(updatedList);
          setEditModalStatus(false);
          console.log('Item updated successfully');
      } else {
          console.error('Failed to update item');
          }
      })
    .catch((error) => {
        console.error('Item updating error:', error);
        });
    };
/*-----------------------save edit api ends-----------------*/

  const courseYearOptions=[
   { value: currentYear - 0, label: currentYear - 0 },
    { value: currentYear - 1, label: currentYear - 1 },
    { value: currentYear - 2, label: currentYear - 2 },
    { value: currentYear - 3, label: currentYear - 3 },
    { value: currentYear - 4, label: currentYear - 4 },
    { value: currentYear - 5, label: currentYear - 5 },
    { value: currentYear - 6, label: currentYear - 6 },
    { value: currentYear - 7, label: currentYear - 7 },
    { value: currentYear - 8, label: currentYear - 8 },
    { value: currentYear - 9, label: currentYear - 9 },
  ]

  useEffect(() => {
                const intervalId = setInterval(() => {  setCurrentDate(new Date()); }, 1000); // Update the date every second
                                                        // console.log('current date:='+currentDate.getFullYear());
                                                        setCurrentYear(currentDate.getFullYear());
                return () => { clearInterval(intervalId); };
              }, []);



 
  const educationOptions=[
    { value: 'Doctorate/PhD',               label: 'Doctorate/PhD'  },
    { value: 'Masters/Post-Graduation',     label: 'Masters/Post-Graduation'  },
    { value: 'Graduation/Diploma',          label: 'Graduation/Diploma'  },
  ]

  const courseOptions=[
    { value: 'B.E',               label: 'B.E'  },
    { value: 'M.E',               label: 'M.E'  },

  ]

  const specializationOptions=[
    { value: '1',               label: '1'  },
    { value: '2',               label: '2'  },
    { value: '3',               label: '3'  },
    { value: '4',               label: '4'  },
  ]

  return (
    <div>
        <MDBRow>
          <MDBCol>
          <h5 style={{color:'black'}}> Education </h5>
          </MDBCol>

          <MDBCol style={{ textAlign:'right'}}>
            <h5 onClick={toggleAddModal} 
            className='blinkText'
            style={{color:'blue', fontSize:15, cursor:'pointer' }}>Add Education</h5>
          </MDBCol>
        </MDBRow>


    {/*---------Mapping starts-------- */ }
    <div>
      {educList.map(data=>(
          <div key={data.id}>

            <span style={{color:'black'}}>{data.course}</span>        <i onClick={() => toggleEditModal(data.id)} className="fas fa-pencil" style={{paddingBottom:'8px', fontSize:12, cursor:'pointer'}} />&nbsp;
                                                          <br></br>
            <span>{data.university}</span><br></br>
            <span>{data.courseStart} - {data.courseEnd} {data.workedFromYear}  | {data.courseType} 
            </span><br></br>
            <span>{data.jobProfile}</span>
            <br></br>


          </div>
      ))}
    </div>
    {/*---------Mapping Ends-------- */ }


          {/* (2.1)------------------>---Modal code starts */}
          <MDBModal tabIndex='-1' show={addModalStatus} setShow={setAddModalStatus}>
            <MDBModalDialog centered className="modal-xl" style={{width:'850px'}}>
            <MDBModalContent >
                
                    <Stscroll6EducationAddModal togglingModal={toggleAddModal}/>
                    
            </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          {/*(2.1)--------->------------- Modal code ends */}

          {/* (2.2)------------------>---Edit_Modal code starts */}
          <MDBModal tabIndex='-1' show={editModalStatus} setShow={setEditModalStatus}>
              <MDBModalDialog centered className="modal-xl" style={{width:'800px'}}>
              <MDBModalContent >

                <div className='profileModal'>
                  <h3>Edit Education</h3>
                  <MDBRow>
                    <label htmlFor="exampleFormControlSelect2" >Education</label><br></br>
                            <Select
                                value={selectedEducation}//selectedOptionYear//usestate var declaration
                                options={educationOptions}                   // dropdown options
                                onInputChange={(inputValue) => inputValue}            // constant
                                onChange={(selectedOptions) => setSelectedEducation(selectedOptions)}                  //selecting or joining to  usestate
                                isClearable={true}// type to filter
                                placeholder="Select"
                            />
                  </MDBRow><br></br>

                  <MDBRow>
                    <label htmlFor="exampleFormControlSelect2" >University/Institute</label><br></br>
                        <input type="text"
                          className="form-control" 
                          style={{width:'100%', marginLeft:'15px',maxWidth:'770px'}}
                          id="formGroupExampleInput1" placeholder='Type Your University/Institute'
                          value={ University || ""}
                          onChange={e=>setUniversity(e.target.value)} 
                          />
                  </MDBRow><br></br>

                  <MDBRow>
                    <label htmlFor="exampleFormControlSelect2" >Course</label><br></br>
                            <Select
                                value={selectedCourse}//selectedOptionYear
                                options={courseOptions}
                                onInputChange={(inputValue) => inputValue}
                                onChange={(selectedOptions) => setSelectedCourse(selectedOptions)}
                                isClearable={true}// type to filter
                                placeholder="Select"
                            />
                  </MDBRow><br></br>

                  <MDBRow>
                    <label htmlFor="exampleFormControlSelect2" >Specialization</label><br></br>
                            <Select
                                value={selectedOptionSpecialization}//selectedOptionYear
                                options={specializationOptions}
                                onInputChange={(inputValue) => inputValue}
                                onChange={(selectedOptions) => setSelectedOptionSpecialization(selectedOptions)}
                                isClearable={true}// type to filter
                                placeholder="Select"
                            />
                  </MDBRow><br></br>


                  <MDBRow>
                    <label htmlFor="exampleFormControlSelect2" >Course Type</label><br></br>
                          
                    <br></br>
                      <MDBCol>
                      <MDBRadio name='courseType' id='inlineRadio1' value='Full-Time' label='Full-Time' inline checked={courseType === 'Full-Time'} onChange={() => setCourseType('Full-Time')} />
                      <MDBRadio name='courseType' id='inlineRadio2' value='Part-Time' label='Part-Time' inline checked={courseType === 'Part-Time'} onChange={() => setCourseType('Part-Time')} />
                      <MDBRadio name='courseType' id='inlineRadio3' value='Correspondance/Distance' label='Correspondance/Distance' inline checked={courseType === 'Correspondance/Distance'} onChange={() => setCourseType('Correspondance/Distance')} />
                      
                      </MDBCol>


                  </MDBRow><br></br>

                  <MDBRow>
                  <label htmlFor="exampleFormControlSelect2" >Course Duration</label><br></br>
                  <MDBRow >
                            <MDBCol>
                              <Select
                                value={selectedCourseStartYear}//selectedOptionYear
                                options={courseYearOptions}
                                onInputChange={(inputValue) => inputValue}
                                onChange={(selectedOptions) => setSelectedCourseStartYear(selectedOptions)}
                                isClearable={true}// type to filter
                                placeholder="Start Year"
                              />
                            </MDBCol>
                            <MDBCol >
                              <Select
                                value={selectedCourseEndYear}
                                options={courseYearOptions}
                                onInputChange={(inputValue) => inputValue}
                                onChange={(selectedOptions) => setSelectedCourseEndYear(selectedOptions)}
                                isClearable={true}
                                placeholder="End Year"
                              />
                            </MDBCol>
                          </MDBRow><br></br>
                  </MDBRow>

                            <br></br>
                      <button type="button" className="dropbtnprofilepagesave"    onClick={saveEditedItem}> Save   </button>&nbsp;
                      <button type="button" className="dropbtnprofilepagecancel2" onClick={()=> setEditModalStatus(false)}>   Cancel </button>&nbsp;
                          
                </div>

              </MDBModalContent>
              </MDBModalDialog>
          </MDBModal>
          {/* (2.2)------------------>---Edit_Modal code ends */}

    </div>
  )
}

export default Stscroll6Education
