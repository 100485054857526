import React from "react";
import { MDBContainer, MDBTypography, MDBRow, MDBCol } from "mdb-react-ui-kit";

import Highlights4 from "../static/Highlights4.jpg";

 function FS5CourseHighlights() {
  return (
    <>
      <MDBContainer
        fluid
        className="p-3 rounded-7  mt-0 fullstackBody"
        id="overview1"
      >
        <MDBRow>
          <MDBTypography variant="h1 text-light text-start mt-1 mb-5 ">
            Highlights Of Program
          </MDBTypography>

          <MDBCol center size="6">
            <img
              src={Highlights4}
              className="round "
              style={{ width: "90%", maxHeight: "400px" }}
              alt="highlights-img"
            />
          </MDBCol>
          <MDBCol center size="6">
            <ul className="fs-5 fw-bold text-light">
              <li>3 Live Real Time two Projects.</li>
              <br></br>
              <li>Choice Based Project Work Either front end Or back end.</li>
              <br></br>
              <li>
                Project Delivered Under 10+ Years Experienced Industry Expert.
              </li>
              <br></br>
              <li>
                Flexible Practice Hours Under Continuous Enhancement and Support
              </li>
              <br></br>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default FS5CourseHighlights;
