import React, { useState, useEffect } from 'react'
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBModalFooter } from 'mdb-react-ui-kit';
import { MDBTable, MDBTableHead, MDBTableBody, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Select from 'react-select';
import { baseURL } from '../../../http';
import Stscroll7ITSkillsAddModal from './Stscroll7ITSkillsAddModal';

function Stscroll7ITSkills() {
  const [itSkillsList, setITSkillsList]   = useState([]); // from DB

  const userInfo                          = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                         = userInfo.email;

  const [modalStatus, setModalStatus]             = useState(false);
  const [editModalStatus, setEditModalStatus]     = useState(false);
  const [editItemID, setEditItemID]               = useState(null);
  const [editData, setEditData]                   = useState({
                                                          skills:'',
                                                          version: '',
                                                          lastUsed: '',
                                                          itExpYear: '',
                                                          itExpMonth: '',
                                                      }); 
                                                

  const [selectedOptionYear, setSelectedOptionYear]                   = useState(null); // value-1
  const [selectedOptionMonth, setselectedOptionMonth]                 = useState(null);
  const [selectedOptionLastUsed, setselectedOptionLastUsed]           = useState(null);

  const [currentYear, setCurrentYear]                                 = useState(null);
  const [currentDate, setCurrentDate]                                 = useState(new Date());

  function toggleModal(e)     { setModalStatus(!modalStatus); }
  
  const toggleEditModal = (itemID) => {
          const selectedItem = itSkillsList.find(item => item.id === itemID);

          // console.log("skills="+selectedItem.skills)
          // console.log("selected items="+JSON.stringify(selectedItem));

          if (selectedItem) {
            setEditItemID(itemID);
            setEditData({ 
              skills: selectedItem.skills,
              version: selectedItem.version,
              lastUsed: selectedItem.lastUsed,
              itExpYear: selectedItem.itExpYear,
              itExpMonth: selectedItem.itExpMonth,
            });

            const defaultLastUsed = lastUsedOptions.find(option => option.value === selectedItem.lastUsed);
            const defaultExpYear  = expYearOptions.find(option  => option.value === selectedItem.itExpYear);
            const defaultExpMonth = expMonthsOptions.find(option=> option.value === selectedItem.itExpMonth);

            setselectedOptionLastUsed(defaultLastUsed);
            setSelectedOptionYear(defaultExpYear);
            setselectedOptionMonth(defaultExpMonth);

            setEditModalStatus(!editModalStatus); // require to open the modal
          }
        };

  
  const fetchITSkillsList = () => {
    const requestBody = {
      email: userEmail,
    };

    fetch(`${baseURL}/getITSkills`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setITSkillsList(data);
      })
      .catch((error) => {
        console.error('Error fetching IT skills:', error);
      });
  };

  useEffect(() => {
    fetchITSkillsList();
  }, []);

  /*----------------------------ITEM DELETE------------------------------- */
  const handleItemDelete = (itemId) => {
    const updatedITSkillList = itSkillsList.filter(item => item.id !== itemId);
    setITSkillsList(updatedITSkillList);

    fetch(`${baseURL}/deleteITSkills/${itemId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          console.log('Item deleted successfully');
        } else {
          console.error('Failed to delete item');
        }
      })
      .catch(error => {
        console.error('Item deleting user:', error);
      });
  };



// Update the edited item in the database and the state
const saveEditedItem = () => {

            const updates = {
              id        : editItemID,
              skills    : editData.skills,
              version   : editData.version,
              lastUsed  : selectedOptionLastUsed ? selectedOptionLastUsed.value : '', // Use selected value from the state
              itExpYear : selectedOptionYear ? selectedOptionYear.value : '',        // Use selected value from the state
              itExpMonth: selectedOptionMonth ? selectedOptionMonth.value : '',     // Use selected value from the state
          };


  fetch(`${baseURL}/updateItSkills/${editItemID}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updates),
  })
    .then((response) => {
      if (response.ok) {
        // Update the state with the edited data
        const updatedList = itSkillsList.map((item) =>
          item.id === editItemID.id ? { ...item, ...editData } : item
        );
        setITSkillsList(updatedList);
        setEditModalStatus(false);
        console.log('Item updated successfully');
      } else {
        console.error('Failed to update item');
      }
    })
    .catch((error) => {
      console.error('Item updating error:', error);
    });
};

const handleInputChange = (exp_inputValue) => {         // onInputChange={handleInputChange}-3
  return exp_inputValue;
};

const expYearOptions = [                              // in options-2
    { value: '1 Year', label: '1 Year'     },
    { value: '2 Years', label: '2 Years'   },
    { value: '3 Years', label: '3 Years'   },
    { value: '4 Years', label: '4 Years'   },
    { value: '5 Years', label: '5 Years'   },
    { value: '6 Years', label: '6 Years'   },
    { value: '7 Years', label: '7 Years'   },
    { value: '8 Years', label: '8 Years'   },
    { value: '9 Years', label: '9 Years'   },
    { value: '10 Years', label: '10 Years' },
  ];
  
  const expMonthsOptions = [
    { value: '1  Month', label: ' 1  Month'  },
    { value: '2  Months', label: '2  Months' },
    { value: '3  Months', label: '3  Months' },
    { value: '4  Months', label: '4  Months' },
    { value: '5  Months', label: '5  Months' },
    { value: '6  Months', label: '6  Months' },
    { value: '7  Months', label: '7  Months' },
    { value: '8  Months', label: '8  Months' },
    { value: '9  Months', label: '9  Months' },
    { value: '10 Months', label: '10 Months' },
  ];
  
  const lastUsedOptions =[
    { value: currentYear - 0, label: currentYear - 0 },
    { value: currentYear - 1, label: currentYear - 1 },
    { value: currentYear - 2, label: currentYear - 2 },
    { value: currentYear - 3, label: currentYear - 3 },
    { value: currentYear - 4, label: currentYear - 4 },
    { value: currentYear - 5, label: currentYear - 5 },
    { value: currentYear - 6, label: currentYear - 6 },
    { value: currentYear - 7, label: currentYear - 7 },
    { value: currentYear - 8, label: currentYear - 8 },
    { value: currentYear - 9, label: currentYear - 9 },
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {  setCurrentDate(new Date()); }, 1000); // Update the date every second
                                            // console.log('current date:='+currentDate.getFullYear());
                                            setCurrentYear(currentDate.getFullYear());
    return () => { clearInterval(intervalId); };
  }, []);

  const selectExpYear   = (selectedOptionYear) => {        //onChange={selectExpYear}  - 4
    setSelectedOptionYear(selectedOptionYear);
 };

 const selectExpMonth = (selectedOptionMonth)=> {
  setselectedOptionMonth(selectedOptionMonth);
 }

   const     selectLastUsed=(selectedLastUsed)=>{
    setselectedOptionLastUsed(selectedLastUsed);
   }

  return (
    <div>
      <MDBRow>
          <MDBCol>
            <h5 style={{color:'black'}}> IT Skills </h5>
          </MDBCol>

          <MDBCol style={{ textAlign:'right'}}>
            <h5 onClick={toggleModal} 
            className='blinkText'
            style={{color:'blue', fontSize:15, cursor:'pointer' }}>Add IT Skills</h5>
          </MDBCol>
        </MDBRow>          
                  
                  
        <MDBTable>
        <MDBTableHead className='bg-gradient'>
          <tr style={{ textAlign: 'center' }}>
            <th scope='col' style={{ width: '80px' }}>Skills</th>
            <th scope='col' style={{ width: '280px' }}>Version</th>
            <th scope='col' style={{ width: '180px' }}>Last Used</th>
            <th scope='col' style={{ width: '180px' }}>Experience</th>
            <th scope='col' style={{ width: '180px' }}>Action</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody style={{ textAlign: 'center' }}>
          
                  {itSkillsList.map(table=>(
                      <tr key={table.id}>
                        <td>{table.skills}</td>
                        <td>{table.version}</td>
                        <td>{table.lastUsed}</td>
                        <td>{table.itExpYear}&nbsp;&nbsp;{table.itExpMonth}&nbsp;</td>
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <i onClick={() => toggleEditModal(table.id)} className="fas fa-pencil" />&nbsp;| &nbsp;
                              <i onClick={() => handleItemDelete(table.id)} className="fas fa-trash-can red-trash-icon"></i>
              
                          </div>
                        </td>
                      </tr>
                  ))}
        </MDBTableBody>
      </MDBTable>
                  
                  
                    {/* (2.1)****************Add Modal code starts *******/}
                  <MDBModal tabIndex='-1' show={modalStatus} setShow={setModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'800px'}}>
                        <MDBModalContent >
                            
                                <Stscroll7ITSkillsAddModal togglingModal={toggleModal}/>

                        </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    {/*(2.1)---------------- Add Modal code ends ---------/}
                  
                  
                  
                    {/* (2.1)****************Edit Modal code starts *******/}
                    <MDBModal tabIndex='-1' show={editModalStatus} setShow={setEditModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'800px'}}>
                          <MDBModalContent >
                            <div style={{ margin:'30px' }}> 
                                  <h3>IT skills edit</h3>
                                  
                                  <MDBRow>
                                    <MDBCol >
                                      <label htmlFor="exampleFormControlSelect1" >Skill / software name</label>
                                        <input type="text"
                                          className="form-control" 
                                          id="formGroupExampleInput1" placeholder='Skill / software name'
                                          value={editData.skills}
                                          onChange={e => setEditData({ ...editData, skills: e.target.value })}
                                          />
                                      <br></br>
                                    </MDBCol>
                                  </MDBRow>

                                  <MDBRow>
                                    <MDBCol style={{width:'200px'}}>
                                      <label htmlFor="exampleFormControlSelect2" >Software Version</label>
                                      <input type="text"
                                          className="form-control" 
                                          id="formGroupExampleInput1" placeholder='Software Version'
                                          value={editData.version}
                                          onChange={e => setEditData({ ...editData, version: e.target.value })}
                                          />
                                    </MDBCol>

                                    <MDBCol >
                                      <label htmlFor="exampleFormControlSelect3" >Last Used</label>
                                        <Select
                                          value={selectedOptionLastUsed}
                                          options={lastUsedOptions}
                                          onInputChange={handleInputChange}
                                          onChange={(selectedOption) => setselectedOptionLastUsed(selectedOption)}
                                          isClearable={true}
                                          placeholder="Last Used"
                                        />
                                    </MDBCol> 
                                  </MDBRow> <br></br>

                                  <div className=''>
                                      <label htmlFor="exampleFormControlSelect2" >Total Experience</label><br></br>
                                      <label htmlFor="exampleFormControlSelect2" >Years</label>
                                      <label htmlFor="exampleFormControlSelect2" style={{marginLeft:'343px'}}>Months</label>
                                      <MDBRow >
                                        <MDBCol>
                                          <Select
                                           value={selectedOptionYear}
                                            options={expYearOptions}
                                            onInputChange={handleInputChange}
                                            onChange={selectExpYear}
                                            isClearable={true}// type to filter
                                            placeholder="Select"
                                          />
                                        </MDBCol>
                                        <MDBCol >
                                          <Select
                                          value={selectedOptionMonth}
                                            options={expMonthsOptions}
                                            onInputChange={handleInputChange}
                                            onChange={selectExpMonth}
                                            isClearable={true}
                                            placeholder="Select"
                                          />
                                        </MDBCol>
                                      </MDBRow><br></br>
                                  </div>
                              </div>
                      
                              <MDBModalFooter>
                                  <button type="button" className="dropbtnprofilepagesave"    onClick={saveEditedItem}> Save   </button>&nbsp;
                                  <button type="button" className="dropbtnprofilepagecancel2" onClick={() => setEditModalStatus(false)}>   Cancel </button>&nbsp;
                              </MDBModalFooter>
                          </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    {/*(2.2)---------------------- Edit Modal code ends -----------*/}
                  
                  
                  


    </div>
  )
}

export default Stscroll7ITSkills
