import React, { useEffect } from 'react';
import StudentProfilePage from './Update Profile Tab/1StudentProfilePage';
import StudentHeader from './1StudentHeader';
import Stscroll1 from './Update Profile Tab/Stscroll1';
import StudentSideNav from './1StudentSideNav';

function StudentDashboard() {
  // useEffect(() => { document.title = "TPA - Student Dashboard";  });

  return (
    <div className='studentprofile studentProfileBackground' >

        <StudentHeader />           <br/>

        <StudentSideNav/>

      
    </div>
  );
}

export default StudentDashboard;
