import React, { useState, useEffect } from 'react'
import {  MDBModal,  MDBModalDialog,  MDBModalContent, MDBCol, MDBRow, MDBRadio, MDBTextArea } from 'mdb-react-ui-kit';
import Select from 'react-select';
import { baseURL } from '../../../http';
import Stscroll8ProjectsAddModal from './Stscroll8ProjectsAddModal';


function Stscroll8Projects() {

  const [empList, setEmpList]                                         = useState([]); // from DB

  const [projectList, setProjectList]                                   = useState([]); // from DB
  const userInfo                                                        = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                                                       = userInfo.email;
  const [showMore, setShowMore]                                       = useState('no');
  const [editModalStatus, setEditModalStatus]                                      = useState(false);
  const [editItemID, setEditItemID]                                                = useState(null);
  const [editData, setEditData]                   = useState({
                                                              projectTitle    : '',           client          : '',
                                                              projectStatus   : '',           projectTag      : '',
                                                              projectFromYear : '',           projectFromMonth: '',
                                                              projectTillYear : '',           projectTillMonth: '',
                                                              projectdetails  : '',           projectLocation : '',
                                                              projectSite     : '',           empNature       : '',
                                                              teamSize        : '',           roleDescription : '',           
                                                            }); 
                

  const [selectedTeamSize, setSelectedTeamSize]                       = useState(null);
  const [selectedWorkedFromYear, setSelectedWorkedFromYear]           = useState(null);
  const [selectedWorkedFromMonth, setSelectedWorkedFromMonth]         = useState(null);
  const [selectedWorkedTillYear, setSelectedWorkedTillYear]           = useState(null);
  const [selectedWorkedTillMonth, setSelectedWorkedTilMonth]          = useState(null);
  
  const [currentYear, setCurrentYear]                                 = useState(null);
  const [currentDate, setCurrentDate]                                 = useState(new Date());

  const [addModalStatus, setAddModalStatus]                             = useState(false);
  function toggleAddModal() {  setAddModalStatus(!addModalStatus);  }

  const toggleEditModal = (itemID) => {
    const selectedItem = projectList.find(item => item.id === itemID);

    if (selectedItem) {
      setEditItemID(itemID);
      setEditData({ 
        projectTitle    : selectedItem.projectTitle,           client          :selectedItem.client,
        projectTag      : selectedItem.projectTag,             projectStatus   :selectedItem.projectStatus,
        projectFromYear : selectedItem.projectFromYear,        projectFromMonth:selectedItem.projectFromMonth,
        projectTillYear : selectedItem.projectTillYear,        projectTillMonth:selectedItem.projectTillMonth,
        projectdetails  : selectedItem.projectdetails,         projectLocation :selectedItem.projectLocation,
        projectSite     : selectedItem.projectSite,            empNature       :selectedItem.empNature,
        teamSize        : selectedItem.teamSize,               roleDescription :selectedItem.roleDescription, 

      });

      setEditModalStatus(!editModalStatus); // require to open the modal
  
    };
  }
    /*--------------------Fetching Student's of all emp_details-------------------- */
    const fetchStudentProjectList = () => {
      const requestBody = {
        email: userEmail,
      };
  
      fetch(`${baseURL}/getAllProjects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          setProjectList(data || "");
        })
        .catch((error) => {
          console.error('Error fetching Projects_details:', error);
        });
    };
  
    useEffect(() => {
      fetchStudentProjectList();
    }, []);
/*--------------------Fetching allempProject for tagproject's of all emp_details-------------------- */
const fetchStudentEmpList = () => {
  const requestBody = {
    email: userEmail,
  };

  fetch(`${baseURL}/getAllStudentEmployment`, {
    method  : 'POST',
    headers : { 'Content-Type': 'application/json', },
    body    : JSON.stringify(requestBody),
  })
    .then((response) => response.json())
    .then((data) => {
      const companyNames = data.map(item => item.currentCompanyname);
      setEmpList(companyNames || "");
      // console.log(companyNames);
    })
    .catch((error) => { console.error('Error fetching Emp_details:', error);  });
};

useEffect(() => { fetchStudentEmpList(); }, []);

    /*-----------------------save edit api starts-----------------*/
const saveEditedItem = () => {

  const updates = {
    id        : editItemID,
    email     : userEmail,

        projectTitle    : editData.projectTitle,                                  client          : editData.client,
        projectTag      : editData.projectTag?editData.projectTag.value:'',                                    projectStatus   : editData.projectStatus,
        projectFromYear : selectedWorkedFromYear?selectedWorkedFromYear.value:'', projectFromMonth: selectedWorkedFromMonth?selectedWorkedFromMonth.value:'',
        projectTillYear : selectedWorkedTillYear?selectedWorkedTillYear.value:'', projectTillMonth: selectedWorkedTillMonth?selectedWorkedTillMonth.value:'',
        projectdetails  : editData.projectdetails,                                projectLocation : editData.projectLocation,
        projectSite     : editData.projectSite,                                   empNature       : editData.empNature,
        teamSize        : selectedTeamSize?selectedTeamSize.value:'',             roleDescription : editData.roleDescription
  
      };
                  

      fetch(`${baseURL}/updateStudentProject/${editItemID}`, {
      method: 'POST',
      headers: {
              'Content-Type': 'application/json',
              },
      body: JSON.stringify(updates),
    })
    .then((response) => {
      if (response.ok) {
          const updatedList = empList.map((item) =>
          item.id === editItemID.id ? { ...item, ...editData } : item
          );
          setEmpList(updatedList);
          setEditModalStatus(false);
          console.log('Item updated successfully');
      } else {
          console.error('Failed to update item');
          }
      })
    .catch((error) => {
        console.error('Item updating error:', error);
        });
    };
/*----------------------------ITEM DELETE------------------------------- */
const handleItemDelete = (itemId) => {
  const updatedEmpList = empList.filter(item => item.id !== itemId);
  setEmpList(updatedEmpList);

  fetch(`${baseURL}/deleteStudentEmployment/${itemId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.ok) {
        console.log('Item deleted successfully');
      } else {
        console.error('Failed to delete item');
      }
    })
    .catch(error => {
      console.error('Item deleting user:', error);
    });
};
/*----------------------------ITEM DELETE ends------------------------------ */

const monthsOptions = [
  { value: 'Jan', label: 'Jan' }, { value: 'Feb', label: 'Feb'  },   { value: 'Mar', label: 'Mar' },  { value: 'Apr', label: 'Apr' },
  { value: 'May', label: 'May' }, { value: 'Jun', label: 'Jun'  },   { value: 'Jul', label: 'Jul' },  { value: 'Aug', label: 'Aug' },
  { value: 'Sep', label: 'Sep' }, { value: 'Oct', label: 'Oct'  },   { value: 'Nov', label: 'Nov' },  { value: 'Dec', label: 'Dec' },
  ]
  const yearOptions=[
    { value: currentYear - 0, label: currentYear - 0 },
    { value: currentYear - 1, label: currentYear - 1 },
    { value: currentYear - 2, label: currentYear - 2 },
    { value: currentYear - 3, label: currentYear - 3 },
    { value: currentYear - 4, label: currentYear - 4 },
    { value: currentYear - 5, label: currentYear - 5 },
    { value: currentYear - 6, label: currentYear - 6 },
    { value: currentYear - 7, label: currentYear - 7 },
    { value: currentYear - 8, label: currentYear - 8 },
    { value: currentYear - 9, label: currentYear - 9 },
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {  setCurrentDate(new Date()); }, 1000); // Update the date every second
                                            // console.log('current date:='+currentDate.getFullYear());
                                            setCurrentYear(currentDate.getFullYear());
    return () => { clearInterval(intervalId); };
  }, []);

  const teamSizeOptions=[
    { value: '1', label: '1'  },      { value: '2', label: '2'  },
    { value: '3', label: '3'  },      { value: '4', label: '4'  },
    { value: '5', label: '5'  },      { value: '6', label: '6'  },
  ];

  const projectOptions= empList.map(companyName => ({
    value: companyName, // Use an appropriate value for each option
    label: companyName, // Use the company name as the label
  }));



  return (
    <div>
        <MDBRow>
          <MDBCol>
            <h5 style={{color:'black'}}> Projects </h5>
          </MDBCol>

          <MDBCol style={{ textAlign:'right'}}>
            <h5 onClick={toggleAddModal} 
            className='blinkText'
            style={{color:'blue', fontSize:15, cursor:'pointer' }}>Add Projects</h5>
          </MDBCol>
        </MDBRow>

    {/*---------Mapping starts-------- */ }
    <div>
      {projectList.map(data=>(
          <div key={data.id}>

            <span style={{color:'black'}}>{data.projectTitle}</span>        <i onClick={() => toggleEditModal(data.id)} className="fas fa-pencil" style={{paddingBottom:'8px', fontSize:12, cursor:'pointer'}} />&nbsp;
                                                          <br></br>
            <span>{data.client}</span><br></br>
            <span>{data.projectFromMonth} {data.projectFromYear} to {data.projectTillMonth} {data.projectTillYear} 
            </span><br></br>
            <span>{data.projectdetails}</span>
            <br/><br/>


          </div>
      ))}
    </div>
    {/*---------Mapping Ends-------- */ }



                  {/* (2.1)------------------>---Add Modal code starts */}
                    <MDBModal tabIndex='-1' show={addModalStatus} setShow={setAddModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
                        <MDBModalContent >
      
                             <Stscroll8ProjectsAddModal togglingModal={toggleAddModal}/> 
                               
                        </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    {/*(2.1)--------->-------------Add Modal code ends */}

                    {/* (2.1)------------------>---Add Modal code starts */}
                    <MDBModal tabIndex='-1' show={editModalStatus} setShow={setEditModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
                        <MDBModalContent >
      
                       <div className='profileModal'>
                        {/* <h3>Edit Projects</h3> */}
                        <MDBRow>

                            <MDBCol>
                            <span style={{fontSize:24}}>Edit Projects</span>
                            </MDBCol>

                            <MDBCol style={{textAlign:'right'}}>
                            <span onClick={() => handleItemDelete(editItemID)} className="fas fa-trash-can red-trash-icon">&nbsp;Delete</span>
                            </MDBCol>

                            </MDBRow>
                        <MDBRow>
                          <label htmlFor="exampleFormControlSelect2" >Project title</label><br/>
                          <input type="text"
                                className="form-control"
                                style={{width:'95%', marginLeft:'15px'}}
                                id="formGroupExampleInput1" placeholder='Type Your Project title'
                                value={ editData.projectTitle || ""}
                                onChange={e=>setEditData({...editData, projectTitle:e.target.value})} 
                                />       
                        </MDBRow><br/>

                        <MDBRow>
                        <label htmlFor="exampleFormControlSelect2" >Tag this Project with Employment/Education ?</label><br></br>
                              <MDBRow >
                                    <Select
                                      value={editData.projectTag || ""}//selectedOptionYear
                                      options={projectOptions || ""}
                                      onInputChange={(inputValue) => inputValue}
                                      onChange={(selectedOption) => setEditData({ ...editData, projectTag: selectedOption })}
                                      isClearable={true}// type to filter
                                      placeholder="Select Employment/Education"
                                    />
                              </MDBRow>
                        </MDBRow><br/>

                        <MDBRow>
                          <label htmlFor="exampleFormControlSelect2" >Client</label><br/>
                          <input type="text"
                                className="form-control"
                                style={{width:'95%', marginLeft:'15px'}}
                                id="formGroupExampleInput1" placeholder='Type Your Client Details'
                                value={ editData.client || ""}
                                onChange={e=>setEditData({...editData, client:e.target.value})}
                                />       
                        </MDBRow><br/>

                        <MDBRow>
                          <label htmlFor="exampleFormControlSelect2" >Project Status</label><br></br>
                          <MDBCol>
                            <MDBRadio name='projectStatus' id='inlineRadio1' value='In-Progress' label='In-Progress' inline checked={editData.projectStatus === 'In-Progress'} onChange={e=>setEditData({...editData, projectStatus:'In-Progress'})} />
                            <MDBRadio name='projectStatus' id='inlineRadio2' value='Finished' label='Finished' inline checked={editData.projectStatus === 'Finished'} onChange={e=>setEditData({...editData, projectStatus:'Finished'})} />
                          </MDBCol>
                        </MDBRow><br></br>

                        {/* worked From */}
                        <MDBRow>
                        <label htmlFor="exampleFormControlSelect2" >Worked From</label><br></br>
                        <MDBRow >
                                  <MDBCol>
                                    <Select
                                      value={selectedWorkedFromYear || ""}//selectedOptionYear
                                      options={yearOptions || ""}
                                      onInputChange={(inputValue) => inputValue}
                                      onChange={(selectedOptions) => setSelectedWorkedFromYear(selectedOptions)}
                                      isClearable={true}// type to filter
                                      placeholder="Select Year"
                                    />
                                  </MDBCol>
                                  <MDBCol >
                                    <Select
                                      value={selectedWorkedFromMonth || ""}
                                      options={monthsOptions || ""}
                                      onInputChange={(inputValue) => inputValue}
                                      onChange={(selectedOptions) => setSelectedWorkedFromMonth(selectedOptions)}
                                      isClearable={true}
                                      placeholder="Select Month"
                                    />
                                  </MDBCol>
                                </MDBRow><br></br>
                        </MDBRow><br/>

                        {/* worked Till */}
                        {(editData.projectStatus==='Finished')?<>
                        <MDBRow>
                              <label htmlFor="exampleFormControlSelect2" >Worked Till</label><br></br>
                              <MDBRow >
                                        <MDBCol>
                                          <Select
                                            value={selectedWorkedTillYear || ""}//selectedOptionYear
                                            options={yearOptions || ""}
                                            onInputChange={(inputValue) => inputValue}
                                            onChange={(selectedOptions) => setSelectedWorkedTillYear(selectedOptions)}
                                            isClearable={true}// type to filter
                                            placeholder="Select Year"
                                          />
                                        </MDBCol>
                                        <MDBCol >
                                          <Select
                                            value={selectedWorkedTillMonth || ""}
                                            options={monthsOptions || ""}
                                            onInputChange={(inputValue) => inputValue}
                                            onChange={(selectedOptions) => setSelectedWorkedTilMonth(selectedOptions)}
                                            isClearable={true}
                                            placeholder="Select Month"
                                          />
                                        </MDBCol>
                                      </MDBRow><br/>
                              </MDBRow><br/>
                            </>:null}

                        {/* Details of Project */}
                        <MDBRow>
                          <label htmlFor="exampleFormControlSelect2" >Details of Project</label><br/>
                          <input type="text"
                                className="form-control"
                                style={{width:'95%', marginLeft:'15px'}}
                                id="formGroupExampleInput1" placeholder='Type Your Project Details'
                                value={ editData.projectdetails || ""}
                                onChange={e=>setEditData({...editData, projectdetails:e.target.value})}
                                />       
                        </MDBRow><br/>

                        {(showMore !== 'yes')?(<>
                        <MDBRow>
                        <label htmlFor="exampleFormControlSelect2" onClick={e=>setShowMore('yes')} style={{color:'blue'}}>Add More Details</label><br></br>
                        </MDBRow><br/>
                        </>):null}

                        {(showMore === 'yes')?(<>
                            <MDBRow>
                              <label htmlFor="exampleFormControlSelect2" >Project Location</label><br/>
                              <input type="text"
                                    className="form-control"
                                    style={{width:'95%', marginLeft:'15px'}}
                                    id="formGroupExampleInput1" placeholder='Type Your Project Details'
                                    value={ editData.projectLocation || ""}
                                    onChange={e=>setEditData({...editData, projectLocation:e.target.value})} 
                                    />        
                            </MDBRow><br/>
                            

                            <MDBRow>
                              <label htmlFor="exampleFormControlSelect2" >Project Site</label><br></br>
                              <MDBCol>
                                <MDBRadio name='projectSite' id='inlineRadio1' value='Offsite' label='Offsite' inline checked={editData.projectSite === 'Offsite'} onChange={() => setEditData({ ...editData, projectSite:'Offsite'})} />
                                <MDBRadio name='projectSite' id='inlineRadio2' value='Onsite' label='Onsite' inline checked={editData.projectSite === 'Onsite'} onChange={() => setEditData({ ...editData, projectSite:'Onsite'})} />
                              </MDBCol>
                            </MDBRow><br></br>

                            <MDBRow>
                              <label htmlFor="exampleFormControlSelect2" >Nature of the Employment</label><br></br>
                              <MDBCol>
                                <MDBRadio name='empNature' id='inlineRadio1' value='Full time' label='Full time' inline checked={editData.empNature === 'Full time'} onChange={() => setEditData({...editData, empNature:'Full time'})} />
                                <MDBRadio name='empNature' id='inlineRadio2' value='Part time' label='Part time' inline checked={editData.empNature === 'Part time'} onChange={() => setEditData({...editData, empNature:'Part time'})} />
                                <MDBRadio name='empNature' id='inlineRadio3' value='Contractual' label='Contractual' inline checked={editData.empNature === 'Contractual'} onChange={() => setEditData({...editData, empNature:'Contractual'})} />
                              </MDBCol>
                            </MDBRow><br></br>

                            <MDBRow>
                            <label htmlFor="exampleFormControlSelect2" >Team Size</label><br></br>
                                  <MDBRow >
                                        <Select
                                          value={selectedTeamSize}//selectedOptionYear
                                          options={teamSizeOptions}
                                          onInputChange={(inputValue) => inputValue}
                                          onChange={(selectedOptions) => setSelectedTeamSize(selectedOptions)}
                                          isClearable={true}// type to filter
                                          placeholder="Select Team size"
                                        />
                                  </MDBRow>
                            </MDBRow><br/>

                            <MDBRow>
                                  <MDBCol style={{ width:'800px'}}>
                                      <label htmlFor="exampleFormControlSelect1" >Role Description</label>
                                      <MDBTextArea
                                          id='textAreaExample'
                                          rows={2}
                                          value={ editData.roleDescription || ""}
                                          onChange={e=>setEditData({...editData, roleDescription:e.target.value})} 
                                          />
                                    
                                    </MDBCol>
                                </MDBRow><br></br>

                            </>):null}
                          
                                  <br></br>
                            <button type="button" className="dropbtnprofilepagesave"    onClick={saveEditedItem}> Save   </button>&nbsp;
                            <button type="button" className="dropbtnprofilepagecancel2" onClick={() => setEditModalStatus(false)}>   Cancel </button>&nbsp;
                                
                      </div>
    
                               
                        </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    {/*(2.1)--------->-------------Add Modal code ends */}
   
    </div>
  )
}

export default Stscroll8Projects
