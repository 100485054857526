import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBBadge,
  MDBTable,
  MDBTableBody,
} from "mdb-react-ui-kit";
import Contact from "../More/Contact";

function ST7Content() {
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);
  
  
  
  return (
    <>
     <Helmet>
        <title>Curriculum | ST7</title>
        <meta
          name="description"
          content="Explore curriculum covering Java, Selenium, Agile, Scrum, DevOps, and more. Hands-on practice and projects included."
        />
        <meta
          name="keywords"
          content="Curriculum,  Java, Selenium, Agile, Scrum, DevOps, Training, Contact-TPA bangalore"
        />
      </Helmet>
    <MDBContainer
      fluid
      className="p-1 rounded-7 fullstackBody mt-5 text-align-center"
      id="course-content"
      style={{ backgroundColor: " rgb(237 213 213)" }}
    >
      <MDBRow>
        <MDBCol md="2"></MDBCol>
        <MDBCol md="8">
          <MDBCardBody>
            <MDBTypography variant="h2 text-light mt-1">
              Curriculum
            </MDBTypography>

            <MDBTable hover>
              <MDBTableBody>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Course Phase 1: JAVA
                </p>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      JAVA Data types, Type Casting, Operators, Strings,
                      Boolean, While loop, for loop and Arrays.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      JAVA Methods, Method Parameters,Method overloading, Scope
                      and Recursion.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      OOPS Concepts, constructor, Modifier, Polymorphisim, Harsh
                      Map , Harsh Set, Abstraction, Threads, Lambda.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      File Handling, Create/Write File, Read Files, Write Files.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      How to add two numbers, Count wors, Reverse String.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <br/>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Course Phase 2 -Selenium
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Basic Terminology, Features, Limitations, Tool suits.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Selenium IDE, IDE - Installation, IDE- First Test case,
                      IDE-Login Test,IDE-Creating Test, IDE-Locating Strategies.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Selenium Web Driver, WebDriver- Drag and Drop,
                      WebDriver-Handling Alerts,
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Selenium Python, Selenium C#, Selenium Maven, Selenium
                      Waits.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Course Phase 3-Agile
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      What is Agile Model?
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Manifesto Principles of Agile Model.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Benifits of Agile Methodology.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4 ">
                  Course Phase-4 : Scrum
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Scrum Frameworks, Roles, Scrum Masters.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Scrum- Events, Artificats, Burn - Down charts.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Scrum Tools, Scrum Benifits.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4 ">
                  Course Phase-4 : DevOps
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      DevOps Architecture, Life cyles, Workflow and Principles
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      DevOps Tools, DevOps Automation, Engineeers.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      DevOps Pipeline and Methodology, Azure DevOps, AWS DevOps.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCol>
        <MDBCol md="2"></MDBCol>
      </MDBRow>
      <MDBBtn
        href="#catiatraining"
        outline
        color="light"
        className="mb-4 mt-3 w-10 fw-bold tpa-info-logo"
        size="lg"
        onClick={toggleShow}
      >
        <MDBIcon fas icon="download pe-2" />
        Download
      </MDBBtn>

      <MDBModal
        tabIndex="-1"
        show={fullscreenXlModal}
        setShow={setFullscreenXlModal}
      >
        <MDBModalDialog size="fullscreen-xl-down" style={{ color: "black" }}>
          <MDBModalContent>
            <MDBModalHeader>
              {/* <MDBModalTitle>Book a time slot with candidate.</MDBModalTitle> */}
              <MDBBtn
                type="button"
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Contact />
            </MDBModalBody>
 
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
    </>
  );
};

export default ST7Content;
