import React, { useState, useEffect } from 'react';
import { MDBCol, MDBRow , MDBRadio} from 'mdb-react-ui-kit'
import Select from 'react-select';
import { baseURL } from '../../../http';


function Stscroll6EducationAddModal({ togglingModal }) {
    
    const userInfo = JSON.parse(localStorage.getItem('user-info'));
    const userEmail = userInfo.email;

    const [selectedCourseStartYear, setSelectedCourseStartYear]                 = useState(null);
    const [selectedCourseEndYear, setSelectedCourseEndYear]                     = useState(null);
    const [selectedEducation, setSelectedEducation]                             = useState(null);
    const [selectedCourse, setSelectedCourse]                                   = useState(null);
    const [selectedOptionSpecialization, setSelectedOptionSpecialization]       = useState(null);
    const [University, setUniversity]                                           = useState(null);
    const [courseType, setCourseType]                                           = useState(null);
    
    const [currentYear, setCurrentYear]                                              = useState(null);
    const [currentDate, setCurrentDate]                                              = useState(new Date());

    const addEducDetails=()=>{
      const sendData={
                    email         : userEmail,
                    education     : selectedEducation?selectedEducation.value:'',
                    university    : University,
                    course        : selectedCourse?selectedCourse.value:'',
                    specialization: selectedOptionSpecialization?selectedOptionSpecialization.value:'',
                    courseType    : courseType,
                    courseStart   : selectedCourseStartYear?selectedCourseStartYear.value:'',
                    courseEnd     : selectedCourseEndYear?selectedCourseEndYear.value:'',
                }
                fetch(`${baseURL}/createStudentEducation`, {
                  method: 'POST',
                  body: JSON.stringify(sendData),
                  headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                  },
                })
                  .then((response) => {
                    if (response.ok) {
                       togglingModal();
                      console.log('FE- Education_detail added successfully');
                    } else {
                      console.error('inside API error ');
                    }
                  })
                  .catch((error) => {
                    console.error('API call error-outside:', error);
                  });
          
              }
              
  const courseYearOptions=[
    { value: currentYear - 0, label: currentYear - 0 },
    { value: currentYear - 1, label: currentYear - 1 },
    { value: currentYear - 2, label: currentYear - 2 },
    { value: currentYear - 3, label: currentYear - 3 },
    { value: currentYear - 4, label: currentYear - 4 },
    { value: currentYear - 5, label: currentYear - 5 },
    { value: currentYear - 6, label: currentYear - 6 },
    { value: currentYear - 7, label: currentYear - 7 },
    { value: currentYear - 8, label: currentYear - 8 },
    { value: currentYear - 9, label: currentYear - 9 },
  ]

  useEffect(() => {
                const intervalId = setInterval(() => {  setCurrentDate(new Date()); }, 1000); // Update the date every second
                                                        // console.log('current date:='+currentDate.getFullYear());
                                                        setCurrentYear(currentDate.getFullYear());
                return () => { clearInterval(intervalId); };
              }, []);


 

  const educationOptions=[
    { value: 'Doctorate/PhD',               label: 'Doctorate/PhD'  },
    { value: 'Masters/Post-Graduation',     label: 'Masters/Post-Graduation'  },
    { value: 'Graduation/Diploma',          label: 'Graduation/Diploma'  },
  ]

  const courseOptions=[
    { value: 'B.E',               label: 'B.E'  },
    { value: 'M.E',               label: 'M.E'  },

  ]

  const specializationOptions=[
    { value: '1',               label: '1'  },
    { value: '2',               label: '2'  },
    { value: '3',               label: '3'  },
    { value: '4',               label: '4'  },
  ]

    return (
    <div className='profileModal'>
      <h3>Add Education</h3>
      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" >Education</label><br></br>
                <Select
                    value={selectedEducation}//selectedOptionYear//usestate var declaration
                    options={educationOptions}                   // dropdown options
                    onInputChange={(inputValue) => inputValue}            // constant
                    onChange={(selectedOptions) => setSelectedEducation(selectedOptions)}                  //selecting or joining to  usestate
                    isClearable={true}// type to filter
                    placeholder="Select"
                />
      </MDBRow><br></br>

      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" >University/Institute</label><br></br>
            <input type="text"
              className="form-control" 
              style={{width:'100%', marginLeft:'15px',maxWidth:'770px'}}
              id="formGroupExampleInput1" placeholder='Type Your University/Institute'
              value={ University || ""}
              onChange={e=>setUniversity(e.target.value)} 
              />
      </MDBRow><br></br>

      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" >Course</label><br></br>
                <Select
                    value={selectedCourse}//selectedOptionYear
                    options={courseOptions}
                    onInputChange={(inputValue) => inputValue}
                    onChange={(selectedOptions) => setSelectedCourse(selectedOptions)}
                    isClearable={true}// type to filter
                    placeholder="Select"
                />
      </MDBRow><br></br>

      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" >Specialization</label><br></br>
                <Select
                    value={selectedOptionSpecialization}//selectedOptionYear
                    options={specializationOptions}
                    onInputChange={(inputValue) => inputValue}
                    onChange={(selectedOptions) => setSelectedOptionSpecialization(selectedOptions)}
                    isClearable={true}// type to filter
                    placeholder="Select"
                />
      </MDBRow><br></br>


      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" >Course Type</label><br></br>
               
        <br></br>
          <MDBCol>
          <MDBRadio name='courseType' id='inlineRadio1' value='Full-Time' label='Full-Time' inline checked={courseType === 'Full-Time'} onChange={() => setCourseType('Full-Time')} />
          <MDBRadio name='courseType' id='inlineRadio2' value='Part-Time' label='Part-Time' inline checked={courseType === 'Part-Time'} onChange={() => setCourseType('Part-Time')} />
          <MDBRadio name='courseType' id='inlineRadio3' value='Correspondance/Distance' label='Correspondance/Distance' inline checked={courseType === 'Correspondance/Distance'} onChange={() => setCourseType('Correspondance/Distance')} />
           
          </MDBCol>


      </MDBRow><br></br>

      <MDBRow>
      <label htmlFor="exampleFormControlSelect2" >Course Duration</label><br></br>
      <MDBRow >
                <MDBCol>
                  <Select
                     value={selectedCourseStartYear}//selectedOptionYear
                     options={courseYearOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedCourseStartYear(selectedOptions)}
                     isClearable={true}// type to filter
                     placeholder="Start Year"
                   />
                 </MDBCol>
                 <MDBCol >
                   <Select
                     value={selectedCourseEndYear}
                     options={courseYearOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedCourseEndYear(selectedOptions)}
                     isClearable={true}
                     placeholder="End Year"
                  />
                </MDBCol>
              </MDBRow><br></br>
      </MDBRow>

                <br></br>
          <button type="button" className="dropbtnprofilepagesave"    onClick={addEducDetails}> Save   </button>&nbsp;
          <button type="button" className="dropbtnprofilepagecancel2" onClick={togglingModal}>   Cancel </button>&nbsp;
              
    </div>
  )
}

export default Stscroll6EducationAddModal
