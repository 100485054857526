import React from 'react'
import { useNavigate } from 'react-router-dom';
import {
    MDBContainer,
    MDBNavbar,
    MDBBtn,
   
  } from "mdb-react-ui-kit";

function StudentHeader() {
    const userInfo                    = JSON.parse(localStorage.getItem('user-info'));
    const userName                    = userInfo.name;
    const userEmail                   = userInfo.email;
    const navigate                    = useNavigate();

    const handleLogout = () => 
            {   
                localStorage.clear(); 
                navigate('/');
                window.location.reload();
             };

  return (
    <div>
      <div>
        <MDBNavbar >
            <MDBContainer fluid className='fixed-navbar-top' >
                <h3 className=''>Student Dashboard</h3>

                <div className='d-flex justify-content-between align-items-center'>
                      <h5>{userName}&nbsp;</h5>
                      
                      <button color='info' onClick={handleLogout} className="btnsignup ml-auto">
                              Logout
                      </button>
                </div>

            </MDBContainer>
        </MDBNavbar>
    </div>
     
    </div>
  );
}


export default StudentHeader
