import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
  MDBTypography,
  MDBIcon,
  MDBCardBody,
} from "mdb-react-ui-kit";

import CAE3Curriculum1 from "./CAE3Curriculum1";
import CAE4Description from "./CAE4Description";
import CAE5CourseHighlights from "./CAE5CourseHighlights";
import CAE7Content from "./CAE7Content";
import { Helmet } from "react-helmet";

function CAE1ProductDesign() {
  return (
    <>
      <div>
        <Helmet>
          <title>Computer Aided Engineering - TPA</title>
          <meta
            name="discription"
            content="A comprehensive program that covers both the basics and advanced concepts in computer-aided engineering in bangalore. It also trains you in the application of different ..."
          />
        </Helmet>
      </div>
      <MDBContainer fluid className="p-1 rounded-7 fullstackBody ">
        <figure className="text-start text-light mb-0 mx-4">
          <MDBTypography variant="h1">
            Job Ready – Computer Aided Engineering
          </MDBTypography>

          <p class="text-light mb-0">
            <MDBIcon far icon="bookmark pe-2" />
            Category: MECHANICAL PROGRAMS /CAE1ProductDesign{" "}
            <span class="fw-bold">6</span>
          </p>
        </figure>

        <MDBRow>
          <MDBCol md="8">
            <MDBCardBody>
              <div className="bg-image hover-zoom">
                <img
                  style={{ width: "100%", maxHeight: "500px" }}
                  alt="car"
                  src="https://hmie.in/images/more/1.jpg"
                  className="round"
                />
              </div>
            </MDBCardBody>
          </MDBCol>
          <MDBCol start md="4">
            <MDBCardBody className="mechanical-body tpa-list-group">
              <MDBListGroup light>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="users pe-3" size="2x" />
                  <span>80 students</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item "
                >
                  <MDBIcon far icon="clock pe-4" size="2x" />
                  <span>Duration:180 hours</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="chalkboard-teacher pe-3" size="2x" />
                  <span> Lectures:85</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="book pe-4" size="2x" />
                  <span>Course completion</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item-google"
                >
                  <MDBIcon fab icon="google pe-4" size="2x" />
                  <span className="text-dark fs-4 fw-bold pe-2">4.6</span>
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star-half-alt" />
                </MDBListGroupItem>

                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="hand-point-right pe-4" size="2x" />
                  <span>Enroll Now</span>
                </MDBListGroupItem>
              </MDBListGroup>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    
      <CAE3Curriculum1 />
      <CAE4Description />
      <CAE5CourseHighlights />
      <CAE7Content />

    </>
  );
}

export default CAE1ProductDesign;
