import React, { useState, useEffect } from 'react'
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBModalFooter, MDBContainer } from 'mdb-react-ui-kit';
import { MDBTable, MDBTableHead, MDBTableBody, MDBRow, MDBCol, MDBRadio, MDBBtn, MDBTextArea } from 'mdb-react-ui-kit';
import { baseURL } from '../../../http';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Stscroll5EmploymentAddModal from './Stscroll5EmploymentAddModal';
const Swal = require("sweetalert2");



function Stscroll5Employment() {
  
  const [empList, setEmpList]                                                      = useState([]); // from DB
                                
  const userInfo                                                                   = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                                                                  = userInfo.email;
  const [addModalStatus, setAddModalStatus]                                        = useState(false);
  const [editModalStatus, setEditModalStatus]                                      = useState(false);
  const [keySkillOptions, setKeySkillOptions]                                      = useState([]);
  const [selectedOptionsKeySkills, setSelectedOptionsKeySkills]                    = useState(null); 
  const [selectedWorkingFromYear, setSelectedWorkingFromYear]                      = useState(null); 
  const [selectedWorkingFromMonth, setSelectedWorkingFromMonth]                    = useState(null); 
  const [selectedFullexpYears, setSelectedFullexpYears]                            = useState(null); 
  const [selectedFullexpMonths, setSelectedFullexpMonths]                          = useState(null); 
  const [selectedInternshipworkingFromYear, setSelectedInternshipworkingFromYear]  = useState(null); 
  const [selectedInternshipworkingFromMonth, setSelectedInternshipworkingFromMonth]= useState(null); 
  const [selectedWorkedTillYear, setSelectedWorkedTillYear]                        = useState(null); 
  const [selectedWorkedTillMonth, setselectedWorkedTillMonth]                      = useState(null); 
  
  const [currentYear, setCurrentYear]                                              = useState(null);
  const [currentDate, setCurrentDate]                                              = useState(new Date());

  const [editItemID, setEditItemID]                                                = useState(null);
  const [editData, setEditData]                   = useState({
                                                                curEmp:'',                        empType: '',
                                                                fullExpYears: '',                 fullExpMonths: '',
                                                                currentCompanyname: '',           currentDesignation: '',
                                                                internshipLocation:'',            internshipWorkingFromYear:'',
                                                                internshipWorkingFromMonth:'',    monthlyStifend:'',
                                                                workedFromYear:'',                workedFromMonth:'',
                                                                workedTillYear:'',                workedTillMonth:'',
                                                                jobProfile:'',                    monthlyStipend:'',
                                                                monthlyStipendCurrency:''

                                                            }); 
  function toggleAddModal() {  setAddModalStatus(!addModalStatus);  }

  const toggleEditModal = (itemID) => {
    const selectedItem = empList.find(item => item.id === itemID);

    if (selectedItem) {
      setEditItemID(itemID);
      setEditData({ 
        curEmp:selectedItem.curEmp,                                            empType: selectedItem.empType,
        fullExpYears: selectedItem.fullExpYears,                               fullExpMonths: selectedItem.fullExpMonths,
        currentCompanyname: selectedItem.currentCompanyname,                   currentDesignation: selectedItem.currentDesignation,
        internshipLocation:selectedItem.internshipLocation,                    internshipWorkingFromYear:selectedItem.internshipWorkingFromYear,
        internshipWorkingFromMonth:selectedItem.internshipWorkingFromMonth,    monthlyStifend:selectedItem.monthlyStifend,
        workedFromYear:selectedItem.workedFromYear,                            workedFromMonth:selectedItem.workedFromMonth,
        workedTillYear:selectedItem.workedTillYear,                            workedTillMonth:selectedItem.workedTillMonth,
        jobProfile:selectedItem.jobProfile,                                    monthlyStipend:selectedItem.monthlyStipend,
        monthlyStipendCurrency:selectedItem.monthlyStipendCurrency
      });

    setEditModalStatus(!editModalStatus); // require to open the modal

  };
}


useEffect(() => { fetchStudentEmpList(); }, []);
  /*--------------------Fetching Student's of all emp_details-------------------- */
  const fetchStudentEmpList = () => {
    const requestBody = {
      email: userEmail,
    };

    fetch(`${baseURL}/getAllStudentEmployment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setEmpList(data || "");
      })
      .catch((error) => {
        console.error('Error fetching Emp_details:', error);
      });
  };

  // useEffect(() => {    fetchStudentEmpList();  }, []);

/*----------------------------ITEM DELETE------------------------------- */
const handleItemDelete = (itemId) => {
  const updatedEmpList = empList.filter(item => item.id !== itemId);
  setEmpList(updatedEmpList);

  fetch(`${baseURL}/deleteStudentEmployment/${itemId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.ok) {
        console.log( " Item added ");
        alert('Item Deleted Successfully');
        setEditModalStatus(false);
        window.location.reload();
      } else {
        alert('Failed to delete item');
        // console.error('Failed to delete item');
      }
    })
    .catch(error => {
        alert('Failed at server side');
        console.error('Item deleting user:', error);
    });
};

useEffect(() => { fetchKeySkillsList(); }, []);

/*-------------------Getting ------*/
const fetchKeySkillsList = () => {
  fetch(`${baseURL}/getAllKeySkills`)
    .then(response => response.json())
    .then(data => {
      const options = data.map(skill => ({
        value: skill.skillName,                      
        label: skill.skillName,             
      }));
      setKeySkillOptions(options || "");
    })
    .catch(error => {
      console.error('Error fetching key skills list:', error);
    });
};

/*-----------------------save edit api starts-----------------*/
const saveEditedItem = () => {

  const updates = {
    id        : editItemID,
    email     : userEmail,

    curEmp                    : editData.curEmp,                       empType                   : editData.empType,
    fullExpYears              : editData.fullExpYears,                 fullExpMonths             : editData.fullExpMonths,
    currentCompanyname        : editData.currentCompanyname,           currentDesignation        : editData.currentDesignation,
    internshipLocation        : editData.internshipLocation,           internshipWorkingFromYear : editData.internshipWorkingFromYear,
    internshipWorkingFromMonth: editData.internshipWorkingFromMonth,   monthlyStifend            : editData.monthlyStifend,
    workedFromYear            : editData.workedFromYear,               workedFromMonth           : editData.workedFromMonth,
    workedTillYear            : editData.workedTillYear,               workedTillMonth           : editData.workedTillMonth,
    jobProfile                : editData.jobProfile,                   monthlyStipend            : editData.monthlyStipend,
    monthlyStipendCurrency    : editData.monthlyStipendCurrency

      };
                  

      fetch(`${baseURL}/updateStudentEmployment/${editItemID}`, {
      method: 'POST',
      headers: {
              'Content-Type': 'application/json',
              },
      body: JSON.stringify(updates),
    })
    .then((response) => {
      if (response.ok) {
          const updatedList = empList.map((item) =>
          item.id === editItemID.id ? { ...item, ...editData } : item
          );
          setEmpList(updatedList);
          alert("Item Edited Successfully");   
          setEditModalStatus(false);
          console.log('Item updated successfully');
      } else {
          console.error('Failed to update item');
          }
      })
    .catch((error) => {
        console.error('Item updating error:', error);
        });
    };
/*-----------------------save edit api ends-----------------*/

const expYearOptions = [                              // in options-2
    { value: '1 Year', label: '1 Year'     },
    { value: '2 Years', label: '2 Years'   },
    { value: '3 Years', label: '3 Years'   },
    { value: '4 Years', label: '4 Years'   },
    { value: '5 Years', label: '5 Years'   },
    { value: '6 Years', label: '6 Years'   },
    { value: '7 Years', label: '7 Years'   },
    { value: '8 Years', label: '8 Years'   },
    { value: '9 Years', label: '9 Years'   },
    { value: '10 Years', label: '10 Years' },
  ];
  
  const expMonthsOptions = [
    { value: '1  Month', label: ' 1  Month'  },
    { value: '2  Months', label: '2  Months' },
    { value: '3  Months', label: '3  Months' },
    { value: '4  Months', label: '4  Months' },
    { value: '5  Months', label: '5  Months' },
    { value: '6  Months', label: '6  Months' },
    { value: '7  Months', label: '7  Months' },
    { value: '8  Months', label: '8  Months' },
    { value: '9  Months', label: '9  Months' },
    { value: '10 Months', label: '10 Months' },
  ];
  
  const workingFromMonthOptions=[
    { value: 'Jan', label: 'Jan'     },
    { value: 'Feb', label: 'Feb'     },
    { value: 'Mar', label: 'Mar'     },
    { value: 'Apr', label: 'Apr'     },
    { value: 'May', label: 'May'     },
    { value: 'Jun', label: 'Jun'     },
    { value: 'Jul', label: 'Jul'     },
    { value: 'Aug', label: 'Aug'     },
    { value: 'Sep', label: 'Sep'     },
    { value: 'Oct', label: 'Oct'     },
    { value: 'Nov', label: 'Nov'     },
    { value: 'Dec', label: 'Dec'     },
  ]

  const joiningDateYearOptions=[
    { value: currentYear - 0, label: currentYear - 0 },
    { value: currentYear - 1, label: currentYear - 1 },
    { value: currentYear - 2, label: currentYear - 2 },
    { value: currentYear - 3, label: currentYear - 3 },
    { value: currentYear - 4, label: currentYear - 4 },
    { value: currentYear - 5, label: currentYear - 5 },
    { value: currentYear - 6, label: currentYear - 6 },
    { value: currentYear - 7, label: currentYear - 7 },
    { value: currentYear - 8, label: currentYear - 8 },
    { value: currentYear - 9, label: currentYear - 9 },
  ]

  useEffect(() => {
                const intervalId = setInterval(() => {  setCurrentDate(new Date()); }, 1000); // Update the date every second
                                                        // console.log('current date:='+currentDate.getFullYear());
                                                        setCurrentYear(currentDate.getFullYear());
                return () => { clearInterval(intervalId); };
              }, []);


  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '20px',
      backgroundColor: '#007BFF',
      color: 'white',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: 'transparent',
        color: 'white',
      },
    }),
  };

  return (
    <div >
      <MDBRow>
          <MDBCol>
            <h5 style={{color:'black'}}> Employment </h5>
          </MDBCol>

          <MDBCol style={{ textAlign:'right'}}>
            <h5 onClick={toggleAddModal} 
            className='animate1'
            style={{color:'blue', fontSize:15, cursor:'pointer' }}>Add Employment</h5>
          </MDBCol>
        </MDBRow>

    {/*---------Mapping starts-------- */ }
    <div>
      {empList.map(data=>(
          <div key={data.id}>

            <span>{data.currentDesignation}</span>        <i onClick={() => toggleEditModal(data.id)} className="fas fa-pencil" style={{paddingBottom:'8px', fontSize:12, cursor:'pointer'}} />&nbsp;
                                                          {/* <i onClick={() => handleItemDelete(data.id)} className="fas fa-trash-can red-trash-icon"/> */}
                                                          <br></br>
            <span>{data.currentCompanyname}</span><br></br>
           {(data.empType === 'Internship')?
                <>
                <span>{data.empType} | {data.internshipWorkingFromMonth} { data.internshipWorkingFromYear} to {data.workedTillMonth} {data.workedTillYear} </span><br></br>
                </>
                :
                <>
                <span>{data.empType} | {data.workedFromMonth} { data.workedFromYear} to {data.workedTillMonth} {data.workedTillYear} </span><br></br>
                </>
           }
            
            
            <span>{data.jobProfile}</span><br></br>
            <br></br>


          </div>
      ))}
    </div>
    {/*---------Mapping Ends-------- */ }



{/* (2.1)------------------>---Add_Modal code starts */}
                    <MDBModal tabIndex='-1' show={addModalStatus} setShow={setAddModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'800px'}}>
                        <MDBModalContent >
      
                                <Stscroll5EmploymentAddModal togglingModal={toggleAddModal} />
                        </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    {/*(2.1)--------->------------- Add_Modal code ends */}
                      
{/* (2.2)------------------>---Edit_Modal code starts */}
                    <MDBModal tabIndex='-1' show={editModalStatus} setShow={setEditModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'800px'}}>
                        <MDBModalContent >
                          <div className='profileModal'>
                            <MDBRow>

                              <MDBCol>
                              <span style={{fontSize:24}}>Edit Employment</span>
                              </MDBCol>

                              <MDBCol style={{textAlign:'right'}}>
                              <span onClick={() => handleItemDelete(editItemID)} className="fas fa-trash-can red-trash-icon">&nbsp;Delete</span>
                              </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <label>Is this your current employment ?</label>
                                <MDBCol>
                                    <MDBRadio name='CurEmployment' id='inlineRadio1' value='Yes' label='Yes' inline checked={editData.curEmp === 'Yes'} onChange={() => setEditData({ ...editData, curEmp: 'Yes'})}/>
                                    <MDBRadio name='CurEmployment' id='inlineRadio2' value='No'  label='No'  inline checked={editData.curEmp === 'No'}  onChange={() => setEditData({ ...editData, curEmp: 'No' })}/>
                                </MDBCol>
                            </MDBRow><br></br>

                            <MDBRow>
                                <label>Employment type</label>
                                <MDBCol>
                                <MDBBtn outline rounded className="mx-2 mb-2" color="dark" size="sm" style={{ color: "black" }}>{(editData.empType === 'Full-Time')?"Full-Time":"Intership"}</MDBBtn>
                                </MDBCol>
                            </MDBRow><br></br>

                            {(editData.empType === 'Full-Time' && editData.curEmp === 'Yes') && (
                            <div className=''>
                                  <label htmlFor="exampleFormControlSelect2" >Total Experience</label><br></br>
                                  <label htmlFor="exampleFormControlSelect2" >Years</label>
                                  <label htmlFor="exampleFormControlSelect2" style={{marginLeft:'343px'}}>Months</label>
                                    <MDBRow >
                                      <MDBCol>
                                        <Select
                                          value={selectedFullexpYears}//selectedOptionYear
                                          options={expYearOptions}
                                          onInputChange={(inputValue) => inputValue}
                                          onChange={(selectedOptions) => setSelectedFullexpYears(selectedOptions)}
                                          isClearable={true}// type to filter
                                          placeholder="Select"
                                        />
                                      </MDBCol>
                                      <MDBCol >
                                        <Select
                                          value={selectedFullexpMonths}
                                          options={expMonthsOptions}
                                          onInputChange={(inputValue) => inputValue}
                                          onChange={(selectedOptions) => setSelectedFullexpMonths(selectedOptions)}
                                          isClearable={true}
                                          placeholder="Select"
                                        />
                                      </MDBCol>
                                    </MDBRow><br></br>
                                  </div>
                                )}
                                
                                  <MDBRow>
                                  <MDBCol style={{ width:'800px'}}>
                                      
                                      {editData.curEmp === 'Yes'?
                                        (<label htmlFor="exampleFormControlSelect1" >Current Company Name</label>):(<label htmlFor="exampleFormControlSelect1" >Previous Company Name</label>)}
                                      <div className='formProfile'>
                                        <input type="text"
                                          className="form-control" 
                                          id="formGroupExampleInput1" placeholder='Type Your Organization'
                                          value={editData.currentCompanyname || ""}
                                          onChange={e=>setEditData({...editData, currentCompanyname:e.target.value})} 
                                          // style=Responsive
                                          style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
                        
                                          />
                                      </div><br></br>
                                    
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                  <MDBCol style={{ width:'800px'}}>
                                      {(editData.curEmp === 'Yes')?
                                        (<label htmlFor="exampleFormControlSelect1" >Current Designation</label>):(<label htmlFor="exampleFormControlSelect1" >Previous Designation</label>)}
                                      <div className='formProfile'>
                                        <input type="text"
                                          className="form-control" 
                                          id="formGroupExampleInput1" placeholder='Type Your Designation'
                                          value={editData.currentDesignation || ""}
                                          onChange={e=>setEditData({...editData, currentDesignation:e.target.value})} 
                                          style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
                                          />
                                      </div><br></br>
                                    
                                    </MDBCol>
                                </MDBRow>
                                      
                              {(editData.empType === 'Full-Time')?(<>
                                {/*-----------------------------Joining year - month drop down----------------- */}
                                <div className=''>
                                  <label htmlFor="exampleFormControlSelect2" >Joining Date</label><br></br>
                                    <MDBRow >
                                      <MDBCol>
                                        <Select
                                          value={selectedWorkingFromYear}//selectedOptionYear
                                          options={joiningDateYearOptions}
                                          onInputChange={(inputValue) => inputValue}
                                          onChange={(selectedOptions) => setSelectedWorkingFromYear(selectedOptions)}
                                          isClearable={true}// type to filter
                                          placeholder="Select Year"
                                        />
                                      </MDBCol>
                                      <MDBCol >
                                        <Select
                                          value={selectedWorkingFromMonth}
                                          options={workingFromMonthOptions}
                                          onInputChange={(inputValue) => inputValue}
                                          onChange={(selectedOptions) => setSelectedWorkingFromMonth(selectedOptions)}
                                          isClearable={true}
                                          placeholder="Select Month"
                                        />
                                      </MDBCol>
                                    </MDBRow><br></br>
                                  </div>

                                {/*-----------------------------Worked Till - month drop down----------------- */}
                                <div className=''>
                                            <label htmlFor="exampleFormControlSelect2" >Worked Till</label><br></br>
                                              <MDBRow >
                                                <MDBCol>
                                                  <Select
                                                    value={selectedWorkedTillYear}//selectedOptionYear
                                                    options={joiningDateYearOptions}
                                                    onInputChange={(inputValue) => inputValue}
                                                    onChange={(selectedOptions) => setSelectedWorkedTillYear(selectedOptions)}
                                                    isClearable={true}// type to filter
                                                    placeholder="Select Year"
                                                  />
                                                </MDBCol>
                                                <MDBCol >
                                                  <Select
                                                    value={selectedWorkedTillMonth}
                                                    options={workingFromMonthOptions}
                                                    onInputChange={(inputValue) => inputValue}
                                                    onChange={(selectedOptions) => setselectedWorkedTillMonth(selectedOptions)}
                                                    isClearable={true}
                                                    placeholder="Select Month"
                                                  />
                                                </MDBCol>
                                              </MDBRow><br></br>
                                            </div>

                                {/*-----------------------------Current Salary -input----------------- */}
                                  <MDBRow>
                                  <MDBCol style={{ width:'800px'}}>
                                      <label htmlFor="exampleFormControlSelect1" >Current Salary</label>
                                      <div className='formProfile'>
                                        <input type="number"
                                          className="form-control" 
                                          id="formGroupExampleInput1" placeholder='Type Your Salary'
                                          value={editData.curSal || ""}
                                          onChange={e=>setEditData({...editData, curSal:e.target.value})} 
                                          // responsive
                                          style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
                                          />
                                      </div><br></br>
                                    
                                    </MDBCol>
                                </MDBRow>

                                {/*-----------------------------Skills used -multi select input----------------- */}
                                <MDBRow>
                                  <MDBCol style={{ width:'800px'}}>
                                      <label htmlFor="exampleFormControlSelect1" >Skills Used</label>
                                      <Select
                                        isMulti
                                        name="colors"            
                                        value={selectedOptionsKeySkills}
                                        options={keySkillOptions}
                                        onChange={(selectedOptions) => setSelectedOptionsKeySkills(selectedOptions)}
                                        className="basic-multi-select demo-input-2 "
                                        classNamePrefix="Enquiry For"
                                        styles={customStyles}
                                        rows={4}
                                        size="lg"
                                      /><br></br>
                                    
                                    </MDBCol>
                                </MDBRow>

                                {/*-----------------------------Job Profile - input----------------- */}
                                <MDBRow>
                                  <MDBCol style={{ width:'800px'}}>
                                      <label htmlFor="exampleFormControlSelect1" >Job profile</label>
                                      <MDBTextArea
                                          // label='Message'
                                          id='textAreaExample'
                                          rows={2}
                                          value={editData.jobProfile || ""}
                                          onChange={(e) => setEditData({...editData, jobProfile:e.target.value})} 
                                          // style={{ marginLeft:'-20px'}}
                                          />
                                    
                                    </MDBCol>
                                </MDBRow><br></br>
                                </> 
                                
                                ):
                                <>

                                {/*-----------------------------Location (internship) - input----------------- */}
                                <MDBRow >
                                    <MDBCol style={{ width:'800px'}}>
                                      <label htmlFor="exampleFormControlSelect1" >Location</label>
                                        <div className='formProfile'>
                                          <input type="text"
                                            className="form-control" 
                                            id="formGroupExampleInput1" placeholder='Type Your Location'
                                            value={editData.internshipLocation || ""}
                                            onChange={(e)=>setEditData({...editData,internshipLocation:e.target.value})} 
                                            style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
                                            />
                                        </div><br></br>
                                      
                                      </MDBCol>
                                  </MDBRow>


                                {/*-----------------------------Department (internship) - input----------------- */}
                                <MDBRow >
                                  <MDBCol style={{ width:'800px'}}>

                                      <label htmlFor="exampleFormControlSelect1" >Department</label>
                                      <div className='formProfile'>
                                        <input type="text"
                                          className="form-control" 
                                          id="formGroupExampleInput1" placeholder='Type Your Department'
                                          value={editData.internshipDepartment || ""}                    
                                          rows={3}
                                          onChange={(e)=>setEditData({...editData,internshipDepartment:e.target.value})} 
                                          style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
                                          />
                                      </div><br></br>
                                      </MDBCol>
                                </MDBRow>

                                {/*-----------------------------WorkingFrom (internship) - dropdown----------------- */}
                                <div>
                                  <label htmlFor="exampleFormControlSelect2" >Working from</label><br></br>
                                    <MDBRow >
                                      <MDBCol>
                                        <Select
                                          value={selectedInternshipworkingFromYear}//selectedOptionYear
                                          options={joiningDateYearOptions}
                                          onInputChange={(inputValue) => inputValue}
                                          onChange={(selectedOptions) => setSelectedInternshipworkingFromYear(selectedOptions)}
                                          isClearable={true}// type to filter
                                          placeholder="Select"
                                        />
                                      </MDBCol>
                                      <MDBCol >
                                        <Select
                                          value={selectedInternshipworkingFromMonth}
                                          options={workingFromMonthOptions}
                                          onInputChange={(inputValue) => inputValue}
                                          onChange={(selectedOptions) => setSelectedInternshipworkingFromMonth(selectedOptions)}
                                          isClearable={true}
                                          placeholder="Select"
                                        />
                                      </MDBCol>
                                    </MDBRow>
                                                      
                                </div><br></br>


                                    {/*-----------------------------Worked Till - month drop down----------------- */}
                                    <div >
                                            <label htmlFor="exampleFormControlSelect2" >Worked Till</label><br></br>
                                              <MDBRow >
                                                <MDBCol>
                                                  <Select
                                                    value={selectedWorkedTillYear}//selectedOptionYear
                                                    options={joiningDateYearOptions}
                                                    onInputChange={(inputValue) => inputValue}
                                                    onChange={(selectedOptions) => setSelectedWorkedTillYear(selectedOptions)}
                                                    isClearable={true}// type to filter
                                                    placeholder="Select Year"
                                                  />
                                                </MDBCol>
                                                <MDBCol >
                                                  <Select
                                                    value={selectedWorkedTillMonth}
                                                    options={workingFromMonthOptions}
                                                    onInputChange={(inputValue) => inputValue}
                                                    onChange={(selectedOptions) => setselectedWorkedTillMonth(selectedOptions)}
                                                    isClearable={true}
                                                    placeholder="Select Month"
                                                  />
                                                </MDBCol>
                                              </MDBRow><br></br>
                                    </div>
                                </>}

                                <br></br>
                                    <button type="button" className="dropbtnprofilepagesave"    onClick={saveEditedItem}> Save   </button>&nbsp;
                                    <button type="button" className="dropbtnprofilepagecancel2" onClick={() => setEditModalStatus(false)}>   Cancel </button>&nbsp;
                                <br></br>

                          </div>
                        </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    {/* (2.2)------------------>---Edit_Modal code ends */}

    </div>
  )
}

export default Stscroll5Employment
