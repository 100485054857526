import React from "react";

import {
  MDBTypography,
  MDBAccordion,
  MDBAccordionItem,
  MDBCol,
  MDBCardBody,
  MDBRow,
} from "mdb-react-ui-kit";

import { Helmet } from "react-helmet";

const FAQ = () => {
  return (
    <>
  
    <div>
    <Helmet>
      
   <title>
  Learning with TPA:FAQ
 </title> 
 
 <meta
  name="description"
  // content="What do TPA courses at bangalore include? How do I take a TPA course? Do I have to start my TPA course at a certain time? And how long do I have to ..."
  content="Frequently asked questions about TPA courses in Bangalore, including qualifications, placements, training methodologies, support, and more."
  />
   <meta
            name="keywords"
            content="TPA courses, Bangalore, qualifications, placements, training methodologies, support, frequently asked questions, FAQ"
          />
</Helmet>

</div>
      <MDBRow
        className="MBfooter  "
        style={{ marginTop: "0.2%", color: "white" }}
      >
        <MDBCol center md="2"></MDBCol>
        <MDBCol center md="6">
          <MDBCardBody className="">
            <MDBTypography variant="h1 text-start" style={{ fontSize: "50px" }}>
              Frequently Asked Questions(FAQ)
            </MDBTypography>
          </MDBCardBody>
        </MDBCol>

        <MDBCol center md="4" className="p-5">
          <img
            // src="./images/hire from image-1.jpeg"
            src="https://th.bing.com/th/id/OIP.Fp44ac24I6ImNsnPBJMgjQHaEK?pid=ImgDet&rs=1"
            className="round"
            alt="hire from image"
            style={{
              width: "100%",

              height: "100%",
            }}
          ></img>
        </MDBCol>
      </MDBRow>
      <MDBRow
        className="mt-2  "
        style={{ backgroundColor: "hsl(38deg 82% 84%)", color: "black" }}
      >
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="10" className="p-5">
          <MDBCol>
            <MDBAccordion alwaysOpen initialActive={0}>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={1}
                headerTitle="What are the qualifications required to do the course?"
              >
                <p className="fs-6 text-start">
                  If you have completed or continuing B.Tech, M.Tech, MCA, BCA,
                  B.Sc, M.Sc or Any Graduation. You can easily do this course
                  and start your career.
                </p>
              </MDBAccordionItem>

              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={2}
                headerTitle="Does your institute provide 100% Placement Guarantee?
            ?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  No, we do not make any fraudulent offers about placements to
                  make you join the course. In fact, we guarantee 100% subject
                  as per real-time standards so you will be ready for the job
                  after completing our course.
                </p>
              </MDBAccordionItem>

              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={3}
                headerTitle="Do you provide placement assistance?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Yes, we are one of the oldest & trusted software training
                  institute in Bangalore and will help with genuine placement
                  assistance in good companies. We will provide you reference to
                  our clint. Based on your performance you can get a job.
                </p>
              </MDBAccordionItem>

              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={4}
                headerTitle="We will get any support form you people in future if I start my career?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Yes, our team will provide you support for any clarification
                  you need in a job.
                </p>
              </MDBAccordionItem>

              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={5}
                headerTitle="Is the course theoretical or practical?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Our training will be a combination of theoretical and
                  practical on each topic. We also provide live exposure on
                  projects and give assignments to test your skill sets.
                </p>
              </MDBAccordionItem>

              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={6}
                headerTitle="Is there any project supported with the course?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Yes, after the completion of the course. the instructor will
                  allot you a real-time project to have a clear understanding of
                  how you are able to conceptualize and implement the real-world
                  application of the course content.
                </p>
              </MDBAccordionItem>

              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="What will be my salary after completing course in your center?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Salary entirely depends on the company. Some companies pay
                  more some pay less. For example, if you are entering a startup
                  company, the salary might be low. If you are entering a large
                  company depends on your skills. Also, the salary depends on
                  what platform you might be assigned to. Usually, the salary
                  details are revealed by the companies during the Interview
                  process.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="Who will be the trainer for the classroom training?

            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  We have an instructor community of industry professionals who
                  are working in leading organizations and are veterans in their
                  respective fields. These experts belong to various industries
                  and are willing to share their talent with learners like you.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="What is the age limit & minimum skills for learning any technical course?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  There is no age limit to learn any technical course. Although
                  we expect the student to have the least minimum skills in
                  using a Computer.
                </p>
              </MDBAccordionItem>

              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="What are the profiles and experiences of trainers at INTERFACE?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  We have a strong team of professionals having expert knowledge
                  in their fields. Our trainers are very supportive and render
                  friendly working environment to the students which stimulate
                  their growth in a positive manner. They all are less with huge
                  industrial and teaching experiences.
                </p>
              </MDBAccordionItem>

              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="Is it necessary to learn C & C++ before learning CATIA Customization?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Learning 'C++' before taking up Java is a good option. It is
                  not required to learn C in order to take up CATIA
                  Customization. So, learning C++ is sufficient as both of them
                  will differ mainly in syntax.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="How do I enroll for the classroom training?
            "
              >
                <p className="fs-6 text-start">
                  Yes, you may cancel your enrollment but Money is not
                  refundable.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="What if I have doubts/queries before or after the session?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  If you have doubts before the class, you can post your queries
                  in the discussion forum or face-to-face during the session. If
                  you have any doubts after the session is over, then you can
                  email the instructors. We assure you that the instructor will
                  respond back to your query on an immediate basis.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="How much time you take after registration to start a batch in classroom training?
            "
              >
                <p className="fs-6 text-start">
                  After getting enrolled with us, we need at least 3-10 days to
                  Schedule a batch.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="What is the size of the batch?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Our institute focuses on individual training approach so we
                  don't have more than 10-15 candidates in a batch in general.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="Can I Join a Demo Class Before Admission?
            "
              >
                <p className="fs-6 text-start">
                  Yes, Call Us ------------ or Apply for demo.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="Talent Pool Academy is a software development company or training institute?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Talent Pool Academy is a complete Custom Software Development
                  and Web Design Company in Bangalore, also providing software
                  courses like JAVA, .NET, PHP, Website Designing, Internship
                  training to B.Tech/B.E, MCA, B.Sc, M.Sc.Students, which is a
                  part of their Graduate/Post Graduate Degree.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="Do you provide group discounts when I enroll with friends?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Yes, we do have group discount s for the friends who enrolls
                  with us same time with more than four. For more details
                  contact counselor.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="Do I get any assistance after completion of my sessions?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  Yes, our instructors will always be there to resolve your
                  queries and take your doubts even after the completion of the
                  course.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="What if I require extra assistance?
            "
              >
                <p className="fs-6 text-start">
                  {" "}
                  If you require extra assistance, Our Support Team is always
                  there to help you.
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                className="acc-paragraph"
                collapseId={7}
                headerTitle="What are Live Projects Training?
            "
              >
                <p className="fs-6 text-start">
                  Live Projects Means where students and professionals can work
                  in a project which gives you to work in real-time Experience.
                  With the help of real-time projects, you can work in any
                  technologies.
                </p>
              </MDBAccordionItem>
            </MDBAccordion>
          </MDBCol>
        </MDBCol>

        <MDBCol center md="1"></MDBCol>
      </MDBRow>
    </>

    
  );
};

export default FAQ;
