// import React, { Component, useState } from 'react';
// import { MDBTable, MDBTableHead, MDBTableBody, MDBRow, MDBCol } from 'mdb-react-ui-kit';
// import {baseURL} from './../../http';

// class AdminSideKeySkillHandler extends Component {


//   constructor(props) {
//     super(props);
//     this.state = {
//       keySkillsList: [],
//     };
//   }

//   componentDidMount() {
//     this.fetchKeySkillsList();
//   }

//   fetchKeySkillsList = () => {
//     fetch(`${baseURL}/getAllKeySkills`)
//       .then(response => response.json())
//       .then(data => {
//         this.setState({ keySkillsList: data });
//       })
//       .catch(error => {
//         console.error('Error fetching student list:', error);
//       });
//   };

//   handleItemDelete = (itemId) => {
//     const updatedKeySkillList = this.state.keySkillsList.filter(item => item.id !== itemId);
//     this.setState({ keySkillsList: updatedKeySkillList });

//     // Delete item from the backend
//     fetch(`${baseURL}/deleteKeyskills/${itemId}`, {
//       method: 'PUT', 
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     })
//     .then(response => {
//       if (response.ok) {
//         console.log('Item deleted successfully');
//       } else {
//         console.error('Failed to delete item');
//       }
//     })
//     .catch(error => {
//       console.error('Item deleting user:', error);
//     });
//   };


//   render() {
//     const { keySkillsList } = this.state; // Corrected variable name
//   return (
//     <div>
//       <MDBRow>
//         <MDBCol >
//           <h2>Key Skills</h2>
//         </MDBCol>
//         <MDBCol className="text-end" >
//           <button className='dropbtn1'onClick={''}>Add Skill</button>
//         </MDBCol>

//       </MDBRow>
//       <MDBTable>
//           <MDBTableHead className='bg-gradient '>
//             <tr style={{textAlign: 'center'}}>
//               <th scope='col' style={{width:'80px'}}>S.No</th>
//               <th scope='col' style={{width:'280px'}}>Skill Name</th>
//               <th scope='col' style={{width:'180px'}}>Action
//               </th></tr>
//           </MDBTableHead>
//       <MDBTableBody style={{textAlign: 'center'}}>
//       {keySkillsList.map(kskill => (
//             <tr  key={kskill.id}>
//               <td >{kskill.id}</td>
//               <td>{kskill.skillName}</td>
//               <td> 
//                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                   {/* <div>
//                       <button className="blue-edit-icon text-align-center" onClick={() => this.handleItemEdit(kskill.id)}><i class="fas fa-pen-to-square"></i>&nbsp;Edit</button>
//                   </div> */}
//                   <div>
//                       <button className="red-trash-icon text-align-center" onClick={() => this.handleItemDelete(kskill.id)}><i class="fas fa-trash-can"></i>&nbsp;Delete</button>
//                   </div>
//                 </div>
//               </td>
//             </tr>
//           ))}
//        </MDBTableBody>
//     </MDBTable>
//     </div>
//   );
// }
// }

// export default AdminSideKeySkillHandler

import React, { useState, useEffect } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { baseURL } from './../../http';
const Swal = require("sweetalert2");

function AdminSideKeySkillHandler() {
  const [keySkillsList, setKeySkillsList] = useState([]); //fetch from DB
  const [keySkill, setKeySkill]=useState(null);           // update to DB

  useEffect(() => {
    fetchKeySkillsList();
  }, []);

  const fetchKeySkillsList = () => {
    fetch(`${baseURL}/getAllKeySkills`)
      .then(response => response.json())
      .then(data => {
        setKeySkillsList(data);
      })
      .catch(error => {
        console.error('Error fetching key skills list:', error);
      });
  };

  const handleItemDelete = (itemId) => {
    const updatedKeySkillList = keySkillsList.filter(item => item.id !== itemId);
    setKeySkillsList(updatedKeySkillList);

    // Delete item from the backend
    fetch(`${baseURL}/deleteKeyskills/${itemId}`, {
      method: 'PUT',
      headers: {  'Content-Type': 'application/json', },
    })
      .then(response => {
        if (response.ok) {  console.log('Item deleted successfully'); } 
        else             {  console.error('Failed to delete item');   }
      })
      .catch(error =>    {  console.error('Item deleting user:', error); });
  };

  const addSkillToDB = async () => {
    try {
      console.log('enter try');
      const sendData = {
        skillName : keySkill,
      }
      const response = await fetch(`${baseURL}/createKeySkills`, {
        method  : 'POST',
        body    : JSON.stringify(sendData),
        headers : {
                    'Content-Type': 'application/json',
                    'Accept'      : 'application/json',
                  },
                    });

          if (response.ok) {
                          console.log('updated success');
                          fetchKeySkillsList();
                          Swal.fire({ title: "KeySkill Created ", icon: "success", timer: 500, position: "center", timerProgressBar: true, 
                                      showConfirmButton: false, })
                      }
          else { Swal.fire({ icon: 'error',  title: 'Error..1', text: '',  })}
    }
  catch { Swal.fire({ icon: 'error', title: 'Error..2', text: 'Something went wrong! in Database', })  }
}
  return (
    <div>
      <MDBRow>
        <MDBCol>
          <h2>Key Skills</h2>
        </MDBCol>
        <MDBCol className="text-end">
          <input type='text' value={keySkill} onChange={e=>setKeySkill(e.target.value)}/>
          <button className='dropbtn1' onClick={ addSkillToDB}>Add Skill</button>
        </MDBCol>
      </MDBRow>
      <MDBTable>
        <MDBTableHead className='bg-gradient'>
          <tr style={{ textAlign: 'center' }}>
            <th scope='col' style={{ width: '80px' }}>S.No</th>
            <th scope='col' style={{ width: '280px' }}>Skill Name</th>
            <th scope='col' style={{ width: '180px' }}>Action</th>
          </tr>
        </MDBTableHead>
          <MDBTableBody style={{ textAlign: 'center' }}>
            {keySkillsList.map(kskill => (
              <tr key={kskill.id}>
                <td>{kskill.id}</td>
                <td>{kskill.skillName}</td>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                      {/* Add an edit button if needed */}
                    </div>
                    <div>
                      <button className="red-trash-icon text-align-center" onClick={() => handleItemDelete(kskill.id)}>
                        <i className="fas fa-trash-can"></i>&nbsp;Delete
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </MDBTableBody>
      </MDBTable>
    </div>
  );
}

export default AdminSideKeySkillHandler;
