import React, { useState, useEffect } from 'react'
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBModalFooter, MDBTextArea } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../../http';
import Stscroll11CareerProfileModal from './Stscroll11CareerProfileModal';

function Stscroll11CareerProfile() {
  const [careerProfile, setCareerProfile]         = useState('');     
  const [careerProfileEdit, setCareerProfileEdit] = useState('');     
  const userInfo                              = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                             = userInfo.email;
  const [modalStatus, setModalStatus]         = useState(false);
  const history                               = useNavigate ();

  function toggleModal() {  setModalStatus(!modalStatus);  }

  /*------------------------useEffect for profile details update----------------- */
useEffect(() => {
  fetchUserData();

}, []);
 
const fetchUserData = async () => {
  try {
        // let items =  userEmail ;         // wrong format - as below like json to send
        const requestBody = { email : userEmail,       
        };
        const response = await fetch(`${baseURL}/getprofile`, {
          method: 'POST',
          body:JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
            'Accept'      : 'application/json',
          },
        });

        
        if (response.ok) {
          // console.log(response);
          
          const data = await response.json();
          setCareerProfile(data.careerProfile || "");
          setCareerProfileEdit(data.careerProfile || "");
        } else {
          // history.push('/login');
          console.log('Error fetching carProfile:');
        }

      } catch (error) { 
        // earlier clog not given  and db car profile is empty
        console.error('Error fetching carProfile:', error);
      }
    };
 

  return (
    <div>
      <h5 style={{color:'black'}}>Career Profile  <i  onClick={toggleModal}className="fas fa-pencil " style={{paddingBottom:'8px', fontSize:12, cursor:'pointer'}}></i></h5>

          <p>
                {careerProfile|| ""}
          </p>
  
         {/* (2.1)------------------>---Modal code starts */}
         <MDBModal tabIndex='-1' show={modalStatus} setShow={setModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
                        <MDBModalContent >
      
                                <Stscroll11CareerProfileModal togglingModal={toggleModal}/>
                        </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    {/*(2.1)--------->------------- Modal code ends */}

      
    </div>
  )
}

export default Stscroll11CareerProfile
