import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBTable, MDBTableHead, MDBTableBody , MDBIcon } from 'mdb-react-ui-kit';
import { MDBCol, MDBRow , MDBInput} from 'mdb-react-ui-kit'
import { baseURL } from '../../http';


function AdminResumeTemplateHandler() {
  const [selectedOptionResumeType, setSelectedOptionResumeType]         = useState(null);
  const [resumeFile, setResumeFile]                                     = useState(null);  // To Upload to Database
  
  const [resumeTemplateList, setResumeTemplateList]                     = useState([]); // for array null array sq.brackets to be given
  const [resumeTypeOptions, setResumeTypeOptions]                       = useState([]); // for array null array sq.brackets to be given
  const [adminResumeType, setAdminResumeType]                           = useState(null);  // To Upload to Database
 

  const [addModalStatus, setAddModalStatus]                             = useState(false);
  const [resumeTypeModalStatus, setResumeTypeModalStatus]               = useState(false);

  function toggleAddResumeTypeModal()     {  setAddModalStatus(!addModalStatus);  }
  function toggleManageResumeTypeModal()  {  setResumeTypeModalStatus(!resumeTypeModalStatus);  }

  const adminResumeUpload = () => {
    if (resumeFile) {
      const formData = new FormData();

      formData.append('document', resumeFile);
      formData.append('resumeTypeName', selectedOptionResumeType.value);

        console.log("resumeTypeName="+selectedOptionResumeType.value);

      fetch(`${baseURL}/uploadByAdminResumeTemplate`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {    console.log('FE-Resume Template uploaded successfully');                     } 
          else             {    console.error('FE-Error  Resume uploading document:', response.statusText);      }
        })
        .catch((error) => {     console.error('Network error while uploading document:', error);
        });

    } else {                    console.error('No resume file selected');                             }
  };

    /*--------------------Fetching Student's of all emp_details-------------------- */
  useEffect(() => { fetchResumeTemplateAdminside(); fetchResumeTemplateTypes(); fetchAdminResumeTypes(); }, []);

  const fetchResumeTemplateAdminside = () => {
    fetch(`${baseURL}/getAdminsResumeTemplate`)
      .then(response => response.json())
      .then(data     => { setResumeTemplateList(data); })
      .catch(error   => { console.error('Error fetching template by Admin:', error); });
  };

  const fetchResumeTemplateTypes = () => {
    fetch(`${baseURL}/getAdminsResumeTemplate`)
      .then(response => response.json())
      .then(data     => { setResumeTemplateList(data); })
      .catch(error   => { console.error('Error fetching template by Admin:', error); });
  };

  const fetchAdminResumeTypes = () => {
    fetch(`${baseURL}/getResumeTypeofAdmin`)
      .then(response => response.json())
      .then(data     => { 
                          const options = data.map(item => item.adminResumeTypes);
                          setResumeTypeOptions(options);
            })
      .catch(error   => { console.error('Error fetching template by Admin:', error); });
  };
  const resTypeOptions=resumeTypeOptions.map(adminResumeTypes =>({
      value: adminResumeTypes, // Use an appropriate value for each option
      label: adminResumeTypes,
  }))
  const handleResumeItemDelete = (itemId) => {

    /**--------for delete in frontend usestate(render)--------- */
    const updatedResumeTemplateList = resumeTemplateList.filter(item => item.id !== itemId);
    setResumeTemplateList(updatedResumeTemplateList);

    /**--------for delete api to backend----------------------- */
    const deleteItem           = resumeTemplateList.find(item => item.id === itemId);
    const deleteFileName       = deleteItem.originalFileName;
    const deleteFileExtension  = deleteItem.originalFileExtension;

    console.log('check='+`${baseURL}/deleteResume/${deleteFileName}/${deleteFileExtension}`);
      fetch(`${baseURL}/deleteResumeByAdmin/${deleteFileName}/${deleteFileExtension}`, {
        method: 'DELETE', 
      })
        .then((response) => {
          if (response.ok) {     console.log('Resume deleted successfully');  
                                                          } 
          else             {     console.error('Error deleting resume:', response.statusText);        }
        })
        .catch((error) => {      console.error('Network error while deleting resume:', error);
        });
  };
  
  
  const handleResumeItemDownload = (itemId) => {

    const downloadItem      = resumeTemplateList.find(item => item.id === itemId);
    const downloadFileName  = downloadItem.originalFileName +'.'+ downloadItem.originalFileExtension;
    
      console.log("downloadfileName = "+downloadFileName);
      console.log("api="+(`${baseURL}/downloadResume/${downloadFileName}`));
    
    fetch(`${baseURL}/downloadResumeByAdmin/${downloadFileName}`,   
    {
      method: 'GET', 
    })
    .then((response) => {   // Create a blob from the response data
      if (response.ok) {    return response.blob(); } 
      else             {    console.error('Error downloading document:', response.statusText);
                            return null; }
    })
    .then((blob) => {
      if (blob) {
        // Create a URL for the blob and trigger a download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
                  a.href = url;
                  a.download = downloadFileName; // You can set the desired file name here
                  document.body.appendChild(a);
                  a.click();
        window.URL.revokeObjectURL(url);
      }
      else            { console.log('Download Error - Inside blob');}
    })
    .catch((error) => { console.error('Network error while downloading document:', error);  });
  };
  
  const handleResumeItemUpdate = (itemId) => {  };
  
  const addResumeType= () => {
      const data={
        adminResumeTypes:adminResumeType
      }
      // console.log("sending="+data.adminResumeTypes)
      fetch(`${baseURL}/addResumeTypeByAdmin`, 
      { 
        method: 'POST',
        body: JSON.stringify(data),
        headers : {
                  'Content-Type': 'application/json',
                  'Accept'      : 'application/json',
                  },
      })
        .then((response) => {
          if (response.ok) {    console.log(' FE-Admins ResumeType uploaded successfully');  alert('Item Added successfully');  } 
          else             {    console.error('Error -Admins ResumeType uploading document:', response.statusText);             }
        })
        .catch((error) => {     console.error('Network error-Admins ResumeType uploaded successfully:', error);
        });
    };

  const deleteResumeType =()=>{

    fetch(`${baseURL}/deleteResumeTypeByAdmin/${selectedOptionResumeType.value}`, {
      method: 'PUT',
      headers: {  'Content-Type': 'application/json', },
    })
      .then(response => {
        if (response.ok) {  console.log('FE- Item deleted successfully');    alert('Item deleted successfully');} 
        else             {  console.error('FE-Failed to delete item');       alert('Item deleted successfully Failed');     }
      })
      .catch(error =>    {  console.error('FE-Item deleting user:', error); });

  }

  return (
    <div style={{Width:'25%'}}>
    {/* Main Rendering-Starts */}

      <MDBRow>

        <MDBCol>
          <h2> Resume Templates</h2> 
        </MDBCol>

         <MDBCol style={{ textDecoration:'underline', color:'blue'}}>   {/* textAlign:'right', */}
          <span className='hand' onClick={toggleAddResumeTypeModal}> Add /</span> &nbsp;
          <span className='hand' onClick={toggleManageResumeTypeModal}> Manage Resume Types</span>
        </MDBCol>
      </MDBRow>

      <MDBTable>
        <MDBTableHead className='bg-gradient'>
          <tr style={{ textAlign: 'center', fontSize:'18px' }}>
            <th scope='col' style={{ width: '80px',  borderBottom:'2px solid blue'}}>S.No</th>
            <th scope='col' style={{ width: '280px', borderBottom:'2px solid blue', minWidth:'400px', maxWidth:'700px' }}>Resume Template Type</th>
            <th scope='col' style={{ width: '280px', borderBottom:'2px solid blue', minWidth:'400px', maxWidth:'700px' }}>File Name <span style={{fontSize:'10px'}}>( of template)</span></th>
            <th scope='col' style={{ width: '180px', borderBottom:'2px solid blue', minWidth:'350px' }}>Action<br></br>
              <span style={{fontSize:'12px'}}>Download   &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;   Update  &nbsp;&nbsp; |&nbsp;&nbsp;   Delete</span></th>
          </tr>
        </MDBTableHead>
        <MDBTableBody style={{ textAlign: 'center' }}>
          {resumeTemplateList.map((table, index)=>(
            <tr key={table.id}>
              <td >{index+1 }</td>
              <td >{table.resumeTypeName}</td>
              <td >{table.originalFileName}.{table.originalFileExtension}</td>
              <td >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MDBIcon fas icon="cloud-download-alt" onClick={() => handleResumeItemDownload(table.id)} className='hand' style={{marginLeft:'15px', color:'green'}} />
                    <MDBIcon fas icon="cloud-upload-alt"   onClick={() => handleResumeItemUpdate(table.id)} className='hand' style={{marginLeft:'45px', color:'blue'}} />
                    <MDBIcon fas icon="trash-alt  "        onClick={() => handleResumeItemDelete(table.id)} className='hand' style={{marginLeft:'45px', color:'red'}} />

                </div>
              </td>
            </tr>
        ))}

        </MDBTableBody>
      </MDBTable>
    {/* Main Rendering-Ends ----------------------------------------------------------------------------------------------*/}

    {/* Modal start - File template Addition */}
      <MDBModal tabIndex='-1' show={addModalStatus} setShow={setAddModalStatus}>
          <MDBModalDialog centered className="modal-xl" style={{width:'800px'}}>
          <MDBModalContent >

          <div className='profileModal1'>
            <h3>Add Resume Template </h3>
            <MDBRow >
              <MDBCol >select Resume Template type</MDBCol>
              <MDBCol >
                  <Select
                      value={selectedOptionResumeType}
                      options={resTypeOptions}  
                      onInputChange={(inputValue) => inputValue}   //correct
                      onChange={(selectedOptions) => setSelectedOptionResumeType(selectedOptions)}      
                      isClearable={true}    // x to remove at right
                      // isSearchable={false} // select is default searchable
                      placeholder="Select Resume Type"
                      style={{ marginLeft: '-20px' }}
                      />
              </MDBCol>
            </MDBRow><br/>

            <MDBRow>
                <MDBCol > 
                  <h5 > Select File Template </h5>
                </MDBCol>
                <MDBCol>

                  <div className="input-group border">
                      <div className="custom-file">
                        <input type="file" className="custom-file-input " id="inputGroupFile01"
                          aria-describedby="inputGroupFileAddon01"
                          onChange={(e) => {
                                            const selectedFile = e.target.files[0];
                                            setResumeFile(selectedFile);
                                          }}
                            />
                      </div>
                    </div>
                    {/* <br/> */}
                    <span style={{fontSize:'12px'}}>Choose format: pdf, doc or docx</span>
                </MDBCol>
            </MDBRow>

          <br></br>
            <button type="button" className="dropbtnprofilepagesave"    onClick={adminResumeUpload}> Upload   </button>&nbsp;&nbsp;
            <button type="button" className="dropbtnprofilepagecancel2" onClick={toggleAddResumeTypeModal}>   Cancel </button>&nbsp;
          <br></br>
            </div>
          </MDBModalContent>
          </MDBModalDialog>
      </MDBModal>
    {/* Modal File Template Addition -Ends --------------------------------------------------------------------------------*/}

    {/* Modal Resume type  Addition  and deletion-Starts */}
      <MDBModal tabIndex='-1' show={resumeTypeModalStatus} setShow={setResumeTypeModalStatus}>
        <MDBModalDialog centered className="modal-xl" style={{width:'600px'}}>
          <MDBModalContent >
            <div className='profileModal1 '>
              <h3>Manage Resume Type </h3>
              <MDBRow >
                {/* <MDBCol >Add Resume type</MDBCol> */}
                <MDBCol > <MDBInput label='Add Resume type' id='form1' type='text' onChange={e=>setAdminResumeType(e.target.value)}  /></MDBCol>
                <MDBCol > <button type="button" className="dropbtnprofilepagesave" onClick={addResumeType}>Add</button></MDBCol>
              </MDBRow><br/>

                <MDBRow>
                  <MDBCol >
                    <Select value={selectedOptionResumeType}
                        options={resTypeOptions}   
                        onInputChange={(inputValue) => inputValue}   //correct
                        onChange={(selectedOptions) => setSelectedOptionResumeType(selectedOptions)}      
                        isClearable={true}    // x to remove at right
                        // isSearchable={false} // select is default searchable
                        placeholder="Select Resume Type"
                        // style={{width:'100%'}}
                        />
                  </MDBCol>
                <MDBCol > <button type="button" className="dropbtnprofilepagecancel2" onClick={deleteResumeType}>Delete</button></MDBCol>
              </MDBRow> 

            </div>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    {/* Modal Resume type  Addition  and deletion-Ends --------------------------------------------------------------------*/}


    </div>
  )
}

export default AdminResumeTemplateHandler
