import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCardBody,
} from "mdb-react-ui-kit";
import ContactBody from "../More/ContactBodyHome";


function Demo() {
  

  return (
    <MDBRow className="pb-3 fullstackBody">
      <MDBCol center md="3">
        <MDBCardBody>
          <MDBTypography
            tag="h3"
            color="light"
            className=" text-start fw-bold "
          >
            Book a Free Demo, now!
          </MDBTypography>

          <MDBTypography tag="h6" color="light" className="pt-2">
            Experience the quality of our course content firsthand with a demo
            session, Live!
          </MDBTypography>
        </MDBCardBody>
      </MDBCol>

      <MDBCol center md="4" style={{marginTop:"10px"}} className="">
      <ContactBody/>
      </MDBCol>
      {/* Input ends */}
      <MDBCol center md="5">
        <img
          src="https://www.gentlemansgazette.com/wp-content/uploads/2020/04/vlcsnap-2020-04-22-03h31m21s506-1030x579.jpg"
          className="img-fluid rounded-circle mt-3"
        />
      </MDBCol>
    </MDBRow>
    
  );
}

export default Demo;
