
import "./App.css";
import React from "react";
import Start from "./Components/Start";

function App() 
{

  return (

      <div>
        <Start/>
        
      </div>
  

  
  );
}

export default App;
