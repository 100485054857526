import Header from "./common/Header";
import PreHeader from "./common/PreHeader";
import Footer from "./common/Footer";
import Home from "./Home/Home";


import { Route, Routes } from "react-router-dom";
import { MDBContainer } from "mdb-react-ui-kit";
import PrivacyPolicies from "./CompanyPolicy/PrivacyPolicies";
import TermsAndCondition from "./CompanyPolicy/TermsAndCondition";
import FAQ from "./More/Faq";

// import SignIn from "./More/SignIn";

import AboutUs from "./More/AboutUs";
import ContactUs from "./More/ContactUs";
import Enquiry from "./Enquiry/Enquiry";
import { useEffect } from "react";

// Internship//
import Internship from "./Internship/Internship";
import InternshipRegister from "./Internship/InternshipRegister";
import InternshipAdvantages from "./Internship/InternshipAdvantages";
import InternshipLearning from "./Internship/InternshipLearning";
import InternshipJoin from "./Internship/InternshipJoin";

import FS1ProductDesign from "./Full Stack Developer/FS1ProductDesign";
//testing software//
import ST1ProductDesign from "./Software Testing/ST1ProductDesign";
//tpa business
import HireTalent from "./TPABusiness/HireTalent ";


//job gurantee course automotive design//
import ProductDesign from "./JobGuranteeCourses/ProductDesign";

//Catia Automation and Customization//

import C1atiaProductDesign from "./Catia Automation and Customization/C1atiaProductDesign";

//Computer aided customization//

import CAE1ProductDesign from "./CAE Computer Aided Engineering/CAE1ProductDesign";

//cfd fluid and dynamics//

import ModalProvider from "./Context/ModalProvider";
import ContactModal from "./Context/ContactModal";

import SignUp from "./More/SignUp";
import SignInNew from "./More/SignInNew";

function Mainsite() {
  useEffect(() => {
    document.title = "TPA Home";
  });

  // const isLocalStorageEmpty = localStorage.length === 0;
 
  return (
    <div>
      {/* <WebWhatsApp/> */}
      <ModalProvider>
        <ContactModal />
        <PreHeader />
        <Header /> 
        <MDBContainer fluid>
          <Routes>

            
            <Route path="/" element={<Home />} />
            
            <Route path="/privacypolicies" element={<PrivacyPolicies />} />
            <Route path="/termsandcondition" element={<TermsAndCondition />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/Enquiry" element={<Enquiry />} />

            <Route path="/Signin" element={<SignInNew/>} />
            <Route path="/SignUp" element={<SignUp />}   />


            {/* internship */}
            <Route path="/internship" element={<Internship />} />
            <Route
              path="/internship-register"
              element={<InternshipRegister />}
              />
            <Route
              path="/internship-advantages"
              element={<InternshipAdvantages />}
              />
            <Route
              path="/internship-Learning"
              element={<InternshipLearning />}
              />
            <Route path="/internship-join" element={<InternshipJoin />} />

            <Route path="/FS1ProductDesign" element={<FS1ProductDesign />} />
            {/*software testing*/}
            <Route path="/ST1ProductDesign" element={<ST1ProductDesign />} />
            {/*TPA business*/}
            <Route path="/HireTalent" element={<HireTalent />} />
            
            {/*Auomotive design*/}
            <Route path="/product-design" element={<ProductDesign />} />

            <Route
              path="/C1atiaProductDesign"
              element={<C1atiaProductDesign />}
              />

            {/*CAE Computer Aided Engineering*/}
            <Route path="/CAE1ProductDesign" element={<CAE1ProductDesign />} />
            {/*cfd fluid and dynamics*/}




            {/* <Route path="/course-templete" element={<Coursetemplete />} /> */}  {/** not used- no seo */}
            {/* <Route path="/all-course" element={<Allcourse />} /> */}  {/** not used- no seo */}
            {/* <Route path="/all-mechanical" element={<Allmechanical />} /> */}  {/** not used- no seo */}
            {/* <Route path="/recommenedCourses" element={<RecommenedCourses />} /> */}  {/** not used- no seo */}
            {/* <Route path="/sign-in" element={<SignIn />} /> */}

            {/* <Route  path="/Frontend1ProductDesign" element={<Frontend1ProductDesign />} /> */}

          </Routes>
        </MDBContainer>
        <Footer />
      </ModalProvider>
    </div>
  );

}

export default Mainsite;
