import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
 
} from "mdb-react-ui-kit";
import swal from "sweetalert";

import "react-phone-input-2/lib/style.css";
import ContactBody from "../More/ContactBodyEnqModal";
// import { colourOptions } from '../data';

import { Helmet } from "react-helmet";

export default function Enquiry() {
  const [centredModal, setCentredModal] = useState(true);
  const toggleShow = () => setCentredModal(!centredModal);

  return (
<>
<div>
    <Helmet>
      
        <title>
        Enquiry - Get in touch with TPA.
      </title> 
        
        <meta
          name="description"
          content="Get in touch with TPA with your research questions, media requests, or other support-related enquiries. Our support team will respond as soon as possible."
        />
        <meta
          name="keywords"
          content="Contact, talentpoolacademy, info@talentpoolacademy.com, Contact talentpoolacademy, TPA Bangalore"
        />
</Helmet>
</div>


    <div>
      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Enquiry</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <ContactBody />
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
    </>
  );
}
