import React, { useState, useEffect } from 'react'
import { MDBRow, MDBCol, MDBModalHeader } from 'mdb-react-ui-kit'
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBModalFooter, MDBInput, MDBTextArea   } from 'mdb-react-ui-kit';
import {  MDBTable, MDBTableHead, MDBTableBody , MDBIcon,  MDBAccordion, MDBAccordionItem, MDBTooltip , MDBBtnGroup  } from 'mdb-react-ui-kit';
import { baseURL } from '../../http';

// import DatePicker from "react-date-picker";
import 'react-calendar/dist/Calendar.css';
// import { json } from 'react-router-dom';

function MyInterviewQues() {
    const [selectedDate, setSelectedDate]                           = useState(new Date());
    const [companyName, setCompanyName]                             = useState(false);
    const [jobPosition, setJobPosition]                             = useState(false);

    const[st, setst]    =useState('');
    const [interviewBasicDetailList, setInterviewBasicDetailList]   = useState([]); // for array null array sq.brackets to be given
    const [interviewQasList, setInterviewQasList]   = useState([]); // for array null array sq.brackets to be given


    const [addIntModalStatus, setAddIntModalStatus]                 = useState(false);
    const [manageIntModalStatus, setManageIntModalStatus]           = useState(false);
    const [addQASModalStatus, setAddQASModalStatus]                 = useState(false);
    const [editQASModalStatus, setEditQASModalStatus]               = useState(false);
    
    const [addQASID, setAddQASID]                                   = useState('');
    const [editQASItemID, setEditQASItemID]                                   = useState('');
    const [addQAScompany, setAddQAScompany]                         = useState('');
    const [addQASintDate, setAddQASintDate]                         = useState('');
    const [addQASemail, setAddQASemail]                             = useState('');
    const [addQAScomJobRole, setAddQAScomJobRole]                   = useState('');
    const [question, setQuestion]                                   = useState('');
    const [answer, setAnswer]                                       = useState('');
    // const [addModalStatus, setAddModalStatus]                       = useState(false);
    const userInfo                                                  = JSON.parse(localStorage.getItem('user-info'));
    const userEmail                                                 = userInfo.email;

    function toggleAddInterviewModal()      {  setAddIntModalStatus(!addIntModalStatus);  }
    function toggleManageInterviewModal()   {  setManageIntModalStatus(!manageIntModalStatus);    }

    const toggleAddQASModal = (itemID, comName, intDate, email, comJobRole) => { setAddQASID(itemID);
                                                                                 setAddQAScompany(comName);     setAddQASintDate(intDate);    
                                                                                 setAddQASemail(email);         setAddQAScomJobRole(comJobRole);    
                                                                                 setAddQASModalStatus(!addQASModalStatus);       // alert('inside modal - id-'+itemID) 
        
//   console.log('itemID'+itemID);     console.log('comName'+comName);           
                                                              }

    const addInterview =()=>{
        const data={    
                email           :   userEmail,
                interviewDate   :   selectedDate ,
                companyName     :   companyName,
                companyJobRole  :   jobPosition 
               }
          fetch(`${baseURL}/createInterviewBasicDetail`, 
          { 
            method: 'POST',
            body: JSON.stringify(data),
            headers : {
                      'Content-Type': 'application/json',
                      'Accept'      : 'application/json',
                      },
          })
            .then((response) => {
              if (response.ok) {    //console.log(' FE-Admins ResumeType uploaded successfully');  
                                    alert('Interview Added successfully'); fetchinterviewBasicDetail(); } 
              else             {    console.error('Error -Admins ResumeType uploading document:', response.statusText);             }
            })
            .catch((error) => {     console.error('Network error-Admins ResumeType uploaded successfully:', error);
            });
        };

    /*--------------------Fetching Student's of all emp_details-------------------- */
    useEffect(() => { fetchinterviewBasicDetail();  fetchinterviewQAS();  }, []);
    
    const fetchinterviewBasicDetail = () => {
        fetch(`${baseURL}/getInterviewBasicDetail/${userEmail}`)
          .then(response => response.json())
          .then(data     => { setInterviewBasicDetailList(data || []); 
                            console.log(JSON.stringify(data))})

          .catch(error   => { console.error('Error fetching Basic Table-1:', error); });
    
    // setInterviewBasicDetailList([
    //          { id:'1', email:'student@example.com',  companyName:'c1', companyJobRole:'Dev',  interviewDate:'2023-4-11'},
    //          { id:'2', email:'student@example.com',  companyName:'c2', companyJobRole:'Eng',  interviewDate:'2023-4-12'},
    //          { id:'3', email:'student@example.com',  companyName:'c3', companyJobRole:'Dev',  interviewDate:'2023-4-15'},
    //          { id:'4', email:'student@example.com',  companyName:'c4', companyJobRole:'Test',  interviewDate:'2023-4-14'}
    //     ])
};

    const fetchinterviewQAS=()=>{
        fetch(`${baseURL}/getInterviewQasDetail/${userEmail}`)
        .then(response => response.json())
        .then(data     => { setInterviewQasList(data || []); console.log(JSON.stringify(data));
        })

        .catch(error   => { console.error('Error fetching qas Table-2:', error); });
    //    setInterviewQasList([
    //         { id:'1', interviewBasicDetailID:"1",  email:"student@example.com", questions:'q1', answers:'a1' },
    //         { id:'2', interviewBasicDetailID:"2",  email:"student@example.com", questions:'q2', answers:'a2' },
    //         { id:'3', interviewBasicDetailID:"2",  email:"student@example.com", questions:'q22', answers:'a22' },
    //         { id:'4', interviewBasicDetailID:"3",  email:"student@example.com", questions:'q3', answers:'a3' },
    //         { id:'5', interviewBasicDetailID:"3",  email:"student@example.com", questions:'q33', answers:'a33' },
    //         { id:'6', interviewBasicDetailID:"3",  email:"student@example.com", questions:'q333', answers:'a333' },
    //         { id:'7', interviewBasicDetailID:"4",  email:"student@example.com", questions:'q4', answers:'a4' }
    //    ])
    }
    const deleteInterviewBasicDetails = (itemId) => {
        const updatedInterviewBasicDetailList = interviewBasicDetailList.filter(item => item.id !== itemId);
        setInterviewBasicDetailList(updatedInterviewBasicDetailList);

        fetch(`${baseURL}/deleteInterviewBasicDetail/${itemId}`, {
        method: 'PUT',
        headers: {    'Content-Type': 'application/json', },
        })
        .then(response => {
            if (response.ok) {  console.log('Item deleted successfully');  alert(`Interview ID-${itemId}, deleted Basic`) 
                                setManageIntModalStatus(false)  } 
            else             {  console.error('Failed to delete item');     }
        })
        .catch(error => {     console.error('Item deleting user:', error);});
    };
  

    const addQAS=()=>{

        const sendData={
            interviewBasicDetailID      : addQASID,         email                       : userEmail,
            questions                   : question,         answers                     : answer,

            addQASintDate               : addQASintDate,
            addQAScomJobRole            : addQAScomJobRole,
            addQAScompanyName           : addQAScompany,
            // companyName                 : se
        }

        fetch(`${baseURL}/createInterviewQas`, {
            method: 'POST',
            body: JSON.stringify(sendData),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          })
            .then((response) => {
              if (response.ok) {    
                alert("QAS for ID "+{addQASID}+" added Successfully"); 
                fetchinterviewQAS(); 
                setAddQASModalStatus(!addQASModalStatus);
             } 
              else { console.error('inside API error '); }
            })
            .catch((error) => { console.error('API call error-outside:', error);    });
    }

    const toggleEditQASModal = (itemID) => {
        const selectedItem = interviewQasList.find(item => item.id == itemID);
        //   console.log("selected items="+JSON.stringify(selectedItem));

        if (selectedItem) { 
            setEditQASItemID(itemID);     
            setQuestion(selectedItem.questions);      
            setAnswer(selectedItem.answers);    
        }
        setEditQASModalStatus(!editQASModalStatus); // opening modal
    }

    const handleItemDelete =(itemID)=>{
        const updatedQASList = interviewQasList.filter(item => item.id !== itemID);
        setInterviewQasList(updatedQASList);

        fetch(`${baseURL}/deleteInterviewQAS/${itemID}`, {
            method: 'PUT',
            headers: {  'Content-Type': 'application/json', },
        })
            .then(response => {
                if (response.ok) {  console.log('Item deleted successfully');    alert("Item of ID-"+{itemID}+" Deleted successfully");   } 
                else             {  console.error('Failed to delete item');      alert("Failed to  delete Item of ID-"+{itemID}+" ...!");  }   })
            .catch(error =>      {  console.error('Item deleting user:', error); alert("Failed due to Server Down");}   );

    }

    const saveEditedQAS=()=>{

        const sendData={
            id              : editQASItemID,         
            // email                       : userEmail,   // id=qas table "id"
            questions       : question,              answers                     : answer,
           

        }

        fetch(`${baseURL}/updateInterviewQas/${editQASItemID}`, {
            method: 'POST',
            body: JSON.stringify(sendData),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          })
            .then((response) => {
              if (response.ok) {    
                alert("QAS for ID "+{editQASItemID}+" updated Successfully"); 
                fetchinterviewQAS(); 
                setEditQASModalStatus(!editQASModalStatus); // closing modal- by sucess response
             } 
              else { console.error('inside API error ');   alert("inside API error");  }
            })
            .catch((error) => { console.error('API call error-outside:', error); alert("Server error");   });
    }

    const noDeleteApprovedQAS=()=>{
        alert("Approved item cannot be deleted"); 
    }

    const [tooltipVisibility, setTooltipVisibility] = useState({});
    const [tooltipCannotDeleteVisibility, setTooltipCannotDeleteVisibility] = useState({});
    const [tooltipCannotEditVisibility, setTooltipCannotEditVisibility] = useState({});

  const handleMouseEnterApproved = (itemID) => {    setTooltipVisibility((prevVisibility) => ({ ...prevVisibility, [itemID]: true }));  };
  const handleMouseLeaveApproved = (itemID) => {    setTooltipVisibility((prevVisibility) => ({ ...prevVisibility, [itemID]: false }));  };
  const handleMouseEnterPending = (itemID) => {    setTooltipVisibility((prevVisibility) => ({ ...prevVisibility, [itemID]: true }));  };
  const handleMouseLeavePending = (itemID) => {    setTooltipVisibility((prevVisibility) => ({ ...prevVisibility, [itemID]: false }));  };
  const handleMouseEnterCannotDelete = (itemID) => {    setTooltipCannotDeleteVisibility((prevVisibility) => ({ ...prevVisibility, [itemID]: true }));  };
  const handleMouseLeaveCannotDelete = (itemID) => {    setTooltipCannotDeleteVisibility((prevVisibility) => ({ ...prevVisibility, [itemID]: false }));  };
  const handleMouseEnterCannotEdit = (itemID) => {    setTooltipCannotEditVisibility((prevVisibility) => ({ ...prevVisibility, [itemID]: true }));  };
  const handleMouseLeaveCannotEdit = (itemID) => {    setTooltipCannotEditVisibility((prevVisibility) => ({ ...prevVisibility, [itemID]: false }));  };

  return (
    <div className='contentBackground'>
      <MDBRow>
        <MDBCol> <h3>My Interview Questions</h3> </MDBCol>
        <MDBCol>
             <button className='dropbtn31' onClick={toggleAddInterviewModal}><MDBIcon far icon="file-alt" style={{color:'green'}} />&nbsp;Add Interview</button> &nbsp;&nbsp;
             <button className='dropbtn32' onClick={toggleManageInterviewModal} ><MDBIcon fas icon="dice-d6" style={{color:'orange'}}/>&nbsp;Manage Interview</button> 
             {/* <button className='dropbtn32' onClick={toggleManageInterviewModal} ><MDBIcon fas icon="plus"style={{border:'2px solid blue', borderRadius:'50%'}}/>&nbsp;Manage Interview</button>  */}
        </MDBCol>
        <br/>
        {/* <MDBIcon fas icon="dice-d6" /> */}
      </MDBRow>
    
       <MDBRow>

            {interviewBasicDetailList.map((table)=>(
                <div key={table.id}>
                <MDBAccordion >

                    <MDBAccordionItem collapseId={1} style={{width:'100%'}}
                    headerTitle={<>
                            <MDBRow style={{width:'100%'}}>
                                <MDBCol style={{width:'100%'}}>
                                    <button className='dropbtn3'>{table.companyJobRole}</button>  
                                    <MDBIcon style={{marginLeft:'20px'}} fas icon="building" /> <span style={{marginLeft:'5px', marginRight:'20px'}}>{table.companyName}</span>
                                    {/* <button style={{marginLeft:'15px'}} className='dropbtn3'>B.TabID={table.id}</button>  */}
                                    <MDBIcon far icon="calendar-alt" /><span style={{marginLeft:'10px'}}>{table.interviewDate} </span>
                                </MDBCol>
                                <MDBCol className='d-flex justify-content-end align-items-center'
                                    style={{margin:'15px'}}>
                                    <MDBIcon  fas icon="plus-square"  onClick={() => toggleAddQASModal(table.id, table.companyName, table.interviewDate, table.email, table.companyJobRole)}></MDBIcon>
                                    <span style={{marginLeft:'8px'}}  onClick={() => toggleAddQASModal(table.id, table.companyName, table.interviewDate, table.email, table.companyJobRole)}>Add QAS </span> 
                                </MDBCol>
                            </MDBRow>
                            </>}>

                                    {/* <h1>B.table ID -{table.id}</h1> */}

                     {interviewQasList
                            // .filter(qasItem => qasItem.interviewBasicDetailID===table.id)
                            .map((qasItem) => (
                                <div key={qasItem.id}>
                                    {((qasItem.interviewBasicDetailID)==(table.id))?
                                   
                                    <>
                                    <MDBRow style={{width:'100%'}}> 
                                        <MDBCol style={{minWidth:'75%' }}> 
                                            <p style={{ fontWeight: 'bold'}}>
                                                 <span style={{textDecoration:'underline', marginRight:'5px'}}>Question:</span> 
                                                 {qasItem.questions}</p>
                                        </MDBCol>
                                        <span className='bttn-group' style={{width:'25%',  display: 'flex', justifyContent: 'flex-end' , height:'25px'}}>  
                                        {/* disabled={qasItem.approveStatus} */}
                                        {(qasItem.approveStatus=='true')?
                                                <button
                                                onMouseEnter={()=>handleMouseEnterCannotEdit(qasItem.id)}
                                                onMouseLeave={()=>handleMouseLeaveCannotEdit(qasItem.id)}
                                                style={{ position: 'relative' }}>
                                                <i  className="fas fa-pencil" style={{ color:'grey', fontSize:12, cursor:'pointer', alignContent:'right'}} />
                                                {tooltipCannotEditVisibility[qasItem.id]&&(
                                                    <span  style={{ position: 'absolute', top: '100%', left: '50%',transform: 'translateY(-180%)', width:'100px', fontSize:'12px',
                                                        backgroundColor: '#333', color: 'white',padding: '5px', borderRadius: '5px', zIndex: '1',display: 'block',}}
                                                        >
                                                        Approved item can't Edit</span>
                                                    )}
                                                    
                                                    </button>
                                                    :<>
                                                    <button>
                                                    <i onClick={() => toggleEditQASModal(qasItem.id)} className="fas fa-pencil" style={{ color:'black',fontSize:12, cursor:'pointer', alignContent:'right'}} />
                                                    </button>
                                                    </>}

                                                {(qasItem.approveStatus=='true')?
                                                    <button 
                                                    // onClick={() => noDeleteApprovedQAS()}
                                                    onMouseEnter={()=>handleMouseEnterCannotDelete(qasItem.id)}
                                                    onMouseLeave={()=>handleMouseLeaveCannotDelete(qasItem.id)}
                                                    style={{ position: 'relative' }}>
                                                    <i className="fas fa-trash-can " style={{ fontSize:12, cursor:'pointer', alignContent:'right', color:'grey'}} />
                                                    {tooltipCannotDeleteVisibility[qasItem.id]&&(
                                                        <span  style={{ position: 'absolute', top: '100%', left: '50%',transform: 'translateY(-180%)', width:'100px', fontSize:'12px',
                                                        backgroundColor: '#333', color: 'white',padding: '5px', borderRadius: '5px', zIndex: '1',display: 'block',}}
                                                        >
                                                        Approved item can't delete</span>
                                                    )}
                                                    
                                                    </button>
                                                    :<>
                                                    <button>
                                                    <i onClick={() => handleItemDelete(qasItem.id)} className="fas fa-trash-can red-trash-icon" style={{ fontSize:12, cursor:'pointer', alignContent:'right'}} />
                                                    </button>
                                                    </>}

                                                    {(qasItem.approveStatus=='true')?
                                                            <button
                                                            onMouseEnter={() => handleMouseEnterApproved(qasItem.id)}
                                                            onMouseLeave={() => handleMouseLeaveApproved(qasItem.id)}
                                                            style={{ position: 'relative' }}>
                                                                <MDBIcon fas icon="check" style={{fontSize:12, color:'darkgreen'}}/>
                                                                {tooltipVisibility[qasItem.id] && (
                                                                    <span style={{ position: 'absolute', top: '100%', left: '50%',transform: 'translateY(-180%)', fontSize:'12px',
                                                                    backgroundColor: '#333', color: 'white',padding: '5px', borderRadius: '5px', zIndex: '1',display: 'block',}}
                                                                    >
                                                                    Approved</span>
                                                                )}
                                                                </button>
                                                    :
                                                    <>
                                                     <button
                                                            onMouseEnter={() => handleMouseEnterPending(qasItem.id)}
                                                            onMouseLeave={() => handleMouseLeavePending(qasItem.id)}
                                                            style={{ position: 'relative' , width:'33px'}}>
                                                               <MDBIcon fas icon="exclamation" style={{fontSize:12, color:'orange'}}/>
                                                                {tooltipVisibility[qasItem.id] && (
                                                                    <span style={{ position: 'absolute', top: '100%', left: '50%',transform: 'translateX(-50%)', fontSize:'12px', 
                                                                    backgroundColor: '#333', color: 'white',padding: '5px', borderRadius: '5px', zIndex: '1',display: 'block',}}
                                                                    >
                                                                    Approval Pending</span>
                                                                )}
                                                                </button>
                                                    </>}
                                                

                                        </span>
                                    </MDBRow>
                                        <p> <span style={{textDecoration:'underline', marginRight:'5px'}}>Answer:</span>&nbsp;&nbsp; {qasItem.answers}</p>
                                        <hr/>
                                    </>
                                    :
                                    null
                                    }
                                </div>
                                
                            ))} 

                    </MDBAccordionItem>
                </MDBAccordion> 
                </div>
            ))}
      </MDBRow> 

    

    {/* (2.1)-------- Add Interview - Modal starts -----*/}
    <MDBModal tabIndex='-1' show={addIntModalStatus} setShow={setAddIntModalStatus}>
        <MDBModalDialog centered className="" >
        <MDBModalContent >

            <div className='profileModal1 '>
                <h3><MDBIcon far icon="file-alt" style={{color:'green'}} />&nbsp;Add Interview</h3>
                
                <MDBRow >
                    <MDBCol > <span>Interview Date&nbsp;</span></MDBCol>
                    <MDBCol>   
                        <MDBInput type="date" id="birthday" name="birthday" onChange={e=>setSelectedDate(e.target.value)} value={selectedDate}/>
                    </MDBCol>
                </MDBRow><br/>

                <MDBRow >
                    <MDBCol > <span>Company Name&nbsp;</span></MDBCol>
                    <MDBCol>   
                            <MDBInput  id='form1' type='text' onChange={e=>setCompanyName(e.target.value)} />
                    </MDBCol>
                </MDBRow><br/>

                <MDBRow >
                    <MDBCol > <span>Job Position&nbsp;</span></MDBCol>
                    <MDBCol>   
                            <MDBInput  id='form1' type='text' onChange={e=>setJobPosition(e.target.value)}/>
                    </MDBCol>
                </MDBRow><br/>
            </div>

            <MDBModalFooter>
                <button type="button" className="dropbtnprofilepagesave"    onClick={addInterview}> Save   </button>&nbsp;
                <button type="button" className="dropbtnprofilepagecancel2" onClick={() => setAddIntModalStatus(false)}>   Cancel </button>&nbsp;
            </MDBModalFooter>
                    
        </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    {/*(2.1)--------- Add Interview - Modal ends -------*/}

    {/* (2.2)-------- Manage Interview - Modal starts---*/}
    <MDBModal tabIndex='-1' show={manageIntModalStatus} setShow={setManageIntModalStatus}>
            <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
            <MDBModalContent >

            <div className='profileModal1 '>
                <h3><MDBIcon fas icon="dice-d6" style={{color:'orange'}}/>&nbsp;Manage Interview</h3>

            <MDBTable>
            <MDBTableHead >
            <tr >
                <th scope='col' >ID</th>
                <th scope='col'  >Interview Date</th>
                <th scope='col'  >Email</th>
                <th scope='col'  >Company Name</th>
                <th scope='col'  >Job Role</th>
                <th scope='col'  >Action</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody >
            {interviewBasicDetailList.map((table, index)=>(
                <tr key={table.id}>
                <td >{table.id }</td>
                {/* <td >{index+1 }</td> */}
                <td >{table.interviewDate}</td>
                <td >{table.email}</td>
                <td >{table.companyName}</td>
                <td >{table.companyJobRole}</td>
                <td ><MDBIcon fas icon="trash-alt  "    
                        onClick={() => deleteInterviewBasicDetails(table.id)} style={{ color:'red'}} /></td>
                {/* <td >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <MDBIcon fas icon="cloud-download-alt" onClick={() => handleResumeItemDownload(table.id)} className='hand' style={{marginLeft:'15px', color:'green'}} />
                        <MDBIcon fas icon="cloud-upload-alt"   onClick={() => handleResumeItemUpdate(table.id)} className='hand' style={{marginLeft:'45px', color:'blue'}} />
                        <MDBIcon fas icon="trash-alt  "        onClick={() => handleResumeItemDelete(table.id)} className='hand' style={{marginLeft:'45px', color:'red'}} />

                    </div>
                </td> */}
                </tr>
            ))}

                </MDBTableBody>
            </MDBTable>
            </div>

            <MDBModalFooter>
                <button type="button" className="dropbtnprofilepagesave"    onClick={'saveManageInterview'}> Save   </button>&nbsp;
                <button type="button" className="dropbtnprofilepagecancel2" onClick={() => setManageIntModalStatus(false)}>   Cancel </button>&nbsp;
            </MDBModalFooter>
                    
        </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    {/*(2.2)--------- Manage Interview - Modal ends---- */}

    {/* (2.3)-------- Add Modal QAS starts -------------*/}
    <MDBModal tabIndex='-1' show={addQASModalStatus} setShow={setAddQASModalStatus}>
        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
        <MDBModalContent >
            <MDBModalHeader className='profileModal1 '>
                <h3 style={{fontSize:'20px'}}>Add QAS - &nbsp;
                <MDBIcon style={{color:'blue'}} fas icon="user-tie"/> ID-{addQASID} &nbsp;
                <MDBIcon style={{color:'green'}} fas icon="building" />  {addQAScompany}</h3>

            </MDBModalHeader>
            <div className='profileModal1 '>
                <MDBTextArea label='Question' id='addQuestion' rows={3} onChange={e=>setQuestion(e.target.value)}/><br/>
                <MDBTextArea label='Answer'   id='addAnswer'   rows={6} onChange={e=>setAnswer(e.target.value)}/>
            </div>

            <MDBModalFooter>
                <button type="button" className="dropbtnprofilepagesave"    onClick={addQAS}> Save   </button>&nbsp;
                <button type="button" className="dropbtnprofilepagecancel2" onClick={() => setAddQASModalStatus(false)}>   Cancel </button>&nbsp;
            </MDBModalFooter>
                    
        </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    {/*(2.3)--------- Add Modal QAS ends ---------------*/}

    {/* (2.4)-------- Edit Modal QAS starts ------------*/}
    <MDBModal tabIndex='-1' show={editQASModalStatus} setShow={setEditQASModalStatus}>
        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
        <MDBModalContent >
            <MDBModalHeader className='profileModal1 '>
                <h3 style={{fontSize:'20px'}}>EDIT QAS - 
                <MDBIcon style={{color:'blue'}} fas icon="user-tie"/> ID-{editQASItemID} 
                <MDBIcon style={{color:'green'}} fas icon="building" />  {addQAScompany}</h3>

            </MDBModalHeader>
            <div className='profileModal1 '>
                <MDBTextArea label='Question' id='addQuestion' rows={3} value={question} onChange={e=>setQuestion(e.target.value)}/><br/>
                <MDBTextArea label='Answer'   id='addAnswer'   rows={6} value={answer} onChange={e=>setAnswer(e.target.value)}/>
            </div>

            <MDBModalFooter>
                <button type="button" className="dropbtnprofilepagesave"    onClick={saveEditedQAS}> Save   </button>&nbsp;
                <button type="button" className="dropbtnprofilepagecancel2" onClick={() => setEditQASModalStatus(false)}>   Cancel </button>&nbsp;
            </MDBModalFooter>
                    
        </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    {/*(2.4)--------- Edit Modal QAS ends --------------*/}




    </div>
  )
}

export default MyInterviewQues

