import React, { useState } from 'react';
import '../../App.css'; // You can style the tabs container
import AdminSideNavStudentList from './AdminSideNavStudentList';
import AdminSideNavAccessRole from './AdminSideNavAccessRole';
import AdminSideNavEnquiry from './AdminSideNavEnquiry';
import AdminSideKeySkillHandler from './AdminSideKeySkillHandler';
// import AdminSideYearOptionsHandler from './AdminSideYearOptionsHandler';
import AdminResumeTemplateHandler from './AdminResumeTemplateHandler';
import AdminAllInterviewQues from './AdminAllInterviewQues';
import ApprovedInterviewQues from './AdminApprovedInterviewQues';
import Test from './Test';
import SortTest from './SortTest';

const tabsData = [  
  { id: 1,    title: 'Registered Students List',             content:    <AdminSideNavStudentList/>       },
  { id: 2,    title: 'Access Role',                          content:    <AdminSideNavAccessRole/>        },
  { id: 3,    title: 'Enquiries',                            content:    <AdminSideNavEnquiry/>           },
  { id: 4,    title: 'KeySkill Handler',                     content:    <AdminSideKeySkillHandler/>      },
  { id: 5,    title: 'Interview Questions for Approval',     content:    <AdminAllInterviewQues/>         },
  { id: 6,    title: 'Approved Interview Questions',         content:    <ApprovedInterviewQues/>         },
  { id: 7,    title: 'Resume Template Handler',              content:    <AdminResumeTemplateHandler/>    },
  // { id: 9,    title: 'Test',                                 content:    <Test/>    },
  // { id: 9,    title: 'Test',                                 content:    <SortTest/>    },
 
];

  function AdminSideNav(){
    
  const [activeTab, setActiveTab] = useState(tabsData[0].id);

  const handleTabClick = (tabId) => { setActiveTab(tabId);  };

  return (
    <div className="vertical-tabs-container" >
      
      <div className="vertical-tabs" style={{width:'12%'}}>
        {tabsData.map((tab) => (
          <div
            key={tab.id}
            className={`vertical-tab ${tab.id === activeTab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {tabsData.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane ${tab.id === activeTab ? 'active' : ''}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminSideNav;
