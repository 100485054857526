import * as React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";  
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";  
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";  
import Container from "@mui/material/Container";  
import { createTheme, ThemeProvider } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import {  MDBModal,  MDBModalDialog,  MDBModalContent} from 'mdb-react-ui-kit';
import {baseURL} from './../../http'

const Swal = require("sweetalert2");

const signupurl = `${baseURL}/signup`;

const defaultTheme = createTheme();

export default function SignUp() {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    usertype:"student",
    status: "",
  });

  const [showPassword, setShowPassword]         = useState(false);
  const [acceptedTerms, setAcceptedTerms]       = useState(false);

  const [modalStatus, setModalStatus]           = useState(false);
  function toggleModalterms_cond(e){       setModalStatus(!modalStatus);}

  useEffect(() => { document.title = "TPA - User Sign UP";  });
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handleAcceptedTermsChange = (event) => {
    setAcceptedTerms(event.target.checked);         
    if(!acceptedTerms) {    toggleModalterms_cond();    }
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(`${signupurl}`, user)
      .then(() => {
        setUser({
          name: "",
          email: "",
          password: "",
          mobile: "",
          usertype:"student",
          status: "Success",
        });
        console.log("Registered Successfully");

        Swal.fire({
          title: "User Data Updated",
          icon: "success",
          timer: 2000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error.response);
        alert('The Email or Mobile Number  already exists');
        setUser({ ...user, status: "Error" });
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  value={user.name}
                  autoFocus
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  value={user.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  autoComplete="mobile"
                  onChange={handleChange}
                  value={user.mobile}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    label="Password"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    id="outlined-adornment-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" checked={acceptedTerms}
                  onChange={handleAcceptedTermsChange} />}
                  label={<span style={{ borderBottom:'2px solid blue', color:'blue' }}>I accept all terms and conditions of TPA.</span>}
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={!acceptedTerms}>
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/sign-in" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>


                    
        {/* (2.1)------------------>---Modal code starts */}
         <MDBModal tabIndex='-1' show={modalStatus} setShow={setModalStatus}>
              <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
              <MDBModalContent >

                <div  className='profileModal' style={{textAlign:'justify', fontSize:'14px'}}>
                  <h3>Terms and conditions*</h3>

                  <p>1.	<strong>"Confidential Information" </strong> shall mean any and all information, know-how and data, technical or non-technical, or description concerning any matters affecting or relating to Employee's services for Company, the business or operations of Company, and/or the products, drawings, plans, processes, or other data of Company disclosed or provided by Company to the Employee, whether disclosed or provided in oral, written, graphic, photographic, electronic or any other form.</p>
                  <p>2.	<strong>The Employee/Student/Trainee agrees to:</strong><br/>
                  <div style={{marginLeft:'20px'}}>
                    <ol style={{listStyleType:'lower-alpha'}}>
                        	<li>Hold the Confidential Information received from Company in strict confidence and shall exercise a reasonable degree of care to prevent disclosure to others;</li>
                        	<li>Not disclose or divulge either directly or indirectly the Confidential Information to others unless first authorized to do so by Company.</li>
                        	<li>Employee will not reproduce the Confidential Information nor use this information commercially or for any purpose other than the performance of his/her duties for Company.</li>
                        	<li>Employee will, upon the request or upon termination of his/her relationship with Company, deliver to Company any drawings, notes, documents, videos, equipment, and materials received from Company or originating from its activities for Company.</li>
                        	<li>Employees or Trainees are not allowed to record / take screen shot of any content during the classes.</li>
                        	<li>Sharing of any content to anybody without written consent from the company’s authority will be treated as breach of this NDA agreement.</li>
                        	<li>During the online classes employee or trainee should make sure, no other person should participate in the training or anyone other than employee or trainee should not even watch the live classes. </li>
                        	<li>In any condition breach of any of the above clause will lead to the high penalty (actuals as per the damage to the company) and legal action will be initiated. </li>
                      </ol>
                  </div>
                  </p>
                  <p>3.	Company shall have the sole right to determine the treatment of any information that is q part or project specific received from Employee, including the right to keep the same as a trade secret, to use and disclose the same without prior patent applications, to file copyright registrations in its own name or to follow any other procedure as Company may deem appropriate.</p>
                  <p>4.	The Employee agrees not to file any patent applications claiming any information, developments, discoveries, technologies, inventions and the like arising from the use of Confidential Information or that could not have been made, developed or discovered but for access to Confidential Information.</p>  
                  <p>5.	Should any court of competent jurisdiction later consider any provisions of this Agreement to be invalid, illegal, or unenforceable, such provisions shall be considered severed from this Agreement. All other provisions, rights, and obligations shall continue without regard to the severed provision, provided that the remaining provisions of this Agreement are in accordance with the intentions of the parties.</p>
                  <p>6. Nothing in this Agreement shall be construed as a promise of continued employment for any specific period of time.</p>
                  <p>7.	Nothing in this Agreement modifies or alters the ‘at-will’ nature of employee’s employment.</p>
                  <p>8.	Violation of this Agreement will subject Employee to disciplinary action according to Employer’s disciplinary policy, up to and including termination.</p>
                  
                  <br></br>

                    <button type="button" className="dropbtnprofilepagecancel2" onClick={toggleModalterms_cond}>   close </button>&nbsp;
    
                </div>
                  

              </MDBModalContent>
              </MDBModalDialog>
          </MDBModal>
          {/*(2.1)--------->------------- Modal code ends */}

    </ThemeProvider>
  );
}
