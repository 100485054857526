import React from "react";

import { MDBCol, MDBRow, MDBTypography } from "mdb-react-ui-kit";

import TPABusinessHire from "./TPABusinessHire";
import TPABusinessHowtohire from "./TPABusinessHowtohire";
import TPABusinessRequest from "./TPABusinessRequest";

import Hirefrom1 from "../static/Hirefrom1.jpg";




import { Helmet } from "react-helmet";

function HireTalent() {

  

  return (
    <>
      <div>
        <Helmet>
          <title>Hire Talet from Us-Hire skilled engineers at zero cost</title>
          <meta
            name="description"
            content="Hire skilled engineers
                    at zero cost-Recruit the ideal candidate for your business needs with
                    TPA at bangalore, in a hassle-free way... "
                            />
           <meta
            name="keywords"
            content="Hire Engineers, Skilled Engineers, Engineering Recruitment, Technical Recruitment, Talent Acquisition, Hiring Solutions, Recruitment Services, Engineering Jobs, Staffing Services, TPA- Bangalore "
          />
          ;
        </Helmet>
      </div>
      <MDBRow
        className="p-5  MBHire"
        style={{ backgroundColor: "hsl(38deg 82% 84%)" }}
      >
        <MDBCol center md="6">
          <MDBTypography variant="h1" style={{ color: "black" }}>
            <b>
              Hire skilled engineers
              <br />
              at zero cost
            </b>
          </MDBTypography>
          <p fs-3 fw-bold style={{ color: "black", textAlign:"justify" }}>
            Look no further! Our team of experienced engineers is ready to
            tackle any project. With expertise in various fields, we deliver
            high-quality solutions that meet your needs. Trust our reliable
            professionals to bring innovation and efficiency to your business.
            our engineers will deliver high-quality results on time. Don't miss
            out on the opportunity to work with the best. Contact us today to
            hire skilled engineers with zero cost and take your projects to the
            next level. Contact us today to discuss your engineering
            requirements.
          </p>
        </MDBCol>
        <MDBCol center md="6">
          <img
           
            src={Hirefrom1}
            className="round"
            alt="hire Talent"
            style={{
              width: "100%",

              height: "150%",
            }}
          ></img>
        </MDBCol>
      </MDBRow>

      

      <TPABusinessHire />
      <TPABusinessHowtohire />
      <TPABusinessRequest />
    </>
  );
}

export default HireTalent;
