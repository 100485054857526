import React from "react";
import { MDBContainer, MDBTypography } from "mdb-react-ui-kit";

 function CAE4Description() {
  return (
    <MDBContainer
      id="course"
      fluid
      className="p-1 rounded-7  "
      style={{ color: "black" }}
    >
      <MDBTypography
        variant="h2 courseoverview-body text-start m-3 "
        style={{ color: "black" }}
      >
        Course Description
      </MDBTypography>
      <p
        md="8"
        className="fs-6 courseoverview-body-start text-start m-3 "
        style={{ color: "black" }}
      >
        Our Competency Team will deliver you real and practical knowledge that you need to solve Complex solid mechanics and structural problems Of Multi Domain Industries using Finite Element Analysis and Sophisticated CAE Tools Like Hypermesh,Ansa,Abaqus Ansys Etc.This Program Offers best practical methods and guidelines for the development and validation of finite element models in solid mechanics and structural analysis. “Learn Through Working” Model develops Skill in Our Trainees Produce accurate and reliable FEA Projects by avoiding the most frequent errors.
      </p>
    </MDBContainer>
  );
};

export default CAE4Description;
