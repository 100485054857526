import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
  MDBTypography,
  MDBIcon,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { Helmet } from "react-helmet";

import Curriculum1 from "./Curriculum1";
import Overview1 from "./Overview1";
import CourseHighlights from "./CourseHighlights";
// import TrainingReviews1 from "./TrainingReviews1";
import TrainingProject from "./TrainingProject";
import TrainingCurriculum1 from "./TrainingCurriculum1";

function ProductDesign() {
  return (
    <>
      <div>
        <Helmet>
          <title>Automotive Product Design - Shape the future of Mobility</title>
         

      <meta
        name="description"
        content="Explore our Automotive Product Design course and shape the future of mobility. 
        Develop skills in sustainable transportation solutions, gain project experience, and secure guaranteed placements. Learn more!"
      />


            <meta
        name="keywords"
        content="Automotive Product Design, Transportation Solutions, Sustainability, Mechanical Programs,  Mobility Solutions, OEM Projects"
      />

        </Helmet>
      </div>
    

      <MDBContainer fluid className=" p-1 rounded-7 fullstackBody">
        <figure className="text-start text-light mb-0 mx-4">
          <MDBTypography variant="h1" >
            Job Ready – Automotive Product Design
          </MDBTypography>

          <p class="text-light mb-0">
            <MDBIcon far icon="bookmark pe-2" />
            Category: MECHANICAL PROGRAMS / PRODUCT DESIGN
            <span class="fw-bold">6</span>
          </p>
        </figure>

        <MDBRow>
          <MDBCol md="8">
            <MDBCardBody>
              <div className="bg-image hover-zoom">
                <img
                  style={{ width: "100%", maxHeight: "500px" }}
                  alt="car"
                  src="https://www.depusa.com/wp-content/uploads/2020/12/Automative1.jpg"
                  className="round"
                />
              </div>
            </MDBCardBody>
          </MDBCol>
          <MDBCol start md="4">
            <MDBCardBody className="mechanical-body tpa-list-group">
              <MDBListGroup light>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="users pe-3" size="2x" />
                  <span>70 students</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item "
                >
                  <MDBIcon far icon="clock pe-4" size="2x" />
                  <span>Duration:180 hours</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="chalkboard-teacher pe-3" size="2x" />
                  <span> Lectures:75</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="book pe-4" size="2x" />
                  <span>Course completion</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item-google"
                >
                  <MDBIcon fab icon="google pe-4" size="2x" />
                  <span className="text-dark fs-4 fw-bold pe-2">4.8</span>
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star-half-alt" />
                </MDBListGroupItem>

                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="hand-point-right pe-4" size="2x" />
                  <span>Enroll Now</span>
                </MDBListGroupItem>
              </MDBListGroup>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      
      <Curriculum1 />
      <Overview1 />
      <CourseHighlights />
      <TrainingCurriculum1 />
      <TrainingProject />

      {/* <TrainingReviews1 /> */}
    </>
  
  );
};
export default ProductDesign;
