import React, { useState, useEffect } from 'react'
import { MDBAccordion, MDBAccordionItem, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { baseURL } from '../../http';

function StudentAllApprovedInterviewQues() {

    const [interviewQasList, setInterviewQasList]               = useState([]); // for array null array sq.brackets to be given
    const [uniqueJobRoles, setUniqueJobRoles]                   = useState([]); // for array null array sq.brackets to be given


    useEffect(() => {  fetchinterviewQAS(); fetchUniqueJobrole(); }, []);
    const fetchUniqueJobrole=()=>{
        fetch(`${baseURL}/getUniqueInterviewRoles`)   
        .then(response => response.json())
        .then(data     => { setUniqueJobRoles(data || []); })
        .catch(error   => { console.error('Error fetching qas Table-2:', error); });
    }
    const fetchinterviewQAS=()=>{
        fetch(`${baseURL}/getAllApprovedQas`)   
        .then(response => response.json())
        .then(data     => { setInterviewQasList(data || []); // console.log(JSON.stringify(data));     
        })
        .catch(error   => { console.error('Error fetching qas Table-2:', error); });
    }
    

  return (
    <div className='contentBackground'>
      <h3>All Student's Interview Questions</h3>

      <MDBRow>
      {uniqueJobRoles.map((jobRole)=>(
          <div key={jobRole }>
            <MDBAccordion >

              <MDBAccordionItem collapseId={jobRole +1} headerTitle={<><MDBRow>
                        <span style={{marginLeft:'5px', marginRight:'20px'}}>{jobRole}</span>
                                                                  </MDBRow></>}>
                {interviewQasList
                    // .filter(qasItem => qasItem.interviewBasicDetailID===table.id)
                    .map((qasItem) => (
                            <div key={qasItem.id}>
                              {((qasItem.jobRole)==(jobRole))?
                              <>
                               <MDBRow style={{width:'100%'}}> 
                                        <MDBCol style={{minWidth:'75%' }}> 
                                        <p ><span style={{textDecoration:'underline', fontWeight: 'bold', marginRight:'5px'}}>Question:</span> 
                                                 {qasItem.questions}</p>
                                        <p ><span style={{  marginRight:'5px'}}>Answer:</span> 
                                        {qasItem.answers}</p>

                                        </MDBCol>
                                        </MDBRow>
                              </>
                              :
                              <>
                              </>}
                             </div>
                          ))}
                

              </MDBAccordionItem>
            </MDBAccordion> 
          </div>
            ))}
      </MDBRow>
    </div>
  )
}

export default StudentAllApprovedInterviewQues
