import React, { useState } from "react";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBTextArea,
  MDBIcon,
} from "mdb-react-ui-kit";
import swal from "sweetalert";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Contact from "../More/Contact";

 function FS2TrainingOptions() {
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const [centredModal, setCentredModal] = useState(true);
  const toggleShow = () => setCentredModal(!centredModal);
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: "CATIA Coustmization", label: "CATIA Coustmization" },
    { value: "CREO Coustmization", label: "CREO Coustmization" },
    { value: "NX Coustmization", label: "NX Coustmization" },
    { value: "CATIA Design", label: "CATIA Design" },
    { value: "NX Design", label: "NX Design" },
    { value: "CREO Design", label: "CREO Design" },
    { value: "Full Stack", label: "Full Stack" },
    { value: "Internship", label: "Internship" },
    { value: "Carrer", label: "Carrer" },
    { value: "Trainer", label: "Trainer" },
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const slot = [
    { value: "Mechanical", label: "Mechanical" },
    { value: "IT", label: "IT" },
    { value: "Business", label: "Business" },
  ];
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let errors = {};

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    // Submit form data here
    console.log("Form data:", formValues);
    setFullscreenXlModal(!fullscreenXlModal);
    swal("Joined! Thank you ,Your Slot Booked", "", "success");
  };
  return (
    <>
      <div>
        <MDBContainer fluid className="my-2  p-1 rounded-7  ">
          <MDBRow className="">
            <MDBCol center md="6" className="">
              <MDBCardBody className="m-5">
                <MDBTypography tag="h1" color="dark" className="py-2 ">
                  Full Stack Developer Course by Talent Pool Academy
                </MDBTypography>
                <br />
                <MDBTypography
                  listUnStyled
                  className="mb-0 text-dark fw-bold fs-6"
                >
                  <li className="mb-1">
                    <MDBIcon
                      icon="long-arrow-alt-right"
                      className="me-2 text-dark"
                    />
                    Upskill in DSA & System Design with this Full Stack
                    Developer Course
                  </li>
                  <li className="mb-1">
                    <MDBIcon
                      icon="long-arrow-alt-right"
                      className="me-2 text-dark"
                    />
                    Build real-world projects by masteringReact, MERN,
                    JavaScript, etc.
                  </li>
                  <li className="mb-1">
                    <MDBIcon
                      icon="long-arrow-alt-right"
                      className="me-2 text-dark"
                    />
                    Learn the essential Full Stack development concepts from
                    scratch
                  </li>
                </MDBTypography>
                <br />
              </MDBCardBody>
            </MDBCol>

            <MDBCol center md="6">
              <MDBRow className="d-flex justify-content-start align-items-center ">
                <MDBCol lg="9" className="m-5 p-3">
                  <Contact />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </>
  );
};

export default FS2TrainingOptions;
