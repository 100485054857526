import React from "react";
import { Helmet } from "react-helmet";
import { MDBContainer, MDBTypography } from "mdb-react-ui-kit";

 function  ST4Description() {
  return (
    <>
      <Helmet>
        <title>Course Description | TPA</title>
        <meta
          name="description"
          content="Take your career to the next level with our Automation Testing Course. Get hands-on training in the latest tools and techniques in the field of automation testing and learn about different types of Automation Testing frameworks, working with popular Automation Testing tools such as Selenium, Appium, designing and implementing test automation scripts, and understanding Continuous Integration and Continuous Testing. Our course covers the essential concepts and practical skills required for you to succeed as an automation tester. With our 100% Job Guarantee*, you'll have the confidence and expertise to secure a high-paying position in the industry. Join the online Automation Testing Course with TPA and get ahead of the competition in your career."
        />
        <meta
          name="keywords"
          content="automation testing, automation testing course, selenium, appium, continuous integration, continuous testing, job guarantee, career advancement, TPA-bangalore"
        />
      </Helmet>
    <MDBContainer
      id="course"
      fluid
      className="p-1 rounded-7  "
      style={{ color: "black" }}
    >
      <MDBTypography
        variant="h2 courseoverview-body text-start m-3 "
        style={{ color: "black" }}
      >
        Course Description
      </MDBTypography>
      <p
        md="8"
        className="fs-6 courseoverview-body-start text-start m-3 "
        style={{ color: "black" }}
      >
       Take your career to the next level with our Automation Testing Course. Get hands-on training in the latest tools and techniques in the field of automation testing and learn about different types of Automation Testing frameworks, working with popular Automation Testing tools such as Selenium, Appium, designing and implementing test automation scripts, and understanding Continuous Integration and Continuous Testing. Our course covers the essential concepts and practical skills required for you to succeed as an automation tester. With our 100% Job Guarantee*, you'll have the confidence and expertise to secure a high-paying position in the industry. Join the online Automation Testing Course with TPA and get ahead of the competition in your career.

      </p>
    </MDBContainer>
    </>
  );
};

export default ST4Description;
