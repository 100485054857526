import React, { useState, useEffect } from 'react'
import { MDBCol, MDBRow , MDBRadio, MDBTextArea} from 'mdb-react-ui-kit'
import Select from 'react-select';
import { baseURL } from '../../../http';
const Swal = require("sweetalert2");

function Stscroll8ProjectsAddModal({ togglingModal }) {

  const userInfo = JSON.parse(localStorage.getItem('user-info'));
  const userEmail = userInfo.email;

  const [projectTitle, setProjectTitle]                               = useState(null);
  const [client, setClient]                                           = useState(null);
  const [projectStatus, setProjectStatus]                             = useState(null);
  const [projectdetails, setProjectdetails]                           = useState(null);
  const [projectLocation, setProjectLocation]                         = useState(null);
  const [projectSite, setProjectSite]                                 = useState(null);
  const [empNature, setEmpNature]                                     = useState(null);
  const [roleDescription, setRoleDescription]                         = useState(null);
  const [selectedTeamSize, setSelectedTeamSize]                       = useState(null);
  const [selectedWorkedFromYear, setSelectedWorkedFromYear]           = useState(null);
  const [selectedWorkedFromMonth, setSelectedWorkedFromMonth]         = useState(null);
  const [selectedWorkedTillYear, setSelectedWorkedTillYear]           = useState(null);
  const [selectedWorkedTillMonth, setSelectedWorkedTilMonth]          = useState(null);
  const [selectedProjectTag, setSelectedProjectTag]                   = useState(null);
  const [showMore, setShowMore]                                       = useState('no');

  const [currentYear, setCurrentYear]                                 = useState(null);
  const [currentDate, setCurrentDate]                                 = useState(new Date());

  const [empList, setEmpList]                                         = useState([]); // from DB
/*--------------------Fetching Student's of all emp_details-------------------- */
const fetchStudentEmpList = () => {
  const requestBody = {
    email: userEmail,
  };

  fetch(`${baseURL}/getAllStudentEmployment`, {
    method  : 'POST',
    headers : { 'Content-Type': 'application/json', },
    body    : JSON.stringify(requestBody),
  })
    .then((response) => response.json())
    .then((data) => {
      const companyNames = data.map(item => item.currentCompanyname);
      setEmpList(companyNames);
      // console.log(companyNames);
    })
    .catch((error) => { console.error('Error fetching Emp_details:', error);  });
};

useEffect(() => { fetchStudentEmpList(); }, []);

/*--------------------Fetching Student's of all emp_details Finished-------------------- */

  const monthsOptions = [
    { value: 'Jan', label: 'Jan' }, { value: 'Feb', label: 'Feb'  },   { value: 'Mar', label: 'Mar' },  { value: 'Apr', label: 'Apr' },
    { value: 'May', label: 'May' }, { value: 'Jun', label: 'Jun'  },   { value: 'Jul', label: 'Jul' },  { value: 'Aug', label: 'Aug' },
    { value: 'Sep', label: 'Sep' }, { value: 'Oct', label: 'Oct'  },   { value: 'Nov', label: 'Nov' },  { value: 'Dec', label: 'Dec' },
    ]
    const yearOptions=[
      { value: currentYear - 0, label: currentYear - 0 },
      { value: currentYear - 1, label: currentYear - 1 },
      { value: currentYear - 2, label: currentYear - 2 },
      { value: currentYear - 3, label: currentYear - 3 },
      { value: currentYear - 4, label: currentYear - 4 },
      { value: currentYear - 5, label: currentYear - 5 },
      { value: currentYear - 6, label: currentYear - 6 },
      { value: currentYear - 7, label: currentYear - 7 },
      { value: currentYear - 8, label: currentYear - 8 },
      { value: currentYear - 9, label: currentYear - 9 },
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {  setCurrentDate(new Date()); }, 1000); // Update the date every second
                                            // console.log('current date:='+currentDate.getFullYear());
                                            setCurrentYear(currentDate.getFullYear());
    return () => { clearInterval(intervalId); };
  }, []);
  
    const teamSizeOptions=[
      { value: '1', label: '1'  },      { value: '2', label: '2'  },
      { value: '3', label: '3'  },      { value: '4', label: '4'  },
      { value: '5', label: '5'  },      { value: '6', label: '6'  },
    ];

    const projectOptions= empList.map(companyName => ({
      value: companyName, // Use an appropriate value for each option
      label: companyName, // Use the company name as the label
    }));

  /**---------------------------send API data to Database---------------------------------------- */
    const addProjectDetails=()=>{

      const sendData={
        email                     : userEmail,
        /*------------------------------------------- */
        projectTitle    : projectTitle,                                           client          : client,
        projectTag      : selectedProjectTag?selectedProjectTag.value:'',         projectStatus   : projectStatus,
        projectFromYear : selectedWorkedFromYear?selectedWorkedFromYear.value:'', projectFromMonth: selectedWorkedFromMonth?selectedWorkedFromMonth.value:'',
        projectTillYear : selectedWorkedTillYear?selectedWorkedTillYear.value:'', projectTillMonth: selectedWorkedTillMonth?selectedWorkedTillMonth.value:'',
        projectdetails  : projectdetails,                                         projectLocation : projectLocation,
        projectSite     : projectSite,                                            empNature       : empNature,
        teamSize        : selectedTeamSize?selectedTeamSize.value:'',             roleDescription : roleDescription
        }

      fetch(`${baseURL}/createStudentProject`, {
        method: 'POST',
        body: JSON.stringify(sendData),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            Swal.fire({
              title: "Projects added",
              icon: "success",
              timer: 900,
              position: "center",
              timerProgressBar: true, 
              showConfirmButton: false,
            })
             togglingModal();
            // console.log('FE- Emp_Project added successfully');
          } 
          else {   console.error('inside API error ');   }
        })
        .catch((error) => { console.error('API call error-outside:', error);     });

    }
  return (
    <div className='profileModal'>
      <h3>Add Projects</h3>
      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" >Project title</label><br/>
        <input type="text"
              className="form-control"
              style={{width:'95%', marginLeft:'15px'}}
              id="formGroupExampleInput1" placeholder='Type Your Project title'
              value={ projectTitle || ""}
              onChange={e=>setProjectTitle(e.target.value)}
              />       
      </MDBRow><br/>

      <MDBRow>
      <label htmlFor="exampleFormControlSelect2" >Tag this Project with Employment/Education</label><br></br>
            <MDBRow >
                  <Select
                     value={selectedProjectTag}//selectedOptionYear
                     options={projectOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedProjectTag(selectedOptions)}
                     isClearable={true}// type to filter
                     placeholder="Select Employment/Education"
                   />
            </MDBRow>
      </MDBRow><br/>

      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" >Client</label><br/>
        <input type="text"
              className="form-control"
              style={{width:'95%', marginLeft:'15px'}}
              id="formGroupExampleInput1" placeholder='Type Your Client Details'
              value={ client || ""}
              onChange={e=>setClient(e.target.value)}
              />       
      </MDBRow><br/>

      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" >Project Status</label><br></br>
        <MDBCol>
          <MDBRadio name='projectStatus' id='inlineRadio1' value='In-Progress' label='In-Progress' inline checked={projectStatus === 'In-Progress'} onChange={() => setProjectStatus('In-Progress')} />
          <MDBRadio name='projectStatus' id='inlineRadio2' value='Finished' label='Finished' inline checked={projectStatus === 'Finished'} onChange={() => setProjectStatus('Finished')} />
        </MDBCol>
      </MDBRow><br></br>

      {/* worked From */}
      <MDBRow>
      <label htmlFor="exampleFormControlSelect2" >Worked From</label><br></br>
      <MDBRow >
                <MDBCol>
                  <Select
                     value={selectedWorkedFromYear}//selectedOptionYear
                     options={yearOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedWorkedFromYear(selectedOptions)}
                     isClearable={true}// type to filter
                     placeholder="Select Year"
                   />
                 </MDBCol>
                 <MDBCol >
                   <Select
                     value={selectedWorkedFromMonth}
                     options={monthsOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedWorkedFromMonth(selectedOptions)}
                     isClearable={true}
                     placeholder="Select Month"
                  />
                </MDBCol>
              </MDBRow><br></br>
      </MDBRow><br/>

      {/* worked Till */}
      {(projectStatus==='Finished')?<>
      <MDBRow>
            <label htmlFor="exampleFormControlSelect2" >Worked Till</label><br></br>
            <MDBRow >
                      <MDBCol>
                        <Select
                          value={selectedWorkedTillYear}//selectedOptionYear
                          options={yearOptions}
                          onInputChange={(inputValue) => inputValue}
                          onChange={(selectedOptions) => setSelectedWorkedTillYear(selectedOptions)}
                          isClearable={true}// type to filter
                          placeholder="Select Year"
                        />
                      </MDBCol>
                      <MDBCol >
                        <Select
                          value={selectedWorkedTillMonth}
                          options={monthsOptions}
                          onInputChange={(inputValue) => inputValue}
                          onChange={(selectedOptions) => setSelectedWorkedTilMonth(selectedOptions)}
                          isClearable={true}
                          placeholder="Select Month"
                        />
                      </MDBCol>
                    </MDBRow><br/>
            </MDBRow><br/>
          </>:null}

      {/* Details of Project */}
      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" >Details of Project</label><br/>
        <input type="text"
              className="form-control"
              style={{width:'95%', marginLeft:'15px'}}
              id="formGroupExampleInput1" placeholder='Type Your Project Details'
              value={ projectdetails || ""}
              onChange={e=>setProjectdetails(e.target.value)}
              />       
      </MDBRow><br/>

      {(showMore !== 'yes')?(<>
      <MDBRow>
      <label htmlFor="exampleFormControlSelect2" onClick={e=>setShowMore('yes')} style={{color:'blue'}}>Add More Details</label><br></br>
      </MDBRow><br/>
      </>):null}

      {(showMore === 'yes')?(<>
          <MDBRow>
            <label htmlFor="exampleFormControlSelect2" >Project Location</label><br/>
            <input type="text"
                  className="form-control"
                  style={{width:'95%', marginLeft:'15px'}}
                  id="formGroupExampleInput1" placeholder='Type Your Project Details'
                  value={ projectLocation || ""}
                  onChange={e=>setProjectLocation(e.target.value)}
                  />       
          </MDBRow><br/>
          

          <MDBRow>
            <label htmlFor="exampleFormControlSelect2" >Project Site</label><br></br>
            <MDBCol>
              <MDBRadio name='projectSite' id='inlineRadio1' value='Offsite' label='Offsite' inline checked={projectSite === 'Offsite'} onChange={() => setProjectSite('Offsite')} />
              <MDBRadio name='projectSite' id='inlineRadio2' value='Onsite' label='Onsite' inline checked={projectSite === 'Onsite'} onChange={() => setProjectSite('Onsite')} />
            </MDBCol>
          </MDBRow><br></br>

          <MDBRow>
            <label htmlFor="exampleFormControlSelect2" >Nature of the Employment</label><br></br>
            <MDBCol>
              <MDBRadio name='empNature' id='inlineRadio1' value='Full time' label='Full time' inline checked={empNature === 'Full time'} onChange={() => setEmpNature('Full time')} />
              <MDBRadio name='empNature' id='inlineRadio2' value='Part time' label='Part time' inline checked={empNature === 'Part time'} onChange={() => setEmpNature('Part time')} />
              <MDBRadio name='empNature' id='inlineRadio3' value='Contractual' label='Contractual' inline checked={empNature === 'Contractual'} onChange={() => setEmpNature('Contractual')} />
            </MDBCol>
          </MDBRow><br></br>

          <MDBRow>
          <label htmlFor="exampleFormControlSelect2" >Team Size</label><br></br>
                <MDBRow >
                      <Select
                        value={selectedTeamSize}//selectedOptionYear
                        options={teamSizeOptions}
                        onInputChange={(inputValue) => inputValue}
                        onChange={(selectedOptions) => setSelectedTeamSize(selectedOptions)}
                        isClearable={true}// type to filter
                        placeholder="Select Team size"
                      />
                </MDBRow>
          </MDBRow><br/>

          <MDBRow>
                <MDBCol style={{ width:'800px'}}>
                    <label htmlFor="exampleFormControlSelect1" >Role Description</label>
                    <MDBTextArea
                        id='textAreaExample'
                        rows={2}
                        value={ roleDescription || ""}
                        onChange={e=>setRoleDescription(e.target.value)} 
                        />
                  
                  </MDBCol>
              </MDBRow><br></br>

          </>):null}
         
                <br></br>
          <button type="button" className="dropbtnprofilepagesave"    onClick={addProjectDetails}> Save   </button>&nbsp;
          <button type="button" className="dropbtnprofilepagecancel2" onClick={togglingModal}>   Cancel </button>&nbsp;
              
    </div>
    
  )
}

export default Stscroll8ProjectsAddModal
