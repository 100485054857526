import React from 'react'
import AdminHeader from './AdminHeader'
import AdminSideNav from './AdminSideNav'

function AdminDashboard() {
  return (
    <div>
      
      <AdminHeader/>  <br/>

      <AdminSideNav/>

      
    </div>

  )
}

export default AdminDashboard
