import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";

function TPABusinessHowtoHire() {
  return (
    <>
      <MDBRow
        className="p-5 MBfooter"
        style={{ color: "black", backgroundColor: "hsl(38deg 82% 84%)" }}
      >
        <MDBTypography
          tag="h1"
          color="light"
          className=" text-start fw-bold mb-5 "
        >
          How to hire from us?
        </MDBTypography>
        {/* <figure class="text-start mb-2 ">
          <p class="  h1 ">How to hire from us?</p>
        </figure> */}
        <MDBCol md="3">
          <MDBCard
            shadow="0"
            border="primary"
            background="white"
            className="mb-3 step-1 h-100"
          >
            <MDBCardBody className="">
              <MDBCardTitle>Step-1</MDBCardTitle>
              <MDBCardText>Request a call back</MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol md="3">
          <MDBCard
            shadow="0"
            border="dark"
            background="white"
            className="mb-3 step-2 h-100"
          >
            <MDBCardBody className="">
              <MDBCardTitle> Step 2</MDBCardTitle>
              <MDBCardText>
                Discuss your requirements and hiring needs
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol md="3">
          <MDBCard
            shadow="0"
            border="secondary"
            background="white"
            className="mb-3  step-2 h-100"
          >
            <MDBCardBody className="">
              <MDBCardTitle>Step 3</MDBCardTitle>
              <MDBCardText>
                Handpick your candidates from our skilled students
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol md="3">
          <MDBCard
            shadow="0"
            border="secondary"
            background="white"
            className="mb-3 step-2 h-100"
          >
            <MDBCardBody className="">
              <MDBCardTitle>Step-4</MDBCardTitle>
              <MDBCardText>
                Interview & hire suitable candidates with ease
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
     
    </>
  );
}

export default TPABusinessHowtoHire;
