import React, { useState, useEffect } from 'react'
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBRadio, MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit';
// import Stscroll12PersonalDetailsModal from './Stscroll12PersonalDetailsModal';
import Select from 'react-select';
import { baseURL } from '../../../http';
const Swal = require("sweetalert2");

function Stscroll12PersonalDetails() {
  
  const userInfo                                    = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                                   = userInfo.email;
  const [gender, setGender]                         = useState('');
  const [maritalStatus, setMaritalStatus]           = useState('');
  const [careerBreak, setCareerBreak]               = useState('');
  const [dobDate, setDobDate]                       = useState('');
  const [dobMonth, setDobMonth]                     = useState('');
  const [dobYear, setDobYear]                       = useState(''); 
  const [category, setCategory]                     = useState('');
  const [workPermit, setWorkPermit]                 = useState('');
  const [address, setAddress]                       = useState('');
  const [differentlyAbled, setDifferentlyAbled]     = useState('');

  const [moreInfo, setMoreInfo]                 = useState('');
  const [selectedDate, setSelectedDate]         = useState('');
  const [selectedMonth, setSelectedMonth]       = useState('');
  const [selectedYear, setSelectedYear]         = useState('');
  const [hometown, setHometown]                 = useState('');
  const [pincode, setPincode]                   = useState('');

  const [currentYear, setCurrentYear]                                 = useState(null);
  const [currentDate, setCurrentDate]                                 = useState(new Date());


  const [modalStatus, setModalStatus]               = useState(false);
  function toggleModal(e){       setModalStatus(!modalStatus);}

  /*------------------------useEffect for profile details update----------------- */
  useEffect(() => {
    fetchUserData();
  }, []);
  
  const fetchUserData = async () => {
    try {
          // let items =  userEmail ;         // wrong format - as below like json to send
          const requestBody = {
            email : userEmail,                
          };
          const response = await fetch(`${baseURL}/getprofile`, {
            method: 'POST',
            body:JSON.stringify(requestBody),
            headers: {
              'Content-Type': 'application/json',
              'Accept'      : 'application/json',
            },
          });
          
          if (response.ok) {
            const data = await response.json();
            setGender(data.gender || "");                       setDobDate(data.dobDate || "");
            setMaritalStatus(data.maritalStatus || "");         setDobMonth(data.dobMonth || "");
            setCareerBreak(data.careerBreak || "");             setDobYear(data.dobYear || ""); 
            setWorkPermit(data.workPermit || "");               setCategory(data.personalCategory || "");
            setAddress(data.permanentAddress || "");            setDifferentlyAbled(data.differentlyAbled || "");
            setPincode(data.pincode || "");                     setHometown(data.hometown || "");
                                                                setSelectedDate(dateOptions.find(option => option.value === data.dobDate))
            setCategory(data.personalCategory || "");           setSelectedMonth(monthOptions.find(option => option.value === data.dobMonth))
            setMoreInfo(data.moreInfo || "");                   setSelectedYear(yearOptions.find(option => option.value === data.dobYear))
          } else {  console.error('Failed to fetch item');
        }
        } catch (error) { console.error('Error getting api:', error);  }
      };

      const addPersDetail =()=>{
        const sendData={
          email                 : userEmail,
          gender                : gender,                                 moreInfo         : moreInfo,
          maritalStatus         : maritalStatus,                          birthDate        : selectedDate?selectedDate.value:'',
          birthMonth            : selectedMonth?selectedMonth.value:'',   birthYear        : selectedYear?selectedYear.value:'',
          personalCategory      : category,                               differentlyAbled : differentlyAbled,
          careerBreak           : careerBreak,                            permanentAddress : address,
          hometown              : hometown,                               pincode          : pincode
        }
        fetch(`${baseURL}/createStudentPersonalDetails`, {
          method: 'POST',
          body: JSON.stringify(sendData),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        })
          .then((response) => {
            if (response.ok) {
              Swal.fire({
                title: "Personal details Updated",
                icon: "success",
                timer: 900,
                position: "center",
                timerProgressBar: true, 
                showConfirmButton: false,
              })
               setModalStatus(false);
              console.log('FE- Personal_detail added successfully');
            } else {
              console.error('inside API error ');
            }
          })
          .catch((error) => {
            console.error('API call error-outside:', error);
          });
    
      }
      const dateOptions=[
        { value: '1', label: '1'  },  { value: '2', label: '2'  }, { value: '3', label: '3'  },  { value: '4', label: '4'  },
        { value: '5', label: '5'  },  { value: '6', label: '6'  }, { value: '7', label: '7'  },  { value: '8', label: '8'  },
        { value: '9', label: '9'  },  { value: '10', label: '10'}, { value: '11', label: '11'},  { value: '12', label: '12'},
        { value: '13', label: '13'},  { value: '14', label: '14'}, { value: '15', label: '15'},  { value: '16', label: '16'},
        { value: '17', label: '17'},  { value: '18', label: '18'}, { value: '19', label: '19'},  { value: '20', label: '20'},
        { value: '21', label: '21'},  { value: '22', label: '22'}, { value: '23', label: '23'},  { value: '24', label: '24'},
        { value: '25', label: '25'},  { value: '26', label: '26'}, { value: '27', label: '27'},  { value: '28', label: '28'},
        { value: '29', label: '29'},  { value: '30', label: '30'}, { value: '31', label: '31'},  
      ];
    
      const monthOptions=[
        { value: 'Jan', label: 'Jan'  }, { value: 'Feb', label: 'Feb'  }, { value: 'Mar', label: 'Mar'  }, 
        { value: 'Apr', label: 'Apr'  }, { value: 'May', label: 'May'  }, { value: 'Jun', label: 'Jun'  }, 
        { value: 'Jul', label: 'Jul'  }, { value: 'Aug', label: 'Aug'  }, { value: 'Sep', label: 'Sep'  }, 
        { value: 'Oct', label: 'Oct'  }, { value: 'Nov', label: 'Nov'  }, { value: 'Dec', label: 'Dec'  }, 
      ];
    
      const yearOptions=[
        { value: currentYear - 18, label: currentYear - 18 },        { value: currentYear - 19, label: currentYear - 19 },
        { value: currentYear - 20, label: currentYear - 20 },        { value: currentYear - 21, label: currentYear - 21 },
        { value: currentYear -22,  label: currentYear - 22 },        { value: currentYear -23,  label: currentYear - 23 },
        { value: currentYear -24,  label: currentYear - 24 },        { value: currentYear -25,  label: currentYear - 25 },
        { value: currentYear -26,  label: currentYear - 26 },        { value: currentYear -27,  label: currentYear - 27 },
        { value: currentYear -28,  label: currentYear - 28 },        { value: currentYear -29,  label: currentYear - 29 },
        { value: currentYear -30,  label: currentYear - 30 },        { value: currentYear -31,  label: currentYear - 31 },
        { value: currentYear -32,  label: currentYear - 32 },        { value: currentYear -33,  label: currentYear - 33 },
        { value: currentYear -34,  label: currentYear - 34 },        { value: currentYear -35,  label: currentYear - 35 },
        { value: currentYear -36,  label: currentYear - 36 },        { value: currentYear -37,  label: currentYear - 37 },
        { value: currentYear -38,  label: currentYear - 38 },        { value: currentYear -39,  label: currentYear - 39 },
        { value: currentYear -40,  label: currentYear - 40 },        { value: currentYear -41,  label: currentYear - 41 },
        { value: currentYear -42,  label: currentYear - 42 },        { value: currentYear -43,  label: currentYear - 43 },
        { value: currentYear -44,  label: currentYear - 44 },        { value: currentYear -45,  label: currentYear - 45 },
        { value: currentYear -46,  label: currentYear - 46 },        { value: currentYear -47,  label: currentYear - 47 },
      ];
      useEffect(() => {
        const intervalId = setInterval(() => {  setCurrentDate(new Date()); }, 1000); // Update the date every second
                                                // console.log('current date:='+currentDate.getFullYear());
                                                setCurrentYear(currentDate.getFullYear());
        return () => { clearInterval(intervalId); };
      }, []);

  return (
    <div>
      <>
      <MDBRow>
       <h5 style={{color:'black'}}>Personal Details  <i  onClick={toggleModal}className="fas fa-pencil" style={{paddingBottom:'8px', fontSize:12, cursor:'pointer'}}></i></h5>
      </MDBRow>
        <div>
          <MDBRow>
            <MDBCol>
              <label htmlFor="exampleFormControlSelect2" >Personal</label><br></br>
              <h6>{gender || ""}, {maritalStatus || ""}</h6>
            </MDBCol><br></br><br></br>

            <MDBCol>
              <label htmlFor="exampleFormControlSelect2" >Career break</label><br></br>
              <h6>{careerBreak || ""}</h6>
            </MDBCol>
          </MDBRow><br/>
          <MDBRow>
          <MDBCol>
              <label htmlFor="exampleFormControlSelect2" >Date of birth</label><br/>
              <h6>{dobDate || ""} {dobMonth || ""} {dobYear || ""}</h6>
            </MDBCol><br/>

            <MDBCol>
              <label htmlFor="exampleFormControlSelect2" >Work permit</label><br></br>
              <h6>{workPermit || ""}</h6>
            </MDBCol>
          </MDBRow><br/>
          
          <MDBRow>
            <MDBCol>
            <label htmlFor="exampleFormControlSelect2" >Category</label><br></br>
              <h6>{category || ""}</h6>
            </MDBCol>
            <MDBCol>
            <label htmlFor="exampleFormControlSelect2" >Address</label><br></br>
              <h6>{address || ""}</h6>
            </MDBCol>
          </MDBRow><br/>

            
          <MDBRow>
            <MDBCol>
              <label htmlFor="exampleFormControlSelect2" >Differently abled</label><br></br>
                <h6>{differentlyAbled || ""}</h6><br/><br/>
          </MDBCol>
          </MDBRow><br/>
            

        </div>
        </>


        {/* (2.1)------------------>---Modal code starts */}
         <MDBModal tabIndex='-1' show={modalStatus} setShow={setModalStatus}>
              <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
              <MDBModalContent >
                  
                    {/* <Stscroll12PersonalDetailsModal togglingModal={toggleModal}/> */}
                    <div className='profileModal'>
                      <h3>Personal Details</h3>  <br/>
                      <MDBRow>
                        <label htmlFor="exampleFormControlSelect1">Gender</label><br></br>
                        <MDBCol >
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={gender === 'Male' ? 'primary' : 'dark'} onClick={() => setGender('Male')}>Male</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={gender === 'Female' ? 'primary' : 'dark'} onClick={() => setGender('Female')}>Female</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={gender === 'Transgender' ? 'primary' : 'dark'} onClick={() => setGender('Transgender')}>Transgender</MDBBtn>
                        </MDBCol>
                      </MDBRow>    <br/>

                      <MDBRow>
                        <label htmlFor="exampleFormControlSelect2">More information</label><br></br>
                        <MDBCol >
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={moreInfo === 'Single parent +' ? 'primary' : 'dark'} onClick={() => setMoreInfo('Single parent +')}>Single parent +</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={moreInfo === 'Working mother +' ? 'primary' : 'dark'} onClick={() => setMoreInfo('Working mother +')}>Working mother +</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={moreInfo === 'Served in military +' ? 'primary' : 'dark'} onClick={() => setMoreInfo('Served in military +')}>Served in military +</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={moreInfo === 'Retired (60+)' ? 'primary' : 'dark'} onClick={() => setMoreInfo('Retired (60+)')}>Retired (60+)</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={moreInfo === 'LGBTQ+ +' ? 'primary' : 'dark'} onClick={() => setMoreInfo('LGBTQ+ +')}>LGBTQ+ +</MDBBtn>
                        </MDBCol>
                      </MDBRow>    <br/>

                      <MDBRow>
                        <label htmlFor="exampleFormControlSelect3">Marital Status</label><br></br>
                        <MDBCol >
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={maritalStatus === 'Single/unmarried' ? 'primary' : 'dark'} onClick={() => setMaritalStatus('Single/unmarried')}>Single/unmarried</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={maritalStatus === 'Married' ? 'primary' : 'dark'} onClick={() => setMaritalStatus('Married')}>Married</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={maritalStatus === 'Widowed' ? 'primary' : 'dark'} onClick={() => setMaritalStatus('Widowed')}>Widowed</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={maritalStatus === 'Divorced' ? 'primary' : 'dark'} onClick={() => setMaritalStatus('Divorced')}>Divorced</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={maritalStatus === 'Seperated' ? 'primary' : 'dark'} onClick={() => setMaritalStatus('Seperated')}>Seperated</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={maritalStatus === 'Other' ? 'primary' : 'dark'} onClick={() => setMaritalStatus('Other')}>Other</MDBBtn>
                        </MDBCol>
                      </MDBRow>   <br/>

                      <MDBRow>
                        <label htmlFor="exampleFormControlSelect4">Date of Birth</label><br></br>
                        <MDBRow>
                          <MDBCol >
                              <Select rounded
                                value={selectedDate || ""}//selectedOptionYear
                                options={dateOptions || ""}
                                onInputChange={(inputValue) => inputValue}
                                onChange={(selectedOptions) => setSelectedDate(selectedOptions)}
                                //isClearable={true}// type to filter
                                placeholder="Select Date"
                                className="custom-select"
                                />
                            </MDBCol>

                            <MDBCol >
                              <Select rounded
                                value={selectedMonth || ""}//selectedOptionYear
                                options={monthOptions || ""}
                                onInputChange={(inputValue) => inputValue}
                                onChange={(selectedOptions) => setSelectedMonth(selectedOptions)}
                                //isClearable={true}// type to filter
                                placeholder="Select Month"
                                className="custom-select"
                                />
                            </MDBCol>

                            <MDBCol >
                              <Select rounded
                                value={selectedYear || ""}//selectedOptionYear
                                options={yearOptions || ""}
                                onInputChange={(inputValue) => inputValue}
                                onChange={(selectedOptions) => setSelectedYear(selectedOptions)}
                                //isClearable={true}// type to filter
                                placeholder="Select Year"
                                className="custom-select"
                                />
                            </MDBCol>
                        </MDBRow>
                      </MDBRow>   <br/>

                      <MDBRow>
                        <label htmlFor="exampleFormControlSelect3">Category</label><br></br>
                        <MDBCol >
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={category === 'General' ? 'primary' : 'dark'}               onClick={() => setCategory('General')}>General</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={category === 'Scheduled Caste (SC)' ? 'primary' : 'dark'}  onClick={() => setCategory('Scheduled Caste (SC)')}>Scheduled Caste (SC)</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={category === 'Scheduled Tribe (ST)' ? 'primary' : 'dark'}  onClick={() => setCategory('Scheduled Tribe (ST)')}>   Scheduled Tribe (ST)</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={category === 'Divorced' ? 'primary' : 'dark'}              onClick={() => setCategory('Divorced')}>  Divorced</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={category === 'OBC' ? 'primary' : 'dark'}                   onClick={() => setCategory('OBC')}> OBC</MDBBtn>
                          <MDBBtn outline rounded className="mx-2 mb-2" size="sm" color={category === 'Non-OBC' ? 'primary' : 'dark'}               onClick={() => setCategory('Non-OBC')}>     Non-OBC</MDBBtn>
                        </MDBCol>
                      </MDBRow>   <br/>

                      <MDBRow>
                        <label htmlFor="exampleFormControlSelect2" >Differently Abled?</label><br></br>
                          <MDBCol>
                          <MDBRadio name='diffAbled' id='inlineRadio1' value='Yes' label='Yes' inline checked={differentlyAbled === 'Yes'} onChange={() => setDifferentlyAbled('Yes')} />
                          <MDBRadio name='diffAbled' id='inlineRadio2' value='No' label='No'   inline checked={differentlyAbled === 'No'} onChange={() => setDifferentlyAbled('No')} />
                          </MDBCol>
                      </MDBRow><br></br>

                      <MDBRow>
                        <label htmlFor="exampleFormControlSelect2" >Have you taken a career break?</label><br></br>
                          <MDBCol>
                          <MDBRadio name='careerBreak' id='inlineRadio3' value='Yes' label='Yes' inline checked={careerBreak === 'Yes'} onChange={() => setCareerBreak('Yes')} />
                          <MDBRadio name='careerBreak' id='inlineRadio4' value='No' label='No'   inline checked={careerBreak === 'No'} onChange={() => setCareerBreak('No')} />
                          </MDBCol>
                      </MDBRow><br></br>

                      <MDBRow>
                      <label htmlFor="exampleFormControlSelect2" >Permanent Address</label><br></br>
                                      <div className='formProfile'>
                                        <input type="text"
                                          className="form-control" 
                                          id="formGroupExampleInput1" placeholder='Type Your Address'
                                          value={address || ""}
                                          onChange={e=>setAddress(e.target.value)} 
                                          style={{ width: '100%', maxWidth: '860px', marginLeft: '-20px', marginRight: 'auto' }}
                        
                                          />
                                      </div><br></br>
                      </MDBRow><br></br>

                      <MDBRow>
                      <label htmlFor="exampleFormControlSelect2" >Hometown--</label><br></br>
                                <div className='formProfile'>
                                  <input type="text"
                                    className="form-control" 
                                    id="formGroupExampleInput1" placeholder='Enter Your Hometown'
                                    value={hometown || ""}
                                    rows={3}
                                    onChange={(e)=>setHometown(e.target.value)} 
                                    style={{ width: '100%', maxWidth: '860px', marginLeft: '-20px', marginRight: 'auto' }}
                                    />
                                </div><br></br>
                      </MDBRow><br></br>

                      <MDBRow>
                      <label htmlFor="exampleFormControlSelect2" >Pincode</label><br></br>
                                <div className='formProfile'>
                                  <input type="number"
                                    className="form-control" 
                                    id="formGroupExampleInput1" placeholder='Enter Your Pincode'
                                    value={pincode || ""}
                                    rows={3}
                                    onChange={(e)=>setPincode(e.target.value)} 
                                    style={{ width: '100%', maxWidth: '860px', marginLeft: '-20px', marginRight: 'auto' }}
                                    />
                                </div><br></br>
                      </MDBRow><br></br>

                          <button type="button" className="dropbtnprofilepagesave"    onClick={addPersDetail}> Save   </button>&nbsp;
                          <button type="button" className="dropbtnprofilepagecancel2" onClick={toggleModal}>   Cancel </button>&nbsp;
                              
                    </div>
                      
              </MDBModalContent>
              </MDBModalDialog>
          </MDBModal>
          {/*(2.1)--------->------------- Modal code ends */}







    </div>
  )
}

export default Stscroll12PersonalDetails
