import React, { useState } from "react";
import ModalContext from "./ModalContext";

const ModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);

  const openModal = (content, title) => {
    setModalOpen(true);
    setModalContent(content);
    setModalTitle(title);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
    setModalTitle(null);
  };

  return (
    <ModalContext.Provider
      value={{ modalOpen, modalContent, modalTitle, openModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
