import React from "react";

import {
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBCardBody,
} from "mdb-react-ui-kit";

import Contact from "../More/ContactBodyHireTalent";

function TPABusinessRequest() {

  

  

  return (
    <>
      <MDBRow className="pb-3 mt-5">
        <MDBCol center md="7">
          <MDBCardBody>
            <MDBTypography
              tag="h1"
              color="dark"
              className=" text-start fw-bold  "
            >
              Request a callback
            </MDBTypography>
            <MDBTypography tag="h6" color="dark" className="pt-2">
              Our placement expert will get in touch with you within 24 hours on
              business working days
            </MDBTypography>
          </MDBCardBody>
        </MDBCol>
        <MDBCol center md="5" className="">
          <Contact />
        </MDBCol>
      </MDBRow>

      
    </>
  );
}

export default TPABusinessRequest;
