import React, { useState, useEffect } from 'react';
import { MDBCol, MDBRow, MDBIcon, MDBModalTitle, MDBModalHeader } from 'mdb-react-ui-kit';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalFooter } from 'mdb-react-ui-kit';
import { baseURL } from '../../../http';
import NotAvilable from "../../static/NotAvailable.JPG";
import StudentBasicDetailModal from './1StudentBasicDetailModal';

function StudentProfilePage() {
  const [pfname, setPfname] = useState('');
  const [mobile, setMobile] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [experience, setExperice] = useState('');
  const [email, setEmail] = useState('');
  const [expMonth, setExpmonth] = useState('');
  const [expSalary, setExpSalary] = useState('');
  const [location, setLocation] = useState('');
  const [currentEmployment, setCurrentEmployment] = useState('');
  const [photoUrl, setPhotoUrl] = useState(''); // Add state for photo URL

  const userInfo = JSON.parse(localStorage.getItem('user-info'));
  const userEmail = userInfo.email;
  const userName = userInfo.name;
  const [profilePercentage, setProfilePercentage] = useState(0);

  const [modalStatus, setModalStatus] = useState(false);
  function toggleModal() {setModalStatus(!modalStatus);}

  /*------------------------useEffect for profile details update------------------------ */
  useEffect(() => {
    fetchUserData();
    fetchCurrentEmployment();
    fetchProfilePhoto();
    fetchProfilePercentage();
  }, []);
  useEffect(() => { document.title = "TPA - Student updateProfilePage";  });

  const fetchUserData = async () => {
    try {
      const requestBody = {
        email: userEmail,
      };
      const response = await fetch(`${baseURL}/getprofile`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPfname(data.name || "");                     
        // console.log('name-'+data.name);
        setEmail(data.email || "");                     
        // console.log('email-'+data.email);
        setMobile(data.phone || "");                    
        // console.log('phone-'+data.phone);
        setLocation(data.location || '');         
        // console.log('location-'+data.location);
        setNoticePeriod(data.noticePeriod || "");       
        // console.log('noti-'+data.noticePeriod);
        setExperice(data.experience || "");             
        // console.log('exp-'+data.experience);
        setExpmonth(data.experienceMonth || "");        
        // console.log('exMon'+data.experienceMonth);
        setExpSalary(data.expectedSalary || "");        
        // console.log('exSal-'+data.expectedSalary);
        // setProfilePercentage('');
      } else {
        console.error('Error fetching user data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchProfilePercentage = () => {
    fetch(`${baseURL}/getProfilePercentage/${userEmail}`)
      .then(response => response.json())
      .then(data =>   {     setProfilePercentage(data.profilePercentage || "");           })
      .catch(error => {     console.error('Error fetching Profile Percentage:', error);   });
  };

  const fetchCurrentEmployment = async () => {
    try {
      const requestBody = {
        email: userEmail,
      };
      const response = await fetch(`${baseURL}/getCurrentEmployment`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                },
      });

      if (response.ok) {
                        const data = await response.json();
                        setCurrentEmployment(data.currentCompanyname || "");
                        // console.log('curCom'+data.currentCompanyname);
                       } 
      else             {    console.error('Error fetching user data:', response.statusText);    }
    } catch (error)    {    console.error('Error fetching user data:', error);                  }
  };

  /* ---------------------------------------- fetch Profile Pic---------------------------*/
  const fetchProfilePhoto = async () => {
    try {
      const response = await fetch(`${baseURL}/uploads/profile_Pictures/${userEmail}`);

      if (response.ok) {
        // If the photo exists, update the photo URL
        setPhotoUrl((`${baseURL}/uploads/profile_Pictures/${userEmail}`)|| "");
        // console.log('photo='+photoUrl);
      } else {
        setPhotoUrl(NotAvilable);
        console.error('Error fetching profile photo api:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching profile photo:', error);
    }
  };

  /* ---------------------------------------- Update Profile Pic---------------------------*/
  function saveProfilePic(e) {
    const imageFile = e.target.files[0];
    setPhotoUrl(URL.createObjectURL(imageFile));

    const formData = new FormData(); // Create a FormData object to send the image file
    formData.append('image', imageFile);

    fetch(`${baseURL}/uploadProfilePicture/${userEmail}`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          console.log('FE-Image uploaded success');
        } else {
          console.error('Error uploading image:', response.statusText);
        }
      })
      .catch((error) => {
        console.error('Network error while uploading image:', error);
      });
  }
  /* ---------------------------------------- handle resize---------------------------*/

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div >
      {screenWidth >= 1000 && (
    <div className="rounded-6" style={{ margin: '0 auto', backgroundColor: 'white', maxWidth: '1200px' }}>
      <MDBRow style={{ width: '100%', margin: '-2px' }}>
        <MDBCol className="w-100 h-100" style={{ maxWidth: '180px', textAlign: 'center', marginLeft: '8px' , paddingTop:'8px'}}>
          <div className="w-100 h-100" style={{ height: '200px', display: 'flex', justifyContent: 'center', paddingBottom:'8px', alignItems: 'center' }}>


            {/**code progress */}
            <div className="image-container"  style={{paddingBottom:'10px', paddingTop:'10px'}}>

              <div style={{background: `conic-gradient(#00c700 0% ${profilePercentage}%, #f2f2f2 ${profilePercentage}% 100%)`,
                        height: '160px', width: '160px', borderRadius: '50%',position: 'relative',
                        display: 'flex', justifyContent: 'center', alignItems: 'center',transform: 'rotate(180deg)',}}>

                <img src={photoUrl || ""} alt="ProfilePicture" 
                style={{ height: '150px', width: '150px',border:'3px solid white', borderRadius:'50%' , transform:'rotate(180deg)'}}/>
    
                <span style={{ position: 'absolute', top: '-5%', left: '0%', transform:'rotate(180deg)',
                              marginLeft:'45px', backgroundColor:'white', color:'#00c700', fontWeight:'bold',
                              border:'3px solid white', width:'65px', textAlign:'center', borderRadius:'40%' }}>{profilePercentage || ""} %</span> 
              </div>
                      
            </div>
              {/**code progress ends*/}

            
          </div>

          <div className="align-text-bottom profile-icon-upload w-100 h-100">
            <div className="file-input-container">
              <input type="file" id="imgs" className="file-input" onChange={saveProfilePic} />
            </div>
          </div>

          <div>
            <label htmlFor="imgs" type="file">
              
              <i className="fas fa-pencil"></i>
            </label>
          </div>
        </MDBCol>

      {/* (col-2)Name------*/}
      <MDBCol   style={{  paddingTop:'10px'}}>{/**  backgroundColor:'yellow', paddingLeft: '40px', */}
                <MDBRow className='profiledetails '>
                  <h3>{pfname || ""} <i onClick={toggleModal} className="fas fa-pencil profile-icon-edit" style={{ fontSize:12}}></i></h3>{/* Profile edit*/}
                  <h6><i className="fas fa-briefcase"></i>&nbsp;{currentEmployment || ""}</h6>
                </MDBRow>
                <MDBRow>
                  <hr style={{ width: '100%' }} />
                </MDBRow>
                <MDBRow style={{ paddingTop: '10px' }}>
                  <MDBCol >
                    <h6><i className="far fa-envelope" style={{ paddingBottom: '8px' }} /> &nbsp;{email || ""}</h6>
                    <h6><i className="fas fa-briefcase" /> {experience || ""} {expMonth}</h6>
                    <h6><MDBIcon icon="map-marker-alt" /> &nbsp;{location || ""}</h6>
                  </MDBCol>

                  <MDBCol  style={{ paddingBottom: '8px', paddingTop: '8px', width: '600px' }}>
                    <h6><i className="fas fa-phone" /> &nbsp;{mobile || ""}</h6>
                    <h6><MDBIcon icon="calendar-week" /> &nbsp;{noticePeriod || ""} Notice Period</h6>
                    <h6><MDBIcon icon="wallet" /> &nbsp;{expSalary || ""}</h6>
                  </MDBCol>
                </MDBRow>


          </MDBCol>
        </MDBRow>
        
        </div>
        )}

{screenWidth <= 1000 && (
<>
    <MDBCol className="rounded-6" style={{ backgroundColor: 'white', maxWidth:'500px', marginLeft:'18px',
    marginRight:'120px',
    maxWidth:'620px'}}>
      <MDBRow>
          <div className="image-container"  style={{paddingBottom:'10px', paddingTop:'10px'}}>

              <div style={{background: `conic-gradient(#00c700 0% ${profilePercentage}%, #f2f2f2 ${profilePercentage}% 100%)`,
                        height: '160px', width: '160px', borderRadius: '50%',position: 'relative',
                        display: 'flex', justifyContent: 'center', alignItems: 'center',transform: 'rotate(180deg)',}}>

                <img src={photoUrl || ""} alt="ProfilePicture" 
                style={{ height: '150px', width: '150px',border:'3px solid white', borderRadius:'50%' , transform:'rotate(180deg)'}}/>

                <span style={{ position: 'absolute', top: '-5%', left: '0%', transform:'rotate(180deg)',
                              marginLeft:'45px', backgroundColor:'white', color:'#00c700', fontWeight:'bold',
                              border:'3px solid white', width:'65px', textAlign:'center', borderRadius:'40%' }}>{profilePercentage || ""} %</span> 
              </div>
        </div>
      </MDBRow>

      <MDBRow style={{margin:'0 auto', textAlign:'center', paddingTop:'10px', width:'90%'}}>
        <h3>{pfname || ""} </h3>
      </MDBRow>

      <MDBRow style={{margin:'0 auto'}}>
          <h6><i className="far fa-envelope" style={{ paddingBottom: '8px' }} /> &nbsp;{email || ""}</h6>
          <h6><i className="fas fa-briefcase" /> {experience || ""} {expMonth}</h6>
          <h6><MDBIcon icon="map-marker-alt" /> &nbsp;{location || ""}</h6>
          <h6><i className="fas fa-phone" /> &nbsp;{mobile || ""}</h6>
          <h6><MDBIcon icon="calendar-week" /> &nbsp;{noticePeriod || ""} Notice Period</h6>
          <h6><MDBIcon icon="wallet" /> &nbsp;{expSalary || ""}</h6>
      </MDBRow>
        
    </MDBCol>
</>
)}



      {/* (2.1)------------------>---Profile Modal code starts */}
      <MDBModal tabIndex='-1' show={modalStatus} setShow={setModalStatus} >
        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
          <MDBModalContent >

              <MDBModalHeader>
                <MDBModalTitle>Basic Details</MDBModalTitle>
              </MDBModalHeader>

              <StudentBasicDetailModal togglingModal={toggleModal} />
                    
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/*(2.1)--------->------------- Profile Modal code ends */}
     
      



      </div>

  );
}

export default StudentProfilePage;
