import React from 'react'

function StudentHomePage() {
  return (
    <div>
      <h1> Student Home Page </h1>
    </div>
  )
}

export default StudentHomePage
