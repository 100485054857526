import React from "react";
import "react-multi-carousel/lib/styles.css";
import {
  MDBContainer,
} from "mdb-react-ui-kit";
import StudentReview from "../Home/StudentReview";

 function C8atiaReviews() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <MDBContainer
      id="review"
      fluid
      className="p-1 rounded-7 courseoptioncertification-fluid  px-5 py-3 mt-4"
    >
      {/* <h1 className="mt-5 mb-3 text-start ">
        Catia Automation and Customization Training Reviews
      </h1> */}
      <StudentReview/>
    </MDBContainer>
  );
};

export default C8atiaReviews;
