import React from "react";

import "react-phone-input-2/lib/style.css";
import ContactBody from "./ContactBodyEnqModal";


function Contact() {
  return (
    <>
   
    
      <h4 class="text-dark mb-4">
        Request a callback to Start your journey with Talent Pool!
      </h4>
      <ContactBody />
    </>
  );
}

export default Contact;
