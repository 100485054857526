import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBBadge,
  MDBTable,
  MDBTableBody,
} from "mdb-react-ui-kit";
import swal from "sweetalert";
import Contact from "../More/Contact";
import TPA_CATIA from "../static/TPA_CATIA_Coustmization_Syllubus.pdf";

function C7atiaContent() {
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);
  
  return (
    <>
      <Helmet>
        <title>Curriculum | TPA</title>
        <meta
          name="description"
          content="Explore the curriculum of our CATIA Customization Course in bangalore. Learn programming concepts, object-oriented programming, application development, DLL development, debugging, and more. Get hands-on practice and master CATIA API development with our comprehensive course."
        />
        <meta
          name="keywords"
          content="CATIA, CATIA customization, CATIA API, CATIA syllabus, programming, object-oriented programming, application development, DLL, debugging"
        />
      </Helmet>
    <MDBContainer
      fluid
      className="p-1 rounded-7 fullstackBody mt-5 text-align-center"
      id="course-content"
      style={{ backgroundColor: " rgb(237 213 213)" }}
    >
      <MDBRow>
        <MDBCol md="2"></MDBCol>
        <MDBCol md="8">
          <MDBCardBody>
            <MDBTypography variant="h2 text-light mt-1">
              Curriculum
            </MDBTypography>

            <MDBTable hover>
              <MDBTableBody>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Programming Phase – 1 Month{" "}
                </p>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Basic Programming Concepts.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Object Oriented Programming Concepts.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Console Application Development.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Windows Form application Development.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      DLL / Class Library Development.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Developing & Debugging Programs in Visual Studio.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Pointers & references
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Interacting with Excel
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light  mt-2 mb-4">
                  API CATIA CAA RADE – 2 Months
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      COM Notations
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Development Architecture,Object Modeler
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Application Frame
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Product Structure & Assembly
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Mechanical Modeler
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Knowledgeware
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCol>
        <MDBCol md="2"></MDBCol>
      </MDBRow>
      <MDBBtn
        href={TPA_CATIA}
        download
        outline
        color="light"
        className="mb-4 mt-3 w-10 fw-bold tpa-info-logo"
        size="lg"
      >
        <MDBIcon fas icon="download pe-2" />
        Download
      </MDBBtn>

      <MDBModal
        tabIndex="-1"
        show={fullscreenXlModal}
        setShow={setFullscreenXlModal}
      >
        <MDBModalDialog size="fullscreen-xl-down" style={{ color: "black" }}>
          <MDBModalContent>
            <MDBModalHeader>
              {/* <MDBModalTitle>Book a time slot with candidate.</MDBModalTitle> */}
              <MDBBtn
                type="button"
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Contact />
            </MDBModalBody>
            {/* <MDBModalFooter>
              <MDBBtn style={{ color: "white" }} onClick={handleSubmit}>
                Submit
              </MDBBtn>
              <MDBBtn type="button" color="secondary" onClick={toggleShow}>
                Close
              </MDBBtn>
            </MDBModalFooter> */}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
    </>
  );
}

export default C7atiaContent;
