import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";

function HoverCards() {
  const [verticalActive, setVerticalActive] = useState("tab1");

  const handleVerticalClick = (value) => {
    if (value === verticalActive) {
      return;
    }

    setVerticalActive(value);
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <MDBRow className="mb-2" id="mechanical">
      <MDBCol center md="3">
        <MDBCardBody className=" ">
          <MDBTabs className="flex-column text-start  ">
            <MDBTabsItem
              className="mx-3 "
              style={{ backgroundColor: "hsl(245deg 73% 90%)" }}
            >
              <MDBTabsLink
                onClick={() => handleVerticalClick("tab1")}
                className="text-dark fs-5 fw-bold "
              >
                Mechanical
              </MDBTabsLink>
            </MDBTabsItem>
            <div>
              <br />
            </div>
            <MDBTabsItem
              className="mx-3 "
              style={{ backgroundColor: "hsl(245deg 73% 90%)" }}
            >
              <MDBTabsLink
                onClick={() => handleVerticalClick("tab2")}
                className="text-dark fs-5 fw-bold "
              >
                IT
              </MDBTabsLink>
            </MDBTabsItem>
            <div>
              <br />
            </div>
            <MDBTabsItem
              className="mx-3"
              style={{ backgroundColor: "hsl(245deg 73% 90%)" }}
            >
              <MDBTabsLink
                onClick={() => handleVerticalClick("tab3")}
                className="text-dark fs-5 fw-bold"
              >
                Business
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCardBody>
      </MDBCol>

      <MDBCol center md="9 ">
        <MDBTabsContent className="">
          <MDBTabsPane show={verticalActive === "tab1"}>
            <MDBTypography
              className="text-dark text-center fw-bold"
              variant="h1"
            >
              Mechanical
            </MDBTypography>
            <Carousel
              responsive={responsive}
              className="mb-6"
              autoPlay={true}
              autoPlaySpeed={5000}
              shouldResetAutoplay={false}
              infinite={true}
              rewindWithAnimation={true}
              rewind={true}
              transitionDuration={500}
              keyBoardControl={true}
            >

          <MDBCard className="m-3 tpa-card-container">
            <Link to="/C1atiaProductDesign" >    
                <MDBCardImage src='https://www.comarch.com/files-com/file_691/automation-and-digitization-in-the-automotive-industry.jpg' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">Automotive Design <br/><br/></MDBCardTitle>
                </MDBCardBody>
                </Link> 
              </MDBCard>

              <MDBCard className="m-3 tpa-card-container">
              <Link to="/C1atiaProductDesign" >
              <MDBCardImage src='https://trustradiusnew.wpengine.com/wp-content/uploads/istock-1135159626-scaled-1200x800.jpg' position='top' alt='...' width="350"
                    height="150" />
                <MDBCardBody>
                  <MDBCardTitle className="carCards">CATIA Automation & Custmization</MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>
              


              <MDBCard className="m-3 tpa-card-container" >
              <Link to="/C1atiaProductDesign" >
              <MDBCardImage src='https://www.plmnordic.com/wp-content/uploads/2022/05/NX.jpg' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">NX Automation & Coustmization</MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>

              <MDBCard className="m-3 tpa-card-container" >
              <Link to="/C1atiaProductDesign" >
              <MDBCardImage src='https://www.capgemini.com/wp-content/uploads/2018/04/rockwell-automation.jpg' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">Creo Automation & Coustmization</MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>

              <MDBCard className="m-3 tpa-card-container" >
              <Link to="/C1atiaProductDesign" >
              <MDBCardImage src='https://wallpaperaccess.com/full/334443.jpg' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">Basic and Advance CATIA, NX, CREO</MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>

              <MDBCard className="m-3 tpa-card-container" >
              <Link to="/C1atiaProductDesign" >
              <MDBCardImage src='https://wallpapercave.com/wp/wp7912904.jpg' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">CAE-Computer Aided Engineering</MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>
             

              <MDBCard className="m-3 tpa-card-container" >
              <Link to="/C1atiaProductDesign" >
              <MDBCardImage src='https://www.plm.automation.siemens.com/media/global/pt/Vehicle-heat-protection-analysis_800x450_tcm70-85173.jpg' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">CFD-Computational Fluid Dynamics</MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>
             

         
              
            </Carousel>
          </MDBTabsPane>
          <MDBTabsPane show={verticalActive === "tab2"} id="it">
            <MDBTypography
              className="text-dark text-center fw-bold"
              variant="h1"
            >
              IT
            </MDBTypography>
            <Carousel
              responsive={responsive}
              className="mb-6"
              autoPlay={true}
              autoPlaySpeed={5000}
              shouldResetAutoplay={false}
              infinite={true}
              rewindWithAnimation={true}
              rewind={true}
              transitionDuration={500}
              keyBoardControl={true}
            >
              
              <MDBCard className="m-3 tpa-card-container" >
              <Link to="/FS1ProductDesign" >
              <MDBCardImage src='https://hawkticehurst.com/imgs/hero.png' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">Full Stack Webdevelopment</MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>
              <MDBCard className="m-3 tpa-card-container" >
              <Link to="/FS1ProductDesign" >
              <MDBCardImage src='https://s3-ap-south-1.amazonaws.com/ricedigitals3bucket/AUPortalContent/2022/09/29160306/Number-Theory4.png' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">AI / ML<br/><br/></MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>

              
              <MDBCard className="m-3 tpa-card-container" >
              <Link to="/CAE1ProductDesign" >
              <MDBCardImage src='https://www.cloudcredential.org/wp-content/uploads/bb-plugin/cache/iot-panorama-c313ef51682adb0273dcc7237645a625-5cb975111e194.jpg' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">IOT<br/><br/></MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>
              
              <MDBCard className="m-3 tpa-card-container" >
              <Link to="/ST1ProductDesign" >
              <MDBCardImage src='https://u-tor.com/wp-content/uploads/2021/03/mobile-testing-concept-illustration_114360-1871.jpg' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards">Software Testing<br/><br/></MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>

             
            </Carousel>
          </MDBTabsPane>
          <MDBTabsPane show={verticalActive === "tab3"}>
            <MDBTypography
              className="text-dark text-center fw-bold"
              variant="h1"
            >
              Business
            </MDBTypography>
            <Carousel
              responsive={responsive}
              className="mb-6"
              autoPlay={true}
              autoPlaySpeed={5000}
              shouldResetAutoplay={false}
              infinite={true}
              rewindWithAnimation={true}
              rewind={true}
              transitionDuration={500}
              keyBoardControl={true}
            >

            <MDBCard className="m-3 tpa-card-container" >
              <Link to="/ST1ProductDesign" >
              <MDBCardImage src='https://treehack.com/wp-content/uploads/2023/03/Tree-1024x1011.png' position='top' alt='...' width="350"
                    height="150"/>
                <MDBCardBody>
                  <MDBCardTitle className="carCards"> Digital Marketing</MDBCardTitle>
                </MDBCardBody>
                </Link>
              </MDBCard>

            </Carousel>
          </MDBTabsPane>
        </MDBTabsContent>
      </MDBCol>
    </MDBRow>
  );
};

export default HoverCards;
