import React from 'react'
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBNavbar, MDBBtn,} from "mdb-react-ui-kit";

function AdminHeader (){
  
  const userInfo                    = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                   = userInfo.email;
  const userName                    = userInfo.name;
  const navigate                    = useNavigate();

  const handleLogout = () => 
          {   
              localStorage.clear(); 
              navigate('/');
              window.location.reload();
            };

      return (
        <div>
          <div>
            <MDBNavbar >
                <MDBContainer fluid className='fixed-navbar-top'>
                    <h3 className=''>Admin Dashboard</h3>
                    
                    
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4>{userName}&nbsp;</h4>
                      
                      <button color='info' onClick={handleLogout} className="btnsignup ml-auto">
                        Logout
                      </button>
                    </div>
                    
                </MDBContainer>
            </MDBNavbar>
        </div>
         
        </div>
      );
    }
  
  
  export default AdminHeader;