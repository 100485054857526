import React, { useEffect } from "react";
import { MDBCol, MDBRow, MDBTypography, MDBIcon } from "mdb-react-ui-kit";

 function Achievements() {
  const [foreignFollowers, setForeignFollowers] = React.useState(0);
  const [classesCompleted, setClassesCompleted] = React.useState(0);
  const [studentEnrolled, setStudentEnrolled] = React.useState(0);
  const [certifiedTeachers, setCertifiedTeachers] = React.useState(0);

  useEffect(() => {
    if (foreignFollowers < 540) {
      setTimeout(() => setForeignFollowers(foreignFollowers + 1), 1);
    }
    if (classesCompleted < 2000) {
      setTimeout(() => setClassesCompleted(classesCompleted + 1), 1);
    }
    if (studentEnrolled < 2124) {
      setTimeout(() => setStudentEnrolled(studentEnrolled + 1), 1);
    }
    if (certifiedTeachers < 15) {
      setTimeout(() => setCertifiedTeachers(certifiedTeachers + 1), 10);
    }
  }, [foreignFollowers, classesCompleted, studentEnrolled, certifiedTeachers]);

  return (
    <>
      <MDBRow>
        <MDBTypography
          className="text-light text-center fw-bold mb-4 "
          variant="h1"
        >
          Center Achievements
        </MDBTypography>

        <p className="mb-5 text-light fw-bold text-center ">
          Here you can review some statistics about our Education Center{" "}
        </p>

        <MDBCol md="2"></MDBCol>
        <MDBCol md="2" className="text-center">
          <MDBIcon size="6x" color="dark" fas icon="globe-africa" />
          <MDBTypography
            variant="h1"
            className="mt-5 mb-2 text-light text-center text-bold"
          >
            {foreignFollowers}
          </MDBTypography>
          <p className="mb-2 text-light fw-bold text-center">
            FOREIGN FOLLOWERS
          </p>
        </MDBCol>
        <MDBCol md="2" className="text-center">
          <MDBIcon size="6x" color="dark" far icon="bell" />

          <MDBTypography
            variant="h1"
            className="mt-5 mb-2 text-light text-center text-bold"
          >
            {classesCompleted}
          </MDBTypography>
          <p className="mb-2 text-light fw-bold text-center">
            CLASSES COMPLETE
          </p>
        </MDBCol>
        <MDBCol md="2" className="text-center">
          <MDBIcon size="6x" color="dark" fas icon="users" />

          <MDBTypography
            variant="h1"
            className="mt-5 mb-2 text-light text-center text-bold"
          >
            {studentEnrolled}
          </MDBTypography>
          <p className="mb-2 text-light fw-bold text-center">
            STUDENTS ENROLLED
          </p>
        </MDBCol>
        <MDBCol md="2" className="text-center">
          <MDBIcon size="6x" color="dark" fas icon="briefcase" />

          <MDBTypography
            variant="h1"
            className="mt-5 mb-2 text-light text-center text-bold"
          >
            {certifiedTeachers}
          </MDBTypography>
          <p className="mb-2 text-light fw-bold text-center">
            CERTIFIED TEACHERS
          </p>
        </MDBCol>
        <MDBCol md="2"></MDBCol>
      </MDBRow>
    </>
  );
};

export default Achievements;
