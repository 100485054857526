import React, { useState, useEffect } from 'react';
import { MDBRow, MDBInput, MDBCol } from 'mdb-react-ui-kit'
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Select from 'react-select';
import { baseURL } from './../../http';

function ManageCourses() {

    const [course, setCourse]                               = useState([]);
    const [availableCourses, setAvailableCourses]           = useState([]);

  useEffect(() => { fetchCourses(); }, []);
  const fetchCourses = () => {
    fetch(`${baseURL}/getStudentCourseByAdmin`)
      .then(response  => response.json())
      .then(data      => { setAvailableCourses(data); })
      .catch(error    => { console.error('Error fetching student list:', error);  });
  };

  const addCourse =()=>{
    const data={  studentCourses  : course    }
    fetch(`${baseURL}/addStudentCourseByAdmin`, 
    { 
      method: 'POST',
      body: JSON.stringify(data),
      headers : { 'Content-Type': 'application/json', 'Accept'      : 'application/json', },
    })  
      .then((response) => {
        if (response.ok) {  alert('Course Added successfully'); fetchCourses(); } 
        else             {  alert('Course Add Failed');        console.error('Error -API response:', response.statusText);   }  })
      .catch((error) =>  {  alert('Server Error');             console.error('Server error', error);                            });
  }
  const deleteCourse =(itemID)=>{
    console.log(itemID)
    fetch(`${baseURL}/deleteCourseByAdmin/${itemID}`, {
        method: 'PUT',
        headers: {  'Content-Type': 'application/json', },
    })
        .then(response => {     if (response.ok) {  alert('Course deleted successfully');     fetchCourses();     } 
                                else             {  alert('Course delete Failed');        console.error('Error -API response:', response.statusText);  }    })
        .catch(error =>   {                         alert('Server Error');                console.error('Server error', error);                             });
}

  return (
    <div>
      <MDBRow className='border'>
        <MDBCol>
            <label>Enter Course Name</label>
            < MDBInput  id='form1' type='text' onChange={e=>setCourse(e.target.value)}  />
        </MDBCol>
        <MDBCol>
            <br/>
            <button type="button" className="dropbtnprofilepagesave" onClick={addCourse}>Add</button>
            <br/>
            <br/>
        </MDBCol>
      </MDBRow>
      <label>Available Courses</label>

      <MDBTable>
        <MDBTableHead className='bg-gradient'>
          <tr>
            {/* <th scope='col'>ID</th>          */}
            <th scope='col'>S.No </th>      <th scope='col'>Student Courses</th>        <th scope='col'>Action</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {availableCourses.map((Course, index) => (
            <tr key={Course.id}>
             <td>{index+1}</td>             <td>{Course.studentCourses}</td>                      
             <td>
                    <i  onClick={() =>deleteCourse(Course.id)} className="fas fa-trash-can red-trash-icon" 
                    style={{ fontSize:12, cursor:'pointer', alignContent:'right'}} />
            </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>


      <MDBRow>

      </MDBRow>
    </div>
  )
}

export default ManageCourses
