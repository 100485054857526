import React, { useState, useEffect } from "react";
import Mainsite from "./Main";
import { Route, Routes } from "react-router-dom";
import AdminDashboard from "./DashboardAdmin/AdminDashboard";
import StudentDashboard from "./DashboardStudent/1StudentDashboard";

function Start() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const userInfo                    = JSON.parse(localStorage.getItem('user-info'));
    const isAdmin                     = userInfo && (userInfo.logintype === 'admin');
    const isStudent                   = userInfo && (userInfo.logintype === 'student');
    const isLocalStorageEmpty         = localStorage.length === 0;

  useEffect(() => {
    
    const loginStatus = localStorage.getItem('user-info');
    if (loginStatus) {
      setIsLoggedIn(true);
     
    } else {
      setIsLoggedIn(false);
      
    }
  }, []); // Empty array is only for the initial render

  return (
    <div>
    {isAdmin ? (
        <Routes>
            <Route path="/" element={<AdminDashboard/>} />
            
        </Routes>
    ) : isStudent ? (
        <Routes>
            <Route path="/" element={<StudentDashboard/>} />
        </Routes>
    ) : isLocalStorageEmpty ? (
        <>
            <Mainsite />
        {/* <AdminDashboard/> */}
        {/* <StudentDashboard /> */}
        </>
    ) : null}
</div>
  )
}

export default Start
