import React from "react";
import { MDBCol, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import { Helmet } from "react-helmet";
import Step1 from "../static/E_larning.png";
import Step2 from "../static/Industry_Limited.png";
import Step3 from "../static/Flexible_working.png";
import Step4 from "../static/Live_work_support.png";
import Step5 from "../static/Career Support.png";
import "react-phone-input-2/lib/style.css";

 function OurMethod() {
  return (
    <>
      <Helmet>
        <title>Our Training Method for job placements | Talent Pool Academy</title>
        <meta
          name="description"
          content="Learn about our method which includes e-learning, industry-linked training, flexible working time, live work support, and career support in bangalore. 
                  Our approach is designed to make you job-ready and able to tackle real-life problems."
        />
        <meta
          name="keywords"
          content="e-learning institute in bangalore, industry-linked training, flexible working, live work and career support, training method, job-ready"
        />
      </Helmet>
      <MDBRow>
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="10">
          <MDBRow className="mb-2" style={{ border: "solid 2px black" }}>
            <MDBCol center md="2 text-align-center">
              <img
                src={Step1}
                alt='career step'
                className="rounded-circle shadow-1-strong"
                width="150"
                height="150"
              />
            </MDBCol>
            <MDBCol center md="10" className="pt-2">
              <MDBBtn
                outline
                color="dark"
                className="fs-6 mb-3 tpa-info-button"
              >
                <small> E-learning</small>
              </MDBBtn>
              <p className="text-start fs-6" style={{ color: "black" }}>
                E-learning serve as a Platform for random access to multiple
                users at the same time save plenty of time.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol center md="1"></MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="10">
          <MDBRow className="mb-2" style={{ border: "solid 2px black" }}>
            <MDBCol center md="2 text-align-center">
              <img
                src={Step2}
                alt='Industry oriented training'
                className="rounded-circle shadow-1-strong"
                width="150"
                height="150"
              />
            </MDBCol>
            <MDBCol md="10" className="pt-2">
              <MDBBtn
                outline
                color="dark"
                className="fs-6 mb-3 tpa-info-button"
              >
                <small>Industry Linked</small>
              </MDBBtn>
              <p className="text-start fs-6" style={{ color: "black" }}>
                Talent Pool focus is not on rote learning, but on experiential
                learning and practical training so that students are job-ready
                and able to tackle real-life problems even before they enter the
                workforce.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol center md="1"></MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="10">
          <MDBRow className="mb-2" style={{ border: "solid 2px black" }}>
            <MDBCol center md="2 text-align-center">
              <img
                src={Step3}
                className="rounded-circle shadow-1-strong"
                alt='flexible training'
                width="150"
                height="150"
              />
            </MDBCol>
            <MDBCol md="10" className="pt-2">
              <MDBBtn
                outline
                color="dark"
                className="fs-6 mb-3 tpa-info-button"
              >
                <small>Flexi Working</small>
              </MDBBtn>
              <p className="text-start fs-6" style={{ color: "black" }}>
                All employees should be at there job during the common core
                period they are free to vary there schedule during flexible hrs
                and plan there personal commitments.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol center md="1"></MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="10">
          <MDBRow className="mb-2" style={{ border: "solid 2px black" }}>
            <MDBCol center md="2 text-align-center">
              <img
                src={Step4}
                className="rounded-circle shadow-1-strong"
                width="150"
                height="150"  
                alt='Live project training'
              />
            </MDBCol>
            <MDBCol center md="10" className="pt-2">
              <MDBBtn
                outline
                color="dark"
                className="fs-6 mb-3 tpa-info-button"
              >
                <small>Live Work Support</small>
              </MDBBtn>
              <p className="text-start fs-6" style={{ color: "black" }}>
                Our Talent Pool Expert Team will not only support you but also
                will help you to get the complete knowledge of your issues &
                resolve track. It’s means you will be Learning while get
                support.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol center md="1"></MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="10">
          <MDBRow className="mb-2" style={{ border: "solid 2px black" }}>
            <MDBCol center md="2 text-align-center">
              <img
                src={Step5}
                className="rounded-circle shadow-1-strong"
                width="150"
                alt='Career support'
                height="150"
              />
            </MDBCol>
            <MDBCol md="10" className="pt-2">
              <MDBBtn
                outline
                color="dark"
                className="fs-6 mb-3 tpa-info-button"
              >
                <small>Career Support</small>
              </MDBBtn>
              <p className="text-start fs-6" style={{ color: "black" }}>
                The activities may occur on an individual or group basis and may
                be face-to-face or at a distance.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol center md="1"></MDBCol>
      </MDBRow>
    </>
  );
};

export default OurMethod;
