import * as React from "react";
import {
  MDBFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBCardBody,
  MDBContainer,
} from "mdb-react-ui-kit";

function Footer() {
  return (
    <MDBFooter
      bgColor="light"
      className="text-center text-lg-start text-muted MBfooter"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
    >
      <MDBContainer
        fluid
        className="border text-center "
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        <MDBRow className="mt-5">
          <MDBCol md="8">
            <MDBCardBody className="p-1">
              <>
                <MDBBtn
                  floating
                  outline
                  color="light"
                  className="me-3 tpa-info-logo"
                  href="#!"
                  role="button"
                >
                  <a
                    href=" https://www.linkedin.com/in/talent-pool-academy/"
                    target="_blank"
                  >
                    <MDBIcon fab icon="linkedin-in" />
                  </a>
                </MDBBtn>

                <MDBBtn
                  floating
                  outline
                  color="light"
                  className="me-3 tpa-info-logo"
                  href="#!"
                  role="button"
                >
                  <a
                    href=" https://www.facebook.com/profile.php?id=100082084068949&mibextid=ZbWKwL/"
                    target="_blank"
                  >
                    <MDBIcon fab icon="facebook-f" />
                  </a>
                </MDBBtn>


                <MDBBtn
                  floating
                  outline
                  color="light"
                  className="me-3 tpa-info-logo"
                  href="#!"
                  role="button"
                >
                  <a href="mailto:email@example.com" target="_blank">
                    <MDBIcon fab icon="google" />
                  </a>
                </MDBBtn>
                
                <p className=" fw-bold text-light text-center mt-3 ">
                  © 2023 Talentpool-academy.in:
                </p>
              </>
              <hr />
            </MDBCardBody>
          </MDBCol>

          <MDBCol md="4" className=" text-start">
            <MDBCardBody className="p-2 mx-2">
              <p className="fw-bold text-light">
                <MDBIcon color="light" icon="envelope" className="me-3" />
                info@talentpool-academy.in
              </p>
              <p className="fw-bold text-light">
                <MDBIcon color="light" icon="globe" className="me-3" />
                www.talentpool-academy.in
              </p>
              <p className="fw-bold text-light">
                <MDBIcon color="light" icon="phone" className="me-3" /> +91
                7483868010
              </p>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

   
    </MDBFooter>
  );
}

export default Footer;
