import React, { useState } from "react";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBTextArea,
  MDBIcon,
  MDBFile,
  MDBAccordion,
  MDBAccordionItem,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBadge,
  MDBTable,
  MDBTableBody,
} from "mdb-react-ui-kit";
import swal from "sweetalert";
import Contact from "../More/Contact";

 function FS7Content() {
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const slot = [
    { value: "Mechanical", label: "Mechanical" },
    { value: "IT", label: "IT" },
    { value: "Business", label: "Business" },
  ];
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let errors = {};

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    // Submit form data here
    console.log("Form data:", formValues);
    setFullscreenXlModal(!fullscreenXlModal);
    swal("Joined! Thank you ,Your Slot Booked", "", "success");
  };
  return (
    <MDBContainer
      fluid
      className="p-1 rounded-7 fullstackBody mt-5 text-align-center"
      id="course-content"
      style={{ backgroundColor: " rgb(237 213 213)" }}
    >
      <MDBRow>
        <MDBCol md="2"></MDBCol>
        <MDBCol md="8">
          <MDBCardBody>
            <MDBTypography variant="h2 text-light mt-1">
              Curriculum
            </MDBTypography>

            <MDBTable hover>
              <MDBTableBody>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Course Phase 1: HTML5
                </p>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Basic concepts of HTML5 , Elements, Atttributes, Headings,
                      Paragraph, Styles.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      HTML5 Tables, lists , Block and Inline Elements.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      HTML5 Classes, Iframes, File path, Sematics, Entities.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      HTML5 Forms, Attributes, Elements, Input Types, Input
                      Attributes,Input Form Attributes.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      HTML5 CANVAS, SVG, Media, Audio, video and Plugins.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Course Phase 2 - CSS3
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Basic concepts of CSS3.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Advanced CSS3, Rounded corner, 2D and 3D Transform,
                      Transition, Animations,Flex box and Media Queries.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      CSS Responsives, RWD Grid view, RWD ViewPort, RWD Images
                      and videoos, RWD Frameworks, RWD Templates,
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      CSS Flex and Grid items, Grid container.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4 ">
                  Course Phase 3- JavaScript
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Basic concepts of Javascript, Variables, Operators,
                      Events, String, Array.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Object Properties, Methods, Constructors, Prototypes,
                      Methods.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Function Parameters, Invocation, call, Apply, Bind,
                      Closures.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Assynchrounouse, Callbacks, Promises, Asynch/Await.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      DOM Methods, Documents, Elements, Animations, Events,
                      Navigation..
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4 ">
                  Course Phase 5- Bootsstrap5
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Bootstrap5 Container, Grid Basics, Typography, Alerts,
                      Button and Button Groups.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Cards, Dropdown, Navbar, Carousel, Modal, Tooltip,
                      Progress bar.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Forms, Bootstrap5 Selecrt Menus, Checks and Radios, Range,
                      Floating Lables,Form validation.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Bootstrap5 Grid System, Stacked/Horizontal.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4 ">
                  Course Phase 5- React JS
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Setup Environment for ReactJs
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Setup Environment for ReactJs 2
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Making the Very first app
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      States in React Props Props and State Combined setState
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Forms in ReactJs
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4 ">
                  Course Phase 6- Laravel
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Composer and Installing Larave Laravel Directory Structure
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      App name Change namespace Configurations Basics of Routes
                      Route Groups Route Names
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Form Submission Basics Views Setting up Master Layout
                      Passing data to views Security @csrf Validation Basics
                      Custom Validation Messages Bootstrap form component
                      example
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Responses Basics JSON Response JSON Response Sending Emai
                      Mark Down Email Migration Basics More adding dropping
                      columns Eloquents
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Creating, Updating, Delete data Query Builder Collections
                      Seeding Basics Using Seeding Factories
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Course Phase 7: MONGO DB
                </p>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Basic Concepts of MONGO DB, Schema less.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      JSON Objects, Key and Values, String Data types, Document
                      type.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      What is the difference between Oracle SQL AND MONGO DB.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Structure of MONGO DB, Installation, COMMANDS,
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      How to create Collections, Drop Collections.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      CRUD Operation in MONGO DB, Create Method, Read Method,
                      Update Method,Delete Method.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Filter is the data while fetching from the data base.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Methods of find, Pretty, Count, Sort, Skip, limit.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCol>
        <MDBCol md="2"></MDBCol>
      </MDBRow>
      <MDBBtn
        href="#catiatraining"
        outline
        color="light"
        className="mb-4 mt-3 w-10 fw-bold tpa-info-logo"
        size="lg"
        onClick={toggleShow}
      >
        <MDBIcon fas icon="download pe-2" />
        Download
      </MDBBtn>

      <MDBModal
        tabIndex="-1"
        show={fullscreenXlModal}
        setShow={setFullscreenXlModal}
      >
        <MDBModalDialog size="fullscreen-xl-down" style={{ color: "black" }}>
          <MDBModalContent>
            <MDBModalHeader>
              {/* <MDBModalTitle>Book a time slot with candidate.</MDBModalTitle> */}
              <MDBBtn
                type="button"
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Contact />
            </MDBModalBody>
            {/* <MDBModalFooter>
              <MDBBtn style={{ color: "white" }} onClick={handleSubmit}>
                Submit
              </MDBBtn>
              <MDBBtn type="button" color="secondary" onClick={toggleShow}>
                Close
              </MDBBtn>
            </MDBModalFooter> */}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
};

export default FS7Content;
