import { useState } from "react";
import http from "../../http";  
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";

function ContactBody() {
  const [inputs, setInputs] = useState({});
  

  const handleChange = (event) => {
    console.log(event);
    console.log("Inside common");
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handlePhoneInput = (number) => {
    console.log(number);
    console.log("Inside phone");
    const name = "mobile";
    const value = number;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const submitForm = () => {
    http
      .post("/requests", inputs)
      .then((res) => {
        swal("Submitted! Thank you ,Your Slot Booked.", "", "success");
        setInputs("");
      })
      .catch((error) => {
        swal("Not Submitted!! ", "", "fail");
        return error;
      });
  };

 

  const [selectedOption] = useState(null);

  const [formErrors] = useState({
    first_name: "",
    email: "",
    phone: "",
    message: "",
  });

  return (
    <MDBCard>
      
      <MDBCard className=" my-12">
        <MDBCardBody className="p-5">
          <MDBRow>
            <MDBCol col="6">
              <MDBInput
                wrapperClass="mb-4"
                label="Name"
                id="first_name"
                rows={3}
                size="lg"
                name="first_name"
                type="text"
                value={inputs.first_name || ""}
                onChange={handleChange}
                className={formErrors.first_name ? "is-invalid" : ""}
              />
            </MDBCol>
            </MDBRow>
            <MDBRow>
              
            <MDBCol col="6">
              <MDBInput
                wrapperClass="mb-4"
                label="Email"
                id="email"
                rows={3}
                size="lg"
                name="email"
                value={inputs.email || ""}
                onChange={handleChange}
                className={formErrors.name ? "is-invalid" : ""}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <PhoneInput
              inputStyle={{
                width: "100%",
                height: "42px",
              }}
              country={"in"}
              name="mobile"
              value={selectedOption}
              onChange={handlePhoneInput}
            />
          </MDBRow>

          <br />
       
          <MDBTextArea
            label="Message"
            id="Message"
            rows={3}
            size="lg"
            name="message"
            value={inputs.message || ""}
            onChange={handleChange}
            className={formErrors.message ? "is-invalid" : ""}
          />
          <br />
          <MDBBtn
            className="tpa-info-button"
            style={{ color: "white" }}
            onClick={submitForm}
          >
            Submit 
          </MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCard>
  );
}

export default ContactBody;
