import React, { useState, useEffect } from "react";
import { components } from 'react-select';

import {MDBModal, MDBModalDialog, MDBModalContent, MDBModalFooter, MDBInput, MDBTextArea, MDBCardLink, } from "mdb-react-ui-kit";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Select from "react-select";
import { baseURL } from "./../../http";
import ManageCourses from "./ManageCourses";

function AdminSideNavStudentList() {
  const [studentList, setStudentList]                             = useState([]);

  const [manageBatchModalStatus, setManageBatchModalStatus]       = useState(false);
  const [manageCourseModalStatus, setManageCourseModalStatus]     = useState(false);
  const [batches, setBatches]                                     = useState([]);
  const [batchCourses, setBatchCourses]                           = useState([]);
  const [selectedBatchCourse, setSelectedBatchCourse]             = useState([]);
  const [batchName, setBatchName]                                 = useState("");
  const [selectedOptionsByRow, setSelectedOptionsByRow]           = useState({});
  const [studentBatchAssign, setStudentBatchAssign]               = useState([]);
  const [selectedDate, setSelectedDate]                           = useState(new Date());
  const [updatedRows, setUpdatedRows]                             = useState([]);
  const [selectedOptionBatch, setSelectedOptionBatch]             = useState("");
  const [selectedOptions, setSelectedOptions]                     = useState([]);


  const [searchName, setSearchName]                               = useState("");
  const [searchEmail, setSearchEmail]                             = useState("");
  const [searchMobile, setSearchMobile]                           = useState("");
  const [searchBatch, setSearchBatch]                             = useState("");

  function toggleManageBatchModal() { setManageBatchModalStatus(!manageBatchModalStatus); }
  function toggleManageCourseModal() {  setManageCourseModalStatus(!manageCourseModalStatus);}

  useEffect(() => { fetchStudentList(); fetchBatchOptions();  fetchBatchCourseOptions();}, []);

  const fetchStudentList = () => {
    fetch(`${baseURL}/allStudentlist`)
      .then((response)  => response.json())
      .then((data)      => { setStudentList(data); })
      .catch((error)    => { console.error("Error fetching student list:", error); });
  };
  /**---------------getting batch options for dropdown delete------------- */
  const fetchBatchOptions = () => {
    fetch(`${baseURL}/getBatchOptions`)
      .then((response)  => response.json())
      .then((data)      => {  setBatches(data || []); })//console.log(JSON.stringify(data));
      .catch((error)    => {  console.error("Error fetching template by Admin:", error);  });
  };
  const batchOptions = batches.map((adminBatchTypes) => ({  value: adminBatchTypes.batchName, label: adminBatchTypes.batchName,}));
  /**---------------getting Course options for adding batch (for input to select)------------- */
  const fetchBatchCourseOptions = () => {
    fetch(`${baseURL}/getStudentCourseByAdmin`)
      .then((response)  =>    response.json())
      .then((data)      => {  setBatchCourses(data || []); }) //console.log(JSON.stringify(data));
      .catch((error)    => {  console.error("Error fetching template by Admin:", error);  });
  };
  const batchCourseOptions = batchCourses.map((options) => ({ value: options.studentCourses,  label: options.studentCourses,  }));
  /**------- */
  const addBatch = () => {
    const data = {
      batchStartDate  : selectedDate,
      batchName       : batchName,
      batchCourse     : selectedBatchCourse.value,
    };
    fetch(`${baseURL}/addBatchByAdmin`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {  "Content-Type": "application/json", Accept: "application/json", },
    })
      .then((response) => { if (response.ok) {  alert("Item Added successfully"); fetchBatchOptions();  } 
                          else               {  alert("Item Add Failed");         console.error("Error -API response:", response.statusText);}
      })
      .catch((error)   => {                     alert("Server Error");            console.error("Server error", error); });
  };

  const deleteBatchFromTable = (batchName) => {
    /**set batches   */

    fetch(`${baseURL}/deleteBatchByAdmin/${batchName}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {  alert("Batch deleted successfully");  fetchBatchOptions();  } 
        else             {  alert("Batch delete Failed");         console.error("Error -API response:", response.statusText); }
      })
      .catch((error) => {   alert("Server Error");                console.error("Server error", error);                       });
  };

  function updateBatch(selectedValues, rowId) {
    /*------------------------Local State update---------------------- */
    setSelectedOptionsByRow((prevSelectedOptions) => {
      const updatedOptions = {...prevSelectedOptions,[rowId]: selectedValues,};
      return updatedOptions;
    });
    setUpdatedRows((prevUpdatedRows) => [...prevUpdatedRows, rowId]);
  }

  // Use useEffect to watch for changes in selectedOptionsByRow and trigger the API call
  useEffect(() => {
    const apiCalls = [];
  
    for (const rowId of updatedRows) {
      const selectedValues = selectedOptionsByRow[rowId];
      const sendData = {
        DBtableID: rowId,
        assignBatch: selectedValues.map((option) => option.value),
      };
  
      // Push the API call promise to the array
      apiCalls.push(
        fetch(`${baseURL}/updateStudentBatchAssign`, {
          method: "POST",
          body: JSON.stringify(sendData),
          headers: { "Content-Type": "application/json", Accept: "application/json" },
        })
        .then((response) => {
          if (response.ok)  {  alert(`Batches updated Successfully for row with ID: ${rowId}`);
                               return { success: true, rowId };  } 
          else {               return { success: false, rowId }; }
        })
        .catch(() => {         return { success: false, rowId    };  })
      );
    }
  
    // Execute all API calls in parallel
    Promise.all(apiCalls)
      .then((results) => {    
        results.forEach((result) => {
          if (result.success) {}           // Handle success
          else                {}  });      // Handle failure
      })
      .catch(() => {  alert("Server Error..!"); });
  
      setUpdatedRows([]); // Clear the updated rows after processing
  }, [selectedOptionsByRow, updatedRows]);
  

  /**----------------all clear-------------- */
  const handleClearBatch = (rowId) => {
    const sendData = {  DBtableID: rowId, }; // Set to null or provide the appropriate value for deletion

    fetch(`${baseURL}/deleteStudentAllBatchAssign`, {
      method: "POST",
      body: JSON.stringify(sendData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          alert(`Batch cleared successfully for row with ID: ${rowId}`);
        } else {
          alert(`Failed to clear batch for row with ID: ${rowId}`);
        }
      })
      .catch(() => {
        alert("Server Error..!");
      });
  };

  /**----------------render each selected batch starts-------------- */
  useEffect(() => {
    fetch(`${baseURL}/getStudentBatchAssign`)
      .then((response) => response.json())
      .then((data) => {
        const batchAssignments = data || [];
        const updatedSelectedOptionsByRowBatches = {};

        batchAssignments.forEach((item) => {
          updatedSelectedOptionsByRowBatches[item.id] = item.batches
            ? item.batches.map((batch) => ({ value: batch, label: batch }))
            : [];
        });

        /***new code to stringify  */
        setStudentBatchAssign(batchAssignments);
        setSelectedOptionsByRow(updatedSelectedOptionsByRowBatches);
  
      })
      .catch((error) => {
        console.error("Error fetching student batch assignments:", error);
      });
  }, []);
  /**----------------render each selected batch Ends-------------- */

  const customStyles = {
    multiValue: (provided) => ({...provided,borderRadius: "20px",backgroundColor: "#007BFF",color: "white",fontSize: "10px",}),
    multiValueLabel: (provided) => ({ ...provided, color: "white" }),
    multiValueRemove: (provided) => ({...provided, color: "white",":hover": { backgroundColor: "transparent", color: "red" },}),
  };

  const [sortDirection, setSortDirection] = useState("asc");
  const sortTable = (key) => {
    const dir = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(dir);

    const sortedData = [...studentList].sort((a, b) => {
      const x = a[key].toLowerCase();
      const y = b[key].toLowerCase();

      return dir === "asc" ? x.localeCompare(y) : y.localeCompare(x);
    });

    setStudentList(sortedData); /**provide array to sort */
  };

  return (
    <div>
      <MDBRow>
        <MDBCol>{" "}<h3>Student List</h3>{" "}
        </MDBCol>
        <MDBCol>
          <button className="dropbtn32" onClick={toggleManageCourseModal}><MDBIcon fas icon="dice-d6" style={{ color: "green" }} />&nbsp;Manage Courses</button>
          <button className="dropbtn32" onClick={toggleManageBatchModal}>&nbsp;&nbsp;<MDBIcon fas icon="dice-d6" style={{ color: "orange" }} />&nbsp;Manage Batch</button>
        </MDBCol>
        <br />
      </MDBRow>
      <MDBTable>
        <MDBTableHead className="bg-gradient">
          <tr>
            <th scope="col" onClick={() => sortTable("id")}>SNo <MDBIcon fas icon="sort-amount-up" />{" "}</th>
            <th scope="col" onClick={() => sortTable("name")}>Name <MDBIcon fas icon="sort-amount-up" />{" "}</th>
            <th scope="col" onClick={() => sortTable("email")}>Email <MDBIcon fas icon="sort-amount-up" />{" "}</th>
            <th scope="col" onClick={() => sortTable("mobile")}>Mobile <MDBIcon fas icon="sort-amount-up" />{" "}</th>
            <th scope="col" onClick={() => sortTable("")}>Assign Batch <MDBIcon fas icon="sort-amount-up" />{" "}</th>
            <th scope="col" onClick={() => sortTable("")}>Assign Courses <MDBIcon fas icon="sort-amount-up" />{" "}</th>
            {/* <th scope="col" onClick={() => sortTable("")}>TEst <MDBIcon fas icon="sort-amount-up" />{" "}</th> */}
          </tr>
          <tr>
            <th>Search</th>
            <th><input type="text" onChange={(e) => setSearchName(e.target.value)} placeholder="Search Name...."></input></th>
            <th><input type="text" onChange={(e) => setSearchEmail(e.target.value)} placeholder="Search Email...."></input></th>
            <th><input  type="text" onChange={(e) => setSearchMobile(e.target.value)} placeholder="Search Mobile No...."></input></th>
            <th><input type='text' onChange={(e) => setSearchBatch(e.target.value)} placeholder='Search Batch....'></input></th>   
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {studentList
            .filter((student) => {
              return (
                (searchName.toLowerCase() == "" ||
                  student.name.toLowerCase().includes(searchName)) &&
                (searchEmail == "" || student.email.includes(searchEmail)) &&
                (searchMobile == "" || student.mobile.includes(searchMobile)) &&
                (searchBatch  ==  ""|| studentBatchAssign.includes(searchBatch))
              );
            })
            .map((student, index) => {
              const batchAssign = studentBatchAssign.find((item) => item.id === student.id);
              const batches = batchAssign ? batchAssign.batches : [];
          
              return (
                <tr key={student.id}>
                  <td>{index + 1}</td>
                  <td>{student.name}</td>
                  <td>{student.email}</td>
                  <td>{student.mobile}</td>
                  <td>
                    <Select isMulti options={batchOptions}  styles={customStyles} placeholder="Select Batches..."
                      value={selectedOptionsByRow[student.id] || batches || []}
                      onChange={(selectedValues) => updateBatch(selectedValues, student.id)}
                      // onClear={() => handleClearBatch(student.id)}
                      components={{
                        ClearIndicator: (props) => (
                          <components.ClearIndicator {...props} onClick={() => handleClearBatch(student.id)} />
                        ),
                      }}
                      isClearable='false'
                    /></td>{/* <td>{JSON.stringify(batches)}</td> */}
                </tr>
              );
            })}
        </MDBTableBody>
      </MDBTable>

      {/* (2.1)-------- Manage Batch - Modal starts---*/}
      <MDBModal tabIndex="-1" show={manageBatchModalStatus} setShow={setManageBatchModalStatus} >
        <MDBModalDialog centered className="modal-xl" style={{ width: "800px" }} >
          {/* className="modal-xl" style={{width:'800px'}} */}
          <MDBModalContent style={{ padding: "10px", width: "900px" }}>
            <MDBRow>
              <MDBCol><h3><MDBIcon fas icon="dice-d6" style={{ color: "orange" }} />&nbsp;Manage Batch{" "}</h3></MDBCol>
              <MDBCol style={{ textAlign: "right" }}>{" "}<h3 onClick={toggleManageBatchModal}  style={{ cursor: "pointer" }}>x</h3></MDBCol>
            </MDBRow>

            <div className="profileModal1 " style={{ border: "2px solid black" }} >
              <h6>Add Batch Details</h6><br />
              <MDBRow>
                <MDBCol style={{ maxWidth: "25%" }}>
                  <MDBInput type="date" id="birthday" name="birthday" label="Start Date" onChange={(e) => setSelectedDate(e.target.value)} value={selectedDate} />
                </MDBCol>
                <MDBCol style={{ maxWidth: "25%" }}>
                  <MDBInput id="form1" label="Add Batch Name" type="text" onChange={(e) => setBatchName(e.target.value)} />
                </MDBCol>
                <MDBCol style={{ maxWidth: "35%" }}>
                  <Select
                    value={selectedBatchCourse}
                    options={batchCourseOptions}
                    onInputChange={(inputValue) => inputValue}
                    onChange={(selectedOptions) =>setSelectedBatchCourse(selectedOptions)}
                    isClearable={true}
                    placeholder="Select Course"
                    style={{ maxWidth: "30px" }}
                  />
                </MDBCol>
                <MDBCol style={{ maxWidth: "15%" }} className="d-flex justify-content-center" >
                  <button type="button" className="dropbtnprofilepagesave" onClick={addBatch} style={{ width: "100px" }} >Add Batch</button>
                </MDBCol>
              </MDBRow><br />
            </div>

            <div className="profileModal1 " style={{ border: "2px solid black" }} >
              <MDBTable>
                <MDBTableHead className="bg-gradient">
                  <tr>
                    {/* <th scope='col'>ID</th>          */}
                    <th>S.No</th> <th>Start Date</th> <th>Batch Name</th> <th>Course</th> <th>Action</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {batches.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.batchStartDate}</td>
                      <td>{item.batchName}</td>
                      <td>{item.batchCourse}</td>
                      <td style={{ alignItems: "center" }}>
                        <MDBIcon
                          fas
                          icon="trash-alt"
                          onClick={() => deleteBatchFromTable(item.batchName)}
                          className="hand"
                          style={{ marginLeft: "45px", color: "red" }}
                        />
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>

            </div>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/*(2.1)--------- Manage Batch - Modal ends---- */}

      {/* (2.2)-------- Manage Course - Modal starts---*/}
      <MDBModal
        tabIndex="-1"
        show={manageCourseModalStatus}
        setShow={setManageCourseModalStatus}
      >
        <MDBModalDialog
          centered
          className="modal-xl"
          style={{ width: "800px" }}
        >
          <MDBModalContent style={{ padding: "15px" }}>
            <MDBRow>
              <MDBCol>
                <h3>
                  <MDBIcon fas icon="dice-d6" style={{ color: "orange" }} />
                  &nbsp;Manage Courses{" "}
                </h3>
              </MDBCol>
              <MDBCol style={{ textAlign: "right" }}>
                {" "}
                <h3
                  onClick={toggleManageCourseModal}
                  style={{ cursor: "pointer" }}
                >
                  x
                </h3>
              </MDBCol>
            </MDBRow>

            <ManageCourses />
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/*(2.1)--------- Manage Batch - Modal ends---- */}
    </div>
  );
}

export default AdminSideNavStudentList;
