import React, { useState } from "react";

import { Helmet } from "react-helmet";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBTypography,
  MDBCardBody,
  MDBTableBody,
  MDBTable,
  MDBBadge,
  MDBIcon,
} from "mdb-react-ui-kit";

import TPA_CAD_CUST_BROCHURE from "../static/TPA_CAD_CUST_BROCHURE.pdf";

function CAE7Content() {
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  return (
    <>
      <Helmet>
        <title>CAE7 Content - Course Curriculum</title>
        <meta
          name="description"
          content="Explore the curriculum of CAE7 content, including FE modeling, deck preparation, and solvers. Download the course brochure for more information."
        />
        <meta
          name="keywords"
          content="CAE7 content, curriculum, FE modeling, deck preparation, solvers, course brochure"
        />
      </Helmet>
    <MDBContainer
      fluid
      className="p-1 rounded-7 fullstackBody mt-3 text-align-center"
      id="course-content"
      style={{ backgroundColor: " rgb(237 213 213)" }}
    >
      <MDBRow>
        <MDBCol md="2"></MDBCol>
        <MDBCol md="8">
          <MDBCardBody>
            <MDBTypography variant="h2 text-light mt-1">
              Curriculum
            </MDBTypography>

            <MDBTable hover>
              <MDBTableBody>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  FE Modeling:
                </p>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      2D Meshing (Shell Meshing)- Meshing of Sheet metal &
                      plastic components
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      3D Meshing -Hex Meshing ,Tetra Meshing
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      1D Element Types, uses & Importance
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Use of 1D element during connections.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <p className="fs-6 text-light courseoverview-body-start mt-4 mb-4 ">
                  Deck Preparation:
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Loading & Boundary conditions applications.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      What is Deck preparation?
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Deck preparation using any one of the above preprocessor.
                    </MDBCol>

                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Deck preparation w.r.t. solvers like Ansys / Nastran/
                      LS-Dyna / Abaqus
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <h1 className="fs-6 text-light courseoverview-body-start mt-4 mb-0 ">
                  Solvers:
                </h1>
                <p className="fs-6 text-light courseoverview-body-start mt-0 mb-4 ">
                  LS-Dyna
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Quasi-static Analysis.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Dynamic Analysis.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <h1 className="fs-6 text-light courseoverview-body-start mt-4 mb-4 ">
                  Ansys
                </h1>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Modal Analysis.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Response Spectrum Analysis.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Bucking Analysis.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCol>
        <MDBCol md="2"></MDBCol>
      </MDBRow>


     <button className="brouchreDownload">
       <a
        href={TPA_CAD_CUST_BROCHURE}
        download
        outline
        color="light"
        className="mb-4 mt-3 w-10 fw-bold "
        size="lg"
      >
        <MDBIcon fas icon="download pe-2" />
        Download
      </a>
      </button>
     
    </MDBContainer>
    </>
  );
}

export default CAE7Content;
