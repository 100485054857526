import React, {  useContext } from "react";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBTypography,
} from "mdb-react-ui-kit";

import ITRegister from "../static/ITRegister.jpg";
import DesignRegister from "../static/DesignRegister.jpg";
import Analysis from "../static/Analysis.avif";
import Electronic from "../static/Electronic.webp";
import Language from "../static/Language.jpg";
import ModalContext from "../Context/ModalContext";

import ContactBody from "../More/ContactBodyEnqModal";

function InternshipRegister() {
  // const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  // const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);

  const { openModal } = useContext(ModalContext);
  const handleOpenModal = () => {
    const modalContent = <ContactBody />;
    const modalTitle = "Enquiry";
    openModal(modalContent, modalTitle);
  };
  
  return (
    <MDBContainer
      fluid
      style={{ background: " hsl(38deg 82% 84%)" }}
      className="MBfooter"
    >
      <MDBRow>
        <div>
          <figure className="text-center m-5" style={{ color: "white" }}>
            <MDBTypography variant="h4">REGISTER TODAY</MDBTypography>
            <MDBTypography variant="h2 text-bold">
              Internships Available
            </MDBTypography>

            <p class="fs-6   text-center " style={{ color: "white", marginLeft:"5%" }}>
              After you apply, we will match you with the best internship based
              on a combination of factors, including the number of slots
              available, and your areas of interest.
            </p>
          </figure>
        </div>
        <MDBCol center md="4" mt="0">
          <MDBTypography
            variant="h2"
            className="text-center  fw-bold "
            style={{ color: "white" }}
          >
            IT
          </MDBTypography>
          <br />
          <br />
          <MDBCard className="">
            <img
              className="reg-img rounded-7 "
              src={ITRegister}
              alt="images"
            ></img>
          </MDBCard>
        </MDBCol>
        {/* <MDBCol center md="1" mt="0"></MDBCol> */}

        <MDBCol center md="8" className="mb-2">
          {/* <MDBCard > */}
          <MDBCardBody>
            <MDBTypography
              variant="h2"
              className=" text-start  fw-bold "
              style={{ color: "white" }}
            >
              Internship Available Course
            </MDBTypography>
            <br />
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
              link
              // href="./Frontend1ProductDesign"
            >
              Frontend Development
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
              link
              
            >
              Backend Development
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Full Stack Web Development
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Artificial Intelligence
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Natural Language Processing
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Computer Vision
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              UI/UX Design
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Software Quality Assurance
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Data Science
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Data Analysis
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Cybersecurity
            </MDBBtn>

            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              AWS Internship
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Devops Internship
            </MDBBtn>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
      <br />
      <MDBRow>
        <MDBCol center md="4">
          <MDBTypography
            variant="h2"
            className="text-center  fw-bold "
            style={{ color: "white" }}
          >
            <br />
            Design
          </MDBTypography>

          <br />
          <MDBCard className="">
            <img
              className="reg-img rounded-7 "
              src={ DesignRegister}
              alt="images"
            ></img>
          </MDBCard>
        </MDBCol>

        <MDBCol center md="8" className="mb-2">
          <MDBCardBody>
            <MDBTypography
              variant="h2"
              className=" text-start  fw-bold "
              style={{ color: "white" }}
            >
              Internship Available Course
            </MDBTypography>
            <br />
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              CATIA - Basic Training
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              CATIA - Advanced Training
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Creo Training
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              NX -Basic Training
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              NX -Advance Training
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Solid Works
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Automative Plastic Design
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Automotive BIW Sheet Metal
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Kinamatics Simulation (Using CATIA)
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Tool and Die design making
            </MDBBtn>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
      <br />
      <MDBRow>
        <MDBCol center md="4">
          <MDBTypography
            variant="h2"
            className="text-center  fw-bold "
            style={{ color: "white" }}
          >
            <br />
            Analysis
          </MDBTypography>

          <br />
          <MDBCard className="">
            <img
              className="reg-img rounded-7 "
              src={Analysis}
              alt="images"
            ></img>
          </MDBCard>
        </MDBCol>

        <MDBCol center md="8" className="mb-2">
          <MDBCardBody>
            <MDBTypography
              variant="h2"
              className=" text-start  fw-bold "
              style={{ color: "white" }}
            >
              Internship Available Course
            </MDBTypography>
            <br />
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Ansyss - Tool Knowledge
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Hypermesh - Tool Knowledge
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Saftey
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Crash
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Durabality
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Abacus
            </MDBBtn>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
      <br />
      <MDBRow>
        {/*Electronics*/}
        <MDBCol center md="4">
          <MDBTypography
            variant="h2"
            className="text-center  fw-bold "
            style={{ color: "white" }}
          >
            <br />
            Electronics
          </MDBTypography>

          <br />
          <MDBCard className="">
            <img
              className="reg-img rounded-7 "
              src={ Electronic}
              alt="images"
            ></img>
          </MDBCard>
        </MDBCol>

        <MDBCol center md="8" className="mb-2">
          <MDBCardBody>
            <MDBTypography
              variant="h2"
              className=" text-start  fw-bold "
              style={{ color: "white" }}
            >
              Internship Available Course
            </MDBTypography>
            <br />
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Embeded Electronis
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              IOT
            </MDBBtn>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
      <br />
      <MDBRow>
        {/*Language*/}
        <MDBCol center md="4">
          <MDBTypography
            variant="h2"
            className="text-center  fw-bold "
            style={{ color: "white" }}
          >
            <br />
            LANGUAGE
          </MDBTypography>
          <br />
          <MDBCard >
            <img
              className="reg-img rounded-7 mb-3"
              src={ Language}
              alt="images"
            ></img>
          </MDBCard>
        </MDBCol>

        <MDBCol center md="8" className="mb-2">
          <MDBCardBody>
            <MDBTypography
              variant="h2"
              className=" text-start  fw-bold "
              style={{ color: "white" }}
            >
              Internship Available Course
            </MDBTypography>
            <br />
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              Java
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              JavaScript
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              HTML
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              CSS
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              C
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              C++
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              PHP
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              VBA
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              C#
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              PHYTHON
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline 
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              MYSQL
            </MDBBtn>
            <MDBBtn onClick={handleOpenModal}
              outline
              rounded
              className="mx-2 mb-2"
              color="dark"
              size="sm"
              style={{ color: "white" }}
            >
              MATLAB
            </MDBBtn>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default InternshipRegister;
