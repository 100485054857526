import React, { useState, useEffect } from 'react'
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { baseURL } from './../../http';

function StudentMyCourse() {

  const [batches, setBatches]   =useState([]);
  const [batchesTable, setBatchesTable]   =useState([]);
  const userInfo                                                                     = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                                                                    = userInfo.email;

  useEffect(() => { fetchStudentBatches(); fetchBatchesTable()}, []);

  const fetchStudentBatches=()=>{
    const data={  email     : userEmail       }
    fetch(`${baseURL}/getStudentBatchAssigned`,
    { 
      method: 'POST',
      body: JSON.stringify(data),
      headers : { 'Content-Type': 'application/json', 'Accept'      : 'application/json', },
    })
      .then(response => response.json())
      .then(data     => { 
                    setBatches(data || []); 
                    // console.log(data);
            })
      .catch(error   => { console.error('Error fetching template by student:', error); });
  };
  

  const fetchBatchesTable=()=>{
    fetch(`${baseURL}/getBatchOptions`)
    .then(response => response.json())
    .then(data     => { setBatchesTable(data||[]); }) //console.log(JSON.stringify(data)); 
    .catch(error   => { console.error('Error fetching template by Admin:', error); });
};

  return (
    <div className='contentBackground'>
        <h3>My Courses</h3>
        <MDBTable>
        <MDBTableHead className='bg-gradient'>
          <tr>
            <th scope='col'>ID</th>
            <th scope='col'>Batch Name <span style={{fontSize:'9px'}}><br/>(Assigned)</span></th>
            <th scope='col'>Course </th>
            <th scope='col'>Start Date</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
              {batches.map((batch, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{batch}</td>
                    <td>

                        {batchesTable.map((batchTable)=>(
                            <span key={batchTable}>
                              {(batch==batchTable.batchName)? batchTable.batchCourse :''}
                            </span>
                        ))}
                    
                    </td>
                    <td>
                        {batchesTable.map((batchTable)=>(
                            <span key={batchTable}>
                              {(batch==batchTable.batchName)? batchTable.batchStartDate :''}
                            </span>
                        ))}
                    </td>

                  </tr>
              ))}
        </MDBTableBody>
        </MDBTable>
      
    </div>
  )
}

export default StudentMyCourse
