import React, { useState, useEffect } from 'react'
import {  MDBModal,  MDBModalDialog,  MDBModalContent, } from 'mdb-react-ui-kit';
import Stscroll4KeySkillsModal from './Stscroll4KeySkillsModal';
import { baseURL } from '../../../http';

function Stscroll4KeySkills() {

  const userInfo                          = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                         = userInfo.email;
  const [keyskills, setKeyskills]         = useState([]);
  const [modalStatus, setModalStatus]     = useState(false);
  
  function toggleModal(e){       setModalStatus(!modalStatus);}

    useEffect(() => { fetchStudentSkillsList(); }, []);

    const fetchStudentSkillsList=()=>{
      const data={  email     : userEmail       }
      fetch(`${baseURL}/getStudentKeySkills`,
      { 
        method: 'POST',
        body: JSON.stringify(data),
        headers : { 'Content-Type': 'application/json', 'Accept'      : 'application/json', },
      })
        .then(response => response.json())
        .then(data     => { 
                      setKeyskills(data || []); 
                      console.log(data);

              })
        .catch(error   => { console.error('Error fetching Keyskills by student:', error); });
    };
    
    
  


  return (
    <div style={{height:'auto'}}>
       <h5 style={{color:'black'}}>Keyskills  <i  onClick={toggleModal}className="fas fa-pencil" style={{paddingBottom:'8px', fontSize:12, cursor:'pointer'}}></i></h5>
         
        
          
            {keyskills.map((skill) => (
              <span key={skill}>
              <button  className='dropbtn41'>{skill}</button>
              </span>
            ))}
          


        
         {/* (2.1)------------------>---Modal code starts */}
         <MDBModal tabIndex='-1' show={modalStatus} setShow={setModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
                        <MDBModalContent >
                            
                                <Stscroll4KeySkillsModal togglingModal={toggleModal}/>
                                
                        </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
          {/*(2.1)--------->------------- Modal code ends */}


    </div>
  )
}

export default Stscroll4KeySkills
