import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";

 function CAE3Curriculum1() {
  return (
    <>
      <MDBContainer
        fluid
        className="p-3 rounded-7 text-start mb-5 fullstackBody mt-5"
      >
        <div class="scrollmenu">
          <a
            className="scroll active "
            href="#course"
            style={{ color: "white", fontWeight: "bold" }}
          >
            Course Info
          </a>
          <a
            class="scroll mx-2"
            href="#course-content"
            style={{ color: "white", fontWeight: "bold" }}
          >
            Curriculum
          </a>
          <a
            class="scroll mx-2"
            href="#program"
            style={{ color: "white", fontWeight: "bold" }}
          >
            Program
          </a>
          <a
            class="scroll mx-2"
            href="#review"
            style={{ color: "white", fontWeight: "bold" }}
          >
            Review
          </a>
          <a
            class="scroll mx-2"
            href="#TrainingCurriculum1"
            style={{ color: "white", fontWeight: "bold" }}
          >
            Enroll Now
          </a>
        </div>
      </MDBContainer>
    </>
  );
};

export default CAE3Curriculum1;
