import {
  MDBCarousel,
  MDBCarouselItem,
  
} from "mdb-react-ui-kit";

import IT2 from "../static/IT (2).jpg";
import FullStack from "../static/IT (3).webp";
import FullStack1 from "../static/FullStack1.jpg";
import industry from "../static/industry.jpg";
import IT9 from "../static/IT (9).jpg";
import Mech1 from "../static/Mech (1).jpg";
import Mech2 from "../static/Mech (2).webp";
import ai1 from "../static/ai1.webp";
import ai5 from "../static/ai5.jpg";
import cad1 from "../static/cad1.webp";
import { Helmet } from "react-helmet";

function Carousel() {
  return (
    <>
     <Helmet>
      
      <title>
      Number one platform to share your Career | TPA - Bangalore
      {/* Best platform to learn and placement your Career in Bangalore */}
    </title> 
    <meta name="keywords" 
         content="CATIA, CAD Aumation & Coustmization NX Customization , Automation &
                   open, Creo Automation, Full stack, web development, software testing, best &
                   no.1 training institute in bangalore with online & offline classrooms" 
         />
   
    <meta name="description" content="TPA creates a Platform to transcend and Where You Excel in IT & 
       Engg industries and make a successful career after completion of our courses
         in bangalore." 
       />
   
   </Helmet>
    <MDBCarousel showControls showIndicators interval={5000} className="" >
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={1}
        // img
        src={Mech1}
        //src={IT2}
        alt="Mechanical"
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">ADVANCE YOUR CAREER FROM OUR</h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
                JOB READY PROGRAMS<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={2}
        // img
        src={cad1}
        alt="Computer Aided Design / CAD"
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">LEARN TODAY, LEAD TOMORROW</h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
              YOUR FUTURE BEGINS HERE<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={3}
        // img
        src={FullStack1}
        //src={IT2}
        alt="Fullstack courses"
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">TAKE THE FIRST STEP TO</h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
              KNOWLEDGE WITH US<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={4}
        // img
        src={IT9}
        //src={IT2}
        alt="Information Technology / IT"
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">GET INDUSTRY READY AND LEARN </h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
              FROM EXPERTS<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={5}
        // img
        src={IT2}
        alt="Information Technology / IT"
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">SKILLS THAT OPEN DOORS </h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
              ENROLL IN OUR JOB-READY COURSE<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={6}
        // img
        src={Mech2}
        alt="Mechanical"
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">LEARN TODAY, LEAD TOMORROW</h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
              YOUR FUTURE BEGINS HERE<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={7}
        // img
        src={ai1}
        alt="artificial Intelligence"
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">LEARN TODAY, LEAD TOMORROW</h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
              YOUR FUTURE BEGINS HERE<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={8}
        // img
        src={ai5}
        alt="artificial Intelligence"
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">LEARN TODAY, LEAD TOMORROW</h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
              YOUR FUTURE BEGINS HERE<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={9}
        // img
        src={FullStack}
        //src={IT2}
        alt="FullStack"
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">TAKE THE FIRST STEP TO</h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
              KNOWLEDGE WITH US<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="w-100 h-100 d-block carousel-inner"
        itemId={10}
        // img
        src={industry}
        //src={IT2}
        alt=""
        width="250"
        height="150"
        loading="lazy"
      >
        {" "}
        <div className="container1">
          <div className="box1">
            <div className="title1">
              <span className="block1"></span>
              <h3 className="anime">TAKE THE FIRST STEP TO</h3>
            </div>
            <br />
            <div className="role1">
              <div className="block1"></div>
              <p className="animepara">
              KNOWLEDGE WITH US<span></span>
              </p>
            </div>
          </div>
        </div>
      </MDBCarouselItem>
      
     
    </MDBCarousel>
    </>
  );
}

export default Carousel;
