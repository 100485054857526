import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCardBody,
} from "mdb-react-ui-kit";

import TermsConditions from "../static/TermsConditions.jpg";

import { Helmet } from "react-helmet";

 function TermsAndConditions() {
  return (
    <>
     <div>
     <Helmet>
        <title>
          Terms and Conditions - Talent Pool Academy
        </title>
        <meta
          name="description"
          content="It is important to read the rules and cancellation policies of the online classes before beginning your class at Talent Pool Academy."
        />
        <meta
          name="keywords"
          content="terms and conditions, online classes, cancellation policies, technical requirements, internet connection, class course schedule, 
                  class participation, disability accommodation"
        />
      </Helmet>

</div>
      <MDBRow
        className="MBfooter  "
        style={{ marginTop: "0.2%", color: "white" }}
      >
        <MDBCol center md="2"></MDBCol>
        <MDBCol center md="6">
          <MDBCardBody className="">
            <MDBTypography variant="h1 text-start" style={{ fontSize: "50px" }}>
              TERMS AND CONDITIONS
            </MDBTypography>
          </MDBCardBody>
        </MDBCol>

        <MDBCol center md="4" className="p-5">
          <img
          src={TermsConditions} 
            className="round"
            alt="hire from image"
            style={{
              width: "100%",

              height: "100%",
            }}
          ></img>
        </MDBCol>
      </MDBRow>
      <MDBRow
        className="mt-2  "
        style={{ backgroundColor: "hsl(38deg 82% 84%)", color: "black" }}
      >
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="10" className="p-5">
          <MDBCol>
            <ol type="1">
              <p>
                <b>
                  It is very important to carefully read the rules and
                  cancellation policies of the online classes before beginning
                  your class.{" "}
                </b>
              </p>
              <li>
                The student should access the virtual classroom at least 2 days
                before their first class in order to learn how to work within
                the system. Make sure to check the videoconference link and
                access the Adobe Connect platform 15 minutes before your class.
              </li>
              <li>
                It is the student’s responsibility to make sure they have the 
                <a href="#">necessary technical requirements </a>and internet
                connection before class begins.  If you need technical
                assistance you can make an appointment with the TPA technical
                staff during their office hours.  Any technical difficulties
                during the class that are the responsibility of the student
                cannot be reimbursed if class time is lost.
              </li>
              <li>
                Double check that you have a stable internet connection BEFORE
                your class starts. Take into consideration that if other people
                in your home or office are downloading files, watching videos or
                playing games on the internet, etc, it could interrupt your
                internet connection and class.
              </li>
              <li>
                Students must be on time for the videoconference; we will not
                make up class time due to late arrivals. 
                <a href="#">Check your course schedule.</a>
              </li>
              <li>
                Please keep in mind that the Virtual Campus operates off Costa
                Rican time; therefore, hours are the same throughout the year
                and there is no seasonal change of schedule.  The student is
                responsible for reviewing if the seasonal schedules have changed
                in their region.  If the student misses class due to a
                misunderstanding of the time table in their region, we will not
                make up classes for the time lost. 
                <a href="#">Check/Change your Time Zone.</a>
              </li>
              <li>
                It is strongly recommended that students actively participate in
                each class. 
              </li>
              <li>
                If you have a disability that could affect your performance in
                this class or that requires additional accommodation, please
                contact TPA before your class starts so we can make the
                appropriate arrangements. 
              </li>
              <li>
                Students must demonstrate appropriate social behaviors in class;
                the appropriateness of their behavior is determined by the
                teacher.  For example, talking on the phone during class or to a
                person outside of the class could lead to being disconnected
                from the class.
              </li>
              <li>
                At the beginning of each new course, it is at the discretion of
                TPA to place the student with a new teacher. This change will
                allow the student to experience different learning styles with
                other teachers who are fully trained to teach Spanish online.
              </li>
              <li>
                In the event your current teacher is not available to facilitate
                class (due to sickness or a last-minute emergency), TPA will not
                cancel class, instead class will be given by a substitute
                teacher.
              </li>
              <li>
                 Read our <a href="#"> class cancellation policies </a>for
                private and group classes.
              </li>
            </ol>
          </MDBCol>
        </MDBCol>

        <MDBCol center md="1"></MDBCol>
      </MDBRow>
    </>
  );
};

export default TermsAndConditions;
