import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
  MDBTypography,
  MDBIcon,
  MDBCardBody,
} from "mdb-react-ui-kit";

import ST3Curriculum1 from "./ST3Curriculum1";
import ST4Description from "./ST4Description";
import ST5CourseHighlights from "./ST5CourseHighlights";
import ST7Content from "./ST7Content";

import SoftwareProduct from "../static/SoftwareProduct.webp";

import { Helmet } from "react-helmet";

 function ST1ProductDesign() {
  return (
    
    <>

<div>
    <Helmet>
      
   <title>
  Software Testing - Certification Training Course with Placement|TPA
 </title> 
 
 <meta
  name="description"
  content="Top courses in Software Testing and Quality Assurance from TPA bangalore· The Complete Quality Assurance Course- Learn QA from Scratch · Master software testing - from zero to Hero ..."
/>

<meta
          name="keywords"
          content="Software Testing, Quality Assurance, Certification, Training Course, Placement, TPA, Bangalore"
        />
</Helmet>

</div>
      <MDBContainer fluid className="p-1 rounded-7 fullstackBody ">
        <figure className="text-start text-light mb-0 mx-4">
          <MDBTypography variant="h1 text-light">
            Job Ready – Software Testing
          </MDBTypography>

          <p class="text-light mb-0">
            <MDBIcon far icon="bookmark pe-2" />
            Category: SOFTWARE PROGRAMS / ST1ProductDesign{" "}
            <span class="fw-bold">6</span>
          </p>
        </figure>

        <MDBRow>
          <MDBCol md="8">
            <MDBCardBody>
              <div className="bg-image hover-zoom">
                <img
                  style={{ width: "100%", maxHeight: "500px" }}
                  alt="car"
                  src={SoftwareProduct }
                  className="round"
                />
              </div>
            </MDBCardBody>
          </MDBCol>
          <MDBCol start md="4">
            <MDBCardBody className="mechanical-body tpa-list-group">
              <MDBListGroup light>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="users pe-3" size="2x" />
                  <span>100 students</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item "
                >
                  <MDBIcon far icon="clock pe-4" size="2x" />
                  <span>Duration:180 hours</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="chalkboard-teacher pe-3" size="2x" />
                  <span> Lectures:75</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="book pe-4" size="2x" />
                  <span>Course completion</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item-google"
                >
                  <MDBIcon fab icon="google pe-4" size="2x" />
                  <span className="text-dark fs-4 fw-bold pe-2">4.8</span>
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star-half-alt" />
                </MDBListGroupItem>

                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="hand-point-right pe-4" size="2x" />
                  <span>Enroll Now</span>
                </MDBListGroupItem>
              </MDBListGroup>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ST3Curriculum1 />
      <ST4Description />
      <ST5CourseHighlights />
      <ST7Content />

      {/* <ST8Reviews /> */}
    </>
  );
};

export default ST1ProductDesign;
