import React from "react";
import { MDBContainer, MDBTypography } from "mdb-react-ui-kit";

function C4atiaDescription() {
  return (
    <MDBContainer
      id="course"
      fluid
      className="p-1 rounded-7 mt-5 "
      style={{ color: "black" }}
    >
      <MDBTypography
        variant="h1 courseoverview-body text-start mt-3 mx-3 "
        style={{ color: "black" }}
      >
        Course Description
      </MDBTypography>
      <p
        md="8"
        className="fs-5 courseoverview-body-start text-start m-3 "
        style={{ color: "black" }}
      >
              TPA Core Skills Development Team Crafted CAD customization training program which is specially designed for freshers and working experts and freelancers in Mechanical Design of all CAD Packages. This Program provides an end to end learning on CAD Customization  Which is blend of CAD & Programming Aiming at transcending careers to a different level in terms of technical growth as well as growth in pay.

      </p>
    </MDBContainer>
  );
};

export default C4atiaDescription;
