import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
  MDBTypography,
  MDBIcon,
  MDBCardBody,
} from "mdb-react-ui-kit";

import FS2TrainingOptions from "./FS2TrainingOptions";
import FS3Curriculum1 from "./FS3Curriculum1";
import FS4Description from "./FS4Description";
import FS5CourseHighlights from "./FS5CourseHighlights";
import FS6Program from "./FS6Program";
import FS7Content from "./FS7Content";

import FullstackProduct from "../static/FullstackProduct.png";

import { Helmet } from "react-helmet";

 function FS1ProductDesign() {
  return (
    <>
     <div>
    <Helmet>
      
   <title>
   Full Stack Developer - Certification Training Course with Placement|TPA
 </title> 
 
 <meta
  name="description"
  content="Top Full Stack Web Development courses in Web Development  helps you to master skills like React js,MongoDB, Express.js, Node.js, Angular, etc. Enroll in this master's program certification in banbalore ."
/>;
</Helmet>

</div>
      <MDBContainer fluid className="p-1 rounded-7 fullstackBody ">
        <figure className="text-start text-light mb-0 mx-4">
          <MDBTypography variant="h1">Full Stack Developer</MDBTypography>

          <p class="text-light mb-0">
            <MDBIcon far icon="bookmark pe-2" />
            Category: SOFTWARE PROGRAMS 
          </p>
        </figure>

        <MDBRow>
          <MDBCol md="8">
            <MDBCardBody>
              <div className="bg-image hover-zoom">
                <img
                  style={{ width: "100%", maxHeight: "500px" }}
                  alt="car"
                  src={FullstackProduct}
                  className="round"
                />
              </div>
            </MDBCardBody>
          </MDBCol>
          <MDBCol start md="4">
            <MDBCardBody className="mechanical-body tpa-list-group">
              <MDBListGroup light>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="users pe-3" size="2x" />
                  <span>120 students</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item "
                >
                  <MDBIcon far icon="clock pe-4" size="2x" />
                  <span>Duration:180 hours</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="chalkboard-teacher pe-3" size="2x" />
                  <span> Lectures:2</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="book pe-4" size="2x" />
                  <span>Course completion</span>
                </MDBListGroupItem>
                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item-google"
                >
                  <MDBIcon fab icon="google pe-4" size="2x" />
                  <span className="text-dark fs-4 fw-bold pe-2">4.8</span>
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star" />
                  <MDBIcon color="warning" fas icon="star-half-alt" />
                </MDBListGroupItem>

                <MDBListGroupItem
                  tag="button"
                  action
                  Borders
                  type="button"
                  className="px-3 Mechanical-Item"
                >
                  <MDBIcon fas icon="hand-point-right pe-4" size="2x" />
                  <span>Enroll Now</span>
                </MDBListGroupItem>
              </MDBListGroup>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <FS2TrainingOptions />
      <FS3Curriculum1 />
      <FS4Description />
      <FS5CourseHighlights />
      <FS6Program />
      <FS7Content />

      {/* <FS8Reviews /> */}
    </>
  );
};

export default FS1ProductDesign;
