import React, { useState } from 'react';
import StudentProfilePage from './Update Profile Tab/1StudentProfilePage';
import Stscroll1 from './Update Profile Tab/Stscroll1';
import StudentHomePage from './1StudentHomePage';
import StudentResumeTemplateDownloader from './StudentResumeTemplateDownloader';
import MyInterviewQues from './MyInterviewQues';
import StudentAllApprovedInterviewQues from './StudentAllApprovedInterviewQues';
import StudentMyCourse from './2StudentMyCourse';

const tabsData = [  
    { id: 1,    title: 'Home',                         content:    <> <StudentHomePage/></>                              },
    { id: 2,    title: 'Update Profile',               content:    <> <StudentProfilePage/> <br/> <Stscroll1/> </>       },
    { id: 3,    title: 'My Courses',                   content:    <StudentMyCourse/>                                    },
    { id: 4,    title: 'My Interview QA\'s',           content:    <MyInterviewQues/>                                    },
    { id: 5,    title: 'All Interview QA\'s',          content:    <StudentAllApprovedInterviewQues/>                    },
    { id: 6,    title: 'Resume-Templates',             content:    <StudentResumeTemplateDownloader/>                    },
   
  ];

  
function StudentSideNav() {
  
    const [activeTab, setActiveTab] = useState(tabsData[0].id);

    const handleTabClick = (tabId) => { setActiveTab(tabId); };
  
    return (
        <div className="vertical-tabs-container-student">
          
          <div className="vertical-tabs-student">
            {tabsData.map((tab) => (
              <div
                key={tab.id}
                className={`vertical-tab-student ${tab.id === activeTab ? 'active' : ''}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.title}
              </div>
            ))}
          </div>
          <div className="tab-content-student">
            {tabsData.map((tab) => (
              <div
                key={tab.id}
                className={`tab-pane-student ${tab.id === activeTab ? 'active' : ''}`}
              >
                {tab.content}
              </div>
            ))}
          </div>
        </div>
      );
    };

export default StudentSideNav
