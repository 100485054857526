import React from "react";
import { MDBCol, MDBRow, MDBTypography, MDBIcon } from "mdb-react-ui-kit";

 function CardsUp() {
  return (
    <MDBRow className="UP-container">
      <MDBCol md="3" className="UP-card">
        <div className="UP-box" style={{ backgroundColor: "#10c45c" }}>
          <div className="UP-content header3">
            <MDBIcon
              size="4x"
              color="light"
              className="fas fa-graduation-cap"
            />
            <MDBTypography
              color="light"
              className=" fw-bold text-start mt-5 "
              variant="h6"
            >
              TRENDING COURSES
            </MDBTypography>
            <p className="fs-6 fw-bold text-start mx-2 para-p">
              All Courses at Talent Pool Academy are Tailored by Industry
              Experts according to the Unique Skills Expected by Top MNCs.
            </p>
          </div>
        </div>
      </MDBCol>

      <MDBCol md="3" className="UP-card">
        <div className="UP-box" style={{ backgroundColor: "#fdc735" }}>
          <div className="UP-content header3">
            <MDBIcon size="4x" color="light" fab icon="telegram-plane" />
            <MDBTypography
              color="light"
              className=" fw-bold text-start mt-4 "
              variant="h5"
            >
              TRANSCEND CAREER
            </MDBTypography>
            <p className="fs-6 fw-bold text-start mx-2 para-p">
              TPA creates a Platform to transcend and Where You Excel in IT &
              Engg industries and make a successful career after completion of
              our courses.
            </p>
          </div>
        </div>
      </MDBCol>

      <MDBCol md="3" className="UP-card">
        <div className="UP-box" style={{ backgroundColor: "#307ad5" }}>
          <div className="UP-content header3">
            <MDBIcon size="4x" color="light" className=" fas fa-laptop-house" />
            <MDBTypography
              color="light"
              className=" fw-bold text-start mt-5 "
              variant="h4"
            >
              HYBRID LEARNING
            </MDBTypography>
            <p className="fs-6 fw-bold text-start mx-2 para-p">
              All Programs has a option to learn through online or physical
              classroom or even on blended/Hybrid Mode on your flexibility
            </p>
          </div>
        </div>
      </MDBCol>
      <MDBCol md="3" className="UP-card">
        <div className="UP-box" style={{ backgroundColor: "#e948ae" }}>
          <div className="UP-content header3 ">
            <MDBIcon size="4x" color="light" fas icon="id-card" />
            <MDBTypography
              color="light"
              className=" fw-bold text-start mt-5 "
              variant="h4"
            >
              CERTIFICATION
            </MDBTypography>
            <p className="fs-6 fw-bold text-start mx-2 para-p">
              Upon successful completion receive a certificate showing your
              achievement for completing one of our rigorous classes.
            </p>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default CardsUp;
