import React from "react";
import {
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBCardBody,
} from "mdb-react-ui-kit";
import Contact from "./Contact";
import AboutusImage from "../static/AboutusImage.png";
import AboutusImage1 from "../static/AboutusImage1.jpg";
import AboutusImage2 from "../static/AboutusImage2.jpg";


import { Helmet } from "react-helmet";

function AboutUs() {
  return (
    <>

      <Helmet>
        <title>About Us - Talent Pool Academy</title>
        <meta
          name="description"
          content="TPA commits to building the skills gap between industry and academics through industry-linked competency development programs, 
          creating a Work Ready Resource Pool (WRRP)."
        />
        <meta
          name="keywords"
          content="TPA, Talent Pool Academy, About Us, industry-linked competency development programs, 
          Work Ready Resource Pool, WRRP, Equal Opportunity Employer, technical skills, industry experience, 
          communication skills, business development, training programs"
        />
      </Helmet>
    
      <MDBRow
        className="MBabout  "
        style={{ marginTop: "0.2%", color: "black" }}
      >
        <MDBRow>
          <MDBCol center md="2"></MDBCol>
          <MDBCol center md="6">
            <MDBCardBody className="">
              <MDBTypography
                variant="h1 text-start"
                style={{ fontSize: "75px" }}
              >
                About Us
              </MDBTypography>
            </MDBCardBody>
          </MDBCol>

          <MDBCol center md="4">
            <img
              src={AboutusImage}
              className="round aboutusImage"
              alt="TPA hire"
            ></img>
          </MDBCol>
        </MDBRow>
      </MDBRow>
      <br />
      <MDBRow
        className=" mt-3 p-5"
        style={{
          backgroundColor: "hsl(38deg 82% 84%)",
          color: "black",
        }}
      >
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="4">
          <img
            src={AboutusImage1}
            className="round"
            alt="TPA hire"
            style={{
              width: "75%",

              height: "75%",
            }}
          ></img>
        </MDBCol>

        <MDBCol center md="7">
          <p className="fs-4 mt-5">
            TPA commits to build the skills gap between industry and Academics
            running industry linked competency development and training programs creates a
            Work Ready Resource Pool(WRRP).
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow
        className="  mt-3 p-5"
        style={{
          color: "black",
        }}
      >
        <MDBCol center md="8">
          <p className=" mobview">
            TPA with an approach targeted at achieving the best possible as its
            philosophy,has achieved remarkable success within a short of time
            with its resources having been engaged at most of the top
            Multinational Companies-including CMM level-5 India and abroad.
            <br />
            As an Equal-Opportunity Employer, candidates are Education,
            Technical skills, Industry Experience, Communication Skills,
            Interpersonal Skills, and Versatility.
            <br />
            Our Business Development Managers work closely with clients and
            candidates to align and meet the exact needs.
          </p>
        </MDBCol>

        <MDBCol center md="4">
          {" "}
          <img
            src={AboutusImage2}
            className="round"
            alt="TPA hire"
            style={{
              width: "100%",

              height: "100%",
            }}
          ></img>
        </MDBCol>
      </MDBRow>
      <MDBRow
        className="  mt-3 p-5"
        style={{
          backgroundColor: "hsl(38deg 82% 84%)",
          color: "black",
        }}
      >
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="4">
          <Contact />
        </MDBCol>
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="5">
          <div className="ratio ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/0By2tcDQ4J8"
              title="YouTube video"
              allowfullscreen
            ></iframe>
          </div>
        </MDBCol>
        <MDBCol center md="1"></MDBCol>
      </MDBRow>

    </>
  );
}

export default AboutUs;
