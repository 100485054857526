import React, { useState, useEffect } from 'react'
import { MDBTable, MDBTableHead, MDBTableBody , MDBRow, MDBCol, MDBCheckbox, MDBIcon  } from 'mdb-react-ui-kit'
import { baseURL } from '../../http';

function ApprovedInterviewQues() {

    const [interviewQasList, setInterviewQasList]               = useState([]); // for array null array sq.brackets to be given

    const [searchEmail, setSearchEmail]                         =useState('');
    const [searchInterviewDate, setSearchInterviewDate]         =useState('');
    const [searchPosition, setSearchPosition]                   =useState('');
    const [searchCompany, setSearchCompany]                     =useState('');
    const [searchQuestion, setSearchQuestion]                   =useState('');
    const [searchAnswer, setSearchAnswer]                       =useState('');

    useEffect(() => {  fetchinterviewQAS();  }, []);
    const fetchinterviewQAS=()=>{
        fetch(`${baseURL}/getAllApprovedQas`)   
        .then(response => response.json())
        .then(data     => { setInterviewQasList(data || []); 
          // console.log(JSON.stringify(data));     
        })
        .catch(error   => { console.error('Error fetching qas Table-2:', error); });
    }




  return (
    <div>
      <MDBRow>
        <MDBCol> <h3>All Interview Ques Handler By Admin</h3></MDBCol>
      </MDBRow>

     {/* <input type='text' onChange={(e) => setSearch(e.target.value)} placeholder='Type to Search....'></input> */}

      <MDBTable bordered>
          <MDBTableHead className='bg-gradient'>
            <tr style={{textAlign: 'center'}}>
              <th scope='col'style={{border:''}}>SNo</th>
              <th scope='col'style={{border:''}}>Email</th>
              <th scope='col'style={{border:''}}>Interview Date</th>
              <th scope='col'style={{border:''}}>Position</th>
              <th scope='col'style={{border:''}}>Company</th>
              <th scope='col'style={{border:''}}>Questions</th>
              <th scope='col'style={{border:''}}>Answers</th>
            </tr>
            <tr>
              <th>Search</th>       
              <th><input type='text' onChange={(e) => setSearchEmail(e.target.value)} placeholder='Search Email....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchInterviewDate(e.target.value)} placeholder='Search Date....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchPosition(e.target.value)} placeholder='Search Positon....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchCompany(e.target.value)} placeholder='Search Company....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchQuestion(e.target.value)} placeholder='Search Question....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchAnswer(e.target.value)} placeholder='Search Answer....'></input></th>   
            </tr>
          </MDBTableHead>
          <MDBTableBody style={{textAlign: 'center'}}>
            {interviewQasList
                .filter((qasItem) =>{ 
                  return (// if null values search not happening
                      (searchEmail.toLowerCase()    == '' || qasItem.email.toLowerCase().includes(searchEmail)) &&  
                      (searchInterviewDate          == '' || qasItem.interviewDate.includes(searchInterviewDate)) &&
                      (searchPosition.toLowerCase() == '' || qasItem.jobRole.toLowerCase().includes(searchPosition)) &&
                      (searchCompany.toLowerCase()  == '' || qasItem.companyName.toLowerCase().includes(searchCompany)) &&
                      (searchQuestion.toLowerCase() == '' || qasItem.questions.toLowerCase().includes(searchQuestion)) &&
                      (searchAnswer.toLowerCase()   == '' || qasItem.answers.toLowerCase().includes(searchAnswer)) 
                  );
              })
              
            .map((qasItem, index) => (
                <tr key={qasItem.id}>
                    {/* <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{index+1} - {qasItem.id}</td> */}
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{index+1} </td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.email}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.interviewDate}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.jobRole}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.companyName}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.questions}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.answers}</td>
                </tr>
            ))}
                  
          </MDBTableBody>
          </MDBTable>


    </div>
  )
}

export default ApprovedInterviewQues
