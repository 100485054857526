import React from "react";
import { Helmet } from "react-helmet";
import {
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBTypography,
} from "mdb-react-ui-kit";

import Program1 from "../static/Program1.jpg";
import Program2 from "../static/Program2.png";
import Program3 from "../static/Program3.jpg";


export default function App() {
  return (
    <>
      <Helmet>
        <title>Job Ready – Automotive Design | TPA</title>
        
        <meta
  name="description"
  content="Transform your career with TPA's Job Ready Automotive Design program in Bangalore. Gain hands-on project experience in passenger car design, plastic trim design, and BIW engineering. Discover full-time and online training options to develop industry-ready skills."
/>

       
        <meta
  name="keywords"
  content="Job Ready, Automotive Design, Passenger Car Design, Plastic Trim Design, BIW Design, Engineering Training, Full Time Training, Online Training, Employability Skills, Industry Skills, TPA Bangalore"
/>

      </Helmet>
    <MDBContainer fluid className="p-1 rounded-7 mt-4 " id="program">
      <MDBRow>
        <MDBCol md="2"></MDBCol>
        <MDBCol md="8">
          <MDBCardBody>
           
            <br />
            <MDBRow>
              <MDBCol size="6" className="mb-5">
                <img
                  src={Program1}
                  className="img-fluid rounded"
                  width="95%" max-height="500px"
                  alt="Program-image"
                />
              </MDBCol>
              <MDBCol size="6" className="text-start mt-5">
                <MDBTypography variant="h4">
                  JR-AD ( Job Ready – Automotive Design){" "}
                </MDBTypography>
                <p className="fs-5">
                  Specialization – Passenger Car Int/Ext Plastic Trim / BIW
                  (Body in White) Design.
                </p>
                <p className="fs-5">Duration – 6 Months</p>
                <p className="fs-5">
                  Training Mode – Full Time in Lab / Online
                </p>
              </MDBCol>
              <p className="fs-4 text-start">
                This Program is engaged by Competency Development team of talent
                Pool Academy which is a “transforming platform” for Engineering
                graduates of Mechanical & related streams. This Program Ensures
                hands on project experience in design and engineering by
                providing “Experienced Work Methodology” & “Industry Ready
                Design Skills” using the methods followed by the top OEM’s and
                Tier 1 companies thereby developing their employability and
                inculcating the long term growth in the Industry with high
                payroll.
              </p>
              <MDBCol size="6" className="mb-5">
                <img
                  src={Program2}
                  className="img-fluid rounded"
                  width="450"
                  alt=""
                />
              </MDBCol>
              <MDBCol size="6">
                <img
                  src={Program3}
                  className="img-fluid rounded"
                  alt=""
                  width="300"
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCol>
        <MDBCol md="2"></MDBCol>
      </MDBRow>
    </MDBContainer>
    </>
  );
}
