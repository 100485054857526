import React from "react";
import {
  MDBTypography,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

import Learning from "../static/Learning.jpg";

function InternshipLearning() {
  return (
    <>
      <MDBRow
        style={{ backgroundColor: "hsl(38deg 82% 84%)", color: "white" }}
        className="p-2 mt-1 MBfooter"
      >
        <MDBTypography variant="h2 text-center" className="mb-5 mt-4">
          From learning to interning
        </MDBTypography>

        <MDBCol center md="6">
          <img
            src={ Learning}
            alt='internship learning'
            className="hifi-img intern-round"
            style={{ width: "100%", maxHeight: "375px" }}
          ></img>
        </MDBCol>
        <MDBCol center md="6">
          <MDBRow>
            <MDBCol md="2"></MDBCol>
            <MDBCol md="8">
              <MDBTypography variant="h5 ">
                Engineering and Technology Students
              </MDBTypography>

              <p className="fs-6 text-start  mb-4">
                Students gain live work experiences of various domains of
                Engineering & Technology, this platform enables skills
                development through Learning by Doing Methodology.
              </p>

              <MDBTypography variant="h5 ">Experimental Learning</MDBTypography>
              <p className="fs-6 text-start   mb-4">
                Students have the opportunity to take initiative, make
                decisions, and experiment.
              </p>

              <MDBTypography variant="h5 ">High-demand Skills</MDBTypography>
              <p className="fs-6 text-start   mb-4">
                Learn Practical Engineering, Entrepreneurship, marketing, data
                analytics, computer science, and more.
              </p>
            </MDBCol>
            <MDBCol md="2"></MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
}

export default InternshipLearning;
