import React, { Component } from 'react'
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import {baseURL} from './../../http';
export class AdminSideNavEnquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
          enquiryList: [],
        };
      }
    
      componentDidMount() {
        this.fetchEnquiryList();
      }
    
      fetchEnquiryList = () => {
        fetch(`${baseURL}/enquirylist`)//change
          .then(response => response.json())
          .then(data => {
            this.setState({ enquiryList: data });
          })
          .catch(error => {
            console.error('Error fetching enquiry list:', error);
          });
      };

      //for delete enquirydata
      handleEnquiryDelete = (enquiryId) => {
    // Delete user from the state
    const updatedEnquiryList = this.state.enquiryList.filter(enquiry => enquiry.id !== enquiryId);
    this.setState({ enquiryList: updatedEnquiryList });

    // Delete enquiry from the backend
    fetch(`${baseURL}/deleteEnquiry/${enquiryId}`, {
      method: 'PUT', // You can use 'DELETE' if your API supports it
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (response.ok) {
        console.log('User deleted successfully');
      } else {
        console.error('Failed to delete user');
      }
    })
    .catch(error => {
      console.error('Error deleting enquiry:', error);
    });
  };
      
    
      render() {
        const { enquiryList } = this.state;
    
        return (
          <div>
          <h2>Enquiry List(s)</h2>
          <MDBTable>
              <MDBTableHead className='bg-gradient'>
                <tr>
                  <th scope='col'>ID</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Mobile</th>
                  <th scope='col'>Message</th>
                  <th scope='col'>Courses</th>
                  <th scope='col'>Action</th>
                </tr>
              </MDBTableHead>
          <MDBTableBody>
              {enquiryList.map(enquiry => (
                <tr  key={enquiry.id}>
                  <td >{enquiry.id}</td>
                  <td>{enquiry.first_name}</td>
                  <td>{enquiry.email}</td>
                  <td>{enquiry.mobile}</td>
                  <td>{enquiry.message}</td>     {/* same database table headers*/}
                  <td>{enquiry.courses}</td>     {/* same database table headers*/}
                  <td>
                    <button className="red-trash-icon text-align-center" 
                          onClick={() => this.handleEnquiryDelete(enquiry.id)}
                        >
                        <i class="fas fa-trash-can"></i>&nbsp;Delete</button>
                  </td>                         {/* same database table headers*/}

                </tr>
              ))}
           </MDBTableBody>
        </MDBTable>
        </div>
      );
    }
    }

export default AdminSideNavEnquiry
