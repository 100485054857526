import React, { useState, useEffect } from 'react'
import { MDBTable, MDBTableHead, MDBTableBody , MDBRow, MDBCol, MDBCheckbox, MDBIcon ,  MDBModalFooter, MDBInput, MDBTextArea,
  MDBModalDialog,  MDBModalContent, MDBModalHeader, MDBModal } from 'mdb-react-ui-kit'
import { baseURL } from '../../http';


function AdminAllInterviewQues() {

    const [interviewQasList, setInterviewQasList]               = useState([]); // for array null array sq.brackets to be given
    const [approveStatus, setApproveStatus]                     = useState(false);

    const userInfo                                              = JSON.parse(localStorage.getItem('user-info'));
    const userEmail                                             = userInfo.email;

    const [searchEmail, setSearchEmail]                         = useState('');
    const [searchInterviewDate, setSearchInterviewDate]         = useState('');
    const [searchPosition, setSearchPosition]                   = useState('');
    const [searchCompany, setSearchCompany]                     = useState('');
    const [searchQuestion, setSearchQuestion]                   = useState('');
    const [searchAnswer, setSearchAnswer]                       = useState('');
    const [searchStatus, setSearchStatus]                       = useState('');

    const [editQASModalStatus, setEditQASModalStatus]               = useState(false);
    const [question, setQuestion]                                   = useState('');
    const [answer, setAnswer]                                       = useState('');
    const [addQAScompany, setAddQAScompany]                         = useState('');
    const [editQASItemID, setEditQASItemID]                         = useState('');

    useEffect(() => {  fetchinterviewQAS();  }, []);
    const fetchinterviewQAS=()=>{
        fetch(`${baseURL}/getAllInterviewQASbyAdmin`)   // getting full qas table
        .then(response => response.json())
        .then(data     => { setInterviewQasList(data || []);     })//console.log(JSON.stringify(data)); 
        .catch(error   => { console.error('Error fetching qas Table-2:', error); });
    }

    const handleItemApprove = (itemID) => {

        //   -----------------update local----------------------------------
        // const updatedInterviewQasList = interviewQasList.map(item => {
        //     if (item.id === itemID) 
        //         { return { ...item, approveStatus: !item.approveStatus };     }
        //     return item;   });
      
        //   setInterviewQasList(updatedInterviewQasList);
        //   ---------------------------------------------------
        const approvalStatusItem = interviewQasList.find((item)=>item.id ===itemID)
        
          fetch(`${baseURL}/updateApprovalStatusByAdmin/${itemID}`, {
          method: 'POST', 
          body: JSON.stringify({ approveStatus: approvalStatusItem.approveStatus }),
          headers: {'Content-Type': 'application/json', 'Accept': 'application/json',},
          })
          .then(response => response.json())
          .then(data => {
              alert("Approval status - updated");
              console.log('Approval status updated successfully:', data);
              fetchinterviewQAS();
          })
          .catch(error => { console.error('Error updating approval status:', error);});
      };

    const toggleEditQASModal = (itemID) => {
        const selectedItem = interviewQasList.find(item => item.id == itemID);
        //   console.log("selected items="+JSON.stringify(selectedItem));

        if (selectedItem) { 
            setEditQASItemID(itemID);     
            setQuestion(selectedItem.questions);      
            setAnswer(selectedItem.answers);    
        }
        setEditQASModalStatus(!editQASModalStatus); // opening modal
    }
    const saveEditedQAS=()=>{

        const sendData={
            id              : '',         
            questions       : question,              answers                     : answer,
        }

        fetch(`${baseURL}/updateInterviewQas/${editQASItemID}`, {
            method: 'POST',
            body: JSON.stringify(sendData),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          })
            .then((response) => {
              if (response.ok) {    
                alert("QAS for  updated Successfully"); 
                fetchinterviewQAS(); 
                setEditQASModalStatus(!editQASModalStatus); // closing modal- by sucess response
             } 
              else { console.error('inside API error ');   alert("inside API error");  }
            })
            .catch((error) => { console.error('API call error-outside:', error); alert("Server error");   });
    }
    const handleItemDelete =(itemID)=>{
      const updatedQASList = interviewQasList.filter(item => item.id !== itemID);
      setInterviewQasList(updatedQASList);

      fetch(`${baseURL}/deleteInterviewQAS/${itemID}`, {
          method: 'PUT',
          headers: {  'Content-Type': 'application/json', },
      })
          .then(response => {
              if (response.ok) {  console.log('Item deleted successfully');    alert("Item of ID-"+{itemID}+" Deleted successfully");   } 
              else             {  console.error('Failed to delete item');      alert("Failed to  delete Item of ID-"+{itemID}+" ...!");  }   })
          .catch(error =>      {  console.error('Item deleting user:', error); alert("Failed due to Server Down");}   );

  }
  
  const [sortDirection, setSortDirection] = useState('asc');
  const sortTable = (key) => {
    const dir = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(dir);

    const sortedData = [...interviewQasList].sort((a, b) => {
      const x = a[key].toLowerCase();
      const y = b[key].toLowerCase();

      return dir === 'asc' ? x.localeCompare(y) : y.localeCompare(x);
    });

    setInterviewQasList(sortedData);
  };



  return (
    <div>
      <MDBRow>
        <MDBCol> <h3>All Interview Ques Handler By Admin?</h3></MDBCol>
      </MDBRow>

     {/* <input type='text' onChange={(e) => setSearch(e.target.value)} placeholder='Type to Search....'></input> */}

      <MDBTable bordered>
          <MDBTableHead className='bg-gradient'>
            <tr style={{textAlign: 'center'}}>
              <th scope='col' onClick={() => sortTable('name')} style={{minWidth:'95px'}}>SNo               <MDBIcon fas icon="sort-amount-up" /> </th>
              <th scope='col' onClick={() => sortTable('email')}  style={{minWidth:'200px'}}>Email             <MDBIcon fas icon="sort-amount-up-alt" /> </th>
              <th scope='col' onClick={() => sortTable('interviewDate')} style={{minWidth:'200px'}}>Interview Date    <MDBIcon fas icon="sort-amount-up-alt" /> </th>
              <th scope='col' onClick={() => sortTable('jobRole')}style={{minWidth:'200px'}}>Position           <MDBIcon fas icon="sort-amount-up" /> </th>
              <th scope='col' onClick={() => sortTable('companyName')} style={{minWidth:'200px'}}>Company           <MDBIcon fas icon="sort-amount-up" /> </th>
              <th scope='col' onClick={() => sortTable('questions')}  style={{minWidth:'200px'}}>Questions         <MDBIcon fas icon="sort-amount-up" /> </th>
              <th scope='col' onClick={() => sortTable('answers')}  style={{minWidth:'200px'}}>Answers          <MDBIcon fas icon="sort-amount-up" /> </th>
              <th scope='col'>Action<br></br>
                    <span style={{fontSize:'9px'}}>Approve | Edit | Delete</span>
              </th>
            </tr>
            <tr>
              <th>Search</th>       
              <th><input type='text' onChange={(e) => setSearchEmail(e.target.value)} placeholder='Search Email....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchInterviewDate(e.target.value)} placeholder='Search Date....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchPosition(e.target.value)} placeholder='Search Positon....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchCompany(e.target.value)} placeholder='Search Company....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchQuestion(e.target.value)} placeholder='Search Question....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchAnswer(e.target.value)} placeholder='Search Answer....'></input></th>   
              <th><input type='text' onChange={(e) => setSearchStatus(e.target.value)} placeholder='Search true/ false....'></input></th>   
            </tr>
          </MDBTableHead>
          <MDBTableBody style={{textAlign: 'center'}}>
            {interviewQasList
                // .filter((qasItem) =>{ return searchEmail.toLowerCase() === '' ? qasItem : qasItem.email.toLowerCase().includes(searchEmail); })
                // if null values below filter-search not happening
                .filter((qasItem) =>{ 
                  return (
                      (searchEmail.toLowerCase()    === '' || qasItem.email.toLowerCase().includes(searchEmail.toLowerCase())) &&
                      (searchInterviewDate          === '' || qasItem.interviewDate.includes(searchInterviewDate)) &&
                      (searchPosition.toLowerCase() === '' || qasItem.jobRole.toLowerCase().includes(searchPosition.toLowerCase())) &&
                      (searchCompany.toLowerCase()  === '' || qasItem.companyName.toLowerCase().includes(searchCompany.toLowerCase())) &&
                      (searchQuestion.toLowerCase() === '' || qasItem.questions.toLowerCase().includes(searchQuestion.toLowerCase())) &&
                      (searchAnswer.toLowerCase()   === '' || qasItem.answers.toLowerCase().includes(searchAnswer.toLowerCase())) &&
                      (searchStatus.toLowerCase()   === '' || qasItem.approveStatus.toString().toLowerCase().includes(searchStatus.toLowerCase()))
                  );
              })
              
            .map((qasItem, index) => (
                <tr key={qasItem.id}>
                    {/* <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{index+1} - {qasItem.id}</td> */}
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{index+1} </td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.email}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.interviewDate}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.jobRole}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.companyName}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.questions}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black'}}>{qasItem.answers}</td>
                    <td style={{fontSize:'12px', textAlign:'left', color:'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                       
                      {/* <div className="form-check form-switch">
                            <input className="form-check-input switchSize"             type="checkbox" role="switch" id={`vehicle-${qasItem.id}`} 
                             checked={qasItem.approveStatus == 'true'} onChange={() => handleItemApprove(qasItem.id)}
                      /></div> */}

                        <span className='bttn-group1 form-switch' >
                          <button> <input className="form-check-input switchSize"     type="checkbox" role="switch" id={`vehicle-${qasItem.id}`} 
                             checked={qasItem.approveStatus == 'true'} onChange={() => handleItemApprove(qasItem.id)}/></button>
                          <button>
                            <MDBIcon fas icon="pen" className="text-align-center"  onClick={() => toggleEditQASModal(qasItem.id)} 
                            style={{ color:'black',fontSize:12, cursor:'pointer', alignContent:'right'}} /> 
                          </button>
                          <button>
                            <MDBIcon fas icon="trash" onClick={() => handleItemDelete(qasItem.id)} className="red-trash-icon text-align-center"
                            style={{ fontSize:12, cursor:'pointer', alignContent:'right'}} />
                          </button>
                        </span>

                    </td>
                </tr>
            ))}
                  
          </MDBTableBody>
          </MDBTable>


    {/* (2.4)-------- Edit Modal QAS starts ------------*/}
    <MDBModal tabIndex='-1' show={editQASModalStatus} setShow={setEditQASModalStatus}>
        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
        <MDBModalContent >
            <MDBModalHeader className='profileModal1 '>
                <h3 style={{fontSize:'20px'}}>EDIT QAS - 
                <MDBIcon style={{color:'blue'}} fas icon="user-tie"/> ID-{editQASItemID} 
                <MDBIcon style={{color:'green'}} fas icon="building" />  {addQAScompany}</h3>

            </MDBModalHeader>
            <div className='profileModal1 '>
                <MDBTextArea label='Question' id='addQuestion' rows={3} value={question} onChange={e=>setQuestion(e.target.value)}/><br/>
                <MDBTextArea label='Answer'   id='addAnswer'   rows={6} value={answer} onChange={e=>setAnswer(e.target.value)}/>
            </div>

            <MDBModalFooter>
                <button type="button" className="dropbtnprofilepagesave"    onClick={saveEditedQAS}> Save   </button>&nbsp;
                <button type="button" className="dropbtnprofilepagecancel2" onClick={() => setEditQASModalStatus(false)}>   Cancel </button>&nbsp;
            </MDBModalFooter>
                    
        </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    {/*(2.4)--------- Edit Modal QAS ends --------------*/}
    </div>
  )
}

export default AdminAllInterviewQues
