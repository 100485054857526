import React from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBIcon,
  MDBNavbarBrand,
  MDBNavbarLink,
  MDBTypography,
} from "mdb-react-ui-kit";

function PreHeader() {
  return (
    <MDBNavbar
      light
      style={{ backgroundColor: "hsl(0deg 5% 33%)" }}
      className="tpa-pre-header-navbar"
    >
      <MDBContainer fluid>
        <MDBNavbarBrand></MDBNavbarBrand>
        <MDBNavbarLink className="tpa-pre-header-link">
          <a
            href=" https://www.instagram.com/talent_pool_academy09/"
            target="_blank"
          >
            <MDBIcon
              fab
              icon="instagram"
              className="me-3"
              color="light"
              size="sm"
            />
          </a>

          <a
            href=" https://www.linkedin.com/in/talent-pool-academy/"
            target="_blank"
          >
            <MDBIcon
              fab
              icon="linkedin"
              className="me-3"
              color="light"
              size="sm"
            />
          </a>

          <a
            href=" https://www.facebook.com/profile.php?id=100082084068949&mibextid=ZbWKwL/"
            target="_blank"
          >
            <MDBIcon
              fab
              icon="facebook-f"
              className="me-3"
              color="light"
              size="sm"
            />
          </a>

          <MDBIcon
            icon="envelope"
            className="me-1 ms-3"
            size="sm"
            color="light"
          />
          <MDBTypography color="light" tag="small">
            info@talentpool-academy.in
          </MDBTypography>
        </MDBNavbarLink>
      </MDBContainer>
    </MDBNavbar>
  );
}

export default PreHeader;


