import React, { useState, useEffect } from 'react'
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBModalFooter, MDBTextArea } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../../http';
const Swal = require("sweetalert2");

function Stscroll9ProfileSummary() {

  const [profileSummary, setProfileSummary]         = useState('');     
  const [profSummEdit, setProfSummEdit]             = useState('');

  const userInfo                                    = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                                   = userInfo.email;
  const [modalStatus, setModalStatus]               = useState(false);
  const history                                     = useNavigate ();

  function toggleModal() {  setModalStatus(!modalStatus);  }

  /*------------------------useEffect for profile details update----------------- */
useEffect(() => {
  fetchUserData();
}, []);
 
const fetchUserData = async () => {
        const requestBody = {        email : userEmail,                };
        fetch(`${baseURL}/getprofile`, {
          method: 'POST',
          body:JSON.stringify(requestBody),
          headers: {  'Content-Type': 'application/json',    'Accept'      : 'application/json',     },
        })
        .then((response) => response.json())
        .then((data) => {
          setProfileSummary(data.profileSummary || "");
          setProfSummEdit(data.profileSummary || "");
        })
        .catch((error) => {
          console.error('Error fetching Profile Summary:', error);
        });
    };
    
const saveProfileData = async () => {
  try {
    const sendData = {
      email           : userEmail,
      profileSummary  : profSummEdit
    };
    
    const response = await fetch(`${baseURL}/updateProfileSummary`, {
                          method  : 'POST',
                          body    : JSON.stringify(sendData),
                          headers : {
                                      'Content-Type': 'application/json',
                                      'Accept'      : 'application/json',
                                    },
                                      });

    if (response.ok) {
      console.log('Profile Summary updated success');
      Swal.fire({
        title: "Profile Summary Updated",
        icon: "success",
        timer: 2000,
        position: "center",
        timerProgressBar: true,
        showConfirmButton: false,
        
      })
      .then(() => {
          setModalStatus(false);
          setProfileSummary(profSummEdit);
      });
      
    } else {
      console.error('Error updating Resume Headline:', response.statusText);
    }
  } catch (error) {
    console.error('Error while enter savi button:', error);
  }
}
  return (
    <div>
      <h5 style={{color:'black'}}>Project Summary  <i  onClick={toggleModal} className="fas fa-pencil" style={{paddingBottom:'8px', fontSize:12}}></i></h5>
            <p>
               {profileSummary || ""}
            </p>



            {/* (2.1)------------------>---Modal code starts */}
                    <MDBModal tabIndex='-1' show={modalStatus} setShow={setModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
                        <MDBModalContent >
      
                           <div>
                            <div style={{ marginLeft: '20px' }}>
                              <br />
                              <h5>Profile Summary</h5> <br />
                              <p style={{ color: 'grey' }}>
                                It is the first thing recruiters notice in your profile. Write concisely
                                what makes you unique and the right person for the job you are looking for.
                              </p>
                              <br />
                              <div className='formProfile'>
                                <MDBTextArea
                                  label='Message'
                                  id='textAreaExample'
                                  rows={4}
                                  value={profSummEdit || ""}
                                  onChange={(e) => setProfSummEdit(e.target.value)}
                                />
                                <br />
                              </div>
                            </div>
                      
                            <MDBModalFooter>
                              
                                <button type="button" className="dropbtnprofilepagesave"    onClick={saveProfileData}> Save   </button>&nbsp;
                                <button type="button" className="dropbtnprofilepagecancel2" onClick={toggleModal}>   Cancel </button>&nbsp;
                                  
                            </MDBModalFooter>
                          </div>
                        </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    {/*(2.1)--------->------------- Modal code ends */}
    </div>
  )
}

export default Stscroll9ProfileSummary
