import { useState } from "react";
import { Helmet } from "react-helmet";
import http from "../../http";    //linked to Enquiry// for homw page
import {
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";


function ContactBody() {
  const [inputs, setInputs] = useState({});

  const options = [
    { value: "CATIA Coustmization", label: "CATIA Coustmization" },
    { value: "CREO Coustmization", label: "CREO Coustmization" },
    { value: "NX Coustmization", label: "NX Coustmization" },
    { value: "CATIA Design", label: "CATIA Design" },
    { value: "NX Design", label: "NX Design" },
    { value: "CREO Design", label: "CREO Design" },
    { value: "Full Stack", label: "Full Stack" },
    { value: "Internship", label: "Internship" },
    { value: "Career", label: "Career" },
    { value: "Trainer", label: "Trainer" },
  ];

  const handleChange = (event) => {
    console.log(event);
    console.log("Inside common");
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handlePhoneInput = (number) => {
    console.log(number);
    console.log("Inside phone");
    const name = "mobile";
    const value = number;
    setInputs((values) => ({ ...values, [name]: value }));
  };

 
  const submitForm = () => {
    http
      .post("/enquiries", inputs)
      .then((res) => {
        swal("Submitted! Thank you ,Your Slot Booked.", "", "success");
        setInputs("");
      })
      .catch((error) => {
        swal("Not Submitted!! ", "", "fail");
        return error;
      });
  };

  

  const [selectedOption] = useState(null);

  const [formErrors] = useState({
    first_name: "",
    email: "",
    phone: "",
    message: "",
  });

  return (
    <>
    <Helmet>
      <title>Home | TPA </title>
      <meta
        name="description"
        content="Contact us to book your slot for our courses at bangalore. Fill out the form with your name, email, phone number, and message. Select the course you're interested in and submit the form to book your slot."
      />
      <meta
        name="keywords"
        content="contact, book slot, course booking, enquiry, CATIA, CREO, NX, Full Stack, Internship, Career, Trainer"
      />
    </Helmet>
    <MDBCard>
      
      <MDBCard className=" my-12">
        <MDBCardBody className="p-5">
          <MDBRow >
            <div >
              <MDBInput
                wrapperClass="mb-4"
                label="Name"
                id="first_name"
                rows={3}
                size="lg"
                name="first_name"
                type="text"
                value={inputs.first_name || ""}
                onChange={handleChange}
                className={formErrors.first_name ? "is-invalid" : ""}
                
              /></div>
            </MDBRow>
            
            
            <MDBRow>

              <PhoneInput
              inputStyle={{
                width: "100%",
                height: "42px",
              }}
              country={"in"}
              name="mobile"
              value={selectedOption}
              onChange={handlePhoneInput}
            />
            
          </MDBRow>
          <br></br>

          <MDBRow >
            <div >
              <MDBInput
                wrapperClass="mb-4"
                label="Email"
                id="email"
                rows={3}
                size="lg"
                type="text"
                name="email"
                value={inputs.email || ""}
                onChange={handleChange}
                className={formErrors.name ? "is-invalid" : ""}
                
              /></div>
            </MDBRow>

     
          
           <Select
          isMulti
          name="colors"
          options={options}
          // className="basic-multi-select demo-input-2 "
          classNamePrefix="Enquiry For"
          size="lg"
        />

          <br />
          <MDBTextArea
            label="Message"
            id="Message"
            rows={3}
            size="lg"
            name="message"
            value={inputs.message || ""}
            onChange={handleChange}
            className={formErrors.message ? "is-invalid" : ""}
          />
          <br />
          <MDBBtn
            className="tpa-info-button"
            style={{ color: "white", width:"100%" }}
            onClick={submitForm}
          >
            <MDBIcon fas icon="calendar-check me-3" size="lg" />
            Book Now
          </MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBCard>
    </>
  );
}

export default ContactBody;
