
import React, { useRef, useState, useEffect } from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBScrollspy, MDBScrollspyLink } from 'mdb-react-ui-kit';
import Stscroll2Resume from './Stscroll2Resume';
import Stscroll3ResumeHeadline from './Stscroll3ResumeHeadline';
import Stscroll4KeySkills from './Stscroll4KeySkills';
import Stscroll9ProfileSummary from './Stscroll9ProfileSummary';
import Stscroll5Employment from './Stscroll5Employment';
import Stscroll6Education from './Stscroll6Education';
import Stscroll7ITSkills from './Stscroll7ITSkills';
import Stscroll8Projects from './Stscroll8Projects';
import Stscroll11CareerProfile from './Stscroll11CareerProfile';
import Stscroll12PersonalDetails from './Stscroll12PersonalDetails';

export default function Stscroll1() {
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const section9 = useRef(null);
  const section10 = useRef(null);
  const section11 = useRef(null);

  const containerRef = useRef(null);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    
    <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
      <MDBRow style={{ marginLeft: '2px' }}>
        {screenWidth >= 1000 && (
          <MDBCol lg={2} md={3} sm={4} className='scrollspylinkname' style={{ backgroundColor: 'white', borderRadius: '10px', width: '20%' ,  zIndex: +52 }}> 
            <MDBScrollspy container={containerRef}  >
              {/* <MDBScrollspyLink targetRef={section1}  style={{ padding: '15px' }}>   Resume             </MDBScrollspyLink> */}
              <MDBScrollspyLink targetRef={section1} style={{ padding: '15px'}}>Resume</MDBScrollspyLink>
              <MDBScrollspyLink targetRef={section2}  style={{ padding: '15px' }}>   Resume Headline    </MDBScrollspyLink>
              <MDBScrollspyLink targetRef={section3}  style={{ padding: '15px' }}>   Keyskills          </MDBScrollspyLink>
              <MDBScrollspyLink targetRef={section4}  style={{ padding: '15px' }}>   Employment         </MDBScrollspyLink>
              <MDBScrollspyLink targetRef={section5}  style={{ padding: '15px' }}>   Education          </MDBScrollspyLink>
              <MDBScrollspyLink targetRef={section6}  style={{ padding: '15px' }}>   IT Skills          </MDBScrollspyLink>
              <MDBScrollspyLink targetRef={section7}  style={{ padding: '15px' }}>   Project            </MDBScrollspyLink>
              <MDBScrollspyLink targetRef={section8}  style={{ padding: '15px' }}>   Profile Summary    </MDBScrollspyLink>
              <MDBScrollspyLink targetRef={section10} style={{ padding: '15px' }}>  Career Profile     </MDBScrollspyLink>
              <MDBScrollspyLink targetRef={section11} style={{ padding: '15px' }}>  Personal details   </MDBScrollspyLink>
            </MDBScrollspy>
          </MDBCol>
        )}
        <MDBCol lg={screenWidth >= 500 ? 10 : 12} md={9} sm={4} id='element' ref={containerRef} className='scrollspy-example custom-scroll-container' 
        style={{ overflow: 'auto', scrollbarWidth: 'thin', height: '1000px', width: screenWidth >= 500 ? '80%' : '100%', alignContent: 'right' }}>
          <div className='profileScrollTabs'>
            <section ref={section1} id='section-1' style={{ paddingTop: '5px' }}>
              <Stscroll2Resume />
            </section>
          </div>
          <br></br>
          <div className='profileScrollTabs'>
            <section ref={section2} id='section-2'>
              <Stscroll3ResumeHeadline />
            </section>
          </div>
          <br></br>
          <div className='profileScrollTabs'>
            <section ref={section3} id='section-3'>
              <Stscroll4KeySkills />
            </section>
          </div>
          <br></br>
          <div className='profileScrollTabs'>
            <section ref={section4} id='section-4'>
              <Stscroll5Employment />
            </section>
          </div>
          <br></br>
          <div className='profileScrollTabs'>
            <section ref={section5} id='section-5'>
              <Stscroll6Education />
            </section>
          </div>
          <br></br>
          <div className='profileScrollTabs'>
            <section ref={section6} id='section-6'>
              <Stscroll7ITSkills />
            </section>
          </div>
          <br></br>
          <div className='profileScrollTabs'>
            <section ref={section7} id='section-7'>
              <Stscroll8Projects />
            </section>
          </div>
          <br></br>
          <div className='profileScrollTabs'>
            <section ref={section8} id='section-8'>
              <Stscroll9ProfileSummary />
            </section>
          </div>
          <br></br>
          <div className='profileScrollTabs'>
            <section ref={section10} id='section-10'>
              <Stscroll11CareerProfile />
            </section>
          </div>
          <br></br>
          <div className='profileScrollTabs'>
            <section ref={section11} id='section-11'>
              <Stscroll12PersonalDetails />
            </section>
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  );
}


