import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCardBody,
  MDBContainer,
} from "mdb-react-ui-kit";

import Privacypolicy from "../static/Privacypolicy.jpg";

import { Helmet } from "react-helmet";

function PrivacyPolicies() {
  return (
    <>
      <div>
        <Helmet>
          <title>
            Privacy and Policy - Talent Pool Academy
          </title>

          <meta
            name="description"
            content="This Privacy Policy document contains types of information that is collected and recorded by TPA and how we use it."
          />
           <meta
            name="keywords"
            content="TPA, Talent Pool Academy, Privacy Policy, personal information, non-personal information, data protection, security measures"
          />
        </Helmet>

      </div>
      <MDBRow
        className="MBfooter  "
        style={{ marginTop: "0.2%", color: "white" }}
      >
        <MDBCol center md="2"></MDBCol>
        <MDBCol center md="6">
          <MDBCardBody className="">
            <MDBTypography variant="h1 text-start" style={{ fontSize: "60px" }}>
              PRIVACY POLICY
            </MDBTypography>
          </MDBCardBody>
        </MDBCol>

        <MDBCol center md="4" className="p-5">
          <img
            src={Privacypolicy}
            className="round"
            alt="hire from image"
            style={{
              width: "100%",
              height: "100%",
            }}
          ></img>
        </MDBCol>
      </MDBRow>

      <MDBRow
        className="mt-2  "
        style={{ backgroundColor: "hsl(38deg 82% 84%)", color: "black" }}
      >
        <MDBCol center md="1"></MDBCol>
        <MDBCol center md="10" className="p-5">
          <ol>
            <p>
              <b>
                By accessing our Site or our Service, you accept our Privacy
                Policy and Terms of Use 
                {/* (found here: [insert link to Terms of Use]), */}
                 and you consent to our collection, storage, use and
                disclosure of your Personal Information as described in this
                Privacy Policy.
              </b>
            </p>
            <li>
              <b>INFORMATION WE COLLECT</b>
            </li>
            <p>
              We collect “Non-Personal Information” and “Personal Information.”
              Non-Personal Information includes information that cannot be used
              to personally identify you, such as anonymous usage data, general
              demographic information we may collect, referring/exit pages and
              URLs, platform types, preferences you submit and preferences that
              are generated based on the data you submit and number of clicks.
              Personal Information includes your email, phone number, address,
              date of birth, marital status and resume, which you submit to us
              through the registration process at the Site.
            </p>
            <li>
              <b>HOW WE USE AND SHARE INFORMATION</b>
            </li>
            <p>
              <b>Personal Information:</b>
            </p>
            <p>
              We may share Personal Information with outside parties if we have
              a good-faith belief that access, use, preservation or disclosure
              of the information is reasonably necessary to meet any applicable
              legal process or enforceable governmental request; to enforce
              applicable Terms of Service, including investigation of potential
              violations; address fraud, security or technical concerns; or to
              protect against harm to the rights, property, or safety of our
              users or the public as required or permitted by law.
            </p>
            <br />
            <p>
              <b>Non-Personal Information:</b>
            </p>
            <p>
              In general, we use Non-Personal Information to help us improve the
              Service and customize the user experience. We also aggregate
              Non-Personal Information in order to track trends and analyze use
              patterns on the Site. This Privacy Policy does not limit in any
              way our use or disclosure of Non-Personal Information and we
              reserve the right to use and disclose such Non-Personal
              Information to our partners, advertisers and other third parties
              at our discretion.
            </p>
            <br />
            <li>
              <b>HOW WE PROTECT INFORMATION</b>
            </li>
            <p>
              We implement security measures designed to protect your
              information from unauthorized access. Your account is protected by
              your account password and we urge you to take steps to keep your
              personal information safe by not disclosing your password and by
              logging out of your account after each use. We further protect
              your information from potential security breaches by implementing
              certain technological security measures including encryption,
              firewalls and secure socket layer technology. However, these
              measures do not guarantee that your information will not be
              accessed, disclosed, altered or destroyed by breach of such
              firewalls and secure server software. By using our Service, you
              acknowledge that you understand and agree to assume these risks.
            </p>
            <br />

            <li>
              <b>YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</b>
            </li>
            <p>
              You have the right at any time to prevent us from contacting you
              for marketing purposes. You can also indicate that you do not wish
              to receive marketing communications from us through SMS, WhatsApp
              or email.
            </p>
            <br />

            <li>
              <b>LINKS TO OTHER WEBSITES</b>
            </li>
            <p>
              As part of the Service, we may provide links to or compatibility
              with other websites or applications. However, we are not
              responsible for the privacy practices employed by those websites
              or the information or content they contain. This Privacy Policy
              applies solely to information collected by us through the Site and
              the Service. Therefore, this Privacy Policy does not apply to your
              use of a third party website accessed by selecting a link on our
              Site or via our Service. To the extent that you access or use the
              Service through or on another website or application, then the
              privacy policy of that other website or application will apply to
              your access or use of that site or application. We encourage our
              users to read the privacy statements of other websites before
              proceeding to use them.
            </p>
            <br />
            <li>
              <b>CHANGES TO OUR PRIVACY POLICY</b>
            </li>
            <p>
              The Company reserves the right to change this policy and our Terms
              of Service at any time. We will notify you of significant changes
              to our Privacy Policy by sending a notice to the primary email
              address specified in your account or by placing a prominent notice
              on our site.
            </p>
          </ol>
        </MDBCol>
        <MDBCol center md="1"></MDBCol>
      </MDBRow>


    </>
  );
};


export default PrivacyPolicies;
