import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom'; 
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {baseURL} from './../../http'
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

function SignInNew() {
  // const [user, setUser] = useState({
  //   name: "",
  //   email: "",
  //   password: "",
  //   mobile: "",
  //   status: "",
  // });

  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const defaultTheme = createTheme();
  useEffect(() => { document.title = "TPA - User Sign IN";  });

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setUser((prevUser) => ({
  //     ...prevUser,
  //     [name]: value,
  //   }));
  // };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };
  
const handleSubmit = async (e) => {
  e.preventDefault();
  console.warn(email, password,'before fetch');
  let items = { email, password };
  let response = await fetch(`${baseURL}/signin`, {
    method: 'POST',
    body: JSON.stringify(items),
    headers: {
      'Content-Type': 'application/json',
      'Accept'      : 'application/json',
    },
  });
  
  try {
        let result = await response.json();
        console.log('Response:', result);

        let logintypeFront=result.logintype;
        console.log("logintype(front)= "+logintypeFront);
        if (result.success) {
          console.log('result success');
          localStorage.setItem('user-info', JSON.stringify(result));
            if(logintypeFront==='student')   {   navigate('/');   window.location.reload();  }
            if(logintypeFront==='admin')     {   navigate('/');   window.location.reload();  }
          } 
        else    {  console.error('Login failed. Please check your credentials.');    }
      } 
  catch (error) {   console.error('An error occurred while processing the login response:/n', error);
                    console.log('Response Status:', response);  }
};

  return (

    <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ marginTop: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <VpnKeyRoundedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" noValidate onSubmit={'handleSubmit'} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                onKeyDown={handleKeyDown} // Added 
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  label="Password"
                  name="password"
                  value={password}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onKeyDown={handleKeyDown} // Added
                />
              </FormControl>
            </Grid>
          </Grid>
          <Button type="button" fullWidth variant="contained" onClick={handleSubmit} sx={{ mt: 3, mb: 2 }} >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  </ThemeProvider>
  );
}

export default SignInNew;



// import React, { useEffect, useState } from 'react';

// import { useNavigate } from 'react-router-dom'; 
// // import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Button from "@mui/material/Button";
// import Grid from "@mui/material/Grid";
// import Avatar from "@mui/material/Avatar";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import TextField from "@mui/material/TextField";
// import {baseURL} from './../../http'
// import { MDBCol , MDBRow} from 'mdb-react-ui-kit';
// import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
// import loginImg from '../static/loginImg2.jpg';

// function SignInNew() {
  
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();

// const handleLogin = async (e) => {
//   e.preventDefault();
//   console.warn(email, password,'before fetch');
//   let items = { email, password };
//   let response = await fetch(`${baseURL}/signin`, {
//     method: 'POST',
//     body: JSON.stringify(items),
//     headers: {
//       'Content-Type': 'application/json',
//       'Accept'      : 'application/json',
//     },
//   });
  
//   try {
        

//         let result = await response.json();
//         console.log('Response:', result);

//       let logintypeFront=result.logintype;
//       console.log("logintype(front)= "+logintypeFront);
//               if (result.success) {
//                 console.log('result success');
//                 localStorage.setItem('user-info', JSON.stringify(result));
//                   if(logintypeFront==='student')   {   navigate('/');   window.location.reload();  }
//                   if(logintypeFront==='admin')     {   navigate('/');   window.location.reload();  }
                  
//                 } else {
//                   console.error('Login failed. Please check your credentials.');
                  
//           }
//       } 
//   catch (error) {
//     console.error('An error occurred while processing the login response:/n', error);
//     console.log('Response Status:', response);
//   }
// };

//   return (
//     <div>
//      <br />
//      <MDBRow>
//         <MDBCol className='loginpage1'>
//               <img src={loginImg} style={{  maxHeight: "650px", marginLeft:"100px",width:"700px" }}></img>
//         </MDBCol>

//         <MDBCol >
//               <form onSubmit={handleLogin} >
//                 <br></br><br></br>
//                 <div className='text-align-center' style={{ height: '50px', width:'400px', marginLeft:"200px"  }}>
//                 <Avatar sx={{ m: 1, bgcolor: "secondary.main", marginLeft:"180px"}}>
//                     {/* <LockOutlinedIcon /> */}
//                     <VpnKeyRoundedIcon/>
//                   </Avatar>
//                   <h3 >Login</h3><br></br>
//                   <Grid item xs={12}>
//                   <TextField 
//                     type="email"
//                     className="form-control"
//                     placeholder="Email *"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     autoFocus
//                   /></Grid>
//                   <br />
//                   <Grid item xs={12}>
//                   <TextField 
//                     type="password"
//                     className="form-control"
//                     placeholder="Password *"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                   />
//                   </Grid>
//                   <br />

//                   {/* <div className='text-align-center'>
//                   <button type="submit" className="btn btn-primary">
//                     Sign-In
//                   </button></div> */}
                  
//                   <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >
//                       Sign In
//                     </Button>
//                     </div>
//               </form>
          
//           </MDBCol>
//       </MDBRow>
//               <br />
//     </div>
//   );
// }

// export default SignInNew;