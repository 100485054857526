import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBInput,
  MDBTypography,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBTableBody,
  MDBTable,
  MDBBadge,
  MDBIcon,
  MDBModalFooter,
} from "mdb-react-ui-kit";

import swal from "sweetalert";
import TPA_Automotive from "../static/TPA_Automotive Design Engineer-2.pdf";

function TrainingProject() {
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);
  
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let errors = {};

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    // Submit form data here
    console.log("Form data:", formValues);
    setFullscreenXlModal(!fullscreenXlModal);
    swal("Registered! ", "", "success");
  };
  return (
    <>
      <Helmet>
        <title>Curriculum | TPA Bangalore</title>
        <meta
  name="description"
  content="Explore TPA's curriculum for automotive design engineers in Bangalore. Learn CAD tool mastering, plastic product design, automotive trim projects, and more."
/>

    
<meta
  name="keywords"
  content="Curriculum, Automotive Design Engineer, CAD Tool Mastering, Plastic Product Design, Automotive Trim Projects, Engineering Training, TPA Bangalore"
/>

      </Helmet>
    <MDBContainer
      fluid
      className="p-1 rounded-7 fullstackBody mt-3 text-align-center"
      id="course-content"
    >
      <MDBRow>
        <MDBCol md="2"></MDBCol>
        <MDBCol md="8">
          <MDBCardBody>
            <MDBTypography variant="h2 text-light   mt-1">
              Curriculum
            </MDBTypography>

            <MDBTable hover>
              <MDBTableBody>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Course Phase 1: CAD Tool Mastering Phase
                </p>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Practical Exposure to Sketcher,Part Design
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>

                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Assembly Design,Drafting and Drawing Reading Skills
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Surface Design and Mastering Complex Shapes Modeling
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Reverse Engineering Methods and Remastering Solids,
                      Surfaces
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Tool Phases Test and Exercises
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Course Phase 2 - Domain Knowledge Industry Ready Project
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Plastic Product Design-Plastic Products
                      Introduction,Material Properties,Manufacturing Process,
                      Plastic Design Guidelines,Mold & Tool Basics.Re-mastering
                      Plastics
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Plastic Interior Trims Design - Interior Trims Basic,
                      Master-section Development, Tooling Consideration, Trims
                      Regulations, className A to B Modelling Class A Surface
                      Guidelines.Plastic Features Design,2D-Proposals.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Automotive Plastic Trim Projects-Door Structure Trims(Map
                      Pocket, Bezel Switch Cover,Door Substrate),A/B-pillars
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4">
                  Domain Phase test-Theory Level Test
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Plastic Part And Model Design Guidelines,Automotive design
                      Industry Basics,Trim Design Basics,Plastic defects &
                      Manufacturing Process
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
                <p className="fs-6 text-light courseoverview-body-start mt-2 mb-4 ">
                  Domain Phase Tool Level Test
                </p>
                <tr className="table-light">
                  <MDBRow className=" NXborder">
                    <MDBCol center className="text-center" md="1">
                      <MDBIcon fas icon="plus" size="2x" />
                    </MDBCol>
                    <MDBCol className="text-start" md="10">
                      Basic Solid Creation,Gap And Flush Analysis,2D
                      Proposals,Master-Sections.
                    </MDBCol>
                    <MDBCol center className="text-end" md="1">
                      <MDBBadge>Practice</MDBBadge>
                    </MDBCol>
                  </MDBRow>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCardBody>
        </MDBCol>
        <MDBCol md="2"></MDBCol>
      </MDBRow>

      <MDBBtn
        href={TPA_Automotive}
        download
        outline
        color="light"
        className="mb-4 mt-3 w-10 fw-bold tpa-info-logo"
        size="lg"
      >
        <MDBIcon fas icon="download pe-2" />
        Download
      </MDBBtn>

      <MDBModal
        tabIndex="-1"
        show={fullscreenXlModal}
        setShow={setFullscreenXlModal}
      >
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="fw-bold fs-5 text-start">
                Sign UP
              </MDBModalTitle>
              <MDBBtn
                type="button"
                className="btn-close "
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBCard className="mt-10">
              <MDBModalBody>
                <MDBInput
                  required
                  wrapperClass="mb-4"
                  label="Full name"
                  id="name"
                  rows={3}
                  size="lg"
                  name="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                  className={formErrors.name ? "is-invalid" : ""}
                />
                <MDBInput
                  required
                  wrapperClass="mb-4"
                  label="Phone"
                  id="Phone"
                  type="number"
                  size="lg"
                  name="phone"
                  value={formValues.phone}
                  onChange={handleInputChange}
                  className={formErrors.phone ? "is-invalid" : ""}
                />
                <MDBInput
                  wrapperClass="mb-3  w-100 fw-bold "
                  label="Email"
                  id="Email"
                  type="text"
                  size="lg"
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  className={formErrors.email ? "is-invalid" : ""}
                />
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  className="mt-3 w-100 mx-5"
                  style={{ color: "white" }}
                  onClick={handleSubmit}
                >
                  Download
                </MDBBtn>
              </MDBModalFooter>
            </MDBCard>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
    </>
  );
}

export default TrainingProject;
