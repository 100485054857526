import React from "react";
import { MDBContainer, MDBTypography, MDBRow, MDBCol } from "mdb-react-ui-kit";

import Highlights1 from "../static/Highlights1.png";


function CourseHighlights() {
  return (
    <>
      <MDBContainer
        fluid
        className="p-3 rounded-7  mt-0 fullstackBody"
        id="overview1"
      >
        <MDBRow>
          <MDBTypography variant="h1 text-light text-start mt-3 ">
            Highlights Of Program
          </MDBTypography>

          <MDBCol center size="6">
            <img
              src={Highlights1}
              className="round "
              style={{ width: "103%", maxHeight: "500px" }}
              alt="highlights-img"
            />
          </MDBCol>
          <MDBCol size="6">
            <ul className="fs-5 fw-bold text-light">
              <li>3 Live Real Time OEM Standard Projects.</li>
              <br></br>
              <li>
                Choice Based Project Work Either on Int/Ext Trims Or BIW Design.
              </li>
              <br></br>
              <li>
                Project Delivered Under 10+ Years Experienced Industry Expert.
              </li>
              <br></br>
              <li>
                Flexible Practice Hours Under Continuous Enhancement and Support
              </li>
              <br></br>
              <li>
                Real Mock Interview and Special Grooming Sessions for
                Interviews.
              </li>
              <br></br>
              <li>Placement Guaranteed for all Oustanding Trainees.</li>
              <br></br>
              <li>
                Unlimited Placement Support till Trainee gets a Job in Specific
                Domain.
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
export default CourseHighlights;
