import React, { useState, useEffect } from 'react';
import { MDBCol, MDBRow, MDBRadio, MDBTextArea  } from 'mdb-react-ui-kit'
import Select from 'react-select';
import { baseURL } from '../../../http';
const Swal = require("sweetalert2");

function Stscroll5EmploymentAddModal({ togglingModal }) {
  const userInfo = JSON.parse(localStorage.getItem('user-info'));
  const userEmail = userInfo.email;

  const [keySkillOptions, setKeySkillOptions]                                      = useState([]);
  const [selectedOptionsKeySkills, setSelectedOptionsKeySkills]                    = useState(null); 
  const [selectedWorkingFromYear, setSelectedWorkingFromYear]                      = useState(null); 
  const [selectedWorkingFromMonth, setSelectedWorkingFromMonth]                    = useState(null); 
  const [selectedFullexpYears, setSelectedFullexpYears]                            = useState(null); 
  const [selectedFullexpMonths, setSelectedFullexpMonths]                          = useState(null); 
  const [selectedInternshipworkingFromYear, setSelectedInternshipworkingFromYear]  = useState(null); 
  const [selectedInternshipworkingFromMonth, setSelectedInternshipworkingFromMonth]= useState(null); 
  const [selectedWorkedTillYear, setSelectedWorkedTillYear]                        = useState(null); 
  const [selectedWorkedTillMonth, setselectedWorkedTillMonth]                      = useState(null); 

  const [currentYear, setCurrentYear]                                              = useState(null);
  const [currentDate, setCurrentDate]                                              = useState(new Date());

  const [addData, setAddData]                                                      = useState({
                                                                                             curEmp:'',                        empType: '',
                                                                                             fullExpYears: '',                 fullExpMonths: '',
                                                                                             currentCompanyname: '',           currentDesignation: '',
                                                                                             internshipLocation:'',            internshipWorkingFromYear:'',
                                                                                             internshipWorkingFromMonth:'',    monthlyStifend:'',
                                                                                             workedFromYear:'',                workedFromMonth:'',
                                                                                             workedTillYear:'',                workedTillMonth:'',
                                                                                             jobProfile:'',                    monthlyStipend:'',
                                                                                             monthlyStipendCurrency:'',        skillsUsed:'',
                                                                                             curSal:'',                        
                                                                                             internshipDepartment:''
                                                                                            });
  
  /**--------------------------- Get + below + Keyskills dropdown-------------------------------- */
  useEffect(() => {
    fetchKeySkillsList();
  }, []);

  /*----------------------------Getting all key skills details for Selecting----------------------*/
  const fetchKeySkillsList = () => {
    fetch(`${baseURL}/getAllKeySkills`)
      .then(response => response.json())
      .then(data => {
        const options = data.map(skill => ({
          value: skill.skillName,                      // DB Table col.Name
          label: skill.skillName,                     // DB Table col.Name
        }));
        setKeySkillOptions(options);
      })
      .catch(error => {
        console.error('Error fetching key skills list:', error);
      });
  };
  
  /**---------------------------send API data to Database---------------------------------------- */
    const addEmpDetails=()=>{

      const sendData={
        email                     : userEmail,
        /*------------------------------------------- */
        curEmp                    : addData.curEmp,                                                                   empType                  : addData.empType,
        fullExpYears              : selectedFullexpYears ? selectedFullexpYears.value : "",                           fullExpMonths            : selectedFullexpMonths ? selectedFullexpMonths.value:"",
        currentCompanyname        : addData.currentCompanyname,                                                       currentDesignation       : addData.currentDesignation,
        internshipLocation        : addData.internshipLocation,                                                       internshipWorkingFromYear: selectedInternshipworkingFromYear ? selectedInternshipworkingFromYear.value:"",
        internshipWorkingFromMonth: selectedInternshipworkingFromMonth ?selectedInternshipworkingFromMonth.value:"",  monthlyStipend           : addData.monthlyStifend,
        workedFromYear            : selectedWorkingFromYear ? selectedWorkingFromYear.value:"",                       workedFromMonth          : selectedWorkingFromMonth ? selectedWorkingFromMonth.value:"",
        workedTillYear            : selectedWorkedTillYear ? selectedWorkedTillYear.value:"",                         workedTillMonth          : selectedWorkedTillMonth ? selectedWorkedTillMonth.value:"",
        jobProfile                : addData.jobProfile,                                                     
        monthlyStipendCurrency    : addData.monthlyStipendCurrency,                                                   skillsUsed               : selectedOptionsKeySkills ? selectedOptionsKeySkills.value:"",
        curSal                    : addData.curSal,                                                                   internshipDepartment     : addData.internshipDepartment                 
      }

      fetch(`${baseURL}/createStudentEmployment`, {
        method: 'POST',
        body: JSON.stringify(sendData),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
             togglingModal();
             alert("Item added Successfully");   
             window.location.reload(); 
            console.log('FE- Emp_detail added successfully');
          } else {
            console.error('inside API error ');
          }
        })
        .catch((error) => {
          console.error('API call error-outside:', error);
        });

    }
    
    /**---------------------------Dropdown options------------------------------------------------ */
  const expYearOptions = [                              
  { value: '1 Year', label: '1 Year'     },
  { value: '2 Years', label: '2 Years'   },
  { value: '3 Years', label: '3 Years'   },
  { value: '4 Years', label: '4 Years'   },
  { value: '5 Years', label: '5 Years'   },
  { value: '6 Years', label: '6 Years'   },
  { value: '7 Years', label: '7 Years'   },
  { value: '8 Years', label: '8 Years'   },
  { value: '9 Years', label: '9 Years'   },
  { value: '10 Years', label: '10 Years' },
  ];

  const expMonthsOptions = [
  { value: '1  Month', label: ' 1  Month'  },
  { value: '2  Months', label: '2  Months' },
  { value: '3  Months', label: '3  Months' },
  { value: '4  Months', label: '4  Months' },
  { value: '5  Months', label: '5  Months' },
  { value: '6  Months', label: '6  Months' },
  { value: '7  Months', label: '7  Months' },
  { value: '8  Months', label: '8  Months' },
  { value: '9  Months', label: '9  Months' },
  { value: '10 Months', label: '10 Months' },
  ];

 const workingFromMonthOptions=[
    { value: 'Jan', label: 'Jan'     },
    { value: 'Feb', label: 'Feb'     },
    { value: 'Mar', label: 'Mar'     },
    { value: 'Apr', label: 'Apr'     },
    { value: 'May', label: 'May'     },
    { value: 'Jun', label: 'Jun'     },
    { value: 'Jul', label: 'Jul'     },
    { value: 'Aug', label: 'Aug'     },
    { value: 'Sep', label: 'Sep'     },
    { value: 'Oct', label: 'Oct'     },
    { value: 'Nov', label: 'Nov'     },
    { value: 'Dec', label: 'Dec'     },
  ]
/* -----------------------------------Year options-------------------------------- */
  const joiningDateYearOptions=[
    { value: currentYear - 0, label: currentYear - 0 },
    { value: currentYear - 1, label: currentYear - 1 },
    { value: currentYear - 2, label: currentYear - 2 },
    { value: currentYear - 3, label: currentYear - 3 },
    { value: currentYear - 4, label: currentYear - 4 },
    { value: currentYear - 5, label: currentYear - 5 },
    { value: currentYear - 6, label: currentYear - 6 },
    { value: currentYear - 7, label: currentYear - 7 },
    { value: currentYear - 8, label: currentYear - 8 },
    { value: currentYear - 9, label: currentYear - 9 },
  ]

  useEffect(() => {
                const intervalId = setInterval(() => {  setCurrentDate(new Date()); }, 1000); // Update the date every second
                                                        // console.log('current date:='+currentDate.getFullYear());
                                                        setCurrentYear(currentDate.getFullYear());
                return () => { clearInterval(intervalId); };
              }, []);

/* -----------------------------------Styles-------------------------------- */
const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '20px',
      backgroundColor: '#007BFF',
      color: 'white',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: 'transparent',
        color: 'white',
      },
    }),
  };

  return (
    <div className='profileModal'>
      <h3>Add Employment</h3>
      <MDBRow>
          <label>Is this your current employment ?</label>
          <MDBCol>
              <MDBRadio name='CurEmployment' id='inlineRadio1' value='Yes' label='Yes' inline checked={addData.curEmp === 'Yes'} onChange={() => setAddData({ ...addData, curEmp: 'Yes'})}/>
              <MDBRadio name='CurEmployment' id='inlineRadio2' value='No'  label='No'  inline checked={addData.curEmp === 'No'}  onChange={() => setAddData({ ...addData, curEmp: 'No' })}/>
          </MDBCol>
      </MDBRow><br></br>

      <MDBRow>
          <label>Employment type</label>
          <MDBCol>
              <MDBRadio name='empType' id='inlineRadio3' value='Full-Time'  label='Full-Time'  inline checked={addData.empType === 'Full-Time'}  onChange={() => setAddData({ ...addData, empType: 'Full-Time'  })}  />
              <MDBRadio name='empType' id='inlineRadio4' value='Internship' label='Internship' inline checked={addData.empType === 'Internship'} onChange={() => setAddData({ ...addData, empType: 'Internship' })} />
          </MDBCol>
      </MDBRow><br></br>

      {/* Total experience years */}
      {(addData.empType === 'Full-Time' && addData.curEmp === 'Yes') && (
      <div className=''>
            <label htmlFor="exampleFormControlSelect2" >Total Experience</label><br></br>
            <label htmlFor="exampleFormControlSelect2" >Years</label>
            <label htmlFor="exampleFormControlSelect2" style={{marginLeft:'343px'}}>Months</label>
              <MDBRow >
                <MDBCol>
                  <Select
                     value={selectedFullexpYears}//selectedOptionYear
                     options={expYearOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedFullexpYears(selectedOptions)}
                     isClearable={true}// type to filter
                     placeholder="Select"
                   />
                 </MDBCol>
                 <MDBCol >
                   <Select
                     value={selectedFullexpMonths}
                     options={expMonthsOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedFullexpMonths(selectedOptions)}
                     isClearable={true}
                     placeholder="Select"
                  />
                </MDBCol>
              </MDBRow><br></br>
            </div>
          )}

          {/* current company name */}
            <MDBRow>
            <MDBCol style={{ width:'800px'}}>
                
                 {addData.curEmp === 'Yes'?
                  (<label htmlFor="exampleFormControlSelect1" >Current Company Name</label>):(<label htmlFor="exampleFormControlSelect1" >Previous Company Name</label>)}
                <div className='formProfile'>
                  <input type="text"
                    className="form-control" 
                    id="formGroupExampleInput1" placeholder='Type Your Organization'
                    value={addData.currentCompanyname || ""}
                    onChange={e=>setAddData({...addData, currentCompanyname:e.target.value})} 
                    // style=Responsive
                    style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
  
                    />
                </div><br></br>
              
              </MDBCol>
          </MDBRow>

        {(addData.empType === 'Full-Time')?(<>
          <MDBRow>
            <MDBCol style={{ width:'800px'}}>
                {(addData.curEmp === 'Yes')?
                  (<label htmlFor="exampleFormControlSelect1" >Current Designation</label>):(<label htmlFor="exampleFormControlSelect1" >Previous Designation</label>)}
                <div className='formProfile'>
                  <input type="text"
                    className="form-control" 
                    id="formGroupExampleInput1" placeholder='Type Your Designation'
                    value={addData.currentDesignation || ""}
                    onChange={e=>setAddData({...addData, currentDesignation:e.target.value})} 
                    style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
                    />
                </div><br></br>
              
              </MDBCol>
          </MDBRow>
                
          {/*-----------------------------Joining year - month drop down----------------- */}
          <div className=''>
            <label htmlFor="exampleFormControlSelect2" >Joining Date</label><br></br>
              <MDBRow >
                <MDBCol>
                  <Select
                     value={selectedWorkingFromYear}//selectedOptionYear
                     options={joiningDateYearOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedWorkingFromYear(selectedOptions)}
                     isClearable={true}// type to filter
                     placeholder="Select Year"
                   />
                 </MDBCol>
                 <MDBCol >
                   <Select
                     value={selectedWorkingFromMonth}
                     options={workingFromMonthOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedWorkingFromMonth(selectedOptions)}
                     isClearable={true}
                     placeholder="Select Month"
                  />
                </MDBCol>
              </MDBRow><br></br>
            </div>

          {/*-----------------------------Worked Till - month drop down----------------- */}
          <div className=''>
                      <label htmlFor="exampleFormControlSelect2" >Worked Till</label><br></br>
                        <MDBRow >
                          <MDBCol>
                            <Select
                              value={selectedWorkedTillYear}//selectedOptionYear
                              options={joiningDateYearOptions}
                              onInputChange={(inputValue) => inputValue}
                              onChange={(selectedOptions) => setSelectedWorkedTillYear(selectedOptions)}
                              isClearable={true}// type to filter
                              placeholder="Select Year"
                            />
                          </MDBCol>
                          <MDBCol >
                            <Select
                              value={selectedWorkedTillMonth}
                              options={workingFromMonthOptions}
                              onInputChange={(inputValue) => inputValue}
                              onChange={(selectedOptions) => setselectedWorkedTillMonth(selectedOptions)}
                              isClearable={true}
                              placeholder="Select Month"
                            />
                          </MDBCol>
                        </MDBRow><br></br>
                      </div>

          {/*-----------------------------Current Salary -input----------------- */}
            <MDBRow>
            <MDBCol style={{ width:'800px'}}>
                <label htmlFor="exampleFormControlSelect1" >Current Salary</label>
                <div className='formProfile'>
                  <input type="number"
                    className="form-control" 
                    id="formGroupExampleInput1" placeholder='Type Your Salary'
                    value={addData.curSal || ""}
                    onChange={e=>setAddData({...addData, curSal:e.target.value})} 
                    // responsive
                    style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
                    />
                </div><br></br>
              
              </MDBCol>
          </MDBRow>

          {/*-----------------------------Skills used -multi select input----------------- */}
          <MDBRow>
            <MDBCol style={{ width:'800px'}}>
                <label htmlFor="exampleFormControlSelect1" >Skills Used</label>
                <Select
                  isMulti
                  name="colors"            
                  value={selectedOptionsKeySkills}
                  options={keySkillOptions}
                  onChange={(selectedOptions) => setSelectedOptionsKeySkills(selectedOptions)}
                  className="basic-multi-select demo-input-2 "
                  classNamePrefix="Enquiry For"
                  styles={customStyles}
                  rows={4}
                  size="lg"
                /><br></br>
              
              </MDBCol>
          </MDBRow>

          {/*-----------------------------Job Profile - input----------------- */}
          <MDBRow>
            <MDBCol style={{ width:'800px'}}>
                <label htmlFor="exampleFormControlSelect1" >Job profile</label>
                <MDBTextArea
                    // label='Message'
                    id='textAreaExample'
                    rows={2}
                    value={addData.jobProfile || ""}
                    onChange={(e) => setAddData({...addData, jobProfile:e.target.value})} 
                    // style={{ marginLeft:'-20px'}}
                    />
              
              </MDBCol>
          </MDBRow><br></br>
          </> 
          
          ):
          <>

          {/*-----------------------------Location (internship) - input----------------- */}
          {/* <label>loc- dept - working from - monthly stipend</label><br></br> */}

          <MDBRow>
            <MDBCol style={{ width:'800px'}}>
               <label htmlFor="exampleFormControlSelect1" >Location</label>
                <div className='formProfile'>
                  <input type="text"
                    className="form-control" 
                    id="formGroupExampleInput1" placeholder='Type Your Location'
                    value={addData.internshipLocation || ""}
                    onChange={e=>setAddData({...addData, internshipLocation:e.target.value})} 
                    style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
                    />
                </div><br></br>
              
              </MDBCol>
          </MDBRow>

          {/*-----------------------------Department (internship) - input----------------- */}
          
          <MDBRow>
            <MDBCol style={{ width:'800px'}}>
               <label htmlFor="exampleFormControlSelect1" >Department</label>
                <div className='formProfile'>
                  <input type="text"
                    className="form-control" 
                    id="formGroupExampleInput1" placeholder='Type Your Department'
                    value={addData.internshipDepartment || ""}
                    onChange={e=>setAddData({...addData, internshipDepartment:e.target.value})} 
                    style={{ width: '115%', maxWidth: '820px', marginLeft: '-20px', marginRight: 'auto' }}
                    />
                </div><br></br>
              
              </MDBCol>
          </MDBRow>

          <div className=''>
            <label htmlFor="exampleFormControlSelect2" >Total Experience</label><br></br>
            <label htmlFor="exampleFormControlSelect2" >Years</label>
            <label htmlFor="exampleFormControlSelect2" style={{marginLeft:'343px'}}>Months</label>
              <MDBRow >
                <MDBCol>
                  <Select
                     value={selectedFullexpYears}//selectedOptionYear
                     options={expYearOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedFullexpYears(selectedOptions)}
                     isClearable={true}// type to filter
                     placeholder="Select"
                   />
                 </MDBCol>
                 <MDBCol >
                   <Select
                     value={selectedFullexpMonths}
                     options={expMonthsOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedFullexpMonths(selectedOptions)}
                     isClearable={true}
                     placeholder="Select"
                  />
                </MDBCol>
              </MDBRow><br></br>
            </div>
          {/*-----------------------------WorkingFrom (internship) - dropdown----------------- */}
          <div className=''>
            <label htmlFor="exampleFormControlSelect2" >Working from</label><br></br>
              <MDBRow >
                <MDBCol>
                  <Select
                     value={selectedInternshipworkingFromYear}//selectedOptionYear
                     options={joiningDateYearOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedInternshipworkingFromYear(selectedOptions)}
                     isClearable={true}// type to filter
                     placeholder="Select Year"
                   />
                 </MDBCol>
                 <MDBCol >
                   <Select
                     value={selectedInternshipworkingFromMonth}
                     options={workingFromMonthOptions}
                     onInputChange={(inputValue) => inputValue}
                     onChange={(selectedOptions) => setSelectedInternshipworkingFromMonth(selectedOptions)}
                     isClearable={true}
                     placeholder="Select Month"
                  />
                </MDBCol>
              </MDBRow>
            </div>              
          <br></br>


              {/*-----------------------------Worked Till - month drop down----------------- */}
              <div >
                      <label htmlFor="exampleFormControlSelect2" >Worked Till</label><br></br>
                        <MDBRow >
                          <MDBCol>
                            <Select
                              value={selectedWorkedTillYear}//selectedOptionYear
                              options={joiningDateYearOptions}
                              onInputChange={(inputValue) => inputValue}
                              onChange={(selectedOptions) => setSelectedWorkedTillYear(selectedOptions)}
                              isClearable={true}// type to filter
                              placeholder="Select Year"
                            />
                          </MDBCol>
                          <MDBCol >
                            <Select
                              value={selectedWorkedTillMonth}
                              options={workingFromMonthOptions}
                              onInputChange={(inputValue) => inputValue}
                              onChange={(selectedOptions) => setselectedWorkedTillMonth(selectedOptions)}
                              isClearable={true}
                              placeholder="Select Month"
                            />
                          </MDBCol>
                        </MDBRow><br></br>
              </div>
          </>}

          <br></br>
              <button type="button" className="dropbtnprofilepagesave"    onClick={addEmpDetails}> Save   </button>&nbsp;
              <button type="button" className="dropbtnprofilepagecancel2" onClick={togglingModal}>   Cancel </button>&nbsp;
          <br></br>

    </div>
  )
}

export default Stscroll5EmploymentAddModal
