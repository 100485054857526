import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBCol, MDBContainer, MDBRow, MDBIcon ,MDBBtn} from 'mdb-react-ui-kit';
import Select from 'react-select';
import { baseURL } from '../../../http';
const Swal = require("sweetalert2");

function StudentBasicDetailModal({ togglingModal }) {
  const [pfname, setPfname] = useState('');
  const [mobile, setMobile] = useState('');
  const [expSalary, setExpSalary] = useState('');
  const [location, setLocation] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('user-info'));
  const userEmail = userInfo.email;
  const userName = userInfo.name;
  const [email, setEmail] = useState('');
  const [expMonth, setExpmonth] = useState('');

  const [selectedOptionYear, setSelectedOptionYear]                   = useState(null); // value-1
  const [selectedOptionMonth, setselectedOptionMonth]                 = useState(null);
  const [selectedOptionNoticePeriod, setselectedOptionNoticePeriod]   = useState(null);

  useEffect(() => {
      async function fetchData() {
          try {
              const requestBody = {
                  email: userEmail,
              };
              const response = await fetch(`${baseURL}/getprofile`, {
                  method: 'POST',
                  body: JSON.stringify(requestBody),
                  headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                  },
              });

              if (response.ok) {
                  const data = await response.json();
                  setPfname(data.name || "");
                  setMobile(data.phone || "");
                  setExpSalary(data.expectedSalary || "");
                  setLocation(data.location || "");
                  setNoticePeriod(data.noticePeriod || "");

                  if (data.experience) {
                    const defaultYearOption = expYearOptions.find(option => option.value === data.experience);
                    if (defaultYearOption) {
                        setSelectedOptionYear(defaultYearOption || "");
                        }
                      }

                if (data.experienceMonth) {
                    const defaultMonthOption = expMonthsOptions.find(option => option.value === data.experienceMonth);
                    if (defaultMonthOption) {
                        setselectedOptionMonth(defaultMonthOption || "");
                          }
                        }

                  if (data.noticePeriod) {
                    const defaultNoticePeriodOption = noticePeriodOptions.find(option => option.value === data.noticePeriod);
                    if (defaultNoticePeriodOption) {
                        setselectedOptionNoticePeriod(defaultNoticePeriodOption || "");
                          }
                        }

                      } else {
                          console.error('Error fetching user data:', response.statusText);

                      }
                  } catch (error) {
                      console.error('Error fetching user data:', error);
                  }
          }

      fetchData(); // Call the fetchData function
  }, []);
    /* ---------------------------------------- Update saveProfileData---------------------------*/

  async function saveProfileData() {
    try {
      const formData = {
        email  : userEmail,       // going
        name   : pfname,          // going
        expY   : selectedOptionYear ? selectedOptionYear.value : null, // Set selected year value or null
        expM   : selectedOptionMonth ? selectedOptionMonth.value : null, // Set selected month value or null
        phone  : mobile,            // going
        loc    : location,            // going
        notice : selectedOptionNoticePeriod? selectedOptionNoticePeriod.value :null, // Set selected notice period values as an array or null
        curSal : expSalary       // going        
      };
      
      const response = await fetch(`${baseURL}/updateProfile`, {
                            method  : 'POST',
                            body    : JSON.stringify(formData),
                            headers : {
                                        'Content-Type': 'application/json',
                                        'Accept'      : 'application/json',
                                      },
                                        });

      if (response.ok) {
        console.log('updated success');
        Swal.fire({
          title: "Profile Data Updated",
          icon: "success",
          timer: 2000,
          position: "center",
          timerProgressBar: true, 
          showConfirmButton: false,
        })
        .then(() => {
          togglingModal();
          window.location.reload();
        });

      } else {
        console.error('Error updating profile1:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating profile2:', error);
    }
  }

/**---------------------------experience dropdown------------------------------------------------ */
const expYearOptions = [                              // in options-2
    { value: '1 Year', label: '1 Year'     },
    { value: '2 Years', label: '2 Years'   },
    { value: '3 Years', label: '3 Years'   },
    { value: '4 Years', label: '4 Years'   },
    { value: '5 Years', label: '5 Years'   },
    { value: '6 Years', label: '6 Years'   },
    { value: '7 Years', label: '7 Years'   },
    { value: '8 Years', label: '8 Years'   },
    { value: '9 Years', label: '9 Years'   },
    { value: '10 Years', label: '10 Years' },
  ];
  
  const expMonthsOptions = [
    { value: '1  Month', label: ' 1  Month'  },
    { value: '2  Months', label: '2  Months' },
    { value: '3  Months', label: '3  Months' },
    { value: '4  Months', label: '4  Months' },
    { value: '5  Months', label: '5  Months' },
    { value: '6  Months', label: '6  Months' },
    { value: '7  Months', label: '7  Months' },
    { value: '8  Months', label: '8  Months' },
    { value: '9  Months', label: '9  Months' },
    { value: '10 Months', label: '10 Months' },
  ];
  
   const noticePeriodOptions = [
        { value: "15 Days or less",     label: "15 Days or less" },
        { value: "1 Month",             label: "1 Month"         },
        { value: "2 Months",            label: "2 Months"        },
        { value: "3 Months",            label: "3 Months"        },
        
      ];
  
      
    const handleInputChange = (exp_inputValue) => {         // onInputChange={handleInputChange}-3
      return exp_inputValue;
    };

    const selectExpYear   = (selectedOptionYear) => {        //onChange={selectExpYear}  - 4
       setSelectedOptionYear(selectedOptionYear);
    };
  
    const selectExpMonth = (selectedOptionMonth)=> {
     setselectedOptionMonth(selectedOptionMonth);
    }
   
      const     selectNoticePeriod=(selectedNoticePeriod)=>{
      setselectedOptionNoticePeriod(selectedNoticePeriod);
      }

  return (
    <div style={{ marginLeft: '20px' , marginRight:'20px'}} >
    
    <div >

          <MDBRow>
            <MDBCol >
                <label htmlFor="formGroupExampleInput1">Names</label>
                    <input type="text"
                    className="form-control"
                    id="formGroupExampleInput1" placeholder='Name'
                    value={pfname || ""}
                    onChange={e => setPfname(e.target.value)} />
            </MDBCol>
            <MDBCol >
                    <label htmlFor="formGroupExampleInput1" >Mobile</label>
                        <input type="number"
                        className="form-control" 
                        id="formGroupExampleInput1" placeholder='Phone'
                        value={mobile || ""}
                        onChange={e=>setMobile(e.target.value)} />
                    <br></br>
            </MDBCol>
          </MDBRow>

          <div className=''>
            <label htmlFor="exampleFormControlSelect2" >Total Experience</label><br></br>
            <label htmlFor="exampleFormControlSelect2" >Years</label>
            <label htmlFor="exampleFormControlSelect2" style={{marginLeft:'450px'}}>Months</label>
              <MDBRow >
                <MDBCol>
                  <Select
                    value={selectedOptionYear}//selectedOptionYear
                    options={expYearOptions}
                    onInputChange={handleInputChange}
                    onChange={selectExpYear}
                    isClearable={true}// type to filter
                    placeholder="Select"
                  />
                </MDBCol>
                <MDBCol >
                  <Select
                    value={selectedOptionMonth}
                    options={expMonthsOptions}
                    onInputChange={handleInputChange}
                    onChange={selectExpMonth}
                    isClearable={true}
                    placeholder="Select"
                  />
                </MDBCol>
              </MDBRow><br></br>
            </div>

              
            <MDBRow>
            <MDBCol style={{ width: '300px' }}>
              <label htmlFor="exampleFormControlSelect1">Location</label>
              <div className='formProfile'>
                  <input
                      className="form-control"
                      id="formGroupExampleInput1"
                      value={location || ""} // Bind the input value to the location state
                      onChange={e => setLocation(e.target.value)}
                      style={{ width: '470px', marginLeft: '-20px' }}
                  />
              </div><br></br>
            </MDBCol>

              <MDBCol >
                                
              <label htmlFor="exampleFormControlSelect1" >Notice Period</label>
              <Select
                value={selectedOptionNoticePeriod}
                options={noticePeriodOptions}       //correct
                onInputChange={handleInputChange}   //correct
                onChange={selectNoticePeriod}       
                isClearable={true}
                placeholder="Select"
                style={{ marginLeft: '-20px' }}
                />
                <br></br>
              </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol style={{ width:'300px'}}>
                <label htmlFor="exampleFormControlSelect1" >Current Salary</label>
                <div className='formProfile'>
                  <input type="number"
                    className="form-control" 
                    id="formGroupExampleInput1" placeholder='Current Salary'
                    value={expSalary || ""}
                    onChange={e=>setExpSalary(e.target.value)} 
                    style={{width:'470px', marginLeft:'-20px'}}
                    />
                </div><br></br>
              
              </MDBCol>
              <MDBCol >
                                
              
              </MDBCol>
          </MDBRow>
                             
              
          <button type="button" className="dropbtnprofilepagesave"    onClick={saveProfileData}> Save   </button>&nbsp;
          <button type="button" className="dropbtnprofilepagecancel2" onClick={togglingModal}>   Cancel </button>&nbsp;
              
                <br></br>
                <br></br>
          </div>

        

    </div>
  )
}

export default StudentBasicDetailModal
