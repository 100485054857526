import React, { useEffect, useState } from 'react'
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBTable, MDBTableHead, MDBTableBody , MDBIcon } from 'mdb-react-ui-kit';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { baseURL } from '../../http';


function StudentResumeTemplateDownloader() {
  
  const [resumeTemplateList, setResumeTemplateList]                     = useState([]); // for array null array sq.brackets to be given

  useEffect(() => { fetchResumeTemplateAdminside(); }, []);

  const fetchResumeTemplateAdminside = () => {
    fetch(`${baseURL}/getAdminsResumeTemplate`)
      .then(response => response.json())
      .then(data     => { setResumeTemplateList(data); })
      .catch(error   => { console.error('Error fetching template by Admin:', error); });
  };

  
  const handleResumeItemDownload = (itemId) => {

    const downloadItem      = resumeTemplateList.find(item => item.id === itemId);
    const downloadFileName  = downloadItem.originalFileName +'.'+ downloadItem.originalFileExtension;
    
      console.log("downloadfileName = "+downloadFileName);
      console.log("api="+(`${baseURL}/downloadResume/${downloadFileName}`));
    
    fetch(`${baseURL}/downloadResumeByAdmin/${downloadFileName}`,   
    {
      method: 'GET', 
    })
    .then((response) => {   // Create a blob from the response data
      if (response.ok) {    return response.blob(); } 
      else             {    console.error('Error downloading document:', response.statusText);
                            return null; }
    })
    .then((blob) => {
      if (blob) {
        // Create a URL for the blob and trigger a download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
                  a.href = url;
                  a.download = downloadFileName; // You can set the desired file name here
                  document.body.appendChild(a);
                  a.click();
        window.URL.revokeObjectURL(url);
      }
      else            { console.log('Download Error - Inside blob');}
    })
    .catch((error) => { console.error('Network error while downloading document:', error);  });
  };


  return (
    <div style={{Width:'25%'}} className='contentBackground'>
      <MDBRow>
        <MDBCol><h2> Resume Templates</h2> </MDBCol>
      </MDBRow>

      <MDBTable>

        <MDBTableHead className='bg-gradient'>
          <tr style={{ textAlign: 'center', fontSize:'18px' }}>
            <th scope='col' style={{ width: '80px',  borderBottom:'2px solid blue'}}>S.No</th>
            <th scope='col' style={{ width: '280px', borderBottom:'2px solid blue', minWidth:'400px', maxWidth:'700px' }}>Resume Template Type</th>
            <th scope='col' style={{ width: '280px', borderBottom:'2px solid blue', minWidth:'400px', maxWidth:'700px' }}>File Name <span style={{fontSize:'10px'}}>( of template)</span></th>
            <th scope='col' style={{ width: '180px', borderBottom:'2px solid blue', minWidth:'350px' }}>Download<br></br></th>
          </tr>
        </MDBTableHead>

        <MDBTableBody style={{ textAlign: 'center' }}>
          {resumeTemplateList.map((table, index)=>(
            <tr key={table.id}>
              <td >{index+1 }</td>
              <td >{table.resumeTypeName}</td>
              <td >{table.originalFileName}.{table.originalFileExtension}</td>
              <td >
                    <MDBIcon fas icon="cloud-download-alt" 
                    onClick={() => handleResumeItemDownload(table.id)} 
                    className='hand' style={{marginLeft:'15px', color:'green'}} />
              </td>
            </tr>
        ))}
        </MDBTableBody>

      </MDBTable>
    </div>
  )
}

export default StudentResumeTemplateDownloader
