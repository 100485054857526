import React, { useState, useEffect } from 'react';
import {
    MDBModalFooter,
    MDBTextArea
  } from 'mdb-react-ui-kit';
  import {baseURL} from '../../../http'
  const Swal = require("sweetalert2");

function Stscroll11CareerProfileModal({togglingModal}) {

    const [careerProfile, setCareerProfile]=useState('');
    const userInfo = JSON.parse(localStorage.getItem('user-info'));
    const userEmail = userInfo.email;

  useEffect(() => {
    async function fetchData() {
    try {
        const requestBody = {
            email: userEmail,
        };
        const response = await fetch(`${baseURL}/getprofile`, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();
            setCareerProfile(data.careerProfile || "");

        } else {
            console.error('Error fetching user data:', response.statusText);

        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
  }
  fetchData(); // Call the fetchData function
  }, []);

  

  async function saveProfileData() {
    try {
      const sendData = {
        email           : userEmail,       // going
        careerProfile  : careerProfile
      };
      
      const response = await fetch(`${baseURL}/updateCareerProfile`, {
                            method  : 'POST',
                            body    : JSON.stringify(sendData),
                            headers : {
                                        'Content-Type': 'application/json',
                                        'Accept'      : 'application/json',
                                      },
                                        });

      if (response.ok) {
        console.log(' Career Profile updated success');
        Swal.fire({
          title: "Career Profile Updated",
          icon: "success",
          timer: 2000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
          
        })
        .then(() => {
          togglingModal();
        });
        
      } else {
        console.error('Error updating Career Profile:', response.statusText);
      }
    } catch (error) {
      console.error('Error while enter savi button:', error);
    }
  }
  return (
    <div>
      <div style={{ marginLeft: '20px' }}>
        <br />
        <h5>Career Profile</h5> <br />
        <p style={{ color: 'grey' }}>
          It is the first thing recruiters notice in your profile. Write concisely
          what makes you unique and the right person for the job you are looking for.
        </p>
        <br />
        <div className='formProfile'>
          <MDBTextArea
            label='Message'
            id='textAreaExample'
            rows={4}
            value={careerProfile || ""}
            onChange={(e) => setCareerProfile(e.target.value)}
          />
          <br />
        </div>
        {/* Modal input components */}
      </div>

      <MDBModalFooter>
        
          <button type="button" className="dropbtnprofilepagesave"    onClick={saveProfileData}> Save   </button>&nbsp;
          <button type="button" className="dropbtnprofilepagecancel2" onClick={togglingModal}>   Cancel </button>&nbsp;
            
      </MDBModalFooter>
    </div>
  )
}

export default Stscroll11CareerProfileModal
