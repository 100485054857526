import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import swal from "sweetalert";
import Select from "react-select";

 function Placement() {
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);
 

  const slot = [
    { value: "Mechanical", label: "Mechanical" },
    { value: "IT", label: "IT" },
    { value: "Business", label: "Business" },
  ];
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let errors = {};

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    // Submit form data here
    console.log("Form data:", formValues);
    setFullscreenXlModal(!fullscreenXlModal);
    swal("Joined! Thank you ,Your Slot Booked", "", "success");
  };
  return (
    <div>
      <MDBContainer
        fluid
        className="my-2 placement-fluid fullstackBody "
        // style={{ backgroundColor: "hsl(245deg 73% 90%)" }}
      >
        <MDBRow className="">
          <MDBCol center md="6" className="">
            <MDBCardBody className="m-5">
              <MDBTypography tag="h3" color="light" className="py-2 ">
                Placement Opportunities
              </MDBTypography>
              <MDBTypography tag="h6" color="light" className="pt-2">
                Talent Pool Academy supports you to get placed in top
                companies,whether you're trying to start a new career or change
                your existing one.
                <br />
                
              </MDBTypography>
            </MDBCardBody>
          </MDBCol>

          <MDBCol center md="6" className="text-align-center">
            <br />
            <MDBRow className="mb-3">

              <MDBCol size="3">
                <MDBCard className="m-3 tpa-card-container">
                <MDBCardImage src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/BMW.svg/900px-BMW.svg.png' position='top' alt='...' 
                width="100" height="100"/>
                </MDBCard>
              </MDBCol>

              <MDBCol size="3">
                <MDBCard className="m-3 tpa-card-container">
                <MDBCardImage src='https://www.itvoice.in/wp-content/uploads/2018/12/infosys-logo.jpg' position='top' alt='...' 
                width="100" height="100"/>
                </MDBCard>
              </MDBCol>

              <MDBCol size="3">
                <MDBCard className="m-3 tpa-card-container">
                <MDBCardImage src='https://th.bing.com/th/id/R.7a24668c1e4d4a3dc65b8bbbc627e818?rik=KcmwImynk8rREw&riu=http%3a%2f%2fvignette2.wikia.nocookie.net%2fqm-coorpration-channel%2fimages%2f0%2f0e%2fLNT_Logo.png%2frevision%2flatest%3fcb%3d20140413171321&ehk=98oJDKUl45rjA9oCJCaCf3uGgaUsAk0webCBxllblws%3d&risl=&pid=ImgRaw&r=0' position='top' alt='...' 
                width="100" height="100"/>
                </MDBCard>
              </MDBCol>
                  
              
              {/* <MDBCol size="4">
                <MDBCard>
                  <MDBRipple
                    rippleColor="light"
                    rippleTag="div"
                    className="bg-image hover-overlay"
                  >
                    <MDBCardImage
                      src="https://th.bing.com/th/id/R.7a24668c1e4d4a3dc65b8bbbc627e818?rik=KcmwImynk8rREw&riu=http%3a%2f%2fvignette2.wikia.nocookie.net%2fqm-coorpration-channel%2fimages%2f0%2f0e%2fLNT_Logo.png%2frevision%2flatest%3fcb%3d20140413171321&ehk=98oJDKUl45rjA9oCJCaCf3uGgaUsAk0webCBxllblws%3d&risl=&pid=ImgRaw&r=0"
                      width="150"
                      fluid
                      alt="..."
                    />
                  </MDBRipple>
                </MDBCard>
              </MDBCol> */}
            </MDBRow>
            <MDBRow className="mb-3">
            <MDBCol size="3">
                <MDBCard className="m-3 tpa-card-container">
                <MDBCardImage src='https://indiancompanies.in/wp-content/uploads/2020/05/TCS-Logo-Tata-consultancy-service.png' position='top' alt='...' 
                width="100" height="100"/>
                </MDBCard>
              </MDBCol>
            <MDBCol size="3">
                <MDBCard className="m-3 tpa-card-container">
                <MDBCardImage src='https://th.bing.com/th/id/OIP.aEkyaFl1WDehkxE_cvltTQHaHa?pid=ImgDet&rs=1' position='top' alt='...' 
                width="100" height="100"/>
                </MDBCard>
              </MDBCol>
            <MDBCol size="3">
                <MDBCard className="m-3 tpa-card-container">
                <MDBCardImage src='https://detailshop.no/wp-content/uploads/2020/08/volvo-logo-1.jpg' position='top' alt='...' 
                width="100" height="100"/>
                </MDBCard>
              </MDBCol>
            </MDBRow>
           
            <MDBRow className="mb-3">
            <MDBCol size="3">
                <MDBCard className="m-3 tpa-card-container">
                <MDBCardImage src='https://boomi.com/wp-content/uploads/wipro-logo.jpg' position='top' alt='...' 
                width="100" height="100"/>
                </MDBCard>
              </MDBCol>
              
            <MDBCol size="3">
                <MDBCard className="m-3 tpa-card-container">
                <MDBCardImage src='https://th.bing.com/th/id/OIP.8v8Va-KUMrOOo5--1Uu5CQHaFj?pid=ImgDet&rs=1' position='top' alt='...' 
                width="100" height="100"/>
                </MDBCard>
              </MDBCol>
              
            <MDBCol size="3">
                <MDBCard className="m-3 tpa-card-container">
                <MDBCardImage src='https://download.logo.wine/logo/HCL_Technologies/HCL_Technologies-Logo.wine.png' position='top' alt='...' 
                width="100" height="100"/>
                </MDBCard>
              </MDBCol>
              
             
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBModal
        tabIndex="-1"
        show={fullscreenXlModal}
        setShow={setFullscreenXlModal}
      >
        <MDBModalDialog size="fullscreen-xl-down">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Book a time slot with candidate.</MDBModalTitle>
              <MDBBtn
                type="button"
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                required
                wrapperClass="mb-4"
                label="Phone"
                id="Phone"
                type="number"
                size="lg"
                name="phone"
                value={formValues.phone}
                onChange={handleInputChange}
                className={formErrors.phone ? "is-invalid" : ""}
              />
              <MDBInput
                wrapperClass="mb-4"
                label="Email"
                id="Email"
                type="text"
                size="lg"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
                className={formErrors.email ? "is-invalid" : ""}
              />

              <Select
                isMulti
                name="slot"
                options={slot}
                className="basic-multi-select"
                classNamePrefix="Enquiry For"
                size="lg"
                placeholder="Select Your Domain"
              />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn style={{ color: "white" }} onClick={handleSubmit}>
                Join Now
              </MDBBtn>
              <MDBBtn type="button" color="secondary" onClick={toggleShow}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default Placement;

// #16b7a8
// #2889b7
