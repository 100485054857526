import React from "react";
import TPABusinessRequest from "../TPABusiness/TPABusinessRequest";
function InternshipAdvantages() {
  return (
    <>
      <TPABusinessRequest />
    </>
  );
}

export default InternshipAdvantages;
