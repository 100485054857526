import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  MDBModalFooter,
  MDBCol,
} from 'mdb-react-ui-kit';
import { baseURL } from '../../../http';
const Swal = require("sweetalert2");

function Stscroll4KeySkillsModal({ togglingModal }) {
  const userInfo                                               = JSON.parse(localStorage.getItem('user-info'));
  const userEmail                                              = userInfo.email;
  const [selectedOptionsKeySkills, setSelectedOptionsKeySkills] = useState([]); // values
  const [modalStatus, setModalStatus]                           = useState(false);
  const [inputValue, setInputValue]                             = useState(''); // Track the input value
  const [menuIsOpen, setMenuIsOpen]                             = useState(false); // Control the menu visibility
  const [keySkillOptions, setKeySkillOptions]                   = useState([]);

  function toggleModal() {
    setModalStatus(!modalStatus);
  }

  async function sendKeySkills() {
    try {

      const updates = {
        email     : userEmail,
        // keyskills: selectedOptionsKeySkills,                             //  in DB- [{value:'React", label:"React"},{value:'MDB", label:"MDB"}]
        keyskills : selectedOptionsKeySkills.map(option => option.value),  //in DB -["React", "MDB"]
        }
      const response = await fetch(`${baseURL}/updateStudentKeySkills`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updates),
      });

      if (response.ok) {
        Swal.fire({
          title: "KeySkills Updated",
          icon: "success",
          timer: 900,
          position: "center",
          timerProgressBar: true, 
          showConfirmButton: false,
        })
            setModalStatus(false);
            console.log('Item updated successfully');
      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  useEffect(() => {
    fetchKeySkillsList();
    // fetchStudentSkillsList();
  }, []);

  /*-------------------Getting  */
  const fetchKeySkillsList = () => {
    fetch(`${baseURL}/getAllKeySkills`)
      .then(response => response.json())
      .then(data => {
        const options = data.map(skill => ({
          value: skill.skillName,                      // DB Table col.Name
          label: skill.skillName,              // DB Table col.Name
        }));
        setKeySkillOptions(options || "");
       
      })
      .catch(error => {
        console.error('Error fetching key skills list:', error);
      });
  };

  
  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '20px',
      backgroundColor: '#007BFF',
      color: 'white',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: 'transparent',
        color: 'white',
      },
    }),
  };

  // Handle input change to control menu visibility
  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    setMenuIsOpen(newValue.trim() !== ''); // Show menu only when input is not empty
  };

  return (
    <div>
      <div style={{ marginLeft: '20px', marginRight: '30px' }}>
        <br />
        <h5>Key Skills</h5>
        <br />
        <p style={{ color: 'grey' }}>
          Tell recruiters what you know or what you are known for, e.g., Direct Marketing, Oracle, Java, etc. We will send you job recommendations based on these skills. Each skill is separated by a comma.
        </p>
        <br />
        <MDBCol>
          <Select
            isMulti
            value={selectedOptionsKeySkills}
            options={keySkillOptions ||""}
            onChange={(selectedOptions) => setSelectedOptionsKeySkills(selectedOptions)}
            styles={customStyles}
            onInputChange={handleInputChange} // Track input changes
            inputValue={inputValue} // Controlled input value           - only usestate
            menuIsOpen={menuIsOpen} // Control menu visibility 
            placeholder="Start typing to search..."
          />
        </MDBCol>
      </div>

      <MDBModalFooter>
        <button type="button" className="dropbtnprofilepagesave" onClick={sendKeySkills}>Save</button>&nbsp;
        <button type="button" className="dropbtnprofilepagecancel2" onClick={togglingModal}>Cancel</button>&nbsp;
      </MDBModalFooter>
    </div>
  )
}

export default Stscroll4KeySkillsModal;
