import React from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCard,
} from "mdb-react-ui-kit";

import { Helmet } from "react-helmet";
import ContactBody from "./ContactBodyContactUSpage";

 function ContactUs() {
  return (
  <> 
   <div>
    <Helmet>
      
   <title>
   Contact Us - TPA Support
 </title> 
 
 <meta
  name="description"
  content="Please fill the contact form with your email id and phone number so we can better assist you. Do any of these articles answer your question?"
/>

<meta
  name="keywords"
  content="Contact, talentpoolacademy, info@talentpoolacademy.com, Contact talentpoolacademy, TPA Bangalore"
/>

</Helmet>

</div>  
    <MDBContainer
      fluid
      className="p-2 rounded-7 internship-fluid1 pb-6 mb-0 pt-4 pb-5 ContactBody"
    >
      <MDBRow>
        <MDBCol md="1"></MDBCol>
        <MDBCol center md="3">
          <MDBCard>
            <MDBCardBody>
              <p className="fs-4 text-start mt-4 mx-0">CONTACT INFO</p>

              <div className="d-flex flex-row align-items-center mb-3">
                <div className="flex-shrink-0">
                  <MDBIcon
                    fas
                    icon="envelope"
                    className="pe-2 mx-0"
                    size="lg"
                  />
                </div>
                <div className="flex-grow-1 text-start ms-1 ">
                  info@talentpoolacademy.com
                </div>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <div className="flex-shrink-0">
                  <MDBIcon fas icon="phone" className="pe-2 mx-0" size="lg" />
                </div>
                <div className="flex-grow-1 text-start ms-1">
                  +91 7483868010
                </div>
              </div>
              <hr />
              <div
                className="media-icon d-flex justify-content-between mb-2"
                style={{ width: "95%", color: "black" }}
              >
                <MDBBtn tag="a" color="none" className=" m-1 mx-0 p-0">
                  <MDBIcon
                    fab
                    icon="facebook-f"
                    style={{ color: "black" }}
                    size="lg"
                  />
                </MDBBtn>
                <MDBBtn tag="a" color="none" className="m-1 mx-2">
                  <MDBIcon fab icon="twitter" color="black" size="lg" />
                </MDBBtn>
                <MDBBtn tag="a" color="none" className="m-1 mx-2 ">
                  <MDBIcon fab icon="google" color="black" size="lg" />
                </MDBBtn>
                <MDBBtn tag="a" color="none" className="m-1 mx-2 ">
                  <MDBIcon fab icon="linkedin-in" color="black" size="lg" />
                </MDBBtn>
              </div>
            </MDBCardBody>
          </MDBCard>

        </MDBCol>
        <MDBCol md="2"></MDBCol>
        <MDBCol md="4">
          <MDBCard
            style={{ backgroundColor: " #fff" }}
            className="p-3 rounded-7 mt-3 "
          >
            <h2 className="">Send me a mesage</h2>
            <ContactBody/>

           




          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </> 
  );
};

export default  ContactUs;
