import React, { useState, useEffect } from 'react'
import {  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBModalFooter, MDBTextArea } from 'mdb-react-ui-kit';
// import Stscroll3ResumeHeadlineModal from './Stscroll3ResumeHeadlineModal';
import StudentProfilePage from './1StudentProfilePage';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../../http';
const Swal = require("sweetalert2");


function Stscroll3ResumeHeadline() {


    const [resHeadline, setresHeadline]         = useState('');
    const [resHeadlineEdit, setresHeadlineEdit] = useState('');

    const userInfo                              = JSON.parse(localStorage.getItem('user-info'));
    const userEmail                             = userInfo.email;
    const [modalStatus, setModalStatus]         = useState(false);
    const history                               = useNavigate ();

    function toggleModal() {  setModalStatus(!modalStatus);  }
  
    /*------------------------useEffect for profile details update----------------- */
  useEffect(() => {// test ok
    fetchResHeadline();
  }, []);
   
  const fetchResHeadline = () => {      // test ok
    
          const requestBody = {   email : userEmail,                  };
          fetch(`${baseURL}/getprofile`, {
            method: 'POST',
            body:JSON.stringify(requestBody),
            headers: { 'Content-Type': 'application/json',    'Accept'      : 'application/json',          },
          })
            .then((response) => response.json())
            .then((data) => {
            setresHeadline(data.resumeHeadline || "");
            setresHeadlineEdit(data.resumeHeadline || "");
          })
          .catch((error) => {
            console.error('Error fetching ResHead:', error);
          });
      };
  
  async function saveProfileData() {
    try {
      const sendData = {
        email           : userEmail,       // going
        resumeHeadline  : resHeadlineEdit
      };
      
      const response = await fetch(`${baseURL}/updateResumeHeadline`, {
                            method  : 'POST',
                            body    : JSON.stringify(sendData),
                            headers : {
                                        'Content-Type': 'application/json',
                                        'Accept'      : 'application/json',
                                      },
                                        });

      if (response.ok) {
        console.log('Resume Headline updated success');
        Swal.fire({
          title: "Resume Headline Updated",
          icon: "success",
          timer: 2000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        })
        .then(() => {
          setModalStatus(false);
          setresHeadline(resHeadlineEdit);
        });
        
      } else {
        console.error('Error updating Resume Headline:', response.statusText);
      }
    } catch (error) {
      console.error('Error while enter savig button:', error);
    }
  }
    

  return (
    <div> 
      <h5 style={{color:'black'}}>Resume Headline  <i  onClick={toggleModal} className="fas fa-pencil " style={{paddingBottom:'8px', fontSize:12, cursor:'pointer'}}></i></h5>
            <p>
                {resHeadline || ""} 
            </p>



            {/* (2.1)------------------>---Modal code starts */}
                    <MDBModal tabIndex='-1' show={modalStatus} setShow={setModalStatus}>
                        <MDBModalDialog centered className="modal-xl" style={{width:'1000px'}}>
                        <MDBModalContent >
      
                           <div style={{ marginLeft: '20px' }}>
                              <br />
                              <h5>Resume Headline</h5> <br />
                              <p style={{ color: 'grey' }}>
                                It is the first thing recruiters notice in your profile. Write concisely
                                what makes you unique and the right person for the job you are looking for.
                              </p>
                              <br />
                              <div className='formProfile'>
                                <MDBTextArea
                                  label='Message'
                                  id='textAreaExample'
                                  rows={4}
                                  value={resHeadlineEdit || ""}
                                  onChange={(e) => setresHeadlineEdit(e.target.value)}
                                />
                                <br />
                              </div>
                            </div>

                            <MDBModalFooter>
        
                            <button type="button" className="dropbtnprofilepagesave"    onClick={saveProfileData}> Save   </button>&nbsp;
                            <button type="button" className="dropbtnprofilepagecancel2" onClick={toggleModal}>   Cancel </button>&nbsp;
                              
                        </MDBModalFooter>
                                
                        </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                    {/*(2.1)--------->------------- Modal code ends */}
    </div>
  )
}

export default Stscroll3ResumeHeadline
