import * as React from "react";
import Carousel from "./Carousel ";
import OurMethod from "./OurMethod";
import Placement from "./Placement";
import HoverCards from "./HoverCards";
import CardsUp from "./CardsUp";
import Achievements from "./Achievements";
import StudentReview from "./StudentReview";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBTypography,
} from "mdb-react-ui-kit";
import Demo from "./Demo";
import { Helmet } from "react-helmet";

 function Home() {
  return (
    <>
    <div>
    <Helmet>
      
   <title>
   Number one platform to share your Career | TPA
   {/* Best platform to learn and placement your Career in Bangalore */}
 </title> 
 <meta name="keywords" 
      content="CATIA, CAD Aumation & Coustmization NX Customization , Automation &
                open, Creo Automation, Full stack, web development, software testing, best &
                no.1 training institute in bangalore with online & offline classrooms" 
      />

 <meta name="description" content="TPA creates a Platform to transcend and Where You Excel in IT & 
    Engg industries and make a successful career after completion of our courses
      in bangalore." 
    />

</Helmet>

</div>

      <MDBContainer fluid style={{ marginTop: "0.2%" }}>
        <Carousel />
      </MDBContainer>
      <MDBContainer className="mt-5">
        <CardsUp />
      </MDBContainer>
      <MDBContainer fluid className="p-1 rounded-7 Ourmethod-fluid mb-5 p-2  ">
        <MDBCard className="fullstackBody">
          <MDBCardBody>
            <MDBTypography
              // tag="h2"
              tag="h1"
              style={{fontSize:'24px'}}
              color="light"
              className="text-start lh-base"
            >
              Talent Pool Academy is a transforming platform in bangalore which powers the
              individual skills like Mechanical based CAD & CAE , IT based Full Stack web development on respective industry which creates a pool
              of talents who can attract highly paying job offers with ease.
            </MDBTypography>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>

      <MDBContainer fluid className="placement-fluid ">
        <OurMethod />
      </MDBContainer>
                                            {/*  Mechanical IT Business tabs  in hovercards*/ }
      <MDBContainer fluid className="placement-fluid ">
        <HoverCards />
      </MDBContainer>


      <Placement />


      <MDBContainer fluid className=" placement-fluid  ">
        <StudentReview />
      </MDBContainer>

      <MDBContainer
        fluid
        className="placement-fluid mb-2 DemoBody"
        // style={{ backgroundColor: "hsl(245deg 73% 90%)" }}
      >
        <Demo />
      </MDBContainer>
      <MDBContainer
        fluid
        className="p-1 rounded-7 Ourmethod-fluid mb-2 mt-2 p-5 Achievement-Body"
      >
        <Achievements />
      </MDBContainer>

      
        {/* <ProfilePictureRender/> */}
    </>
  );
};

export default Home;
